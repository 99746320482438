import React from 'react';
import { CounterBox } from '../../../components/atoms/CounterBox';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const GreyText = styled(Typography)(({ theme }) => ({
	color: theme.palette.greyPalette.grey1,
}));

const DescriptionColumn = ({ row }) => {
	if (row.type === 'counter') {
		return <CounterBox {...row} />;
	} else if (row.type === 'greytext') {
		return <GreyText>{row.text}</GreyText>;
	} else if (row.type === 'primary') {
		return <Typography color="primary">{row.text}</Typography>;
	} else if (row.type === 'address') {
		return (
			<div>
				<Typography>{row.street}</Typography>
				<Typography>{row.city}</Typography>
				<Typography>{row.country}</Typography>
			</div>
		);
	} else return <Typography>{row.text}</Typography>;
};
export { DescriptionColumn };
