import React, { useCallback } from 'react';
import { PurchaseTemplate } from '../templates/PurchaseTemplate';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { HeaderText } from '../atoms/HeaderText';
import numeral from 'numeral';
import { CarePackageCard } from '../organisms/CarePackageCard/CarePackageCard';

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginLeft: -8,
}));

const ExtraTextWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'flex-start',
	marginBottom: 12,
}));

const Container = styled('div')(({ theme }) => ({
	maxWidth: 871,
	marginLeft: 0,
}));

const ReadMoreText = styled(Typography)(({ theme }) => ({
	fontFamily: 'roboto',
	fontSize: '10px',
	lineHeight: '12px',
	color: theme.palette.primary.main,
	cursor: 'pointer',
}));

const StyledLink = styled('a')(({ theme }) => ({
	textDecoration: 'none',
}));

export const ChooseProductsPage = ({
	unitPrice,
	setCurrency,
	currency,
	currencyOptions,
	carePackageEnums,
	carePackageOptions,
	onSelect,
	selectedCarePackage,
}) => {
	const getFormattedPrice = useCallback(
		price => {
			if (price === 0) return 'Free';
			else return [currency, numeral(price).format('0,0'), '/ Robot / Year'].join(' ');
		},
		[currency]
	);

	return (
		<PurchaseTemplate
			totalPrice={unitPrice}
			setCurrency={setCurrency}
			currency={currency}
			currencyOptions={currencyOptions}
		>
			<Container>
				<HeaderText variant="h6">
					<FormattedMessage
						id="servicePackageTitle"
						defaultMessage="Choose your service package:"
					/>
				</HeaderText>
				<ExtraTextWrapper>
					<StyledLink
						target="_blank"
						href="https://storage.googleapis.com/webshop-public-orion/f8902d66-7ab6-11ec-9054-c340d190479a/GoBe%20Care%20Service%20Program_EU.pdf"
					>
						<ReadMoreText>
							<FormattedMessage
								id="readMoreGobePackages"
								defaultMessage="Read more about the GoBe Robots service Program"
							/>
						</ReadMoreText>
					</StyledLink>
				</ExtraTextWrapper>
				<Wrapper>
					{carePackageEnums.map(packageEnum => {
						const option = carePackageOptions[packageEnum];
						const selected = selectedCarePackage === packageEnum;

						return (
							<CarePackageCard
								selected={selected}
								onSelect={() => onSelect(packageEnum)}
								price={getFormattedPrice(option.price)}
								title={option.title}
								features={option.features}
							/>
						);
					})}
				</Wrapper>
			</Container>
		</PurchaseTemplate>
	);
};
