import React, { useState, useEffect } from 'react';
import {
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonLabel,
	IonModal,
	IonGrid,
	IonList,
	IonListHeader,
	IonItem,
	IonSearchbar,
	IonFabButton,
} from '@ionic/react';
import { people, add, personCircle, remove } from 'ionicons/icons';
import FormInputListItem from '../FormInputListItem/FormInputListItem';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../Authentication/Authenticated';
import { useForm } from 'react-hook-form';
import { publish } from '../../actions/publish';
import { timeUuid } from '../../actions/timeUuid';

import classes from './UserGroups.module.css';
import classNames from 'classnames';

import Messages from './UserGroups.messages';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';

interface CreateGroupModalProps {
	orgId: string;
}

const CreateGroupModal: React.FC<CreateGroupModalProps> = (props: any) => {
	const { intl, users, selectedOrganization } = props;

	const { usersByOrganizationId } = users;
	const username = useTypedSelector(state => state.accountState.user.username);
	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const userGroups = useTypedSelector(state => state.userGroupsState.userGroupsByOrganizationId);
	const encodedUser = b64EncodeUnicode(username);

	const { handleSubmit, control, errors, setError } = useForm();
	const [searchText, setSearchText] = useState('');
	const [availableUsers, setAvailableUsers] = useState<any[]>([]);
	const [selectedUsers, setSelectedUsers] = useState([]);

	useEffect(() => {
		setAvailableUsers(
			usersByOrganizationId[props.orgId]
				? Object.values(usersByOrganizationId[props.orgId])
				: []
		);
	}, [props.orgId, usersByOrganizationId]);

	const onSelect = (user: any) => {
		setSelectedUsers(prev => prev.concat(user));
		setAvailableUsers(prev => prev.filter((item: any) => item.username !== user.username));
	};
	const onUnSelect = (user: any) => {
		setSelectedUsers(prev => prev.filter((r: any) => r.username !== user.username));
		setAvailableUsers(prev => prev.concat(user));
	};

	const isUserGroupNameUnique = (groupName: string) => {
		if (userGroups[selectedOrganizationId]) {
			let userGroupsByOrg = userGroups[selectedOrganizationId];
			let isUnique = true;
			Object.values(userGroupsByOrg).forEach(element => {
				if (element.name === groupName) isUnique = false;
			});
			return isUnique;
		} else return true;
	};

	const onCreate = handleSubmit(data => {
		data.name = data.name.trim();
		if (!isUserGroupNameUnique(data.name)) {
			setError('nameUnique', {
				type: 'manual',
				message: 'error',
			});
			return;
		}

		const userGroupId = timeUuid();

		publish(
			`microservice/${props.orgId ||
				selectedOrganization.orgId}/${encodedUser}/createUserGroup`,
			{
				data: {
					orgId: props.orgId,
					userGroupId: userGroupId,
					name: data.name,
					add: selectedUsers?.length > 0 ? selectedUsers.map((u: any) => u.username) : [],
				},
				requestId: 'newUserGroup',
			}
		);
		props.setParameter('createdGroup', 'CREATED_NEW_USER_GROUP', {
			userGroupId: userGroupId,
			name: data.name,
		});
		props.onDismiss();
		resetData();
	});
	const onCancel = () => {
		props.onDismiss();
		resetData();
	};
	const resetData = () => {
		selectedUsers.forEach((u: any) => {
			if (
				availableUsers.findIndex(
					(availableUser: any) => availableUser.username === u.username
				) < 0
			) {
				setAvailableUsers((prev: any[]) => prev.concat(u));
			}
		});
		setSelectedUsers([]);
	};

	return (
		<IonModal
			cssClass={classes.addModal}
			isOpen={props.isOpen}
			swipeToClose
			show-backdrop
			onDidDismiss={props.onDismiss}
		>
			<form onSubmit={onCreate} className={classes.addForm}>
				<IonList>
					<IonListHeader className={classes.modalHeader} lines="none">
						<div className={classes.headerContainer}>
							<IonIcon
								className={classes.headerIcon}
								color="primary"
								size="large"
								icon={people}
							/>
							<IonLabel color="primary" className={classes.headerTitle}>
								{intl.formatMessage({
									id: 'UserGroups.createModal.title',
								})}
							</IonLabel>
						</div>
						<IonLabel className={classes.headerHint}>
							{intl.formatMessage({
								id: 'UserGroups.createModal.titleHint',
							})}
						</IonLabel>
					</IonListHeader>
				</IonList>
				<IonGrid className={classes.modalContent}>
					<IonRow className={classes.userGroupNameContainer}>
						<FormInputListItem
							type="text"
							name="name"
							label={intl.formatMessage({
								id: 'UserGroups.name',
							})}
							control={control}
							rules={{ required: true }}
							required
							errorMsg={
								errors.name
									? intl.formatMessage({ id: 'UserGroups.createModal.nameError' })
									: errors.nameUnique
									? intl.formatMessage({
											id: 'UserGroups.createModal.nameNotUnique',
									  })
									: ''
							}
						/>
					</IonRow>
					<IonRow className={classes.usersContent}>
						<IonList>
							<IonGrid className="ion-no-padding">
								<IonRow className={classes.usersRow}>
									<IonCol>
										<IonLabel color="primary" className={classes.colTitle}>
											<FormattedMessage {...Messages.availableUsers} />
										</IonLabel>
										{availableUsers.length > 0 ? (
											<>
												<IonSearchbar
													className={classes.searchBar}
													searchIcon="none"
													showCancelButton="never"
													value={searchText}
													placeholder={props.intl.formatMessage({
														id: 'UserGroups.createModal.searchUsers',
													})}
													onIonChange={e =>
														setSearchText(e.detail.value!)
													}
													autocomplete="on"
												/>
												<IonList lines="none" className={classes.usersList}>
													{availableUsers.map(
														(user: any, index: number) => {
															if (user) {
																return (
																	<IonItem
																		key={index}
																		className={
																			user.username
																				.toLowerCase()
																				.indexOf(
																					searchText
																				) > -1
																				? classNames(
																						classes.userItem,
																						'ion-no-padding'
																				  )
																				: classes.hidden
																		}
																	>
																		<div
																			className={
																				classes.userPicContainer
																			}
																		>
																			<IonIcon
																				className={
																					classes.userPic
																				}
																				size="large"
																				icon={personCircle}
																			/>
																		</div>
																		<IonLabel
																			className={
																				classes.userName
																			}
																		>
																			{user.username}
																		</IonLabel>
																		<IonFabButton
																			size="small"
																			className={
																				classes.selectBtn
																			}
																			onClick={() =>
																				onSelect(user)
																			}
																		>
																			<IonIcon
																				className={
																					classes.fabIcon
																				}
																				icon={add}
																			/>
																		</IonFabButton>
																	</IonItem>
																);
															} else {
																return null;
															}
														}
													)}
												</IonList>
											</>
										) : (
											<IonLabel className={classes.noUsers}>
												<FormattedMessage {...Messages.noAvailable} />
											</IonLabel>
										)}
									</IonCol>
									<IonCol>
										<IonLabel color="primary" className={classes.colTitle}>
											<FormattedMessage {...Messages.selectedUsers} />
										</IonLabel>
										{selectedUsers.length > 0 ? (
											<>
												<IonLabel className={classes.usersSelected}>
													<FormattedMessage
														{...Messages.usersSelected}
														values={{ count: selectedUsers.length }}
													/>
												</IonLabel>
												<IonList
													lines="none"
													className={classes.noSelection}
												>
													{selectedUsers.map((user: any, index: any) => {
														return (
															<IonItem
																key={index}
																className={classNames(
																	classes.userItem,
																	'ion-no-padding'
																)}
															>
																<div
																	className={
																		classes.userPicContainer
																	}
																>
																	<IonIcon
																		className={classes.userPic}
																		size="large"
																		icon={personCircle}
																	/>
																</div>
																<IonLabel
																	className={classes.userName}
																>
																	{user.username}
																</IonLabel>
																<IonFabButton
																	size="small"
																	className={classes.selectBtn}
																	onClick={() => onUnSelect(user)}
																>
																	<IonIcon
																		className={classes.fabIcon}
																		icon={remove}
																	/>
																</IonFabButton>
															</IonItem>
														);
													})}
												</IonList>
											</>
										) : (
											<IonLabel className={classes.noUsers}>
												<FormattedMessage {...Messages.noSelection} />
											</IonLabel>
										)}
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonList>
					</IonRow>
					<IonRow className={classes.btnRow}>
						<IonCol>
							<IonButton
								className={classes.cancelBtn}
								expand="block"
								shape="round"
								size="large"
								fill="outline"
								onClick={onCancel}
							>
								{intl.formatMessage({
									id: 'cancel',
								})}
							</IonButton>
						</IonCol>
						<IonCol className={classes.submitCol}>
							<IonButton
								expand="block"
								shape="round"
								type="submit"
								size="large"
								fill="outline"
								// disabled={availableUsers.length > 0 && selectedUsers.length === 0}
							>
								{intl.formatMessage({
									id: 'create',
								})}
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</form>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => ({
	users: state.usersState,
	client: state.mqttState.client,
	selectedOrganization: state.selectedOrganizationState.organization,
});

export default injectIntl(
	isAuthenticated(
		connect(mapStateToProps, { setParameter })(CreateGroupModal),
		'CreateGroupModal'
	)
);
