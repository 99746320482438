import React from 'react';
import styled from 'styled-components';
import { Typography, Divider } from '@material-ui/core';

const StepElementWrapper = styled('div')(({ noLabel, disabled }) => ({
	marginRight: 16,
	marginLeft: 16,
	height: 42,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	minWidth: noLabel ? 0 : 150,
	cursor: disabled ? 'auto' : 'pointer',
}));

const Wrapper = styled('div')({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'row',
});

const IconWrapper = styled('div')(() => ({
	marginRight: 8,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const IconLabel = styled(Typography)(({ color }) => ({
	color: color,
	textTransform: 'uppercase',
}));

export const Step = ({ label, fontColor, hideIcon, icon, hideDivider, onClick, disabled }) => {
	return (
		<>
			<StepElementWrapper disabled={disabled} onClick={onClick} noLabel={label === ''}>
				<Wrapper>
					{hideIcon ? null : <IconWrapper>{icon}</IconWrapper>}
					<IconLabel color={fontColor ? fontColor : 'auto'}>{label}</IconLabel>
				</Wrapper>
			</StepElementWrapper>
			{hideDivider ? null : <Divider orientation="vertical" />}
		</>
	);
};
