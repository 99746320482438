import React, { FC, useEffect, useState, useCallback } from 'react';
import { IonGrid, IonRow, IonInput, IonList, IonItem, IonLabel, IonCol } from '@ionic/react';
import { useForm } from 'react-hook-form';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './OrganizationForm.messages';
import { publish } from '../../actions/publish';

import classes from './OrganizationForm.module.css';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';

interface OrganizationContactFormProps {
	organization: any;
	isEditable?: boolean;
	onSubmit: (data: any) => void;
	saved: boolean;
	segment: string;
}

const OrganizationContactForm: FC<OrganizationContactFormProps> = (props: any) => {
	const { isEditable, organization, intl, saved, segment } = props;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);
	const { register, getValues } = useForm();

	const parseContactPerson = useCallback((organization: any) => {
		const contactPerson = JSON.parse(JSON.stringify(organization))?.contactPerson;

		return contactPerson || {};
	}, []);

	const [changingContact, setChangingContact] = useState(parseContactPerson(organization));

	useEffect(() => {
		if (saved && segment === 'contact') {
			onEditContactPersonSubmit(getValues());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getValues, saved, segment]);

	useEffect(() => {
		if (!isEditable) {
			setChangingContact(parseContactPerson(organization));
		}
	}, [isEditable, organization, parseContactPerson]);

	const updateLocalContactPerson = (e: CustomEvent, prop: string) => {
		const change = e.detail.value;
		const contactToChange = changingContact;
		contactToChange[prop] = change;

		setChangingContact(contactToChange);
	};

	const onEditContactPersonSubmit = (data: any) => {
		const contactPerson: any = organization.contactPerson || {};

		for (const key in data) {
			contactPerson[key] = data[key];
		}

		publish(`microservice/${organization.orgId}/${encodedUser}/updateOrgBasicInfo`, {
			data: {
				orgId: organization.orgId,
				name: data.name,
				contactPerson,
			},
			requestId: 'updateOrgBasicInfoId',
		});
	};
	const onChangePhoneNumber = (value: any, data: any, event: any, formattedValue: any) => {
		changingContact.phoneNumber = formattedValue;
	};

	return (
		<form onSubmit={onEditContactPersonSubmit} className={classes.editForm}>
			<IonGrid className={classes.formGrid}>
				<IonRow className={classes.detailsHeader}>
					<IonLabel>
						<FormattedMessage {...Messages.contactPerson} />
					</IonLabel>
				</IonRow>
				<IonRow>
					<IonCol className={classes.detailsCol}>
						<IonList>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.firstName} />
								</IonLabel>
								<IonLabel
									className={
										!isEditable ? classes.inputLbContact : classes.hidden
									}
								>
									{changingContact?.firstName}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="firstName"
									placeholder={intl.formatMessage({
										id: 'ContactPerson.firstNameHint',
									})}
									value={changingContact?.firstName}
									ref={register({ required: true })}
									readonly={!isEditable}
									onIonChange={e => updateLocalContactPerson(e, 'firstName')}
								/>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.lastName} />
								</IonLabel>
								<IonLabel
									className={
										!isEditable ? classes.inputLbContact : classes.hidden
									}
								>
									{changingContact?.lastName}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="lastName"
									placeholder={intl.formatMessage({
										id: 'ContactPerson.lastNameHint',
									})}
									value={changingContact?.lastName}
									ref={register({ required: true })}
									readonly={!isEditable}
									onIonChange={e => updateLocalContactPerson(e, 'lastName')}
								/>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.jobTitle} />
								</IonLabel>
								<IonLabel
									className={
										!isEditable ? classes.inputLbContact : classes.hidden
									}
								>
									{changingContact?.jobTitle}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="jobTitle"
									placeholder={intl.formatMessage({
										id: 'ContactPerson.jobTitleHint',
									})}
									value={changingContact?.jobTitle}
									ref={register({ required: true })}
									readonly={!isEditable}
									onIonChange={e => updateLocalContactPerson(e, 'jobTitle')}
								/>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.email} />
								</IonLabel>
								<IonLabel
									className={
										!isEditable ? classes.inputLbContact : classes.hidden
									}
								>
									{changingContact?.email}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="email"
									placeholder={intl.formatMessage({
										id: 'ContactPerson.emailHint',
									})}
									type="email"
									pattern="email"
									value={changingContact?.email}
									ref={register({ required: true })}
									readonly={!isEditable}
									onIonChange={e => updateLocalContactPerson(e, 'email')}
								/>
							</IonItem>
							{/* <IonItem disabled={!isEditable} lines="none">
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.phoneNumber} />
								</IonLabel>
									<IonLabel
									className={!isEditable ? classes.inputLbContact : classes.hidden}
								>
										{changingContact?.phoneNumber}
									</IonLabel>

									<IonInput
									className={isEditable ? classes.editable : classes.hidden}
										name="phoneNumber"
										placeholder={intl.formatMessage({
											id: 'ContactPerson.phoneNumberHint',
										})}
										value={changingContact?.phoneNumber}
										ref={register({ required: true })}
										readonly={!isEditable}
										onIonChange={e => updateLocalContactPerson(e, 'firstName')}
									/>
							</IonItem> */}
						</IonList>
						<div className={classes.phoneItem}>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.phoneNumber} />
							</IonLabel>
							<IonLabel
								className={!isEditable ? classes.inputLbContact : classes.hidden}
							>
								{changingContact?.phoneNumber}
							</IonLabel>

							<div className={isEditable ? '' : classes.hidden}>
								<PhoneInput
									country="dk"
									value={
										changingContact?.phoneNumber
											? changingContact.phoneNumber
											: ''
									}
									onChange={(value, data, event, formattedValue) =>
										onChangePhoneNumber(value, data, event, formattedValue)
									}
								/>
								<IonInput
									className={classes.hidden}
									name="phoneNumber"
									value={changingContact?.phoneNumber}
									ref={register({ required: true })}
									readonly={!isEditable}
								/>
							</div>
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</form>
	);
};
const mapStateToProps = (state: any) => ({
	organizations: state.organizationState.organizations,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(
	isAuthenticated(enhance(OrganizationContactForm), 'OrganizationContactForm')
);
