import _ from 'lodash';
import { useState, useEffect, useMemo } from 'react';
import { useTypedSelector } from '../reducers';

export default function useThrottledSelector<R, T extends (state: any) => R>(
	selector: T,
	equalityFn?: ((left: R | undefined, right: R | undefined) => boolean) | undefined,
	time = 1000
) {
	const selectorData = useTypedSelector(selector, equalityFn);
	const [throttledData, _setThrottleData] = useState<R>(selectorData);

	const updateThrottledData = useMemo(() => {
		return _.throttle(_setThrottleData, time, { leading: false, trailing: true });
	}, [time]);

	useEffect(() => {
		updateThrottledData(selectorData);
	}, [selectorData, updateThrottledData]);

	useEffect(() => () => updateThrottledData.cancel(), [updateThrottledData]);

	return throttledData;
}
