import React from 'react';
import { Caroussel } from '../modules/Caroussel';
import { OrderIncluded } from '../modules/OrderIncluded';

export const RobotInfo = () => {
	return (
		<div>
			<Caroussel />
			<OrderIncluded />
		</div>
	);
};
