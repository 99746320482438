import React from 'react';
import styled from 'styled-components';
import { TableCell as MuiTableCell, TableRow as MuiTableRow } from '@material-ui/core';

export const TableRow = styled(MuiTableRow)(({ theme }) => ({
	verticalAlign: 'baseline',
	'&:last-child td, &:last-child th': { border: 0 },
}));

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
	borderBottom: `1px solid ${theme.palette.greyPalette.grey5}`,
	fontSize: theme.typography.fontSize,
}));
