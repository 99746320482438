import React, { useRef, useEffect, useState } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from '../../../actions/setParam';
import { SET_NAV_CAMERA_HEIGHT } from '../../../actions/types';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from '../../../../../../reducers';
import DynamicMedia, {
	UNCROPPED_VIDEO_FRAME_CONFIG,
} from '../../../components/dynamicMedia/DynamicMedia';
import { closeFullScreen, openFullscreen } from '../../../utils/fullScreen';
import ZoomTransition from '../zoomTransition';
import { PrimaryCameraState } from '../useCallerPeerConnection/peerConnection';

type PropsFromParent = {
	isGreyedOut: boolean;
	isPaused: boolean;
	robotId: string;
	primaryCameraState: PrimaryCameraState;
	onPlaybackBegan: () => void;
	mediaStream: MediaStream;
};

const reduxConnector = connect(
	(state: AppRootState) => ({
		// localStream: state.goBeState.sessionState.localStream,
		drivingMode: state.goBeState.sessionState.drivingMode,
		remoteVoiceVolume: state.goBeState.sessionState.remoteVoiceVolume,
		host: state.mqttState.mqttConfig.host,
		isExpanded: state.menuState.isExpanded,
		fullScreenStatus: state.goBeState.sessionState.fullScreenStatus,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

/** Video component for playing the video feed from robot's primary camera (zoom or wide cam) */
const RemotePrimaryCamVideo = ({
	mediaStream,
	setParameter,
	remoteVoiceVolume,
	isGreyedOut: greyVideo,
	drivingMode,
	isPaused,
	host,
	fullScreenStatus,
	isExpanded,
	primaryCameraState,
	onPlaybackBegan,
}: ComponentProps) => {
	const videoRef = useRef<HTMLVideoElement | null>(null);
	useEffect(() => {
		videoRef.current!.srcObject = mediaStream;
	}, [mediaStream]);

	const [isVideoLoading, setIsVideoLoading] = useState(true);

	const cropConfig = null; // We should get this from the robot later
	useEffect(() => {
		videoRef.current!.volume = parseInt(remoteVoiceVolume) / 100;
	}, [remoteVoiceVolume, mediaStream]);

	/** Callback triggered when the underlying video element can begin playback of the video stream */
	const onCanPlay = () => {
		if (isPaused) videoRef.current?.pause();
		else {
			videoRef.current
				?.play()
				.catch(error => console.warn('Unable to play RemotePrimaryCamVideo', error));
		}
		setIsVideoLoading(false);
		onPlaybackBegan();
	};
	useEffect(() => {
		if (isPaused) videoRef.current?.pause();
		else {
			videoRef.current
				?.play()
				.catch(error => console.warn('Unable to play RemotePrimaryCamVideo', error));
		}
	}, [isPaused]);

	const resizeNavCamera = (value: number) => {
		setParameter('navCameraHeight', SET_NAV_CAMERA_HEIGHT, value);
	};

	const onFullScreenClick = () => {
		if (fullScreenStatus) {
			closeFullScreen();
		} else {
			openFullscreen();
		}
		window.dispatchEvent(new Event('openFullScreen'));
	};

	return (
		<div>
			<DynamicMedia
				mediaComponentProps={{
					onCanPlay,
					autoPlay: true,
					playsInline: true,
					loop: true,
				}}
				resizeNavCamera={resizeNavCamera}
				devEnvironment={host.includes('development')}
				videoRef={videoRef}
				className="dynamic-media"
				drivingMode={drivingMode}
				fullScreenStatus={fullScreenStatus}
				isExpanded={isExpanded}
				onFullScreenClick={onFullScreenClick}
				// devEnvironment={host.includes("development")}
				cropOptions={cropConfig || UNCROPPED_VIDEO_FRAME_CONFIG}
			/>

			{/* the default video component */}
			{/*<video
					ref={videoRef}
					onCanPlay={handleCanPlay}
					autoPlay
					playsInline
					loop
					className={
						remoteVideoStatus
							? greyVideo
								? drivingMode
									? 'remoteVideo drivingModeContainer'
									: 'remoteVideo greyVideo'
								: drivingMode
								? 'remoteVideo drivingModeContainer'
								: 'remoteVideo'
							: 'displayNone'
					}
				/>
			*/}
			{!isVideoLoading ? <ZoomTransition primaryCameraState={primaryCameraState} /> : null}
			{/* loading indicator */}
			{isVideoLoading ? (
				<div className="sessionStartContainer">
					<div className="sessionStartLoading" />
					<div className="sessionStartText">Connecting to GoBe Robot…</div>
				</div>
			) : null}
		</div>
	);
};

export default reduxConnector(RemotePrimaryCamVideo);
