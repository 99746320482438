import { TextField as MUITextField } from '@material-ui/core';
import styled from 'styled-components';

export const TextField = styled(MUITextField)(({ theme }) => ({
	width: '100%',
	'& .MuiInput-underline:before': {
		borderBottomColor: '#EEEEEE',
	},
	'& .MuiInput-underline:hover:before': {
		borderBottomColor: 'black',
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: 'black',
	},
	'& label span': { // for asterisk		
		color: '#56AE4D',
	},
}));
