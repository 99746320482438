/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from 'react';
import { Device } from '../../../../../types/types';
import { SortMethod } from '../../reducers/roasterReducers';
import { IDeviceCurrentState } from '../../utils/statusConverter';

export type FilterRequiredDeviceInfo = Pick<
	Device,
	'deviceId' | 'orgId' | 'name' | 'location' | 'deviceGroupsIds'
> & {
	currentState: IDeviceCurrentState;
};

// See this StackOverflow question/answer https://stackoverflow.com/a/6969486/4906477
// to better understand what's going on here.
/** Escape regex-special characters from a text. This makes it safe to pass the text as the constructor of RegExp */
function escapeRegExp(text: string) {
	return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
function escapeReplacement(text: string) {
	return text.replace(/\$/g, '$$$$');
}

export default function useFilteredRosterDevicesIds(props: {
	sort: { sortMethod: SortMethod; isSortedAscending: boolean };
	searchTerm: string;
	userDevicesById: Record<
		string,
		Pick<Device, 'deviceId' | 'orgId' | 'name' | 'location' | 'deviceGroupsIds'> & {
			currentState: IDeviceCurrentState;
		}
	>;
	devicesCurrentStateById: Record<string, { online: boolean; currentState: IDeviceCurrentState }>;
	deviceGroupsNamesById: Record<string, string>;
	userDevicesIds: string[];
	isRelatedDataLoading: boolean;
}): string[] {
	const {
		sort,
		searchTerm,
		userDevicesById,
		devicesCurrentStateById,
		deviceGroupsNamesById,
		userDevicesIds,
		isRelatedDataLoading,
	} = props;

	const [filteredUserDevicesIds, setFilteredUserDevicesIds] = useState<string[]>([]);

	useEffect(() => {
		console.log('LOADING RELATED DATA', isRelatedDataLoading);
	}, [isRelatedDataLoading]);

	const getDevicesWithSearchTermApplied = useCallback(
		(searchTerm: string, devicesIds: string[]) => {
			if (!searchTerm) {
				return devicesIds;
			}
			const safeSearchTerm = escapeRegExp(
				searchTerm.trim().replace(/\s{2,}/gi, ' ') // remove duplicate spaces
			);
			return devicesIds.filter(deviceId => {
				const device = userDevicesById[deviceId];

				const textToSearchFrom = (
					[device?.name, device?.location, device?.deviceId].join(' ') +
					' ' +
					((device?.deviceGroupsIds ?? []) as string[])
						?.map(deviceGroupId => deviceGroupsNamesById[deviceGroupId])
						.join(' ')
				)
					// remove multi-white spaces
					.replace(/\s{2,}/gi, ' ');

				const searchTexts = [
					new RegExp(safeSearchTerm, 'gi'),
					// search also for each word individually
					...safeSearchTerm.split(' ').map(text => new RegExp(text, 'gi')),
				];
				return searchTexts.some(text => text.test(textToSearchFrom));
			});
		},
		[deviceGroupsNamesById, userDevicesById]
	);

	const getDevicesWithSortApplied = useCallback(
		(sort: { sortMethod: SortMethod; isSortedAscending: boolean }, devicesIds: string[]) => {
			function deviceNameSortComparator(a: string, b: string): number {
				if (userDevicesById[a]?.name?.trim() < userDevicesById[b]?.name?.trim()) return -1;
				else if (userDevicesById[a]?.name?.trim() > userDevicesById[b]?.name?.trim()) return +1;
				else return 0;
			}

			function deviceGroupSortComparator(a: string, b: string): number {
				const a_deviceGroupsNames = ((userDevicesById[a]?.deviceGroupsIds ?? []) as string[])
					.map(id => deviceGroupsNamesById[id])
					.sort()
					.join('');
				const b_deviceGroupsNames = ((userDevicesById[b]?.deviceGroupsIds ?? []) as string[])
					.map(id => deviceGroupsNamesById[id])
					.sort()
					.join('');
				if (a_deviceGroupsNames < b_deviceGroupsNames) return -1;
				else if (a_deviceGroupsNames > b_deviceGroupsNames) return +1;
				else return 0;
			}

			function deviceAvailabilitySortComparator(a: string, b: string): number {
				const onlineAndAvailableDiff =
					+(
						!!devicesCurrentStateById[b]?.online &&
						!!(devicesCurrentStateById[b]?.currentState === 'available')
					) -
					+(
						!!devicesCurrentStateById[a]?.online &&
						!!(userDevicesById[a]?.currentState === 'available')
					);
				const onlineDiff =
					+!!devicesCurrentStateById[b]?.online - +!!devicesCurrentStateById[a]?.online;
				// online-and-available devices first, then online devices next
				return Math.max(-1, Math.min(1, 2 * onlineAndAvailableDiff + onlineDiff));
			}

			function deviceLastUsageSortComparator(a: string, b: string): number {
				return 0;
			}

			function deviceIsFavouriteSortComparator(a: string, b: string): number {
				return 0;
			}

			const getComparatorForSortMethod = () => {
				const directionMultiplier = sort.isSortedAscending ? 1 : -1;
				return (a: string, b: string) => {
					switch (sort.sortMethod) {
						case 'name':
							return directionMultiplier * deviceNameSortComparator(a, b);
						case 'deviceGroup':
							return directionMultiplier * deviceGroupSortComparator(a, b);
						case 'availability':
							return directionMultiplier * deviceAvailabilitySortComparator(a, b);
						case 'lastUsage':
							return directionMultiplier * deviceLastUsageSortComparator(a, b);
						case 'isFavourite':
							return directionMultiplier * deviceIsFavouriteSortComparator(a, b);
					}
				};
			};
			return devicesIds.sort(getComparatorForSortMethod());
		},
		[deviceGroupsNamesById, userDevicesById, devicesCurrentStateById]
	);

	// useEffect(() => {
	// 	if (!isRelatedDataLoading) setHasLoadedRelatedData(true);
	// }, [isRelatedDataLoading]);

	// const [hasLoadedRelatedData, setHasLoadedRelatedData] = useState(false);
	useEffect(() => {
		// if (!hasLoadedRelatedData) return;
		setFilteredUserDevicesIds(
			getDevicesWithSortApplied(
				sort,
				getDevicesWithSearchTermApplied(searchTerm, userDevicesIds)
			)
		);
	}, [
		getDevicesWithSearchTermApplied,
		getDevicesWithSortApplied,
		// hasLoadedRelatedData,
		searchTerm,
		sort,
		userDevicesIds,
	]);

	return filteredUserDevicesIds;
}
