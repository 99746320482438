import { injectIntl, FormattedMessage } from 'react-intl';
import React, { FC } from 'react';
import isAuthenticated from '../Authentication/Authenticated';
import { IonGrid, IonRow, IonList, IonCol, IonText, IonLabel } from '@ionic/react';
import FormInputListItem from '../FormInputListItem/FormInputListItem';

import ReactSelect from '../ReactSelect/ReactSelect';
import classes from './InviteUser.module.css';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import { useTypedSelector } from '../../reducers';

interface InviteUserFormProps {
	control: any;
	errors: any;
	existentUser: any;
}

const InviteUserForm: FC<InviteUserFormProps> = (props: any) => {
	const { intl, control, errors, existentUser, users } = props;
	const { inviteUserToUserGroupIds } = users;

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 * @param {string} labelProperty - The property that contains the name of the entity
	 * @param {string} valueProperty - The property that contains the identifying value of the entity
	 */
	const selectOptions = (entity: string, labelProperty: string, valueProperty: string) => {
		let label;
		let value;

		const orgId = props.selectedOrganization.orgId;
		const options = [];
		let currentEntities: any;
		if (entity === 'userGroup') {
			currentEntities = props.userGroups.userGroupsByOrganizationId[orgId];
		} else if (entity === 'deviceGroup') {
			currentEntities = props.deviceGroups.deviceGroupsByOrganizationId[orgId];
		} else if (entity === 'organizations') {
			currentEntities = Object.assign(props.organization.organizations);
		}

		if (currentEntities === null) currentEntities = [];
		else {
			for (let i in currentEntities) {
				label = currentEntities[i][labelProperty];
				value = currentEntities[i][valueProperty];

				options.push({
					label,
					value,
				});
			}
		}

		return options;
	};

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 */
	const currentEntities = (entity: string, labelProperty: string, valueProperty: string) => {
		let selectedEntities = [];
		if (entity === 'organization') {
			selectedEntities.push(props.selectedOrganization);
		}

		const options = [];
		if (selectedEntities != null && selectedEntities.length > 0) {
			for (let key in selectedEntities) {
				options.push({
					label: selectedEntities[key][labelProperty],
					value: selectedEntities[key][valueProperty],
				});
			}
		}
		if (entity === 'organization') {
			return options.length > 0 && options[0];
		}
		return options;
	};

	const userGroupDefaultValue = () => {
		const options = selectOptions('userGroup', 'name', 'userGroupId');
		if (
			Array.isArray(inviteUserToUserGroupIds) === false ||
			inviteUserToUserGroupIds.length === 0
		) {
			return null;
		}
		const defaultValue = options.filter(option =>
			inviteUserToUserGroupIds.includes(option.value)
		);
		return defaultValue;
	};

	return (
		<IonList className={classNames(classes.formList)}>
			<ReactSelect
				className={classes.select}
				lbClass={classes.selectLb}
				label={intl.formatMessage({
					id: 'InviteModal.organizationHint',
				})}
				defaultValue={currentEntities('organization', 'name', 'orgId')}
				options={selectOptions('organizations', 'name', 'orgId')}
				placeHolder={intl.formatMessage({
					id: 'InviteModal.organizationHint',
				})}
				name="userOrganization"
				control={control}
				isRequired={false}
				fullWidth
			/>
			<IonGrid className="ion-no-padding">
				<IonRow className={classes.nameRow}>
					<IonCol>
						<FormInputListItem
							type="text"
							name="firstName"
							label={intl.formatMessage({
								id: 'InviteModal.firstname',
							})}
							placeholderText={intl.formatMessage({
								id: 'InviteModal.firstnameHint',
							})}
							control={control}
							rules={{ required: true }}
							errorMsg={errors.firstName && 'First name is required'}
						/>
					</IonCol>
					<IonCol>
						<FormInputListItem
							type="text"
							name="lastName"
							label={intl.formatMessage({
								id: 'InviteModal.lastname',
							})}
							placeholderText={intl.formatMessage({
								id: 'InviteModal.lastnameHint',
							})}
							control={control}
							rules={{ required: false }}
						/>
					</IonCol>
				</IonRow>
			</IonGrid>
			<FormInputListItem
				type="text"
				name="email"
				label={intl.formatMessage({
					id: 'InviteModal.email',
				})}
				placeholderText={intl.formatMessage({
					id: 'InviteModal.emailHint',
				})}
				control={control}
				rules={{
					required: true,
					pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
				}}
				errorMsg={
					(errors.email && 'Email is required') ||
					(existentUser && 'The user already exists within the organization')
				}
			/>
			{/* <ReactSelect
				className={classes.select}
				options={selectOptions('deviceGroup', 'name', 'deviceGroupId')}
				placeHolder={
					<>
						<FormattedMessage id="InviteModal.deviceGroupHint" />
						<IonLabel>
							<FormattedMessage id="InviteModal.deviceGroupHint" />
						</IonLabel>
						<IonText color="primary"> *</IonText>
					</>
				}
				name="deviceGroupNames"
				control={control}
				isMulti
				// isRequired={false}
				errorMsg={errors.deviceGroups && 'Device Groups is required'}
				fullWidth
			/> */}
			{/* {spinoutType !== 'beam' && (
				<ReactSelect
					className={classes.select}
					options={selectOptions('userGroup', 'name', 'userGroupId')}
					defaultValue={userGroupDefaultValue()}
					placeHolder={intl.formatMessage({
						id: 'InviteModal.userGroupsHint',
					})}
					name="userGroupNames"
					control={control}
					isMulti
					isRequired={false}
					fullWidth
				/>
			)} */}
		</IonList>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	organization: state.organizationState,
	selectedOrganization: state.selectedOrganizationState.organization,
	userGroups: state.userGroupsState,
	deviceGroups: state.deviceGroupsState,
	users: state.usersState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(InviteUserForm), 'InviteUserForm'));
