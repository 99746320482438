import React from 'react';
import styled from 'styled-components';
import { IoCheckmarkCircleOutline, IoCheckmarkDone } from 'react-icons/io5';
import { FormattedMessage } from 'react-intl';
import { IconText } from '../../../../components/molecules/IconText';

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	alignSelf: 'center',
}));

const CheckMarkIcon = styled(IoCheckmarkCircleOutline)(({ theme }) => ({
	color: theme.palette.primary.main,
}));

const DoubleCheckMarkIcon = styled(IoCheckmarkDone)(({ theme }) => ({
	color: theme.palette.primary.main,
}));

const HeaderWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignSelf: 'flex-start',
	marginBottom: 24,
}));

const ContentWrapper = styled('div')(({ theme }) => ({
	maxWidth: 370,
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	paddingLeft: 8,
}));

const ItemWrapper = styled('div')(({ theme }) => ({
	minWidth: 180,
	marginBottom: 30,
}));

export const OrderIncluded = () => {
	return (
		<Wrapper>
			<HeaderWrapper>
				<IconText
					fontColor="primary"
					fontVariant="h5"
					icon={<DoubleCheckMarkIcon />}
					label={
						<FormattedMessage id="includedHeader" defaultMessage="What's included" />
					}
				/>
			</HeaderWrapper>
			<ContentWrapper>
				<ItemWrapper>
					<IconText
						icon={<CheckMarkIcon />}
						label={<FormattedMessage id="goBeRobot" defaultMessage="GoBe Robot" />}
					/>
				</ItemWrapper>
				<ItemWrapper>
					<IconText
						icon={<CheckMarkIcon />}
						label={
							<FormattedMessage
								id="12monthWarranty"
								defaultMessage="12 months limited warranty"
							/>
						}
					/>
				</ItemWrapper>
				<ItemWrapper>
					<IconText
						icon={<CheckMarkIcon />}
						label={
							<FormattedMessage
								id="dockingStation"
								defaultMessage="Docking station"
							/>
						}
					/>
				</ItemWrapper>
				<ItemWrapper>
					<IconText
						icon={<CheckMarkIcon />}
						label={<FormattedMessage id="flightCase" defaultMessage="Flight Case" />}
					/>
				</ItemWrapper>
			</ContentWrapper>
		</Wrapper>
	);
};
