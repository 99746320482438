import {
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonLabel,
	IonModal,
	IonGrid,
	IonList,
	IonListHeader,
	IonSearchbar,
	IonItem,
	IonFabButton,
} from '@ionic/react';
import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { setParameter } from '../../actions/setParam';
// Styling
import classes from './RobotTransfer.module.css';
import classNames from 'classnames';
// custom components
import ReactSelect from '../ReactSelect/ReactSelect';
import isAuthenticated from '../Authentication/Authenticated';
import Messages from './RobotTransferModal.messages';
// // icons
import { add, remove, repeat } from 'ionicons/icons';
import { publish } from '../../actions/publish';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from '../../reducers';
import { equalityFnc } from '../../utils/conformState';
import { b64EncodeUnicode } from '../../utils/encoding';
import { store } from '../../store/store';
import { subscribeToOrganization } from '../../providers/mqtt';
import { Device } from '../../types/types';

const RobotTransfer: FC = (props: any) => {
	const {
		intl,
		collection,
		renderSelectedRobots,
		unCheckAll,
		setSelectRobots,
		organizationName,
	} = props;
	const user = useTypedSelector(state => state.accountState.user);
	const { handleSubmit, control } = useForm();
	let encodedUser = b64EncodeUnicode(user.username);

	const [firstOrgInList, setFirstOrgInList] = useState('');
	const [selectedOrgId, setSelectedOrgId] = useState('');
	const [robotsOrgId, setRobotsOrgId] = useState('');
	const [robotsWithRobotGroup, setRobotsWithRobotGroup] = useState<any>([]);

	const organizations = useTypedSelector(
		state => state.organizationState.organizations,
		(left, right) => equalityFnc(left, right)
	);
	const accountsOrganization = useTypedSelector(
		state => state.accountState.user.selectedOrganizationId
	);

	useEffect(() => {
		if (collection && collection.length > 0 && collection[0].orgId) {
			setRobotsOrgId(collection[0].orgId);
		}
	}, [collection]);

	useEffect(() => {
		let robotsWithRG : any = [];
		collection.forEach((robot: any) => {
			if (robot.deviceGroupsIds && robot.deviceGroupsIds.length > 0) {
				robotsWithRG = [...robotsWithRG, robot];
			}
		});

		setRobotsWithRobotGroup(robotsWithRG);
	}, [collection]);

	const onTransfer = handleSubmit(data => {
		if (!selectedOrgId && selectedOrgId === '') {
			alert('No id for selected organization');
			return;
		}
		const robotIds = collection.map((robot: any) => robot.deviceId);
		const deviceType = collection[0].deviceType;
		const deviceOrgId = collection[0].orgId;
		const states = store.getState();

		if (collection?.length > 0 && deviceOrgId && accountsOrganization) {
			const groupedDevices: Record<string, Array<string>> = {};
			//make sure to remove robots that is already a part of a device group from the old group.
			collection.forEach((element: any) => {
				const e = states.deviceState.devicesByOrganizationId[deviceOrgId][
					element.deviceId
				] as Device;
				if (e && e.deviceGroupsIds) {
					if (Array.isArray(groupedDevices[e.deviceGroupsIds[0]]))
						groupedDevices[e.deviceGroupsIds[0]].push(e.deviceId);
					else groupedDevices[e.deviceGroupsIds[0]] = [e.deviceId];
				}
			});
			for (const key in groupedDevices) {
				publish(`microservice/${deviceOrgId}/${encodedUser}/updateDeviceGroupDevices`, {
					requestId: 'updateDeviceGroupDevicesId',
					data: {
						deviceGroupId: key,
						remove: groupedDevices[key],
					},
				});
			}
		}

		let wrappedClient = states.mqttState.client;
		subscribeToOrganization(wrappedClient, selectedOrgId);
		publish(`microservice/${accountsOrganization}/${encodedUser}/changeDeviceOrg`, {
			data: {
				deviceId: robotIds,
				deviceType: deviceType,
				orgId: selectedOrgId,
			},
		});
		props.onDismiss();
		unCheckAll();
		setSelectRobots(false);
	});

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 * @param {string} labelProperty - The property that contains the name of the entity
	 * @param {string} valueProperty - The property that contains the identifying value of the entity
	 */
	const selectOptions = (entity: string, labelProperty: string, valueProperty: string) => {
		const options = [];
		let currentEntities: any;
		if (entity === 'organizations') {
			if (organizations) {
				currentEntities = Object.assign(organizations);
			}
		}
		if (currentEntities === null) currentEntities = [];
		else {
			for (let i in currentEntities) {
				options.push({
					label: currentEntities[i][labelProperty],
					value: currentEntities[i][valueProperty],
				});
			}
		}

		return options;
	};

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 */
	const currentEntities = (entity: string, labelProperty: string, valueProperty: string) => {
		let selectedEntities = [];
		if (entity === 'organization') {
			if (organizations) {
				if (props.selectedOrganization.orgType !== 'bor') {
					if (props.selectedOrganization.childOrgs != null) {
						selectedEntities.push(
							props.selectedOrganization.childOrgs[
								Object.keys(props.selectedOrganization.childOrgs)[0]
							]
						);
						if (firstOrgInList === '') {
							setFirstOrgInList(
								props.selectedOrganization.childOrgs[
									Object.keys(props.selectedOrganization.childOrgs)[0]
								]
							);

							setSelectedOrgId(
								props.selectedOrganization.childOrgs[
									Object.keys(props.selectedOrganization.childOrgs)[0]
								].orgId
							);
						}
					}
				} else {
					selectedEntities.push(organizations[Object.keys(organizations)[0]]);
					if (firstOrgInList === '') {
						setFirstOrgInList(organizations[Object.keys(organizations)[0]].orgId);
						setSelectedOrgId(organizations[Object.keys(organizations)[0]].orgId);
					}
				}
			}
		}

		const options = [];
		if (selectedEntities != null && selectedEntities.length > 0) {
			for (let key in selectedEntities) {
				options.push({
					label: selectedEntities[key][labelProperty],
					value: selectedEntities[key][valueProperty],
				});
			}
		}
		return options;
	};

	const onOrgChange = (orgId: string) => {
		setSelectedOrgId(orgId);
	};

	return (
		<IonModal
			cssClass={classes.addModal}
			isOpen={props.isOpen}
			swipeToClose
			show-backdrop
			onDidDismiss={props.onDismiss}
		>
			<form onSubmit={onTransfer} className={classes.addForm}>
				<IonList>
					<IonListHeader className={classes.modalHeader} lines="none">
						<div className={classes.headerContainer}>
							<IonIcon
								className={classes.headerIcon}
								color="primary"
								size="large"
								icon={repeat}
							/>
							<IonLabel color="primary" className={classes.headerTitle}>
								<FormattedMessage {...Messages.title} />
							</IonLabel>
						</div>
						<IonLabel color="gray" className={classes.from}>
							<FormattedMessage {...Messages.from} />
							{organizationName}
						</IonLabel>
					</IonListHeader>
				</IonList>
				<IonLabel>
					<FormattedMessage {...Messages.toOrganization} />{' '}
					<span className={classes.required}>*</span>
				</IonLabel>
				<ReactSelect
					className={classes.select}
					defaultValue={currentEntities('organization', 'name', 'orgId')}
					value={currentEntities('organization', 'name', 'orgId')}
					options={selectOptions('organizations', 'name', 'orgId')}
					placeHolder={intl.formatMessage({
						id: 'InviteModal.organizationHint',
					})}
					name="deviceOrganization"
					control={control}
					isRequired={false}
					position="absolute"
					onChange={(selected: any) => onOrgChange(selected.value)}
				/>
				<IonGrid className={classes.modalContent}>
					<IonRow>{renderSelectedRobots()}</IonRow>
					<IonRow className={classes.btnRow}>
						{robotsWithRobotGroup.length > 0 && collection.length > 1 ? ( 
							<div style={{color: 'red', width: '100%', textAlign: 'center', paddingBottom: '15px'}}>
								<FormattedMessage
									id="items_count"
									defaultMessage="{count,plural,=0{There is no item.}one{Unassign the following robot from its Robot Group: }other{Unassign the following robots from their Robot Groups: }} {robotNames}"
									values={{ count: robotsWithRobotGroup.length, robotNames: robotsWithRobotGroup.map((robot: any) => robot.name).join(', ') }}
								/>
							</div> 
						) : null}
						{(robotsOrgId && selectedOrgId == robotsOrgId && collection.length > 1) ? ( 
							<div style={{color: 'red', width: '100%', textAlign: 'center', paddingBottom: '15px'}}>
								You cannot transfer more than one robot to the their own organization. Please, try one robot at a time.
							</div> 
						) : null}
						<IonCol>
							<IonButton
								className={classes.cancelBtn}
								expand="block"
								shape="round"
								size="large"
								fill="outline"
								onClick={props.onDismiss}
							>
								<FormattedMessage {...Messages.cancel} />
							</IonButton>
						</IonCol>
						<IonCol className={classes.submitCol}>
							<IonButton
								disabled={(robotsOrgId && selectedOrgId == robotsOrgId && collection.length > 1) || (robotsWithRobotGroup.length > 0 && collection.length > 1)}
								expand="block"
								shape="round"
								type="submit"
								size="large"
								fill="outline"
							>
								<FormattedMessage {...Messages.save} />
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</form>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	organization: state.organizationState,
	selectedOrganization: state.selectedOrganizationState.organization,
	devices: state.deviceState,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(RobotTransfer), 'RobotTransfer')
);
