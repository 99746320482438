import React, { ReactNode, useEffect, useState } from 'react';
import { IonContent, IonPage, IonLabel, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';

import classNames from 'classnames';
import classes from './LoginTemplate.module.css';
import { useTypedSelector } from '../../reducers';

interface LoginTemplateProps {
	hintMessage?: any;
	mainContent: ReactNode;
	btnContent: ReactNode;
	onSubmit?: any;
}

const LoginTemplate: React.FC<LoginTemplateProps> = (props: any) => {
	const { hintMessage, mainContent, btnContent, onSubmit } = props;
	const [savedSpinoutType, setSavedSpinoutType] = useState('');
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);

	useEffect(() => {
		if (spinoutType) {
			setSavedSpinoutType(spinoutType);
		}
	}, [spinoutType]);

	return (
		<IonPage>
			<IonContent>
				<IonGrid className={classNames(classes.contentGrid, 'ion-no-padding')}>
					<IonRow className={classes.contentGridRow}>
						<IonCol
							className={classNames(
								classes.leftContainer,
								'ion-hide-sm-down ion-hide-md-down'
							)}
						>
							<div className={classNames(classes.homeCover, classes[savedSpinoutType])} />
						</IonCol>
						<IonCol className={classes.rightContainer}>
							<IonRow className={classes.formContainer}>
								<IonGrid>
									<IonRow
										className={classNames(
											classes.topLogoContainer,
											'ion-justify-content-center ion-padding'
										)}
									>
										<div className={classNames(classes.logo, classes[savedSpinoutType])} />
										{hintMessage ? (
											<div>
												<IonLabel className={classes.lbInstruction}>
													{hintMessage}
												</IonLabel>
											</div>
										) : null}
									</IonRow>
									<form onSubmit={onSubmit}>
										<IonRow className="ion-justify-content-center">
											{mainContent}
										</IonRow>
										<IonRow className={classes.btnRow}>
											<IonCol size="12">{btnContent}</IonCol>
										</IonRow>
									</form>
								</IonGrid>
							</IonRow>
							<IonRow className="ion-justify-content-center">
								<div className={classes.dividerContainer}>
									<div>
										<hr className={classes.divider} />
									</div>
									<div className={classes.logoContainer}>
										<IonIcon
											src="./assets/img/blue-ocean-logo.svg"
											className={classes.blueoceanLogo}
										/>
									</div>
									<div>
										<hr className={classes.divider} />
									</div>
								</div>
							</IonRow>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default LoginTemplate;
