import {
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonLabel,
	IonModal,
	IonGrid,
	IonList,
	IonListHeader,
} from '@ionic/react';
import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { setParameter } from '../../actions/setParam';
// Styling
import classes from './RobotMoveGroup.module.css';
// custom components
import ReactSelect from '../ReactSelect/ReactSelect';
import isAuthenticated from '../Authentication/Authenticated';
import Messages from './RobotMoveGroup.messages';
import { useForm } from 'react-hook-form';
import { useTypedSelector, AppRootState } from '../../reducers';
import { equalityFnc, getStateEntityByOrgId } from '../../utils/conformState';
import { DeviceGroup } from '../../types/types';
import RobotGroupName from '../Robots/RobotGroupName';
import { b64EncodeUnicode } from '../../utils/encoding';
import { publish } from '../../actions/publish';

const RobotMoveGroup: FC = (props: any) => {
	const { robot, onDismiss } = props;
	const { handleSubmit, control } = useForm();

	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);

	const deviceGroups = useTypedSelector(
		(state: AppRootState) =>
			(state.deviceGroupsState.deviceGroupsByOrganizationId as Record<
				string,
				Record<string, DeviceGroup>
			>)[selectedOrganizationId],
		(left, right) => equalityFnc(left, right, false)
	);
	const deviceGroupsIds = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: robot.deviceId,
				orgId: selectedOrganizationId,
				propertyOptions: ['deviceGroupsIds'],
			}),
		(left, right) => equalityFnc(left, right)
	);
	const onTransfer = handleSubmit(data => {
		if (!data.deviceGroupsId || !deviceGroupsIds) {
			console.error('onTransfer has not deviceGroupsIds enough');
			return;
		};

		// add the robot to the new robotGroup
		publish(`microservice/${selectedOrganizationId}/${encodedUser}/updateDeviceGroupDevices`, {
			requestId: 'updateDeviceGroupDevicesId',
			data: {
				deviceGroupId: data.deviceGroupsId.value,
				add: [robot.deviceId],
			},
		});
		// remove the robot from the old robotGroup
		if (deviceGroupsIds && deviceGroupsIds.deviceGroupsIds && deviceGroupsIds.deviceGroupsIds.length > 0)
			publish(
				`microservice/${selectedOrganizationId}/${encodedUser}/updateDeviceGroupDevices`,
				{
					requestId: 'updateDeviceGroupDevicesId',
					data: {
						deviceGroupId: deviceGroupsIds.deviceGroupsIds[0],
						remove: [robot.deviceId],
					},
				}
			);

		onDismiss();
	});

	const selectOptions = () => {
		const options: any = [];
		for (let key in deviceGroups) {
			if (!deviceGroupsIds.deviceGroupsIds || deviceGroupsIds.deviceGroupsIds[0] !== key)
				options.push({
					label: deviceGroups[key].name,
					value: deviceGroups[key].deviceGroupId,
				});
		}
		return options;
	};

	return (
		<IonModal
			cssClass={classes.addModal}
			isOpen={props.isOpen}
			swipeToClose
			show-backdrop
			onDidDismiss={props.onDismiss}
		>
			<form onSubmit={onTransfer} className={classes.addForm}>
				<IonList>
					<IonListHeader className={classes.modalHeader} lines="none">
						<div className={classes.headerContainer}>
							<IonLabel color="primary" className={classes.headerTitle}>
								<FormattedMessage {...Messages.title} />
							</IonLabel>
						</div>
						<IonLabel color="gray" className={classes.from}>
							<FormattedMessage {...Messages.from} />
							<b>{robot.name}</b>
						</IonLabel>
					</IonListHeader>
				</IonList>
				<IonLabel>
					<FormattedMessage {...Messages.fromGroup} />
				</IonLabel>
				<IonLabel className={classes.groupContainer}>
					<RobotGroupName
						robotId={robot.deviceId}
						orgId={robot.orgId}
						customClass={classes.groupName}
					/>
				</IonLabel>
				<br />
				<IonLabel>
					<FormattedMessage {...Messages.toGroup} />{' '}
					<span className={classes.required}>*</span>
				</IonLabel>
				<ReactSelect
					className={classes.select}
					options={selectOptions()}
					defaultValue={selectOptions()[0]}
					placeHolder={<FormattedMessage {...Messages.switch} />}
					name="deviceGroupsId"
					control={control}
					isRequired={false}
					position="absolute"
				/>
				<IonGrid className={classes.modalContent}>
					<IonRow className={classes.btnRow}>
						<IonCol>
							<IonButton
								className={classes.cancelBtn}
								expand="block"
								shape="round"
								size="large"
								fill="outline"
								onClick={props.onDismiss}
							>
								<FormattedMessage {...Messages.cancel} />
							</IonButton>
						</IonCol>
						<IonCol className={classes.submitCol}>
							<IonButton
								expand="block"
								shape="round"
								type="submit"
								size="large"
								fill="outline"
							>
								<FormattedMessage {...Messages.move} />
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</form>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => ({
	organization: state.organizationState,
	selectedOrganization: state.selectedOrganizationState.organization,
	devices: state.deviceState,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(RobotMoveGroup), 'RobotMoveGroup')
);
