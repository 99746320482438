import React, { FC } from 'react';
import classes from './Robots.module.css';

import Battery from '../Battery/Battery';
import BatteryDetails from '../BatteryDetails/BatteryDetails';
import batteryLevelConverter from '../../utils/batteryLevelConverter';
import { useTypedSelector } from '../../reducers';
import { getStateEntityByOrgId, equalityFnc } from '../../utils/conformState';

interface RobotNameProps {
	robotId: string;
	orgId: string;
}

const RobotBattLevel: FC<RobotNameProps> = props => {
	const { robotId, orgId } = props;

	const robot = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: robotId,
				orgId,
				propertyOptions: ['online', 'status', 'battery'],
			}),
		(left, right) => equalityFnc(left, right)
	);
	let status = robot.status ? JSON.parse(robot.status) : { battery: null, currentState: null };

	if (status && status.battery) {
		return (
			<div className={classes.battTextContainer}>
				<div
					className={
						robot.online
							? classes.batteryRoster
							: (classes.batteryRoster, classes.batteryRosterOff)
					}
				>
					<Battery
						batteryPercent={
							robot.battery && robot.battery.level != null
								? robot.battery.level
								: status.battery?.level
						}
						online={robot.online}
						charging={
							robot.battery && robot.battery.charging ? robot.battery.charging : false
						}
					/>
					<div className={classes.batteryDetailsWrapper}>
						<BatteryDetails
							batteryPercent={
								robot.battery && robot.battery.level != null
									? robot.battery.level
									: status.battery?.level
							}
							charging={
								robot.battery && robot.battery.charging
									? robot.battery.charging
									: false
							}
						/>
					</div>
				</div>
				<div className={robot.online ? classes.batteryText : classes.batteryTextGrey}>
					{robot.battery?.level ? robot.battery.level : status.battery?.level}%
				</div>
			</div>
		);
	} else {
		return <div className={classes.chargeContainer} />;
	}
};

export default RobotBattLevel;
