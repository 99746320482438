import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Wrapper = styled('div')({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'row',
});

const IconWrapper = styled('div')(({ theme }) => ({
	marginRight: 8,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

export const IconText = ({ icon, label, fontColor, fontVariant, labelStyle }) => {
	return (
		<Wrapper>
			{icon ? <IconWrapper>{icon}</IconWrapper> : null}
			<Typography
				variant={fontVariant ? fontVariant : 'body1'}
				color={fontColor ? fontColor : 'auto'}
				style={labelStyle}
			>
				{label}
			</Typography>
		</Wrapper>
	);
};
