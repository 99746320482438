import {
	UPDATE_USER,
	UPDATE_FIRSTNAME,
	UPDATE_LANGUAGE,
	UPDATE_LASTNAME,
	UPDATE_PROFILEPICTURELINK,
	UPDATE_SELECTEDORGANIZATIONID,
	UPDATE_USERGROUPS,
	UPDATE_COUNTRY,
	UPDATE_DEVICEGROUPS,
	UPDATE_LOCATION,
	UPDATE_ORGANIZATIONS,
	UPDATE_ROLES,
	UPDATE_DEVICES,
	UPDATE_DEVICESIDS,
	UPDATE_ISACTIVE,
	UPDATE_LASTLOGIN,
	UPDATE_STATUS,
	UPDATE_AGREEMENTS,
	UPDATE_ACCEPTED_AGREEMENTS,
	UPDATE_DEVICE_PROPS,
	UPDATE_ISSSO,
} from '../actions/types';
import { DeviceGroup, Organization, UserGroup } from '../types/types';
import { toCamelCase } from '../utils/snakeCaseToCamelCase';

export interface AcceptedAgreementsPayload {
	userId: string;
	agreementId: string;
	content: string;
	dateOfAcceptance: number;
	ipAddress: string;
	language: string;
	orgId: string;
	spinoutType: string;
	type: string;
	version: number;
}

export interface AgreementsPayload {
	createdAt: string;
	id: string;
	content: string;
	language: string;
	orgId: string;
	spinoutType: string;
	type: string;
	version: number;
}

export interface DeviceBasicInfo {
	orgId: string;
	deviceId: string;
	name: string;
	location: string;
	timeZone: string;
	deviceGroupId: string;
	deviceType: string;
}

export interface Account {
	country: string;
	deviceGroups: Record<string, DeviceGroup>;
	firstName: string;
	language: string;
	lastName: string;
	location: string;
	profilePictureLink: string;
	rememberMe: false;
	selectedOrganizationId: string;
	userGroups: Record<string, UserGroup>;
	username: string;
	organizations: Record<string, Organization>;
	loggedIn: boolean;
	roles: Record<string, any>;
	agreements: Array<AgreementsPayload>;
	agreementsLoaded: boolean;
	acceptedAgreements: Array<AcceptedAgreementsPayload>;
	acceptedAgreementsLoaded: boolean;
	devices: Record<string, DeviceBasicInfo>;
	devicesIds: Array<Array<string>>;
	isSso: boolean;
}

export interface AccountState {
	user: Account;
}

const initialState: AccountState = {
	user: {
		country: '',
		deviceGroups: {},
		firstName: '',
		language: 'english',
		lastName: '',
		location: '',
		profilePictureLink: '',
		rememberMe: false,
		selectedOrganizationId: '',
		userGroups: {},
		username: '',
		organizations: {},
		loggedIn: false,
		roles: {},
		agreements: [],
		agreementsLoaded: false,
		acceptedAgreements: [],
		acceptedAgreementsLoaded: false,
		devices: {},
		devicesIds: [],
		isSso: false,
	},
};

export default (state = initialState, action: Record<string, any>) => {
	switch (action.type) {
		case UPDATE_USER:
			let userData = action.payload.user;
			if (userData && (!userData.agreements || userData.agreements.length === 0)) {
				// don't update the agreements if they come empty
				// this is introduced so on MQTT reconnect the safety agreement is still available
				userData.agreements = state.user.agreements;
			}
			return {
				...state,
				user: userData,
			};
		case UPDATE_FIRSTNAME:
			return {
				...state,
				user: {
					...state.user,
					firstName: action.payload.firstName,
				},
			};
		case UPDATE_LASTNAME:
			return {
				...state,
				user: {
					...state.user,
					lastName: action.payload.lastName,
				},
			};
		case UPDATE_LANGUAGE:
			return {
				...state,
				user: {
					...state.user,
					language: action.payload.language,
				},
			};
		case UPDATE_PROFILEPICTURELINK:
			return {
				...state,
				user: {
					...state.user,
					profilePictureLink: action.payload.profilePictureLink,
				},
			};
		case UPDATE_SELECTEDORGANIZATIONID:
			return {
				...state,
				user: {
					...state.user,
					selectedOrganizationId: action.payload.selectedOrganizationId,
				},
			};
		case UPDATE_USERGROUPS:
			return {
				...state,
				user: {
					...state.user,
					userGroups: action.payload.userGroups,
				},
			};
		case UPDATE_COUNTRY:
			return {
				...state,
				user: {
					...state.user,
					country: action.payload.country,
				},
			};
		case UPDATE_DEVICES:
			return {
				...state,
				user: {
					...state.user,
					devices: action.payload.devices,
				},
			};
		case UPDATE_DEVICESIDS:
			return {
				...state,
				user: {
					...state.user,
					devicesIds: action.payload.devicesIds,
				},
			};
		case UPDATE_DEVICEGROUPS:
			return {
				...state,
				user: {
					...state.user,
					deviceGroups: action.payload.deviceGroups,
				},
			};
		case UPDATE_LOCATION:
			return {
				...state,
				user: {
					...state.user,
					location: action.payload.location,
				},
			};
		case UPDATE_ISACTIVE:
			return {
				...state,
				user: {
					...state.user,
					isActive: action.payload.isActive,
				},
			};
		case UPDATE_LASTLOGIN:
			return {
				...state,
				user: {
					...state.user,
					lastLogin: action.payload.lastLogin,
				},
			};
		case UPDATE_STATUS:
			return {
				...state,
				user: {
					...state.user,
					status: action.payload.status,
				},
			};
		case UPDATE_ORGANIZATIONS:
			return {
				...state,
				user: {
					...state.user,
					organizations: action.payload.organizations,
				},
			};
		case UPDATE_ROLES:
			return {
				...state,
				user: {
					...state.user,
					roles: action.payload.roles,
				},
			};
		case UPDATE_AGREEMENTS:
			const agreementsArray: AgreementsPayload[] = action.payload;

			if (!action.payload || action.payload.length < 0) return state;

			for (let i = 0; i < agreementsArray.length; i++) {
				agreementsArray[i].content = agreementsArray[i]?.content.replace(
					/#semicolon#/g,
					';'
				);
			}
			return {
				...state,
				user: {
					...state.user,
					agreements: agreementsArray,
					agreementsLoaded: true,
				},
			};

		case UPDATE_ACCEPTED_AGREEMENTS:
			const acceptedAgreements: AcceptedAgreementsPayload[] = action.payload;
			if (!acceptedAgreements || acceptedAgreements.length < 0) return state;

			for (let i = 0; i < acceptedAgreements.length; i++) {
				acceptedAgreements[i].content = acceptedAgreements[i].content?.replace(
					/#semicolon#/g,
					';'
				);
			}

			return {
				...state,
				user: {
					...state.user,
					acceptedAgreements: acceptedAgreements,
					acceptedAgreementsLoaded: true,
				},
			};

		case UPDATE_DEVICE_PROPS:
			const props = action.payload.props;
			const deviceId = action.payload.deviceId;
			const deviceToUpdate = state.user.devices[toCamelCase(deviceId)];

			if (!deviceToUpdate) return state;

			for (const key in props) {
				deviceToUpdate[key as keyof DeviceBasicInfo] = props[key];
			}

			return {
				...state,
				user: {
					...state.user,
					devices: {
						...state.user.devices,
						[toCamelCase(deviceId)]: deviceToUpdate,
					},
				},
			};

		case UPDATE_ISSSO:
			return {
				...state,
				user: {
					...state.user,
					isSso: action.payload.isSso,
				},
			};

		default:
			if (action.type === 'SIGN_OUT_USER') {
				return {
					user: {
						country: '',
						deviceGroups: {},
						firstName: '',
						language: 'english',
						lastName: '',
						location: '',
						profilePictureLink: '',
						rememberMe: false,
						selectedOrganizationId: '',
						userGroups: {},
						username: '',
						organizations: {},
						roles: {},
						agreements: [],
						agreementsLoaded: false,
						acceptedAgreements: [],
						acceptedAgreementsLoaded: false,
					},
				};
			}
			return state;
	}
};
