import React from 'react';
import styled from 'styled-components';
import { IoAddSharp } from 'react-icons/io5';
import { IoRemoveSharp } from 'react-icons/io5';
import { FormHelperText } from '@material-ui/core';

const StyledCounterBox = styled('div')(() => ({
	width: 77,
	height: '40px',
	border: '1px solid #d2d2d2',
	borderRadius: '4px',
	marginBottom: '-10px',
	display: 'flex',
	justifyContent: 'space-evenly',
	alignItems: 'center',
	'&:hover, &.Mui-focusVisible': {
		cursor: 'pointer',
	},
}));

const AddIcon = styled(IoAddSharp)(() => ({
	width: '10px',
	height: '15px',
}));
const RemoveIcon = styled(IoRemoveSharp)(() => ({
	width: '10px',
	height: '15px',
}));
const InputText = styled('input')(() => ({
	border: 'none',
	width: '25px',
	textAlign: 'center',
	'&:focus': {
		border: 'none',
		outline: 'none',
	},
	'&::-webkit-inner-spin-button': {
		appearance: 'none',
	},
	'&::-webkit-outer-spin-button': {
		appearance: 'none',
	},
}));
const StyledHelperText = styled(FormHelperText)(() => ({
	color: 'red',
	marginTop: 15,
	marginBottom: -12,
}));
const CounterBox = ({ units, setUnits }) => {
	return (
		<div>
			<div>
				<StyledCounterBox>
					<RemoveIcon onClick={() => (units > 1 ? setUnits(units - 1) : null)} />
					<InputText
						type="number"
						value={units === 0 ? '' : units}
						onChange={e => setUnits(Number(e.target.value))}
					/>
					<AddIcon onClick={() => setUnits(units + 1)} />
				</StyledCounterBox>
			</div>
			{units >= 1 ? null : <StyledHelperText>Enter number</StyledHelperText>}
		</div>
	);
};
export { CounterBox };
