import React, { FC } from 'react';
import { IonList, IonListHeader, IonIcon, IonLabel, IonItem } from '@ionic/react';
import { FormattedMessage } from 'react-intl';

import { timeOutline } from 'ionicons/icons';

import classes from './RobotEdit.module.css';
import Messages from './RobotEdit.messages';

interface RobotActivityProps {
	robotId: string;
	orgId: string;
}

const RobotActivity: FC<RobotActivityProps> = props => {
	// const { robotId, orgId } = props;

	return (
		<div className={classes.recentContainer}>
			<IonList lines="none">
				<IonListHeader>
					<IonIcon color="primary" size="small" icon={timeOutline} />
					<IonLabel color="primary" className={classes.recentLb}>
						<FormattedMessage {...Messages.recentActivity} />
					</IonLabel>
				</IonListHeader>
				<IonItem />
			</IonList>
		</div>
	);
};

export default RobotActivity;
