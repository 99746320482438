import React from 'react';
import './index.scss';
import { PropsFromParent } from './model';

const Indicator: React.FC<PropsFromParent> = ({ value }) => {
	return (
		<div className="indicatorContainer">
			<div className="indicatorsWrapper">
				<div
					className={
						parseInt(value) > 80 ? 'greyIndicator redIndicator' : 'greyIndicator '
					}
				/>
				<div
					className={
						parseInt(value) > 60 ? 'greyIndicator yellowIndicator' : 'greyIndicator '
					}
				/>
				<div
					className={
						parseInt(value) > 40 ? 'greyIndicator  greenIndicator' : 'greyIndicator'
					}
				/>
				<div
					className={
						parseInt(value) > 20 ? 'greyIndicator  greenIndicator' : 'greyIndicator'
					}
				/>
				<div
					className={
						parseInt(value) > 0 ? 'greyIndicator  greenIndicator' : 'greyIndicator'
					}
				/>
			</div>
		</div>
	);
};

export default Indicator;
