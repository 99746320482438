import { MqttRouter } from 'mqtt-sub-router';
import { store } from '../store/store';
import { subscribeToOrganization, subscribeToDevice } from '../providers/mqtt';
import { publish } from '../actions/publish';
import { b64EncodeUnicode } from '../utils/encoding';

const AccountRouter = new MqttRouter()
	.listen(
		'getOwnAccountInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			let user = store.getState().accountState.user;
			let wrappedClient = store.getState().mqttState.client;
			const spinoutType = store.getState().versionState.spinoutType;

			store.dispatch({
				type: 'AWAIT_DATA_FETCH',
				payload: {},
			});

			store.dispatch({
				type: 'SET_ADMIN_ROLE',
				payload: {},
			});

			for (const key in data) {
				if (key === 'language') {
					if (data[key] && data[key].length > 2) {
						data[key] = data[key].toLowerCase();
					} else {
						data[key] = 'english';
					}
				} else if (key === 'selectedOrganizationId') {
					if (!data[key]) {
						try {
							data[key] = Object.keys(data.orgs)[0];
						} catch (error) {
							console.log(error);
						}
					}
					if (data[key] === null || data[key] === undefined || data[key].length === 0)
						return;
				} else if (key === 'orgs') {
					store.dispatch({
						type: `UPDATE_ORGANIZATIONS`,
						payload: { organizations: data[key] },
					});
					continue;
				} else if (key === 'roles') {
					for (const id in data[key]) {
						subscribeToOrganization(wrappedClient, id);
						publish(
							`microservice/${id}/${b64EncodeUnicode(user.username)}/getOrgInfo`,
							{
								requestId: `getOrgInfoId-${id}`,
								data: { spinoutType },
							}
						);
					}
				}

				store.dispatch({
					type: `UPDATE_${key.toUpperCase()}`,
					payload: { [key]: data[key] },
				});
			}

			if (data.devices) {
				Object.keys(data.devices).forEach(deviceId => {
					subscribeToDevice(wrappedClient, deviceId);
				});
			}
			if (!data.roles || !data.orgs) {
				store.dispatch({
					type: `CHANGE_LOADER`,
					payload: { showLoader: false, location: 'AccountRouter' },
				});
			}
		}
	)
	.listen(
		'updateOwnInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			for (const key in data) {
				if (key === 'language') {
					if (data[key]) data[key] = data[key].toLowerCase();
					else data[key] = 'english';
				}
				if (
					key === 'country' ||
					key === 'firstName' ||
					key === 'language' ||
					key === 'lastName' ||
					key === 'location' ||
					key === 'profilePictureLink' ||
					key === 'selectedOrganizationId'
				)
					store.dispatch({
						type: `UPDATE_${key.toUpperCase()}`,
						payload: { [key]: data[key] },
					});
			}
		}
	)
	.listen(
		'updatePassword/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (status === 'success') {
				console.log('successfully changed the password');
			} else if (status === 'error') {
				console.log('error on changing password');
			}
		}
	)
	.listen(
		'passwordReset/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `PASSWORD_RESET_RESPONSE`,
				payload: { requestId, status },
			});
		}
	)
	.listen(
		'updateUserInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (data.username !== store.getState().accountState.user.username) return;

			for (const key in data) {
				if (
					key === 'country' ||
					key === 'firstName' ||
					key === 'language' ||
					key === 'lastName' ||
					key === 'location' ||
					key === 'profilePictureLink' ||
					key === 'selectedOrganizationId'
				)
					store.dispatch({
						type: `UPDATE_${key.toUpperCase()}`,
						payload: { [key]: data[key] },
					});
			}
		}
	)
	.listen(
		'getOrgUsers/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `FETCH_USERS_SUCCESS`,
				payload: { users: data },
			});
		}
	)
	.listen(
		'getOrgDevices/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			let wrappedClient = store.getState().mqttState.client;
			const accountDevices = store.getState().accountState.user.devices;
			store.dispatch({
				type: `FETCH_DEVICES_SUCCESS`,
				payload: { devices: data, accountDevices },
			});

			data.forEach(device => {
				subscribeToDevice(wrappedClient, device.deviceId);
			});
		}
	)
	.listen(
		'getDeviceGroups/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `FETCH_DEVICE_GROUPS_SUCCESS`,
				payload: data,
			});
			store.dispatch({
				type: `CHANGE_LOADER`,
				payload: { showLoader: false, location: 'AccountRouter' },
			});
		}
	)
	.listen(
		'getOrgInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data || !data.orgId) {
				store.dispatch({
					type: 'ORGANIZATION_INFORMATION_DENIED',
					payload: {},
				});
				store.dispatch({
					type: `CHANGE_LOADER`,
					payload: { showLoader: false, location: 'AccountRouter' },
				});
				return;
			}
			const spinoutType = store.getState().versionState.spinoutType;
			const encodedUser = b64EncodeUnicode(store.getState().accountState.user.username);
			const orgId = data.orgId;

			for (const key in data) {
				if (key === 'childOrgs') {
					if (data[key] && Object.keys(data[key]).length > 0) {
						for (const id in data[key]) {
							// make sure to only display the correct spinout type organizations
							if (
								data[key][id].spinoutType &&
								data[key][id].spinoutType !== spinoutType
							)
								delete data[key][id];
						}
						for (const id in data[key]) {
							if (!data[key][id].orgId) data[key][id].orgId = id;
						}
						store.dispatch({
							type: `FETCH_ORGANIZATION_CHILDREN`,
							payload: { children: Object.values(data[key]) },
						});

						store.dispatch({
							type: 'ORGANIZATION_CHILDREN_FETCHED',
							payload: {},
						});

						store.dispatch({
							type: 'ORGANIZATIONS_FETCHED',
							payload: {},
						});
					}
				}

				store.dispatch({
					type: `UPDATE_SEL_ORG_${key.toUpperCase()}`,
					payload: { [key]: data[key] },
				});
			}

			store.dispatch({
				type: 'ORGANIZATION_INFORMATION_FETCHED',
				payload: {},
			});

			store.dispatch({
				type: `FETCH_ORGANIZATIONS`,
				payload: { organizations: [data] },
			});

			publish(`microservice/${orgId}/${encodedUser}/getOrgUsers`, {
				requestId: 'someId',
			});

			publish(`microservice/${orgId}/${encodedUser}/getOrgDevices`, {
				requestId: 'someId',
				data: {
					orgId: orgId,
					encodedEmail: encodedUser,
				},
			});

			publish(`microservice/${orgId}/${encodedUser}/getUserGroups`, {
				requestId: 'someId',
			});

			publish(`microservice/${orgId}/${encodedUser}/getDeviceGroups`, {
				requestId: 'someId',
				data: {
					orgId: orgId,
					encodedEmail: encodedUser,
				},
			});

			publish(`microservice/${orgId}/${encodedUser}/getOrgSSOInfo`, {
				requestId: `getOrgSSOInfoId-${orgId}`,
				data: {},
			});
		}
	)
	.listen(
		'getUserGroups/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `FETCH_USER_GROUPS_SUCCESS`,
				payload: data,
			});
		}
	)
	.listen(
		'createUserGroup/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `SET_USER_GROUP`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on create user group');
			}
		}
	)
	.listen(
		'updateUserGroupInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `UPDATE_USER_GROUP`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on update user group info');
			}
		}
	)
	.listen(
		'updateUserGroupUsers/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `UPDATE_USER_GROUP_USERS`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on update user group users');
			}
		}
	)
	.listen(
		'updateUserGroupDeviceGroups/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `UPDATE_USER_GROUP_DEVICE_GROUPS`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on update user group device groups');
			}
		}
	)
	.listen(
		'deleteUserGroup/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `DELETE_USER_GROUP`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on delete user group');
			}
		}
	)
	.listen(
		'createDeviceGroup/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `INSERT_NEW_DEVICE_GROUP`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on create device group');
			}
		}
	)
	.listen(
		'updateDeviceGroupInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `UPDATE_DEVICE_GROUP`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on edit device group');
			}
		}
	)
	.listen(
		'updateDeviceGroupDevices/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `UPDATE_DEVICE_GROUP_DEVICES`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on edit device group');
			}
		}
	)
	.listen(
		'deleteDeviceGroup/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			if (status === 'success') {
				// store.dispatch({
				// 	type: `DELETE_DEVICE_GROUP`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on edit device group');
			}
		}
	)
	.listen(
		'updateOrgBasicInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;

			if (status === 'success') {
				// store.dispatch({
				// 	type: `UPDATE_DEVICE_GROUP_DEVICES`,
				// 	payload: data,
				// });
			} else if (status === 'error') {
				console.log('error on edit device group');
			}
		}
	)
	// .listen('savedRules', ({ message, params, topic, originalTopic, mqttClient }) => {
	// 	const parsedData = JSON.parse(message);
	// 	let role = store.getState().rolesState.activeRole;

	// 	store.dispatch({
	// 		type: `UPDATE_SEL_ORG_SAVED_USER_ROLES`,
	// 		payload: parsedData,
	// 		organizationType: role.organizationType,
	// 	});

	// 	store.dispatch({
	// 		type: 'ROLES_FETCHED',
	// 		payload: {},
	// 	});
	// })
	// .listen('savedRuleByName', ({ message, params, topic, originalTopic, mqttClient }) => {
	// 	const parsedData = JSON.parse(message);

	// 	store.dispatch({
	// 		type: `INITIALIZE_USER_ROLES`,
	// 		payload: parsedData,
	// 	});

	// 	store.dispatch({
	// 		type: 'ROLE_NAMES_FETCHED',
	// 		payload: {},
	// 	});
	// })
	.listen(
		'getAgreementsContent/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `UPDATE_AGREEMENTS`,
				payload: data,
			});
		}
	)
	.listen(
		'getAcceptedAgreements/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `UPDATE_ACCEPTED_AGREEMENTS`,
				payload: data,
			});
		}
	)
	.listen(
		'getOrgAcceptedAgreements/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;

			store.dispatch({
				type: `UPDATE_ORG_ACCEPTED_AGREEMENTS`,
				payload: data,
			});
		}
	)
	.listen(
		'getOrgSSOInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `FETCH_ORGANIZATION_SSO_CONFIGURATION`,
				payload: data,
			});
		}
	)
	.listen(
		'updateOrgSSOSandboxInfo/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			console.log('updateOrgSSOSandboxInfo data: ', data);
			window.dispatchEvent(
				new CustomEvent('updateOrgSSOSandboxInfo', {
					detail: {
						status: status,
						message:
							status == 'success'
								? 'SSO sandbox mode configuration has been saved successfully.'
								: 'There is some error in processing your request. Please try again!',
					},
				})
			);
			if (!data) return;
			store.dispatch({
				type: `UPDATE_ORGANIZATION_SSO_SANDBOX_CONFIGURATION`,
				payload: data,
			});
		}
	)
	.listen(
		'addSSODomainToSandbox/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			console.log('addSSODomainToSandbox data: ', data);
			window.dispatchEvent(
				new CustomEvent('addSSODomainToSandbox', {
					detail: {
						status: status,
						message:
							status == 'success'
								? 'SSO sandbox mode configuration has been saved successfully.'
								: 'There is some error in processing your request. Please try again!',
					},
				})
			);
			if (!data) return;
			store.dispatch({
				type: `UPDATE_ORGANIZATION_SSO_SANDBOX_CONFIGURATION`,
				payload: data,
			});
		}
	)
	.listen(
		'removeSSODomainToSandbox/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			console.log('removeSSODomainToSandbox data: ', data);
			window.dispatchEvent(
				new CustomEvent('removeSSODomainToSandbox', {
					detail: {
						status: status,
						message:
							status == 'success'
								? 'SSO sandbox mode configuration has been saved successfully.'
								: 'There is some error in processing your request. Please try again!',
					},
				})
			);
			if (!data) return;
			store.dispatch({
				type: `UPDATE_ORGANIZATION_SSO_SANDBOX_CONFIGURATION`,
				payload: data,
			});
		}
	)
	.listen(
		'applySSOSandboxToLive/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			//if (!data) return;

			if (data) {
				store.dispatch({
					type: `UPDATE_ORGANIZATION_SSO_LIVE_CONFIGURATION`,
					payload: data,
				});
			}
			window.dispatchEvent(
				new CustomEvent('applySSOSandboxToLive', {
					detail: {
						status: status,
						message:
							status == 'success'
								? 'SSO sandbox mode configuration has been applied to live mode successfully.'
								: 'There is some error in processing your request. Please try again!',
					},
				})
			);
		}
	)
	.listen(
		'verifyDomain/response',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			window.dispatchEvent(
				new CustomEvent('verifyDomain', {
					detail: {
						status: data?.result || status,
						domain: data.domain,
					},
				})
			);
			if (
				(data.result && data.result === 'success') ||
				(!data.result && status === 'success')
			) {
				store.dispatch({
					type: `UPDATE_ORGANIZATION_SSO_SANDBOX_CONFIGURATION`,
					payload: data.data,
				});
			}
		}
	);
export default AccountRouter;
