import React from 'react';
import styled from 'styled-components';
import { Content } from './Content';
import { Footer } from './Footer';

export const Card = styled('div')(({ theme, selected }) => ({
	width: 277,
	height: 382,
	backgroundColor: selected ? theme.palette.primary.main : theme.palette.greyPalette.grey6,
	border: '1px solid ' + theme.palette.greyPalette.grey7,
	borderRadius: 10,
	margin: '8px',
	padding: 16,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	cursor: 'pointer',
}));

export const CarePackageCard = ({ selected, onSelect, price, title, features }) => {
	return (
		<Card selected={selected} onClick={onSelect}>
			<Content title={title} selected={selected} features={features} />
			<Footer selected={selected} price={price} />
		</Card>
	);
};
