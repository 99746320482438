import { defineMessages } from 'react-intl';

export default defineMessages({
	name: {
		id: 'Robots.name',
		defaultMessage: 'Name',
		description: 'Name of robot',
	},
	group: {
		id: 'Robots.group',
		defaultMessage: 'Name',
		description: 'Name',
	},
	location: {
		id: 'Robots.location',
		defaultMessage: 'Location',
		description: 'Location',
	},
	status: {
		id: 'Robots.status',
		defaultMessage: 'Status',
		description: 'Status',
	},
	charge: {
		id: 'Robots.charge',
		defaultMessage: 'Charge',
		description: 'Charge',
	},
	latestSession: {
		id: 'UsersPage.latestSession',
		defaultMessage: 'Latest session',
		description: 'Latest session',
	},
	edit: {
		id: 'Robots.edit',
		defaultMessage: 'Edit robot',
		description: 'Edit robot',
	},
	moveTo: {
		id: 'Robots.moveTo',
		defaultMessage: 'Move to group',
		description: 'Move to group',
	},
	serialNumber: {
		id: 'Robots.serialNumber',
		defaultMessage: 'Serial Number',
		description: 'Serial Number',
	},
	addRobot: {
		id: 'Robots.add',
		defaultMessage: 'Add New Robot',
		description: 'Add New Robot',
	},
	online: {
		id: 'Robots.online',
		defaultMessage: 'Available',
		description: 'Available',
	},
	onCall: {
		id: 'Robots.onCall',
		defaultMessage: 'In Session',
		description: 'In Session',
	},
	offline: {
		id: 'Robots.offline',
		defaultMessage: 'Offline',
		description: 'Offline',
	},
	transferRobot: {
		id: 'Robots.transfer',
		defaultMessage: 'Transfer Robot',
		description: 'Transfer Robot',
	},
	transfer: {
		id: 'Robots.transfer',
		defaultMessage: 'transfer',
		description: 'transfer',
	},
	unassigned: {
		id: 'unassigned',
		defaultMessage: 'Unassigned',
		description: 'Unassigned',
	},
	noRobots: {
		id: 'Robots.noRobots',
		defaultMessage: 'No Robots here!',
		description: 'No Robots here!',
	},
	noRobotsHint: {
		id: 'Robots.noRobotsHint',
		defaultMessage: "You don't have any Robots yet!",
		description: "You don't have any Robots yet!",
	},
	selectRobots: {
		id: 'Robots.selectRobots',
		defaultMessage: 'Select Robots',
		description: 'Select Robots',
	},
	addRobotsToGroups: {
		id: 'Robots.addRobotsToGroups',
		defaultMessage: '{number} Robots have been selected:',
		description: '{number} Robots have been selected:',
	},
});
