import React from 'react';
import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';

export const RoundedButtonBase = styled(ButtonBase)`
	border-radius: 100px;
	box-shadow: ${p => p.theme.shadows[0]};
	width: fit-content;
	text-transform: uppercase;
	font-family: ${p => p.theme.typography.fontFamily};
	font-size: ${p => p.theme.typography.fontSize}px;
`;
