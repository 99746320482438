import { createTheme } from '@material-ui/core/styles';
import { fontFamilies } from './fonts/fontFamilies';

const customPalette = {
	primary: '#58B255',
	secondary: '#1E1F22',
	grey: {
		grey1: '#A5A5A6',
		grey2: '#eeeeee',
		grey3: '#D2D2D2',
		grey4: '#78787A',
		grey5: '#F1F1F3',
		grey6: '#FFFFFF',
		grey7: '#CCCCCC',
		grey8: '#000000',
		grey9: '#AAAAAA',
	},
};

const shadows = ['none', '0px 2px 6px #0000000A', '0px 2px 10px #0000000A'];

export const theme = createTheme({
	palette: {
		primary: {
			main: customPalette.primary,
		},
		secondary: {
			main: customPalette.secondary,
		},
		greyPalette: customPalette.grey,
		text: {
			primary: customPalette.secondary,
			disabled: customPalette.grey.grey1,
		},
		webshopPalette: {
			purchase: customPalette.primary,
			raas: '#E1C406',
			rental: '#DF760D',
			addons: '#3b86ff',
		},
	},
	typography: {
		fontFamily: fontFamilies.roboto,
		fontSize: 15,
		color: customPalette.secondary,
		grey: customPalette.grey.grey1,
		h1: {
			fontSize: 28,
			fontFamily: fontFamilies.industryMedium,
			color: customPalette.secondary,
			fontStyle: 'normal',
		},
		h2: {
			fontSize: 20,
			fontFamily: fontFamilies.industryLight,
			color: customPalette.secondary,
			fontStyle: 'normal',
		},
		h3: {
			fontSize: 28,
			fontFamily: fontFamilies.industryLight,
			color: customPalette.secondary,
			fontStyle: 'normal',
		},
		h4: {
			fontSize: 28,
			fontFamily: fontFamilies.industryDemi,
			color: customPalette.secondary,
			fontStyle: 'normal',
		},
		h5: {
			fontSize: 14,
			fontFamily: fontFamilies.industryBold,
			color: customPalette.secondary,
			fontStyle: 'normal',
		},
		h6: {
			fontSize: 14,
			fontFamily: fontFamilies.industryDemi,
			color: customPalette.primary,
			textTransform: 'uppercase',
			fontStyle: 'normal',
		},
		body1: {
			fontSize: 14,
			fontFamily: fontFamilies.roboto,
			color: customPalette.secondary,
			lineHeight: "20px"
		},
		body2: {
			fontSize: 17,
			fontFamily: fontFamilies.roboto,
			color: customPalette.secondary,
		}
	},
	shape: {
		borderRadius: 5,
	},
	shadows: shadows,
	components: {
		MuiPaper: {
			styleOverrides: {
				root: {
					boxShadow: shadows.main,
					borderRadius: 5,
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					backgroundColor: customPalette.grey.grey2,
				},
			},
		},
	},
});
