import React from 'react';
import styled from 'styled-components';
import { RoundedButtonBase } from './RoundedButtonBase';

const RoundedButtonOutlineDefault = styled(RoundedButtonBase)(
	({ theme, $color, $align, disabled }) => ({
		color: disabled ? theme.palette.greyPalette.grey3 : $color,
		backgroundColor: theme.palette.common.white,
		border: `1px solid ${disabled ? theme.palette.greyPalette.grey3 : $color}`,
		alignSelf: $align ? $align : 'auto',
		'&:hover, &.Mui-focusVisible': {
			backgroundColor: $color,
			boxShadow: 'none',
			border: `1px solid ${disabled ? theme.palette.greyPalette.grey3 : $color}`,
			color: theme.palette.common.white,
		},
	})
);

const LargeRoundedButtonOutline = styled(RoundedButtonOutlineDefault)(({ theme }) => ({
	padding: '12px 70px',
}));

const SmallRoundedButtonOutline = styled(RoundedButtonOutlineDefault)(({ theme }) => ({
	padding: '8px 32px',
}));

export const RoundedButtonOutline = ({
	variant = 'large',
	children,
	color = 'black',
	align,
	onClick,
	disabled,
}) => {
	if (variant === 'small') {
		return (
			<SmallRoundedButtonOutline
				$color={color}
				$align={align}
				onClick={onClick}
				disabled={disabled}
			>
				{children}
			</SmallRoundedButtonOutline>
		);
	}

	return (
		<LargeRoundedButtonOutline
			$color={color}
			$align={align}
			onClick={onClick}
			disabled={disabled}
		>
			{children}
		</LargeRoundedButtonOutline>
	);
};
