import { MqttRouter } from 'mqtt-sub-router';
import { store } from '../store/store';
import { subscribeToOrganization } from '../providers/mqtt';
import { publish } from '../actions/publish';

const OrganizationRouter = new MqttRouter()
	.listen(
		'deviceCreated',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `INSERT_NEW_DEVICE_SUCCESS`,
				payload: { newDevice: data },
			});
		}
	)
	.listen(
		'deviceInfoUpdated',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `UPDATE_DEVICE`,
				payload: { device: data },
			});
		}
	)
	.listen(
		'updateOrgId',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `DELETE_DEVICE_SUCCESS`,
				payload: { device: data },
			});
			store.dispatch({
				type: `UNLINK_DEVICE_FROM_ORG`,
				payload: data,
			});
			//TODO: should also be removed from user groups
			store.dispatch({
				type: `DELETE_DEVICE_GROUP_DEVICE`,
				payload: { device: data },
			});
		}
	)
	.listen(
		'deviceDeleted',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `DELETE_DEVICE_SUCCESS`,
				payload: { device: data },
			});
		}
	)
	.listen(
		'deviceOrgChanged',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			data.devices.map(device => {
				store.dispatch({
					type: `DELETE_DEVICE_SUCCESS`,
					payload: { device: device.updatedDevice, oldOrgId: device.oldOrgId },
				});
				store.dispatch({
					type: `DELETE_DEVICE_GROUP_DEVICE`,
					payload: { device: device.updatedDevice, oldOrgId: device.oldOrgId },
				});
			});
		}
	)
	.listen(
		'deviceGroupCreated',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `INSERT_NEW_DEVICE_GROUP`,
				payload: data,
			});
		}
	)
	.listen(
		'deviceGroupInfoUpdated',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `UPDATE_DEVICE_GROUP`,
				payload: data,
			});
		}
	)
	.listen(
		'deviceGroupDevicesUpdated',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `UPDATE_DEVICE_GROUP_DEVICES`,
				payload: data,
			});
			store.dispatch({
				type: `UPDATE_DEVICES_DEVICE_GROUP`,
				payload: data,
			});
		}
	)
	.listen(
		'deviceGroupDeleted',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `DELETE_DEVICE_GROUP`,
				payload: data,
			});
		}
	)
	.listen(
		'userGroupCreated',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `SET_USER_GROUP`,
				payload: data,
			});
		}
	)
	.listen(
		'userGroupInfoUpdated',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `UPDATE_USER_GROUP`,
				payload: data,
			});
		}
	)
	.listen(
		'userGroupUsersUpdated',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `UPDATE_USER_GROUP_USERS`,
				payload: data,
			});
			store.dispatch({
				type: `UPDATE_USERS_USER_GROUP`,
				payload: data,
			});
		}
	)
	.listen(
		'userGroupDeviceGroupsUpdated',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `UPDATE_USER_GROUP_DEVICE_GROUPS`,
				payload: data,
			});

			store.dispatch({
				type: `UPDATE_DEVICE_GROUP_USER_GROUPS`,
				payload: data,
			});
		}
	)
	.listen(
		'userGroupDeleted',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `DELETE_USER_GROUP`,
				payload: data,
			});
		}
	)
	.listen(
		'orgUpdated',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			const selectedOrg = store.getState().selectedOrganizationState.organization;

			store.dispatch({
				type: `UPDATE_ORGANIZATION`,
				payload: data,
			});
			if (selectedOrg.orgId === data.orgId) {
				store.dispatch({
					type: `UPDATE_SEL_ORG_CONTACTPERSON`,
					payload: { contactPerson: data.contactPerson },
				});
				store.dispatch({
					type: `UPDATE_SEL_ORG_NAME`,
					payload: { name: data.name },
				});
				store.dispatch({
					type: `UPDATE_SEL_ORG_LANGUAGE`,
					payload: { language: data.language },
				});

				store.dispatch({
					type: `UPDATE_SEL_ORG_SHIPPINGADDRESS`,
					payload: { shippingAddress: data.shippingAddress },
				});

				store.dispatch({
					type: `UPDATE_SEL_ORG_BILLINGADDRESS`,
					payload: { billingAddress: data.billingAddress },
				});
			}
		}
	)
	.listen(
		'userInvited',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `INSERT_NEW_USER`,
				payload: { user: data },
			});
		}
	)
	.listen(
		'userInfoUpdated',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `UPDATE_USER_ON_EDIT`,
				payload: { user: data },
			});
		}
	)
	.listen(
		'userDeviceGroupsUpdated',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `UPDATE_USER_DEVICE_GROUPS`,
				payload: data,
			});
		}
	)
	.listen(
		'userDeleted',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			store.dispatch({
				type: `REMOVE_USER`,
				payload: { user: data },
			});
		}
	)
	.listen('orgAdded', ({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
		if (data.orgId) {
			const wrappedClient = store.getState().mqttState.client;
			subscribeToOrganization(wrappedClient, data.orgId);
		}
		store.dispatch({
			type: `INSERT_NEW_ORGANIZATION_SUCCESS`,
			payload: { newOrganization: data },
		});

		const selectedOrg = store.getState().selectedOrganizationState.organization;
		store.dispatch({
			type: `UPDATE_SEL_ORG_ADD_NEW_CHILD_ORG`,
			payload: { newOrganization: data },
		});

		store.dispatch({
			type: `INSERT_NEW_CHILD_ORG`,
			payload: { newOrganization: data, parentOrganization: selectedOrg.orgId },
		});

		if (data.devices) {
			const { accountsOrganization, encodedUser, deviceId, deviceType, orgId } = data.devices;
			publish(`microservice/${accountsOrganization}/${encodedUser}/changeDeviceOrg`, {
				data: {
					deviceId,
					deviceType,
					orgId,
				},
			});
		}
	})
	.listen(
		'deleteOrg',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;
			store.dispatch({
				type: `DELETE_ORGANIZATION`,
				payload: data.orgId,
			});
		}
	)
	.listen(
		'roleAssignedToUsers',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;

			store.dispatch({
				type: `USER_ROLES_UPDATE`,
				payload: data,
			});
		}
	)
	.listen(
		'roleUnassignedFromUsers',
		({ data, requestId, status, params, topic, originalTopic, mqttClient }) => {
			if (!data) return;

			store.dispatch({
				type: `USER_ROLES_UNASSIGN`,
				payload: data,
			});

			const username = store.getState().accountState.user.username;
			if (data.usersIds.includes(username)) {
				store.dispatch({
					type: `UPDATE_ROLES`,
					payload: { roles: undefined },
				});
			}
		}
	);
export default OrganizationRouter;
