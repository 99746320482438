import React from 'react';
import styled from 'styled-components';
import gobeImg from '../../../../assets/images/GoBeRobotSide.jpg';
import { Divider, Typography } from '@material-ui/core';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { Footer } from '../../../../components/molecules/Footer';
import { useHistory } from 'react-router';

export const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
}));

const StyledImg = styled('img')(({ theme, viewHeight }) => ({
	height: 'auto',
	width: '25%',
}));

const StyledDivider = styled(Divider)(({ theme, viewHeight }) => ({
	backgroundColor: theme.palette.greyPalette.grey3,
	width: 1,
	height: viewHeight / 3,
}));

const ColumnContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	flex: 1,
}));

const LeftSide = styled('div')(({ theme }) => ({
	flex: 1,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: 24,
}));

const RightSide = styled('div')(({ theme }) => ({
	flex: 1,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: 24,
}));

const CheckMarkIcon = styled(IoCheckmarkCircleOutline)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: 75,
	height: 75,
	marginBottom: 22,
}));

const CenteredText = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
	marginBottom: 20,
	padding: 0,
}));
export const OrderReceivedPage = ({ children, hideHeader, setSelectedPlan }) => {
	const viewPortHeight = window.innerHeight;
	return (
		<Wrapper>
			<ColumnContainer>
				<LeftSide>
					<StyledImg
						src={gobeImg}
						alt="Gobe Robot front with person"
						viewHeight={viewPortHeight}
					/>
				</LeftSide>
				<StyledDivider viewHeight={viewPortHeight} />
				<RightSide>
					<CheckMarkIcon />
					<CenteredText variant="h1">Order received</CenteredText>
					<CenteredText variant="body2">
						Thank you for your order, we will contact you soon.
					</CenteredText>
					<div
						onClick={() => {
							setSelectedPlan('start');
						}}
					>
						<Footer nextButtonLabel="Back" />
					</div>
				</RightSide>
			</ColumnContainer>
		</Wrapper>
	);
};
