export const checkLength = (string: string) => {
	return string.length >= 8;
};
export const checkRobotPasswordLength = (string: string) => {
	return string.length >= 20;
};
export const checkOneLowerCase = (string: string) => {
	return /(?=.*[a-z])/.test(string);
};
export const checkOneUpperCase = (string: string) => {
	return /(?=.*[A-Z])/.test(string);
};
export const checkOneNumeric = (string: string) => {
	return /(?=.*[0-9])/.test(string);
};

export const checkPassword = (string: string) => {
	return (
		checkLength(string) &&
		checkOneLowerCase(string) &&
		checkOneUpperCase(string) &&
		checkOneNumeric(string)
	);
};

export const checkRobotPassword = (string: string) => {
	return (
		checkRobotPasswordLength(string)
	);
}
