import React, { FC, useEffect, useState } from 'react';

interface AgreementProps {
	agreement: { value: string; description: string; message: string };
	messageHeight: number;
}

const AgreementsFrame: FC<AgreementProps> = props => {
	const { agreement, messageHeight } = props;

	const [height, setHeight] = useState(700);
	const [width, setWidth] = useState(650);

	useEffect(() => {
		if (messageHeight && messageHeight > 1000) setHeight(messageHeight);
	}, [messageHeight]);

	return (
		<div>
			<iframe
				className="agreementFrame"
				style={{
					width,
					position: 'sticky',
					bottom: 100,
					zIndex: 1000,
					left: 'calc(50% - 325px)',
					boxSizing: 'border-box',
					height: "-webkit-fill-available",
					maxHeight: '80vh'
				}}
				title={agreement.value}
				id={agreement.value}
				frameBorder="0"
				src={
					'data:text/html;charset=utf-8;http-equiv=content-type,' +
					escape(
						agreement.description
							.replace('72pt 72pt 72pt 72pt', '0')
							.replace(/<body /g, `<body id="${'body-' + agreement.value}" `)
							.replace(/href/g, ' target="_blank" href')
							.replace(
								/<\/body>/g,
								`<script>
								let height;
								const sendPostMessage = () => {
									if(document.getElementsByTagName('html')[0].offsetHeight === 0)
									{
										setTimeout(() => {sendPostMessage},100);
									}
									if (height !== document.getElementsByTagName('html')[0].offsetHeight) {
										height = document.getElementsByTagName('html')[0].offsetHeight;
										window.parent.postMessage({
										${agreement.value}: height
										}, '*');
									}
								}
								window.onload = () => sendPostMessage();
								</script></body>`
							)
					)
				}
			/>
		</div>
	);
};

export default AgreementsFrame;
