import React, { useState } from 'react';
import isAuthenticated from '../../../components/Authentication/Authenticated';
import styled from 'styled-components';
import { Header } from './organisms/Header';
import { Cards } from './organisms/Cards';
import { Footer } from './organisms/Footer';
import { FMFooterLogo } from 'ui-elements/FMFooterLogo';
import { Route, Switch, Redirect, BrowserRouter, HashRouter } from 'react-router-dom';
import { PurchasePage } from '../Purchase/PurchasePage';

const Wrapper = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.greyPalette.grey2,
	position: 'absolute',
	top: 0,
	bottom: 0,
	right: 0,
	left: 0,
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	overflow: 'scroll',
}));

const WebshopStartPage = () => {
	const [selectedPlan, setSelectedPlan] = useState('start');

	switch (selectedPlan) {
		case 'start':
			return (
				<Wrapper>
					<Header />
					<Cards onPlanChange={setSelectedPlan} />
					<Footer />
					<FMFooterLogo />
				</Wrapper>
			);
		case 'purchase':
			return <PurchasePage setSelectedPlan={setSelectedPlan}/>;
		default:
			return (
				<Wrapper>
					<Header />
					<Cards onPlanChange={setSelectedPlan} />
					<Footer />
					<FMFooterLogo />
				</Wrapper>
			);
	}
};

export default isAuthenticated(WebshopStartPage, 'WebshopStartPage');
