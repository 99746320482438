import React, { FC, useEffect, useState } from 'react';
import {
	IonContent,
	IonPage,
	IonLabel,
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonList,
	IonButton,
} from '@ionic/react';
import { UserInviteTokenData } from '../../types/types';
import { injectIntl } from 'react-intl';
import JWTDecode from 'jwt-decode';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';

import classNames from 'classnames';
import classes from './AcceptInvite.module.css';

import FormInputListItem from '../../components/FormInputListItem/FormInputListItem';
import { RouteComponentProps, useParams, withRouter } from 'react-router';
import { automaticLogin } from '../../providers/mqtt';

import ErrorModal from '../../components/ErrorModal/ErrorModal';

import { eyeSharp, eyeOffSharp, checkmarkCircle } from 'ionicons/icons';
import { b64EncodeUnicode } from '../../utils/encoding';
import PasswordRequirements from '../../components/PasswordRequirements/PasswordRequirements';
import { checkOneLowerCase, checkOneNumeric, checkOneUpperCase } from '../../utils/validator';

import { SET_MQTTCLIENT, UPDATE_USER } from '../../actions/types';
import { useTypedSelector } from '../../reducers';

interface AcceptInviteProps extends RouteComponentProps {}

// const AcceptInviteConfirmPage: FC<AcceptInviteProps> = (props: any) => {
const AcceptInviteConfirmPage: FC<AcceptInviteProps> = (props: any) => {
	const { intl, confirmAccount, history } = props;
	const { control, handleSubmit, watch } = useForm();
	const [tokenError, setTokenError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [showInputErrorPassword, setShowInputErrorPassword] = useState(true);
	const [passwordLengthError, setPasswordLengthError] = useState('');
	const [showInputErrorRetyped, setShowInputErrorRetyped] = useState(true);
	const [retypedLengthError, setRetypedLengthError] = useState('');
	const [passwordInputValue, setPasswordInputValue] = useState('');
	const [retypedInputValue, setRetypedInputValue] = useState('');
	const [orgName, setOrgName] = useState('');
	const [firstName, setFirstName] = useState('');
	const [tokenObj, setTokenObj] = useState<any>();
	const [passwordType, setPasswordType] = useState(true);
	const [passwordTypeConfirm, setPasswordTypeConfirm] = useState(true);
	const [client, setClient] = useState<any>(null);
	let { token } = useParams<Record<string, any>>();
	const password = watch('password');

	const user = useTypedSelector(state => state.accountState.user);

	useEffect(() => {
		if (!token) {
			return;
		}

		if (client) {
			return;
		}

		let tokenData: UserInviteTokenData;
		try {
			tokenData = JWTDecode(token);
			setTokenObj(tokenData);
			setOrgName(tokenData.org.name || '');
			setFirstName(tokenData.first_name || '');
			if (tokenData.exp < new Date().getTime() / 1000) {
				setTokenError(intl.formatMessage({ id: 'ConfirmPage.tokenExpired' }));
				return;
			}
		} catch (error) {
			setTokenError('');
			return history.push('/');
		}

		if (tokenData.is_existing_user || tokenData.is_sso_user) {
			if (tokenData.type === 'orgCreated') {
				history.push(`/organization-setup/${token}`);
			} else {
				history.push(`/accept-invite/${token}`);
			}
			return;
		}

		const { username, temp_password: password } = tokenData;

		automaticLogin(
			username,
			password,
			async (response: { client: any; connected: boolean }) => {
				if (response && response.connected) {
					setClient(response.client);
					setParameter('client', 'SET_MQTTCLIENT', response.client);
					return;
				}

				setClient(null);
				setParameter('client', 'SET_MQTTCLIENT', { connected: false });

				setTokenError(intl.formatMessage({ id: 'ConfirmPage.tokenExpired' }));
				return history.push('/');
			}
		);
	}, [intl, history, token, client]);

	useEffect(() => {
		if (!tokenObj) {
			return;
		}

		const { type, id, success } = confirmAccount;
		if (!type || !id || !success) {
			return;
		}
		if (type !== 'ConfirmAccount') {
			return;
		}

		setParameter('', 'CLEAR_PASSWORD_REDUCER', {});

		if (success !== 'success') {
			alert('An error happened, please try setting your password again');
			window.location.reload();
			return;
		}

		alert('Password successfully confirmed, account now registered');

		automaticLogin(
			tokenObj.username,
			password,
			async (response: { client: any; connected: boolean }) => {
				if (response && response.connected) {
					props.setParameter('showLoader', 'CHANGE_LOADER', true);
					const account = user as any;
					account.loggedIn = true;
					account.username = tokenObj.username;
					account.password = password;
					props.setParameter('user', UPDATE_USER, account);
					props.setParameter('client', SET_MQTTCLIENT, response.client);

					if (tokenObj.type === 'inviteUser') {
						history.push(`/account-setup`);
					} else if (tokenObj.type === 'orgCreated') {
						// don't show org setup if user has no org (effectively deleted from the system), this is a workaround
						if (!user.organizations) {
							history.push(`/`);
						} else {
							history.push(`/organization-setup`);
						}
					}
				} else {
					props.setParameter('client', SET_MQTTCLIENT, { connected: false });
					alert('An error happened, please try setting your password again');
					window.location.reload();
					return;
				}
			}
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [confirmAccount.success, confirmAccount.type]);

	const resetPassword = handleSubmit(data => {
		const password = data.password;
		const confirmPassword = data.confirmPassword;
		if (password !== confirmPassword) {
			setTokenError('');
			setPasswordError(intl.formatMessage({ id: 'ConfirmPage.passwordNoMatch' }));
		} else {
			props.setParameter('request', 'CONFIRM_ACCOUNT', {
				id: 'ConfirmAccountID-' + tokenObj.username,
				type: 'ConfirmAccount',
			});
			if (token.includes('&source')) {
				token = token.split('&source')[0];
			}

			if (
				data.confirmPassword &&
				typeof data.confirmPassword === 'string' &&
				data.confirmPassword !== ''
			) {
				client.mqttClient.publish(
					`microservice/${b64EncodeUnicode(tokenObj.username)}/accountConfirmation`,
					JSON.stringify({
						requestId: 'ConfirmAccountID-' + tokenObj.username,
						data: { password: data.confirmPassword, token },
					})
				);
			}

			setTokenError('');
			setPasswordError('');
		}
	});

	const checkPasswordLength = (event: any) => {
		if (!event.detail.value) return;
		const value = event.detail.value;
		const targetName = event.target.name;
		switch (targetName) {
			case 'password':
				setPasswordInputValue(value);
				setPasswordError('');
				if (value.length < 8) {
					setShowInputErrorPassword(true);
					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordLength' })
					);
				} else if (!checkOneLowerCase(value)) {
					setShowInputErrorPassword(true);
					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordLowerCase' })
					);
				} else if (!checkOneNumeric(value)) {
					setShowInputErrorPassword(true);
					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordNumeric' })
					);
				} else if (!checkOneUpperCase(value)) {
					setShowInputErrorPassword(true);
					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordUpperCase' })
					);
				} else {
					setShowInputErrorPassword(false);
					setPasswordLengthError('');
				}
				return;
			case 'confirmPassword':
				setRetypedInputValue(value);
				setPasswordError('');
				if (value.length < 8) {
					setShowInputErrorRetyped(true);
					setRetypedLengthError(intl.formatMessage({ id: 'ConfirmPage.passwordLength' }));
				} else {
					if (password !== value) {
						setShowInputErrorRetyped(true);
						setRetypedLengthError(
							intl.formatMessage({ id: 'ConfirmPage.passwordNoMatch' })
						);
					} else {
						setShowInputErrorRetyped(false);
						setRetypedLengthError('');
					}
				}
				return;
			default:
				break;
		}
	};

	return (
		<>
			{tokenError && tokenError.length > 0 ? (
				<ErrorModal
					isOpen
					onConfirm={() => props.history.push('/')}
					onDismiss={() => props.history.push('/')}
					type="token"
				/>
			) : (
				''
			)}
			<IonPage>
				<IonContent>
					<IonGrid className={classNames(classes.passwordGrid, 'ion-no-padding')}>
						<IonRow className={classes.passwordGridRow}>
							<IonCol
								className={classNames(
									classes.leftContainer,
									'ion-hide-sm-down ion-hide-md-down'
								)}
							/>
							<IonCol className={classes.rightContainer}>
								<IonRow className={classes.passwordFormContainer}>
									<IonGrid>
										<IonRow
											className={classNames(
												classes.topLogoContainer,
												'ion-justify-content-center ion-padding'
											)}
										>
											<div className={classes.logo} />
											<div>
												<IonLabel className={classes.lbInstruction}>
													{props.intl.formatMessage(
														{
															id: 'SetPassword.setWelcome',
														},
														{ orgId: orgName, firstName }
													)}{' '}
												</IonLabel>
											</div>
										</IonRow>
										<form className={classes.passwordForm}>
											<IonRow>
												<IonLabel className={classes.errorContainer}>
													{tokenError || passwordError}
												</IonLabel>
											</IonRow>
											<IonRow className="ion-justify-content-center">
												<IonList
													className={classNames(
														classes.forgotPswList,
														'ion-padding'
													)}
												>
													<div>
														{password && (
															<PasswordRequirements
																password={password}
																checkMarkContainer={
																	retypedLengthError !== '' &&
																	classes.checkMarkContainerError
																}
															/>
														)}
														<FormInputListItem
															type={
																passwordType ? 'password' : 'text'
															}
															name="password"
															control={control}
															rules={{ required: true }}
															errorMsg={
																showInputErrorPassword
																	? passwordLengthError
																	: undefined
															}
															onChange={checkPasswordLength}
															required
															label={intl.formatMessage({
																id: 'SetPassword.password',
															})}
															placeholderText={intl.formatMessage({
																id: 'LoginPage.passwordHint',
															})}
															iconUrl={
																passwordType
																	? eyeSharp
																	: eyeOffSharp
															}
															iconCallback={() =>
																setPasswordType(prev => !prev)
															}
														/>
													</div>
													{!showInputErrorRetyped && (
														<div
															className={
																classes.checkMarkConfirmationContainer
															}
														>
															<IonIcon
																size="small"
																color="primary"
																icon={checkmarkCircle}
															/>
														</div>
													)}
													<FormInputListItem
														type={
															passwordTypeConfirm
																? 'password'
																: 'text'
														}
														name="confirmPassword"
														control={control}
														rules={{
															required: true,
														}}
														errorMsg={
															showInputErrorRetyped
																? retypedLengthError
																: undefined
														}
														onChange={checkPasswordLength}
														required
														label={intl.formatMessage({
															id: 'SetPassword.retypePassword',
														})}
														placeholderText={intl.formatMessage({
															id: 'SetPassword.retypePassword',
														})}
														iconUrl={
															passwordTypeConfirm
																? eyeSharp
																: eyeOffSharp
														}
														iconCallback={() =>
															setPasswordTypeConfirm(prev => !prev)
														}
													/>
												</IonList>
											</IonRow>
											<IonRow className={classes.btnRow}>
												<IonCol size="12">
													<IonButton
														className="round"
														shape="round"
														size="large"
														disabled={
															tokenError ||
															passwordError ||
															retypedLengthError ||
															passwordLengthError ||
															passwordInputValue.length < 8 ||
															retypedInputValue.length < 8
																? true
																: false
														}
														onClick={resetPassword}
													>
														{props.intl.formatMessage({
															id: 'SetPassword.continue',
														})}
													</IonButton>
												</IonCol>
											</IonRow>
										</form>
									</IonGrid>
								</IonRow>
								<IonRow className="ion-justify-content-center">
									<div className={classes.dividerContainer}>
										<div>
											<hr className={classes.divider} />
										</div>
										<div>
											<IonIcon
												src="./assets/img/blue-ocean-logo.svg"
												className={classes.blueoceanLogo}
											/>
										</div>
										<div>
											<hr className={classes.divider} />
										</div>
									</div>
								</IonRow>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonPage>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	confirmAccount: state.setPasswordState,
});
const enhance = connect(mapStateToProps, { setParameter });

export default injectIntl(withRouter(enhance(AcceptInviteConfirmPage)));
