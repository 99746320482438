import { useEffect, useCallback, useRef, useState } from 'react';
import { Machine, StateSchema, assign, actions } from 'xstate';
import { useMachine } from '@xstate/react';
import { debounce, keys, throttle, truncate } from 'lodash';
import { RTCInboundRtpStreamStatsEmitter } from '../rtcStats';

const angularMultiplier = (speed: number) => {
	if (speed > 0.8) {
		return 0.84;
	} else {
		return 1.69;
	}
};

type MouseNavControlMode = 'mouse';
type KeyboardNavControlMode = 'keyboard';
type NavControlMode = MouseNavControlMode | KeyboardNavControlMode;

type KeysPressState = {
	up: 0 | 1;
	down: 0 | 1;
	left: 0 | 1;
	right: 0 | 1;
	p: 0 | 1;
};
type MouseState = { horizontal: number; vertical: number };

type KeyEvent = { key: keyof KeysPressState; state: 0 | 1 };
type MouseEvent = MouseState;

type USER_INPUT_EVENT<T extends NavControlMode> = {
	type: 'USER_INPUT_EVENT';
	mode: NavControlMode;
	value: T extends MouseNavControlMode ? MouseEvent : KeyEvent;
};

type SPEED_CHANGED_EVENT = { type: 'SPEED_CHANGED'; speed: number };
type DATACHANNEL_READY_EVENT = { type: 'DATACHANNEL_READY'; datachannel: RTCDataChannel };
type NavControlMachineEvent =
	| USER_INPUT_EVENT<NavControlMode>
	| SPEED_CHANGED_EVENT
	| DATACHANNEL_READY_EVENT
	| { type: 'PAUSE' | 'RESUME' | 'ABORT' | 'HAS_VIDEO' | 'NO_VIDEO' }
	| { type: 'MODE_CHANGED'; mode: 'mouse' | 'keyboard' };
interface NavControlMachineContext {
	datachannel: RTCDataChannel | undefined;
	speed: number;
	keys: KeysPressState;
	mouse: { vertical: number; horizontal: number };
	mode: NavControlMode;
}
interface NavControlMachineStateSchema extends StateSchema {
	states: {
		paused: {};
		stallingCommands: {};
		sendingCommands: {
			states: {
				commandLocked: {};
				sending: {};
			};
		};
		aborted: {};
	};
}

/** The time in milliseconds, between two successive repetitions of key input  */
const COMMAND_REPETITION_INTERVAL_MS = 200;
const NavControlMachine = Machine<
	NavControlMachineContext,
	NavControlMachineStateSchema,
	NavControlMachineEvent
>(
	{
		id: 'navPilot',
		initial: 'stallingCommands',
		context: {
			datachannel: undefined,
			speed: 0,
			keys: { up: 0, down: 0, left: 0, right: 0, p: 0 },
			mouse: { vertical: 0, horizontal: 0 },
			mode: 'keyboard',
		},
		states: {
			paused: {
				entry: ['resetKeysInput', 'sendStopCommand'],
				on: {
					RESUME: {
						target: 'stallingCommands',
						// update the keys being tracked in the context
					},
				},
			},
			stallingCommands: {
				on: {
					USER_INPUT_EVENT: {
						target: undefined, // remain in this state
						cond: 'isDifferentUserInput',
						actions: ['handleUserNavInput'], // update the keys being tracked in the context
					},
					HAS_VIDEO: {
						target: 'sendingCommands',
					},
				},
			},
			sendingCommands: {
				initial: 'commandLocked',
				states: {
					commandLocked: {
						after: {
							50: 'sending',
						},
						activities: ['sendSingleCommand'],
						on: {
							USER_INPUT_EVENT: {
								target: undefined, // remain in the same state
								cond: 'isDifferentUserInput',
								actions: ['handleUserNavInput'],
							},
							SPEED_CHANGED: {
								target: undefined, // remain in the same state
								cond: 'isDifferentSpeed',
								actions: 'setSpeed',
							},
							DATACHANNEL_READY: {
								target: undefined, // remain in the same state
								actions: 'setDatachannel',
							},
							NO_VIDEO: {
								target: '#navPilot.stallingCommands',
								actions: ['sendStopCommand'],
							},
						},
					},
					sending: {
						activities: ['sendKeysRepeatedly'],
					},
				},
				on: {
					SPEED_CHANGED: {
						target: '.commandLocked',
						cond: 'isDifferentSpeed',
						actions: 'setSpeed',
					},
					USER_INPUT_EVENT: {
						target: '.commandLocked',
						cond: 'isDifferentUserInput',
						actions: ['handleUserNavInput'],
					},
					DATACHANNEL_READY: {
						target: '.commandLocked',
						actions: 'setDatachannel',
					},
					NO_VIDEO: {
						target: 'stallingCommands',
					},
				},
			},
			aborted: {
				type: 'final',
			},
		},
		on: {
			PAUSE: {
				target: 'paused',
			},
			DATACHANNEL_READY: {
				target: undefined, // no target, stay in whatever state we are in
				actions: 'setDatachannel',
			},
			SPEED_CHANGED: {
				target: undefined, // no target, stay in whatever state we are in
				actions: 'setSpeed',
			},
			ABORT: {
				target: 'aborted',
			},
		},
	},
	{
		actions: {
			resetKeysInput: assign({
				keys: (ctx, event) => ({ up: 0, down: 0, left: 0, right: 0, p: 0 }),
				mouse: (ctx, event) => ({ vertical: 0, horizontal: 0 }),
			}),
			sendStopCommand: (context, event) => {
				const { datachannel } = context;
				if (datachannel === undefined) return;

				try {
					const command = `NAV 0 0 ${performance.now()}`;
					datachannel.send(command);
				} catch (error) {
					console.error('sendStopCommand datachannel.send.failed', error);
				}
			},
			/** Action handler for USER_INPUT_EVENT events */
			handleUserNavInput: assign({
				keys: (ctx, _e) => {
					const event = (_e as unknown) as USER_INPUT_EVENT<'keyboard'>;
					if (event.mode !== 'keyboard') {
						// user has switched to a different input other than 'mouse' reset keyboard nav input's value
						return { ...ctx.keys, up: 0, down: 0, right: 0, left: 0 };
					} else {
						return { ...ctx.keys, [event.value.key]: event.value.state };
					}
				},
				mouse: (ctx, _e) => {
					const event = (_e as unknown) as USER_INPUT_EVENT<'mouse'>;
					if (event.mode !== 'mouse') {
						// user has switched to a different input other than 'mouse' reset mouse nav input's value
						return { vertical: 0, horizontal: 0 };
					} else return event.value;
				},
				mode: (ctx, _e) => {
					const event = (_e as unknown) as USER_INPUT_EVENT<NavControlMode>;
					return event.mode;
				},
			}),
			setSpeed: assign({
				speed: (context, event) => {
					const _event = event as SPEED_CHANGED_EVENT;
					return _event.speed / 100;
				},
			}),
			setDatachannel: assign({
				datachannel: (context, event) => {
					const _event = event as DATACHANNEL_READY_EVENT;
					return _event.datachannel;
				},
			}),
		},
		guards: {
			isDifferentUserInput: (context, _e) => {
				const event = (_e as unknown) as USER_INPUT_EVENT<NavControlMode>;
				// if the user is now using a completely new form of navigation, then it is certainly different
				if (event.mode !== context.mode) return true;
				else {
					if (context.mode === 'mouse') {
						const input = (event.value as unknown) as MouseEvent;
						return (
							context.mouse.horizontal != input.horizontal ||
							context.mouse.vertical != input.vertical
						);
					} else {
						const input = (event.value as unknown) as KeyEvent;
						return context.keys[input.key] !== input.state;
					}
				}
			},
			isDifferentSpeed: (context, event) => {
				const _event = event as SPEED_CHANGED_EVENT;
				return context.speed !== _event.speed;
			},
		},
		activities: {
			sendKeysRepeatedly: context => {
				const { keys, datachannel, speed } = context;
				if (datachannel === undefined) return;

				if (keys.p === 1) {
					try {
						datachannel.send(`DOCK CONTINUE ${performance.now()}`);
					} catch (error) {
						console.error('datachannel.send.leading.failed', error);
					}
					const interval = setInterval(() => {
						try {
							datachannel.send(`DOCK CONTINUE ${performance.now()}`);
						} catch (error) {
							console.error('datachannel.send.repeating.failed', error);
						}
					}, COMMAND_REPETITION_INTERVAL_MS);

					return () => {
						clearInterval(interval);
					};
				} else {
					const { linear, angular } = computeNavComponents(context);
					const command = `NAV ${linear} ${angular}`;

					// send the command immediately to the robot
					try {
						datachannel.send(command + ` ${performance.now()}`);
					} catch (error) {
						console.error('datachannel.send.leading.failed', error);
					}
					if (keys.up === 0 && keys.right === 0 && keys.down === 0 && keys.left === 0) {
						// if it a stop command, we wont repeat it
						return;
					} else {
						// and also repeatedly every X milliseconds
						const interval = setInterval(() => {
							try {
								datachannel.send(command + ` ${performance.now()}`);
							} catch (error) {
								console.error('datachannel.send.repeating.failed', error);
							}
						}, COMMAND_REPETITION_INTERVAL_MS);

						return () => {
							clearInterval(interval);
						};
					}
				}
			},
			sendSingleCommand: (context, event) => {
				const { keys, datachannel, speed } = context;
				if (datachannel === undefined) return;

				if (keys.p === 1) {
					datachannel.send(`DOCK START ${performance.now()}`);
				} else {
					const { linear, angular } = computeNavComponents({ ...context });
					const command = `NAV ${linear} ${angular}`;

					try {
						datachannel.send(command + ` ${performance.now()}`);
					} catch (error) {
						console.error('sendSingleCommand datachannel.send.failed', error);
					}
				}
			},
		},
	}
);

const computeNavComponents = (
	args: Pick<NavControlMachineContext, 'keys' | 'mode' | 'mouse' | 'speed'>
): { linear: number; angular: number } => {
	const { mode, keys, mouse, speed } = args;

	let [YComponent, XComponent] = [0, 0];
	if (mode === 'keyboard') {
		YComponent = minMax(keys.up - keys.down, -1.0, +1.0);
		XComponent = minMax(keys.left - keys.right, -1.0, +1.0);
	} else if (mode === 'mouse') {
		YComponent = mouse.vertical;
		XComponent = mouse.horizontal;
	}

	return {
		linear: speed * YComponent,
		angular: angularMultiplier(speed) * speed * XComponent,
	};
};

const minMax = (input: number, min: number, max: number) => Math.min(max, Math.max(min, input));

export type NavKey =
	| 'ArrowUp'
	| 'w'
	| 'W'
	| 'ArrowDown'
	| 's'
	| 'S'
	| 'ArrowRight'
	| 'd'
	| 'D'
	| 'ArrowLeft'
	| 'a'
	| 'A'
	| 'p';

const STATS_SAMPLING_PERIOD_MS = 100;

const NavKeyMapping: Record<NavKey, keyof KeysPressState> = {
	ArrowUp: 'up',
	w: 'up',
	W: 'up',
	ArrowDown: 'down',
	s: 'down',
	S: 'down',
	ArrowRight: 'right',
	d: 'right',
	D: 'right',
	ArrowLeft: 'left',
	a: 'left',
	A: 'left',
	p: 'p',
};

const useNavController = () => {
	const navFrames = useRef(1);
	const primaryFrames = useRef(1);

	useEffect(() => {
		console.info('NavController Hook Re-rendered');
	}, []);

	const [currentState, _sendEvent] = useMachine(NavControlMachine);
	const sendEvent = useRef(_sendEvent);
	useEffect(() => {
		sendEvent.current = _sendEvent;
	}, [_sendEvent]);

	const setDataChannel = useCallback((datachannel: RTCDataChannel) => {
		(window as any).datachannel = datachannel;
		sendEvent.current({ type: 'DATACHANNEL_READY', datachannel });
	}, []);

	const setNavSpeed = useCallback((speed: number) => {
		sendEvent.current({ type: 'SPEED_CHANGED', speed });
		console.info('SPEED_CHANGED');
	}, []);

	const pause = useCallback(() => {
		sendEvent.current({ type: 'PAUSE' });
	}, []);

	const resume = useCallback(() => {
		sendEvent.current({ type: 'RESUME' });
	}, []);

	const onKeyEvent = useCallback((eventType: 'keydown' | 'keyup', key: NavKey) => {
		if (!Object.keys(NavKeyMapping).includes(key as string)) return;

		sendEvent.current({
			type: 'USER_INPUT_EVENT',
			mode: 'keyboard',
			value: {
				key: NavKeyMapping[key],
				state: eventType === 'keydown' ? 1 : 0,
			},
		});
	}, []);

	const onMouseEvent = useCallback((event: { vertical: number; horizontal: number }) => {
		sendEvent.current({
			type: 'USER_INPUT_EVENT',
			mode: 'mouse',
			value: event,
		});
	}, []);

	const [navCamVideoRTCRtpReceiver, setNavCamVideoRTCRtpReceiver] = useState<RTCRtpReceiver>();
	const [primaryVideoRTCRtpReceiver, setPrimaryVideoRTCRtpReceiver] = useState<RTCRtpReceiver>();

	// listen for bytes received on cameras' rtp receiver
	useEffect(() => {
		if (!primaryVideoRTCRtpReceiver || !navCamVideoRTCRtpReceiver) return;

		let hasPrimaryCamVideo = false;
		let hasNavCamVideo = false;

		const primaryCamStatsListener = new RTCInboundRtpStreamStatsEmitter(
			primaryVideoRTCRtpReceiver,
			null, // there is only one MediaStreamTrack associated with an RTCRtpReceiver
			STATS_SAMPLING_PERIOD_MS
		);
		const navCamStatsListener = new RTCInboundRtpStreamStatsEmitter(
			navCamVideoRTCRtpReceiver,
			null, // there is only one MediaStreamTrack associated with an RTCRtpReceiver
			STATS_SAMPLING_PERIOD_MS
		);

		primaryCamStatsListener.start();
		navCamStatsListener.start();

		const primaryBytesListenerId = primaryCamStatsListener.addEventListener(
			'framesDecoded',
			framesDecoded => {
				primaryFrames.current = framesDecoded;
				hasPrimaryCamVideo = framesDecoded !== 0;
			}
		);
		const navBytesListenerId = navCamStatsListener.addEventListener(
			'framesDecoded',
			framesDecoded => {
				navFrames.current = framesDecoded;
				hasNavCamVideo = framesDecoded !== 0;
			}
		);

		const interval = setInterval(() => {
			if (hasPrimaryCamVideo && hasNavCamVideo) {
				sendEvent.current({ type: 'HAS_VIDEO' });
			} else {
				sendEvent.current({
					type: 'NO_VIDEO',
				});
			}
		}, STATS_SAMPLING_PERIOD_MS);

		return () => {
			console.warn('bytes-tracking-useEffect unMounted');
			clearInterval(interval);

			primaryCamStatsListener.stop();
			navCamStatsListener.stop();

			primaryCamStatsListener.removeListener('framesDecoded', primaryBytesListenerId);
			navCamStatsListener.removeListener('framesDecoded', navBytesListenerId);
		};
	}, [navCamVideoRTCRtpReceiver, primaryVideoRTCRtpReceiver]);

	/** Set the receiver for a video track that needs to be tracked
	 * in order to allow/disallow navigation */
	const setTrackedRTCRtpReceiver = useCallback(
		(cam: 'primaryCam' | 'navCam', rtcpReceiver: RTCRtpReceiver) => {
			if (cam === 'navCam') setNavCamVideoRTCRtpReceiver(rtcpReceiver);
			else setPrimaryVideoRTCRtpReceiver(rtcpReceiver);
		},
		[]
	);

	const returnValue = useRef({
		onKeyEvent,
		onMouseEvent,
		setTrackedRTCRtpReceiver,
		setDataChannel,
		setNavSpeed,
		pause,
		resume,
		// FIXME: Prince -> Meisam : A RefObject is not updated on re-renders
		// Move these properties below to a state object
		navFrames,
		primaryFrames,
	});
	return returnValue.current;
};

export default useNavController;
