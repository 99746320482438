import React, { FC, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { IonGrid, IonRow, IonList, IonListHeader, IonLabel, IonItem, IonIcon } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';

import classes from './RobotForm.module.css';
import classNames from 'classnames';
import Messages from './RobotForm.messages';

import isAuthenticated from '../../components/Authentication/Authenticated';
import BatteryDetails from '../BatteryDetails/BatteryDetails';
import Battery from '../Battery/Battery';
import batteryLevelConverter from '../../utils/batteryLevelConverter';
import { useTypedSelector, AppRootState } from '../../reducers';
import { equalityFnc } from '../../utils/conformState';
import { DeviceGroup } from '../../types/types';

interface RobotFormProps {
	device: any;
	classStyle: string;
}

const RobotDetails: FC<RobotFormProps> = props => {
	const { device, classStyle } = props;

	const [hoverBatt, setHoverBatt] = useState(false);

	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const deviceGroups = useTypedSelector(
		(state: AppRootState) =>
			(state.deviceGroupsState.deviceGroupsByOrganizationId as Record<
				string,
				Record<string, DeviceGroup>
			>)[selectedOrganizationId],
		(left, right) => equalityFnc(left, right)
	);

	const renderGroup = () => {
		const group = device.deviceGroupsIds && deviceGroups[device.deviceGroupsIds[0]];
		return (
			group && (
				<IonLabel position="fixed" className={classes.formLbItem}>
					{group.name}
				</IonLabel>
			)
		);
	};
	return (
		<div className={classNames(classes.editForm, classStyle)}>
			<IonGrid className={classes.formGrid}>
				<IonRow>
					<IonList className="ion-padding">
						<IonListHeader>
							<IonLabel>
								<FormattedMessage {...Messages.robotDetails} />
							</IonLabel>
						</IonListHeader>
						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.name} />
							</IonLabel>
							<IonLabel className={classes.robotName}>{device.name}</IonLabel>
						</IonItem>
						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.location} />
							</IonLabel>
							<IonLabel className={classes.location}>{device.location}</IonLabel>
						</IonItem>
						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.timeZone} />
							</IonLabel>
							<IonLabel className={classes.timeZone}>{device.timeZone}</IonLabel>
						</IonItem>
						<div
							className={
								hoverBatt
									? classes.batteryDetailsWrapperShow
									: classes.batteryDetailsWrapper
							}
						>
							<BatteryDetails
								batteryPercent={
									device.battery && device.battery.level != null
										? device.battery.level
										: 40
								}
								charging={
									device.battery && device.battery.charging
										? device.battery.charging
										: false
								}
							/>
						</div>
						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.Battery} />:
							</IonLabel>
							<div className={classes.battTextContainer}>
								<div
									className={
										device.online
											? classes.batteryRoster
											: (classes.batteryRoster, classes.batteryRosterOff)
									}
									onMouseOver={e => setHoverBatt(true)}
									onMouseOut={e => setHoverBatt(false)}
								>
									<Battery
										batteryPercent={
											device.battery && device.battery.level != null
												? device.battery.level
												: 40
										}
										online={device.online}
										charging={
											device.battery && device.battery.charging
												? device.battery.charging
												: false
										}
									/>
								</div>
								<div className={classes.batteryText}>
									{
										batteryLevelConverter(
											device.battery != null ? device.battery.level : 40,
											8
										).hour
									}
									h:
									{
										batteryLevelConverter(
											device.battery != null ? device.battery.level : 40,
											8
										).min
									}
									m
								</div>
								{device.battery && device.battery.charging ? (
									<div className={classes.chargingText}>
										{'- '} <FormattedMessage {...Messages.charging} />
									</div>
								) : (
									''
								)}
							</div>
						</IonItem>
						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.status} />:
							</IonLabel>
							{device.currentState != null ? (
								device.currentState === 'inSession' ? (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.onCall} />
									</IonLabel>
								) : device.currentState === 'available' ? (
									<IonLabel className={classes.statusNameLb}>
										<IonIcon
											className={classes.availableIcon}
											icon={checkmarkCircle}
										/>{' '}
										<FormattedMessage {...Messages.online} />
									</IonLabel>
								) : device.currentState === 'reserved' ? (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.reserved} />
									</IonLabel>
								) : device.currentState === 'onHold' ? (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.onHold} />
									</IonLabel>
								) : device.currentState === 'configuring' ? (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.configuring} />
									</IonLabel>
								) : device.currentState === 'unavailable' ? (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.unavailable} />
									</IonLabel>
								) : device.currentState === 'updating' ? (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.updating} />
									</IonLabel>
								) : (
									<IonLabel className={classes.statusNameLb}>
										<FormattedMessage {...Messages.offline} />
									</IonLabel>
								)
							) : (
								<>
									{device.online === true ? (
										<IonLabel className={classes.statusNameLb}>
											<FormattedMessage {...Messages.online} />
										</IonLabel>
									) : (
										<IonLabel className={classes.statusNameLb}>
											<FormattedMessage {...Messages.offline} />
										</IonLabel>
									)}
								</>
							)}
						</IonItem>
						<IonItem>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.type} />
							</IonLabel>

							<IonLabel className={classes.type}>{device.deviceType}</IonLabel>
						</IonItem>

						<IonItem disabled>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.gobeCare} />:
							</IonLabel>
						</IonItem>
						<IonItem hidden={true}>
							<IonLabel
								position="fixed"
								className={classNames(classes.subTitleLb, classes.formLb)}
							>
								<FormattedMessage {...Messages.linkedTo} />:
							</IonLabel>
						</IonItem>
						<IonItem hidden={true}> {/* Hidden until stability is reached */}
							<IonLabel position="fixed" className={classNames(classes.formLb)}>
								<FormattedMessage {...Messages.robotGroup} />:
							</IonLabel>
							{renderGroup()}
						</IonItem>
					</IonList>
				</IonRow>
			</IonGrid>
		</div>
	);
};
export default injectIntl(isAuthenticated(RobotDetails, 'RobotDetails'));
