import React from 'react';
import { PurchaseTemplate } from '../templates/PurchaseTemplate';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import numeral from 'numeral';
import { Table } from '../../../../components/molecules/Table/Table';
import { currencyTrans } from '../../utils';

export const ShoppingBagPage = ({
	units,
	setUnits,
	totalPrice,
	shippingPrice,
	totalUnitPrice,
	setCurrency,
	currency,
	promoCode,
	setPromoCode,
	onPromoCodeApply,
	discount,
	currencyOptions,
	promoCodeErrorMessage,
	carePackagePriceSum,
	carePackageDisplayName,
	selectedCarePackage,
}) => {
	const rows = [
		{
			labelColumn: {
				type: 'highlight',
				label: <FormattedMessage id="gobeRobot" defaultMessage="GoBe Robot" />,
			},
			descriptionColumn: {
				type: 'counter',
				units,
				setUnits,
			},
			priceColumn: {
				value: numeral(totalUnitPrice).format('0,0') + ' ' + currencyTrans[currency],
			},
		},
		{
			labelColumn: {
				type: '',
				label: carePackageDisplayName || (
					<FormattedMessage id="GobeCare" defaultMessage="GoBe Care package" />
				),
			},
			descriptionColumn: {
				text: selectedCarePackage === 'NONE' ? '0' : units,
			},
			priceColumn: {
				value: numeral(carePackagePriceSum).format('0,0') + ' ' + currencyTrans[currency],
			},
		},
		{
			labelColumn: {
				type: '',
				label: (
					<FormattedMessage id="enterPomoCode" defaultMessage="Enter your promo code" />
				),
			},
			descriptionColumn: {},
			priceColumn: {
				type: 'promoCode',
				placeholderText: 'Promo code',
				value: promoCode,
				onChange: setPromoCode,
				onClick: onPromoCodeApply,
				errorMessage: promoCodeErrorMessage,
			},
		},
		{
			labelColumn: {
				type: '',
				label: <FormattedMessage id="discount" defaultMessage="Discount" />,
			},
			descriptionColumn: {},
			priceColumn: {
				value: numeral(discount).format('0,0.00') + ' ' + currencyTrans[currency],
			},
		},
		{
			labelColumn: {
				type: '',
				label: <FormattedMessage id="shipping cost" defaultMessage="Shipping cost" />,
			},
			descriptionColumn: {},
			priceColumn: {
				value: numeral(shippingPrice).format('0,0') + ' ' + currencyTrans[currency],
			},
		},
		{
			labelColumn: {
				type: '',
				label: <FormattedMessage id="total" defaultMessage="Total Price" />,
			},
			descriptionColumn: {},
			priceColumn: {
				type: 'primaryBold',
				value: numeral(totalPrice).format('0,0') + ' ' + currencyTrans[currency],
			},
		},
	];
	return (
		<PurchaseTemplate
			totalPrice={totalPrice}
			setCurrency={setCurrency}
			currency={currency}
			currencyOptions={currencyOptions}
		>
			<Typography variant="h6">
				<FormattedMessage id="checkShoppingBag" defaultMessage="Check shopping bag" />:
			</Typography>
			<Table rows={rows} />
		</PurchaseTemplate>
	);
};
