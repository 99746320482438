import React from 'react';
import Battery from '../../../components/battery';
import './index.scss';
import { PropsFromParent } from './model';
import batteryLevelConverter from '../../../utils/batteryLevelConverter';

const BatteryDetails: React.FC<PropsFromParent> = ({ batteryPercent, charging = false }) => {
	const batteryColor = () => {
		if (parseInt(batteryPercent) >= 75) {
			return '#56ae4d';
		} else if (parseInt(batteryPercent) >= 50) {
			return '#ffce00';
		} else if (parseInt(batteryPercent) >= 25) {
			return 'orange';
		} else {
			return '#d92e2e';
		}
	};
	return (
		<div className="batteryDetailsContainer">
			<div className="battSquare">
				<div className="squareGreenText" style={{ color: `${batteryColor()}` }}>
					{batteryLevelConverter(batteryPercent, 8).hour} hr{' '}
					{batteryLevelConverter(batteryPercent, 8).min} min{' '}
					<span>({batteryPercent}%)</span>
				</div>
				<div className="squareText">
					Estimated GoBe Robot session time remaining. {charging ? '(Charging)' : ''}
				</div>
			</div>
			<div className="battTriangle" />
		</div>
	);
};

export default BatteryDetails;
