import React from 'react';
import { PurchaseTemplate } from '../templates/PurchaseTemplate';
import { FormattedMessage } from 'react-intl';
import { Table } from '../../../../components/molecules/Table/Table';
import numeral from 'numeral';
import { currencyTrans } from '../../utils';

export const SummaryPage = ({
	units,
	totalPrice,
	shippingPrice,
	totalUnitPrice,
	shippingInfo,
	billingInfo,
	taxNumber,
	currency,
	discount,
	carePackageDisplayName,
	carePackagePriceSum,
}) => {
	const rows = [
		{
			labelColumn: {
				type: 'highlight',
				label: <FormattedMessage id="gobeRobot" defaultMessage="GoBe Robot" />,
			},
			descriptionColumn: {
				text: units,
			},
			priceColumn: {
				value: numeral(totalUnitPrice).format('0,0') + ' ' + currencyTrans[currency],
			},
		},
		{
			labelColumn: {
				type: 'subHeader',
				label: <FormattedMessage id="billingInfo" defaultMessage="Billing info" />,
			},
			descriptionColumn: {},
			priceColumn: {},
		},
		{
			labelColumn: {
				type: 'contactInfo',
				contactName: [
					billingInfo.orderPersonFirstName,
					billingInfo.orderPersonLastName,
				].join(' '),
				companyName: billingInfo.companyName,
			},
			descriptionColumn: {
				type: 'address',
				street: [billingInfo.streetLine1, billingInfo.streetLine2, billingInfo.state]
					.filter(item => item !== '')
					.join(', '),
				city: [billingInfo.zip, billingInfo.city].join(' '),
				country: [billingInfo.countryCode],
			},
			priceColumn: {
				value: 'VAT/TAX no. ' + taxNumber,
			},
		},
		{
			labelColumn: {
				type: 'subHeader',
				label: <FormattedMessage id="shippingInfo" defaultMessage="Shipping info" />,
			},
			descriptionColumn: {},
			priceColumn: {},
		},
		{
			labelColumn: {
				type: 'contactInfo',
				contactName: [
					shippingInfo.orderPersonFirstName,
					shippingInfo.orderPersonLastName,
				].join(' '),
				companyName: shippingInfo.companyName,
			},
			descriptionColumn: {
				type: 'address',
				street: [shippingInfo.streetLine1, shippingInfo.streetLine2, shippingInfo.state]
					.filter(item => item !== '')
					.join(', '),
				city: [shippingInfo.zip, shippingInfo.city].join(' '),
				country: [shippingInfo.countryCode],
			},
			priceColumn: {},
		},
		{
			labelColumn: {
				type: 'subHeader',
				label: <FormattedMessage id="add-ons" defaultMessage="Add-Ons" />,
			},
			descriptionColumn: {},
			priceColumn: {},
		},
		{
			labelColumn: {
				label: <FormattedMessage id="GoBe Care package" />,
			},
			descriptionColumn: {
				type: 'greytext',
				text: carePackageDisplayName || (
					<FormattedMessage id="noCarePackage" defaultMessage="No Care Package" />
				),
			},
			priceColumn: {
				value: numeral(carePackagePriceSum).format('0,0') + ' ' + currencyTrans[currency],
			},
		},
		{
			labelColumn: {
				type: 'subHeader',
				label: <FormattedMessage id="paymentInfo" defaultMessage="Payment info" />,
			},
			descriptionColumn: {},
			priceColumn: {},
		},
		{
			labelColumn: {
				label: <FormattedMessage id="discount" defaultMessage="Discount" />,
			},
			descriptionColumn: {},
			priceColumn: {
				value: numeral(discount).format('0,0.00') + ' ' + currencyTrans[currency],
			},
		},
		{
			labelColumn: {
				label: <FormattedMessage id="shipping cost" defaultMessage="Shipping cost" />,
			},
			descriptionColumn: {},
			priceColumn: {
				value: numeral(shippingPrice).format('0,0') + ' ' + currencyTrans[currency],
			},
		},
		{
			labelColumn: {
				label: <FormattedMessage id="total" defaultMessage="Total Price" />,
			},
			descriptionColumn: {},
			priceColumn: {
				type: 'primaryBold',
				value: numeral(totalPrice).format('0,0') + ' ' + currencyTrans[currency],
			},
		},
	];
	return (
		<PurchaseTemplate hideHeader>
			<Table rows={rows} />
		</PurchaseTemplate>
	);
};
