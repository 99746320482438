import React, { FC, useState, useCallback, useEffect } from 'react';

import { injectIntl, FormattedMessage } from 'react-intl';
import {
	IonLabel,
	IonList,
	IonItem,
	IonListHeader,
	IonCol,
	IonRow,
	IonGrid,
	IonSelect,
	IonSelectOption,
	IonIcon,
} from '@ionic/react';
import classes from './PilotAppSettings.module.css';
import Messages from './PilotAppSettings.messages';

import isAuthenticated from '../Authentication/Authenticated';
import { mic, micOff, volumeHigh, volumeMute } from 'ionicons/icons';
import classNames from 'classnames';
import { setParameter } from '../../actions/setParam';
import Slider from '../../../src/SubApp/gb_operator_module/src/pages/session/slider';
import { useTypedSelector } from '../../reducers';
import { UPDATE_MICROPHONE, UPDATE_SPEAKER, UPDATE_CAMERA } from '../../actions/types';
import { connect } from 'react-redux';

interface AudioVideoSettingsProps {}

const AudioVideoSettings: FC<AudioVideoSettingsProps> = (props: any, localStream) => {
	const [audioInputSelect, setAudioInputSelect] = useState<any>([]);
	const [audioOutputSelect, setAudioOutputSelect] = useState<any>([]);
	const [videoInputSelect, setVideoInputSelect] = useState<any>([]);

	const videoElement = document.querySelector('video');

	const hardwareSettings = useTypedSelector(state => state.hardwareState.settings);

	const [speakerVolume, setSpeakerVolume] = useState<any>(
		hardwareSettings?.speakers?.speakerLevel || 40
	);
	const [microphoneVolume, setMicrophoneVolume] = useState<any>(
		hardwareSettings?.microphone?.microphoneLevel || 40
	);

	const [microphoneName, setMicrophoneName] = useState<any>('');
	const [speakerName, setSpeakerName] = useState<any>('');
	const [cameraName, setCameraName] = useState<any>('');

	const [microphoneSound, setMicrophoneSound] = useState<any>(0);

	const [audioContextState, setAudioContextState] = useState<any>();
	const [javascriptNodeState, setJavascriptNodeState] = useState<any>();
	const [microphoneStream, setMicrophoneStream] = useState<any>();
	useEffect(() => {
		if (hardwareSettings?.microphone) {
			if (hardwareSettings?.microphone?.name && hardwareSettings?.microphone?.id) {
				setTimeout(() => {
					setMicrophoneName(hardwareSettings.microphone.name);
				}, 250);
			} else {
				if (
					audioInputSelect &&
					audioInputSelect.length > 0 &&
					audioInputSelect[0].inputLabel === 'microphone' &&
					audioInputSelect.length >= 2
				) {
					setTimeout(() => {
						let tempMicrophoneSelect = JSON.parse(JSON.stringify(audioInputSelect));
						setMicrophoneName(audioInputSelect[1].inputLabel);
						tempMicrophoneSelect.splice(0, 1);
						setAudioInputSelect(tempMicrophoneSelect);
					}, 250);
				} else if (audioInputSelect && audioInputSelect.length > 0) {
					setTimeout(() => {
						setMicrophoneName(audioInputSelect[0].inputLabel);
					}, 250);
				}
			}

			if (hardwareSettings?.microphone)
				setMicrophoneVolume(hardwareSettings.microphone?.microphoneLevel || 40);
		}
	}, [hardwareSettings, audioInputSelect]);

	useEffect(() => {
		if (hardwareSettings?.speakers) {
			if (hardwareSettings?.speakers?.name && hardwareSettings?.speakers?.id) {
				setTimeout(() => {
					setSpeakerName(hardwareSettings?.speakers?.name);
				}, 250);
			} else {
				if (
					audioOutputSelect &&
					audioOutputSelect.length > 0 &&
					audioOutputSelect[0].inputLabel === 'speaker' &&
					audioOutputSelect.length >= 2
				) {
					setTimeout(() => {
						let tempSpeakerSelect = JSON.parse(JSON.stringify(audioOutputSelect));
						setSpeakerName(audioOutputSelect[1].inputLabel);
						tempSpeakerSelect.splice(0, 1);
						setAudioOutputSelect(tempSpeakerSelect);
					}, 250);
				} else if (audioOutputSelect && audioOutputSelect.length > 0) {
					setTimeout(() => {
						setSpeakerName(audioOutputSelect[0].inputLabel);
					}, 250);
				}
			}

			if (hardwareSettings?.speakers)
				setSpeakerVolume(hardwareSettings.speakers?.speakerLevel || 40);
		}
	}, [hardwareSettings, audioOutputSelect]);

	useEffect(() => {
		if (hardwareSettings?.camera) {
			if (hardwareSettings?.camera?.name && hardwareSettings?.camera?.id) {
				setTimeout(() => {
					setCameraName(hardwareSettings?.camera?.name);
				}, 250);
			} else {
				if (
					videoInputSelect &&
					videoInputSelect.length > 0 &&
					videoInputSelect[0].inputLabel === 'camera' &&
					videoInputSelect.length >= 2
				) {
					setTimeout(() => {
						let tempVideoSelect = JSON.parse(JSON.stringify(videoInputSelect));
						setCameraName(videoInputSelect[1].inputLabel);
						tempVideoSelect.splice(0, 1);
						setVideoInputSelect(tempVideoSelect);
					}, 250);
				} else if (videoInputSelect && videoInputSelect.length > 0) {
					setTimeout(() => {
						setCameraName(videoInputSelect[0].inputLabel);
					}, 250);
				}
			}
		}
	}, [hardwareSettings, videoInputSelect]);

	const AddMicrophoneOption = useCallback(
		(label: any, value: any) => {
			if (
				audioInputSelect &&
				(audioInputSelect || []).findIndex(
					(micOption: { inputValue: any }) => micOption?.inputValue === value
				) === -1
			) {
				setAudioInputSelect((audioInputSelect: any) => [
					...audioInputSelect,
					{ inputLabel: label, inputValue: value },
				]);
			}
		},
		[audioInputSelect]
	);

	const AddSpeakerOption = useCallback(
		(label: any, value: any) => {
			if (
				audioOutputSelect &&
				(audioOutputSelect || []).findIndex(
					(speakerOption: { inputValue: any }) => speakerOption?.inputValue === value
				) === -1
			) {
				setAudioOutputSelect((audioOutputSelect: any) => [
					...audioOutputSelect,
					{ inputLabel: label, inputValue: value },
				]);
			}
		},
		[audioOutputSelect]
	);

	const AddVideoOption = useCallback(
		(label: any, value: any) => {
			if (
				videoInputSelect &&
				(videoInputSelect || []).findIndex(
					(videoOption: { inputValue: any }) => videoOption?.inputValue === value
				) === -1
			) {
				setVideoInputSelect((videoInputSelect: any) => [
					...videoInputSelect,
					{ inputLabel: label, inputValue: value },
				]);
			}
		},
		[videoInputSelect]
	);

	const handleError = useCallback((error: any) => {
		console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
	}, []);

	const gotDevices = useCallback(
		(deviceInfos: any) => {
			for (let i = 0; i !== deviceInfos.length; ++i) {
				const deviceInfo = deviceInfos[i];
				const option = document.createElement('option');
				option.value = deviceInfo.deviceId;
				if (option.value.toLowerCase() !== 'default') {
					if (deviceInfo.kind === 'audioinput') {
						option.text = deviceInfo.label || 'microphone';
						AddMicrophoneOption(option.text, option.value);
					} else if (deviceInfo.kind === 'videoinput') {
						option.text = deviceInfo.label || 'camera';
						AddVideoOption(option.text, option.value);
					} else if (deviceInfo.kind === 'audiooutput') {
						option.text = deviceInfo.label || 'speaker';

						AddSpeakerOption(option.text, option.value);
					} else {
						console.log('Some other kind of source/device: ', deviceInfo);
					}
				}
			}
		},
		[AddMicrophoneOption, AddSpeakerOption, AddVideoOption]
	);

	const gotStream = useCallback(
		(stream: any) => {
			if (videoElement) videoElement.srcObject = stream;

			let audioContext = new AudioContext();
			setAudioContextState(audioContext);

			if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
				navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
					audioContext = new AudioContext();
					setAudioContextState(audioContext);
					setMicrophoneStream(stream);

					let analyser = audioContext.createAnalyser();
					let microphone = audioContext.createMediaStreamSource(stream);
					let javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

					setJavascriptNodeState(javascriptNode);
					analyser.smoothingTimeConstant = 0.8;
					analyser.fftSize = 1024;

					microphone.connect(analyser);
					analyser.connect(javascriptNode);
					javascriptNode.connect(audioContext.destination);
					javascriptNode.onaudioprocess = function() {
						let array = new Uint8Array(analyser.frequencyBinCount);
						analyser.getByteFrequencyData(array);
						let values = 0;

						let length = array.length;
						for (let i = 0; i < length; i++) {
							values += array[i];
						}

						let average = values / length;

						if (average > 100) {
							average = 100;
						}

						setMicrophoneSound(average);
						// changeHeight((average / 100) * 12);
					};
				});
			}

			return navigator.mediaDevices.enumerateDevices();
		},
		[videoElement]
	);

	const start = useCallback(() => {
		navigator.permissions
			.query({ name: 'camera' || 'microphone' })
			.then(function(permissionStatus) {
				permissionStatus.onchange = function() {
					start();
				};
			});

		if (
			audioInputSelect.length === 0 &&
			audioOutputSelect.length === 0 &&
			videoInputSelect.length === 0
		) {
			navigator.mediaDevices
				.enumerateDevices()
				.then(gotDevices)
				.catch(handleError);
		} else {
			if (
				audioInputSelect.length === 1 &&
				audioInputSelect[0].inputLabel === 'microphone' &&
				audioOutputSelect.length === 1 &&
				audioOutputSelect[0].inputLabel === 'speaker' &&
				videoInputSelect.length === 1 &&
				videoInputSelect[0].inputLabel === 'camera'
			) {
				const constraints = {
					video: true,
					audio: {
						deviceId: audioInputSelect.value
							? { exact: audioInputSelect.value }
							: undefined,
					},
				};

				navigator.mediaDevices
					.getUserMedia(constraints)
					.then(gotDevices)
					.catch(handleError);
			}
		}
	}, [audioInputSelect, audioOutputSelect, gotDevices, handleError, videoInputSelect]);

	useEffect(() => {
		start();
	}, [start]);

	useEffect(() => {
		return () => {
			if (videoElement) stopStreamedVideo(videoElement);
		};
	}, [videoElement]);

	useEffect(() => {
		return () => {
			if (javascriptNodeState) {
				javascriptNodeState.onaudioprocess = () => {
					setJavascriptNodeState(null);
				};
				setAudioContextState(null);
				microphoneStream.getTracks().forEach((track: any) => {
					track.stop();
				});
			}
		};
	}, [audioContextState, javascriptNodeState, microphoneStream]);

	const onMicrophoneVolumeChange = (deltaY: number) => {
		setMicrophoneVolume(deltaY);
		props.setParameter('microphone', UPDATE_MICROPHONE, {
			microphoneLevel: deltaY,
			id: hardwareSettings?.microphone?.id || '',
			name: hardwareSettings?.microphone?.name || '',
		});
	};

	const onSpeakerVolumeChange = (deltaY: number) => {
		setSpeakerVolume(deltaY);

		props.setParameter('speakers', UPDATE_SPEAKER, {
			speakerLevel: deltaY,
			id: hardwareSettings?.speakers?.id || '',
			name: hardwareSettings?.speakers?.name || '',
		});
	};

	const onChangeMicrophone = (e: CustomEvent) => {
		if (e.detail?.value) {
			setMicrophoneName(e.detail.value);
			navigator.mediaDevices.enumerateDevices().then(function(devices) {
				devices.forEach(function(device) {
					if (device.kind === 'audioinput' && device.label === e.detail?.value) {
						if (
							hardwareSettings?.microphone &&
							device.label !== hardwareSettings.microphone.name
						) {
							props.setParameter('microphone', UPDATE_MICROPHONE, {
								microphoneLevel: microphoneVolume,
								id: device.deviceId,
								name: device.label,
							});
						}
					}
				});
			});
		}
	};

	const onChangeSpeaker = (e: CustomEvent) => {
		if (e.detail?.value) {
			setSpeakerName(e.detail.value);
			navigator.mediaDevices.enumerateDevices().then(function(devices) {
				devices.forEach(function(device) {
					if (device.kind === 'audiooutput' && device.label === e.detail?.value) {
						if (
							hardwareSettings?.speakers &&
							device.label !== hardwareSettings.speakers.name
						) {
							props.setParameter('speakers', UPDATE_SPEAKER, {
								speakerLevel: speakerVolume,
								id: device.deviceId,
								name: device.label,
							});
						}
					}
				});
			});
		}
	};

	const changeVideoSelect = (e: CustomEvent) => {
		if (e.detail?.value) {
			setCameraName(e.detail?.value);
			navigator.mediaDevices.enumerateDevices().then(function(devices) {
				devices.forEach(function(device) {
					if (device.kind === 'videoinput' && device.label === e.detail?.value) {
						if (
							hardwareSettings?.camera &&
							device.label !== hardwareSettings.camera.name
						) {
							props.setParameter('camera', UPDATE_CAMERA, {
								id: device.deviceId,
								name: device.label,
							});
						}

						const constraints = {
							video: {
								deviceId: device.deviceId ? { exact: device.deviceId } : undefined,
							},
						};
						navigator.mediaDevices
							.getUserMedia(constraints)
							.then(gotStream)
							.catch(handleError);
					}
				});
			});
		}
	};

	const stopStreamedVideo = (videoElem: any) => {
		const stream = videoElem.srcObject;

		if (stream) {
			const tracks = stream.getTracks() || [];

			tracks.forEach(function(track: any) {
				track.stop();
			});

			videoElem.srcObject = null;
		}
	};

	return (
		<IonGrid className={classes.formGrid}>
			<IonRow>
				<IonList className="ion-padding">
					<IonListHeader>
						<IonLabel className={classes.detailHeader}>
							<FormattedMessage {...Messages.audioVideoSettings} />:
						</IonLabel>
					</IonListHeader>
				</IonList>
			</IonRow>

			<IonRow>
				<IonCol sizeLg="6" sizeMd="12" sizeSm="12" className={classes.leftColWithSelect}>
					<IonRow>
						<IonCol size="12" className={classes.firstCol}>
							<IonItem
								className={classNames(classes.selectContainer, 'ion-no-padding')}
							>
								<IonLabel position="floating">
									<FormattedMessage id="PilotApp.microphone" />
								</IonLabel>
								<IonSelect
									interface="popover"
									value={microphoneName}
									onIonChange={onChangeMicrophone}
									key="microphoneName"
								>
									{audioInputSelect &&
										audioInputSelect.length > 0 &&
										audioInputSelect.map((value: any) => {
											return (
												value && (
													<IonSelectOption key={value?.inputLabel}>
														{value?.inputLabel}
													</IonSelectOption>
												)
											);
										})}
								</IonSelect>
							</IonItem>
						</IonCol>
						<IonCol />
					</IonRow>

					<IonRow>
						<IonCol size="12">
							<IonItem
								className={classNames(classes.selectContainer, 'ion-no-padding')}
							>
								<IonLabel position="floating">
									<FormattedMessage id="PilotApp.speakers" />
								</IonLabel>
								<IonSelect
									value={speakerName}
									interface="popover"
									onIonChange={onChangeSpeaker}
									key="speakerName"
								>
									{audioOutputSelect &&
										audioOutputSelect.length > 0 &&
										audioOutputSelect.map((value: any) => {
											return (
												value && (
													<IonSelectOption key={value?.inputLabel}>
														{value?.inputLabel}
													</IonSelectOption>
												)
											);
										})}
								</IonSelect>
							</IonItem>
						</IonCol>
						<IonCol />
					</IonRow>

					<IonRow>
						<IonCol size="12">
							<IonItem
								className={classNames(classes.selectContainer, 'ion-no-padding')}
							>
								<IonLabel position="floating">
									<FormattedMessage id="PilotApp.camera" />
								</IonLabel>

								<IonSelect
									interface="popover"
									onIonChange={changeVideoSelect}
									value={cameraName}
									key="cameraName"
								>
									{videoInputSelect &&
										videoInputSelect.length > 0 &&
										videoInputSelect.map((value: any) => {
											return (
												value && (
													<IonSelectOption key={value?.inputLabel}>
														{value.inputLabel}
													</IonSelectOption>
												)
											);
										})}
								</IonSelect>
							</IonItem>
						</IonCol>
						<IonCol />
					</IonRow>
				</IonCol>

				<IonCol sizeLg="6" sizeMd="12" sizeSm="12" className={classes.rightColWithSelect}>
					<IonRow className={classes.rowSliders}>
						<IonCol size="2">
							<div className={classes.audioRecognizeContainer}>
								<div
									className={classes.audioStrength}
									style={{ height: `${4 + microphoneSound / 2}px` }}
								/>
								<div
									className={classes.audioStrengthMax}
									style={{ height: `${4 + microphoneSound}px` }}
								/>
								<div
									className={classes.audioStrength}
									style={{ height: `${4 + microphoneSound / 2}px` }}
								/>
							</div>
						</IonCol>
						<IonCol size="10">
							<IonRow className={classes.sliderDiv}>
								<IonCol size="2" className={classes.leftCol}>
									{microphoneVolume !== 0 && microphoneVolume !== '0' && (
										<IonIcon
											size="large"
											icon={mic}
											className={classes.ionIcon}
										/>
									)}

									{(microphoneVolume === 0 || microphoneVolume === '0') && (
										<IonIcon
											size="large"
											icon={micOff}
											className={classes.ionIconOff}
										/>
									)}
								</IonCol>

								<IonCol size="3" className={classes.rightCol}>
									<IonLabel
										className={
											microphoneVolume !== 0 && microphoneVolume !== '0'
												? classNames(classes.volumeStatusText)
												: classNames(classes.volumeStatusTextWhenIs0)
										}
									>
										{microphoneVolume}%
									</IonLabel>
								</IonCol>

								<IonCol size="7" className={classes.sliderCol}>
									<Slider
										onChange={onMicrophoneVolumeChange}
										value={microphoneVolume}
										icon="speed-green.svg"
										id="navVideoSpeed"
									/>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol size="2" className={classes.secondSliderLabel} />

						<IonCol size="10">
							<IonRow className={classes.sliderDiv2}>
								<IonCol size="2" className={classes.leftCol}>
									{speakerVolume !== 0 && speakerVolume !== '0' && (
										<IonIcon
											size="large"
											icon={volumeHigh}
											className={classes.ionIcon}
										/>
									)}

									{(speakerVolume === 0 || speakerVolume === '0') && (
										<IonIcon
											size="large"
											icon={volumeMute}
											className={classes.ionIconOff}
										/>
									)}
								</IonCol>

								<IonCol size="3" className={classes.rightCol}>
									<IonLabel
										className={
											speakerVolume !== '0' && speakerVolume !== 0
												? classNames(classes.volumeStatusText)
												: classNames(classes.volumeStatusTextWhenIs0)
										}
									>
										{speakerVolume}%
									</IonLabel>
								</IonCol>
								<IonCol size="7" className={classes.sliderCol}>
									<Slider
										onChange={onSpeakerVolumeChange}
										value={speakerVolume}
										icon="speed-green.svg"
										id="navVideoSpeed"
									/>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow>

					<IonRow className={classes.videoRow}>
						<IonCol sizeLg="2" />

						<IonCol sizeLg="10" sizeSm="12">
							<div className={classes.videoLandscape}>
								<video
									className={classes.videoStream}
									id="video"
									playsInline
									autoPlay
								/>
							</div>
						</IonCol>
					</IonRow>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default injectIntl(
	isAuthenticated(connect(null, { setParameter })(AudioVideoSettings), 'AudioVideoSettings')
);
