/* eslint-disable camelcase */
import { useRef, useEffect, useState, useCallback, useMemo, useLayoutEffect } from 'react';
import PeerConnectionWithSignalling, {
	PeerConnectionEndReasonCode,
	PrimaryCameraState,
	RemoteTrackKey,
} from './peerConnection';
import mqtt, { IClientOptions } from 'mqtt';
import MqttReconnector from './mqttReconnect';
import MqttReconnector_Deprecated from './mqttReconnector.deprecated';
import { ACKPayload } from './signalling';

export type ConnectionState = RTCPeerConnectionState;
const useCallerPeerConnection = ({
	mqttConfig,
	callerInfo,
	robotId,
	onDataChannel,
	onStarted,
	onEnded,
}: {
	mqttConfig: Partial<IClientOptions>;
	callerInfo: { avatar?: string; name: string; id: string; username: string; password: string };
	robotId: string;
	onDataChannel: (dataChannel: RTCDataChannel) => void;
	onStarted: () => void;
	onEnded: (reason: PeerConnectionEndReasonCode) => void;
}) => {
	// memoize the credentials, it wont change ever !
	const credentialsRef = useRef({
		username: callerInfo.username,
		password: callerInfo.password,
	});
	const mqttClient = useMemo(() => {
		return mqtt
			.connect({
				...mqttConfig,
				path: `/${mqttConfig.path}`,
				username: credentialsRef.current.username,
				password: credentialsRef.current.password,
				reconnectPeriod: 0, // disable auto reconnection, we handle this manually
			} as IClientOptions)
			.on('connect', () => {
				console.log('RTC mqtt CONNECT');
			});
	}, [mqttConfig]);

	const refs = useRef({
		/** a memoized peer connection to ensure referential stability */
		peerConnection: new PeerConnectionWithSignalling(
			callerInfo.id,
			robotId,
			{ name: callerInfo.name, avatar: callerInfo.avatar },
			mqttClient
		),
		/** container for media tracks added by the remote peer */
		remoteStream: new MediaStream(),
	});
	const media = usePCMediaStreams(refs.current.peerConnection);

	// bind PeerConnection object to window, for debugging purposes
	useEffect(() => {
		if (process.env.NODE_ENV !== 'production') {
			(window as any).peerConnection = refs.current.peerConnection;
			(window as any).sessionID = refs.current.peerConnection.uuid;
		}
		return () => {
			(window as any).peerConnection = null;
		};
	}, []);

	const [isPeerConnectionPaused, setIsPeerConnectionPaused] = useState(
		refs.current.peerConnection.isPaused
	);

	useLayoutEffect(() => {
		const pc = refs.current.peerConnection;

		const onPause = () => setIsPeerConnectionPaused(true);
		const onUnPause = () => setIsPeerConnectionPaused(false);

		pc.addEventListener('pause', onPause);
		pc.addEventListener('unpause', onUnPause);

		return () => {
			pc.removeEventListener('pause', onPause);
			pc.removeEventListener('unpause', onUnPause);
		};
	}, []);

	// Kill peer connection when caller component is dieing
	useLayoutEffect(() => {
		const peerConnection = refs.current.peerConnection;
		console.info('caller.peerConnection.cleanup.registered');
		return () => {
			peerConnection.end('CLEANUP');
			console.info('caller.peerConnection.closed');
			console.info('caller.peerConnection.cleanup.unregistered');
		};
	}, []);

	// close mqtt client when caller component is dieing
	useLayoutEffect(() => {
		const client = mqttClient;
		console.info('caller.mqttClient.cleanup.registered');
		return () => {
			client.unsubscribe('#');
			// client.end();
			console.info('caller.mqttClient.unsubscribed from all messages');
			console.info('caller.mqttClient.cleanup.unregistered');
		};
	}, [mqttClient]);

	// listen for connection state changes on the peer connection
	// const [connectionState, setConnectionState] = useState<ConnectionState>(
	// 	refs.current.peerConnection.connectionState
	// );
	useLayoutEffect(() => {
		const peerConnection = refs.current.peerConnection;
		// peerConnection.onConnectionStateChange = setConnectionState;
		peerConnection.onEnded = onEnded;
		return () => {
			peerConnection.onConnectionStateChange = null;
			peerConnection.onEnded = null;
		};
	}, [onEnded]);

	// cleanup remote tracks when the caller component is exiting
	useLayoutEffect(() => {
		const remoteStream = refs.current.remoteStream;
		return () => {
			remoteStream.getTracks().forEach(track => {
				track.stop();
				remoteStream.removeTrack(track);
			});
		};
	}, []);

	// attach external callbacks to the peerConnection
	// As much as possible, we put external callbacks in a separate useEffect.
	// 	So that their reference changes does not affect other logic here due to re-renders
	useLayoutEffect(() => {
		const peerConnection = refs.current.peerConnection;
		peerConnection.onDataChanel = onDataChannel;
		peerConnection.onStarted = onStarted;

		return () => {
			peerConnection.onDataChanel = null;
			peerConnection.onStarted = null;
		};
	}, [onDataChannel, onStarted]);

	const [primaryCameraState, setPrimaryCameraState] = useState<PrimaryCameraState>(
		refs.current.peerConnection.primaryCameraState
	);
	useLayoutEffect(() => {
		const peerConnection = refs.current.peerConnection;
		peerConnection.onPrimaryCameraStateChange = setPrimaryCameraState;
		return () => {
			peerConnection.onPrimaryCameraStateChange = null;
		};
	}, []);

	const [mqttReconnector, setMqttReconnector] = useState<MqttReconnector>();
	useEffect(() => {
		mqttReconnector?.start(); // start the reconnector, immediately it is set to a non-null value
		return () => mqttReconnector?.stop(); // stop reconnector when the component is unmounting
	}, [mqttReconnector]);

	const [robotCapabilities, setRobotCapabilities] = useState<ACKPayload['capabilities']>();
	const startPeerConnection = useCallback(
		(stream: MediaStream, initialVolume: number) => {
			const peerConnection = refs.current.peerConnection;
			peerConnection
				.start(stream, initialVolume)
				.then(capabilities => {
					if (capabilities?.keepalive_over_mqtt) {
						setMqttReconnector(new MqttReconnector(mqttClient, peerConnection));
					} else {
						setDeprecatedMqttReconnector(
							new MqttReconnector_Deprecated(mqttClient, peerConnection)
						);
					}
					setRobotCapabilities(capabilities);
				})
				.catch(error => {
					console.error('Unable to start peer connection', error);
				});
		},
		[mqttClient]
	);

	const endPeerConnection = useCallback(
		(reason: PeerConnectionEndReasonCode = 'LOCAL_HANGUP') => {
			refs.current.peerConnection.end(reason);
			if (mqttReconnector instanceof MqttReconnector) {
				mqttReconnector?.stop();
			}
		},
		[mqttReconnector]
	);

	const togglePrimaryCamera = useCallback(() => {
		const { peerConnection } = refs.current;
		peerConnection.togglePrimaryCamera().catch(error => console.error(error));
	}, []);

	// ********************************************************************************
	// ********************************************************************************
	// Handle state change for deprecated mqtt reconnector
	const [deprecatedMqttReconnector, setDeprecatedMqttReconnector] = useState<
		MqttReconnector_Deprecated
	>();

	useEffect(() => {
		const mediaTrack = media.primaryMediaStream.getVideoTracks()[0];
		if (!deprecatedMqttReconnector || !media.primaryRTPTransceiver || !mediaTrack) return;

		const rtcRtpReceiver = media.primaryRTPTransceiver.receiver;
		// As a workaround for the legacy implementation, we wait a while before starting the reconnector
		// If we start it immediately, chances are rtcRtpReceiver might have not started receiving bytes yet,
		// 	and might thus trigger an infinite reconnection of the mqtt client
		const timeoutId = setTimeout(() => {
			deprecatedMqttReconnector.start(rtcRtpReceiver, mediaTrack);
		}, 10000);
		return () => {
			clearTimeout(timeoutId);
			deprecatedMqttReconnector.stop();
		};
	}, [deprecatedMqttReconnector, media.primaryMediaStream, media.primaryRTPTransceiver]);
	// ********************************************************************************

	return {
		robotCapabilities,
		startPeerConnection,
		endPeerConnection,
		pausePeerConnection: refs.current.peerConnection.pause,
		unpausePeerConnection: refs.current.peerConnection.unpause,
		isPeerConnectionPaused,
		togglePrimaryCamera,
		primaryCameraState,
		...media,
	};
};

export default useCallerPeerConnection;

function usePCMediaStreams(pc: PeerConnectionWithSignalling) {
	const [primaryMedia, setPrimaryMedia] = useState<{
		stream: MediaStream;
		transceiver: RTCRtpTransceiver | null;
	}>({ stream: pc.primaryMediaStream, transceiver: null });

	const [navMedia, setNavMedia] = useState<{
		stream: MediaStream;
		transceiver: RTCRtpTransceiver | null;
	}>({ stream: new MediaStream(), transceiver: null });

	useEffect(() => {
		pc.onPrimaryMediaStreamChanged = (stream, transceiver) => {
			setPrimaryMedia({ stream, transceiver });
		};
		pc.onNavMediaStreamChanged = (stream, transceiver) => {
			setNavMedia({ stream, transceiver });
		};
	}, [pc]);

	return {
		primaryMediaStream: primaryMedia.stream,
		primaryRTPTransceiver: primaryMedia.transceiver,

		navMediaStream: navMedia.stream,
		navRTPTransceiver: navMedia.transceiver,
	};
}
