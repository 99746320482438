import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Steps } from '../../../components/templates/Steps';
import { ChooseProductsPage } from './pages/ChooseProductsPage';
import { ShippingPage } from './pages/ShippingPage';
import { ShoppingBagPage } from './pages/ShoppingBagPage';
import { SummaryPage } from './pages/SummaryPage';
import { OrderReceivedPage } from './pages/OrderReceivedPage';
import { BillingPage } from './pages/BillingPage';
import MainContent from '../../../components/MainContent/MainContent';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Snackbar } from 'components/Snackbar';

const CREATE_ORDER = gql`
	mutation CreateOrder($input: CreateOrderInput!) {
		createOrder(input: $input) {
			_id
		}
	}
`;

const ESTIMATE_QUERY = gql`
	query EstimateQuery($input: CostEstimateInput!) {
		costEstimate(input: $input) {
			totalPrice
			unitPrice
			unitSum
			shippingCost
			discount
			isDiscountCodeValid
			gobeCarePackageUnitPrice
			gobeCarePackagePriceSum
		}
	}
`;

const START_QUERY = gql`
	query StartQuery($input: ProductPricesInput!) {
		currencies {
			currencyCode
		}
		prices(input: $input) {
			_id
			productName
			price
		}
	}
`;

const productName = 'GoBe';

const minFeatures = ['Help Center Access (Tier 0)'];
const standardFeatures = [
	'Basic Support (Tier 1)',
	'Advanced Support (Tier 2)',
	'Software Updates',
	'Preventive Diagnostics',
	'Annual Online Inspiration Training',
];
const plusFeatures = [
	'Premium Feature Access',
	'Annual Online Super User Training',
	'Free shipping of parts, modules and replacements',
	'Free of cost service ',
];

const gobeCareEnums = ['NONE', 'GOBE_CARE', 'GOBE_CARE_PLUS'];

export const PurchasePage = ({ setSelectedPlan }) => {
	const [units, setUnits] = useState(1);
	const [currency, setCurrency] = useState('DKK');
	const [promoCode, setPromoCode] = useState('');
	const [isBillingFormikValid, setIsBillingFormikValid] = useState(false);
	const [isShippingFormikValid, setIsShippingFormikValid] = useState(false);
	const [billingAddressInfo, setBillingAddressInfo] = useState({
		companyName: '',
		orderPersonFirstName: '',
		orderPersonLastName: '',
		streetLine1: '',
		streetLine2: '',
		zip: '',
		city: '',
		state: '',
		countryCode: '',
		phoneNumber: '',
		emailAddress: '',
	});
	const [shippingAddressInfo, setShippingAddressInfo] = useState({
		companyName: '',
		orderPersonFirstName: '',
		orderPersonLastName: '',
		streetLine1: '',
		streetLine2: '',
		zip: '',
		city: '',
		state: '',
		countryCode: '',
		phoneNumber: '',
		emailAddress: '',
	});
	const [shippingSameAsBilling, setShippingSameAsBilling] = useState(true);
	const [taxNumber, setTaxNumber] = useState('');
	const [countryName, setCountryName] = useState('');
	const [selectedPageIndex, setSelectedPageIndex] = useState(0);
	const [orderErrorSnackbarOpen, setOrderErrorSnackbarOpen] = useState(false);
	const [generalErrorSnackbarOpen, setGeneralErrorSnackbarOpen] = useState(false);
	const [selectedCarePackage, setSelectedCarePackage] = useState(gobeCareEnums[1]);

	const [totalPrice, setTotalPrice] = useState(0);
	const [unitPrice, setUnitPrice] = useState(0);
	const [totalUnitPrice, setTotalUnitPrice] = useState(0);
	const [shippingCost, setShippingCost] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [carePackagePriceSum, setCarePackagePriceSum] = useState(0);

	const [createOrder] = useMutation(CREATE_ORDER);

	const { error, data } = useQuery(START_QUERY, {
		variables: {
			input: {
				currencyCode: currency,
			},
		},
	});

	const currenciesArr = data?.currencies;

	const carePackageOptions = useMemo(() => {
		const prices = data?.prices;
		return {
			NONE: {
				features: minFeatures,
				title: <FormattedMessage id="noPackageTitle" defaultMessage="No package" />,
				price: prices?.find(priceObj => priceObj.productName === 'GobeCarePackageNone')
					?.price,
			},
			GOBE_CARE: {
				features: minFeatures.concat(standardFeatures),
				title: <FormattedMessage id="gobeCareTitle" defaultMessage="GoBe Care" />,
				price: prices?.find(priceObj => priceObj.productName === 'GobeCarePackageGobeCare')
					?.price,
			},
			GOBE_CARE_PLUS: {
				features: minFeatures.concat(standardFeatures).concat(plusFeatures),
				title: <FormattedMessage id="gobeCarePlusTitle" defaultMessage="GoBe Care+" />,
				price: prices?.find(
					priceObj => priceObj.productName === 'GobeCarePackageGobeCarePlus'
				)?.price,
			},
		};
	}, [data?.prices]);

	const carePackageDisplayName = useMemo(() => {
		return selectedCarePackage.toString() !== 'NONE'
			? carePackageOptions[selectedCarePackage]?.title || ''
			: null;
	}, [selectedCarePackage, carePackageOptions]);

	const { error: estimateError, data: estimateData } = useQuery(ESTIMATE_QUERY, {
		variables: {
			input: {
				productName: productName,
				units: units,
				currencyCode: currency,
				shippingCountryCode:
					billingAddressInfo.countryCode !== '' ? billingAddressInfo.countryCode : 'DK',
				promoCode: promoCode,
				gobeCarePackage: selectedCarePackage,
			},
		},
	});

	const promoCodeErrorMessage = useMemo(() => {
		const isCodeValid = promoCode !== '' && estimateData?.costEstimate?.isDiscountCodeValid;

		if (isCodeValid) return null;

		if (!isCodeValid && promoCode === '') return null;

		return 'Discount code not valid';
	}, [promoCode, estimateData?.costEstimate?.isDiscountCodeValid]);

	useEffect(() => {
		if (estimateData) {
			setTotalPrice(estimateData?.costEstimate?.totalPrice);
			setUnitPrice(estimateData?.costEstimate?.unitPrice);
			setTotalUnitPrice(estimateData?.costEstimate?.unitSum);
			setShippingCost(estimateData?.costEstimate?.shippingCost);
			setDiscount(estimateData?.costEstimate?.discount);
			setCarePackagePriceSum(estimateData?.costEstimate?.gobeCarePackagePriceSum);
		}
	}, [estimateData]);

	const disableNextPageButton = useMemo(() => {
		if (units >= 1) {
			return false;
		}
		return true;
	}, [units]);

	useEffect(() => {
		if (estimateError || error) {
			setGeneralErrorSnackbarOpen(true);
		}
	}, [estimateError, error]);

	const handleApplyPromoCode = useCallback(promoCode => {
		setPromoCode(promoCode);
	}, []);

	const handleGoToNextPage = useCallback(() => {
		setSelectedPageIndex(selectedPageIndex + 1);
	}, [selectedPageIndex]);

	const handleGoToPreviousPage = useCallback(
		pageIndex => {
			if (steps.length === selectedPageIndex + 1) {
				return;
			}
			if (pageIndex < selectedPageIndex) {
				setSelectedPageIndex(pageIndex);
			}
		},
		[selectedPageIndex]
	);

	const handleSelectPackage = useCallback(packageEnum => {
		setSelectedCarePackage(packageEnum);
	}, []);

	const handleCreateOrder = useCallback(() => {
		createOrder({
			variables: {
				input: {
					productName: productName,
					units: units,
					currencyCode: currency,
					shippingCountryCode: shippingSameAsBilling
						? billingAddressInfo.countryCode
						: shippingAddressInfo.countryCode,
					gobeCarePackage: selectedCarePackage,
					taxNumber: taxNumber,
					promoCode: promoCode,
					shippingAddress: billingAddressInfo,
					billingAddress: shippingSameAsBilling
						? billingAddressInfo
						: shippingAddressInfo,
				},
			},
		})
			.then(() => {
				handleGoToNextPage();
			})
			.catch(e => {
				console.log('error', e);
				setOrderErrorSnackbarOpen(true);
			});
	}, [
		billingAddressInfo,
		shippingAddressInfo,
		units,
		productName,
		handleGoToNextPage,
		currency,
		promoCode,
		taxNumber,
		unitPrice,
	]);

	const steps = [
		{
			id: 'chooseProducts',
			label: <FormattedMessage id="chooseProducts" defaultMessage="Choose products" />,
			renderContent: () => (
				<ChooseProductsPage
					unitPrice={unitPrice}
					setCurrency={setCurrency}
					currency={currency}
					currencyOptions={currenciesArr}
					carePackageEnums={gobeCareEnums}
					carePackageOptions={carePackageOptions}
					selectedCarePackage={selectedCarePackage}
					onSelect={handleSelectPackage}
				/>
			),
			footer: {
				onNextClick: handleGoToNextPage,
				hideBackButton: true,
			},
		},
		{
			id: 'billing',
			label: <FormattedMessage id="billing" defaultMessage="Billing" />,
			renderContent: () => (
				<BillingPage
					unitPrice={unitPrice}
					setCurrency={setCurrency}
					currency={currency}
					addressInfo={billingAddressInfo}
					onAddressChange={setBillingAddressInfo}
					setIsFormValid={setIsBillingFormikValid}
					currencyOptions={currenciesArr}
					taxNumber={taxNumber}
					onTaxNumberChange={setTaxNumber}
					setCountryName={setCountryName}
					onGoToPreviousPage={handleGoToPreviousPage}
				/>
			),
			footer: {
				disableNextButton: !isBillingFormikValid,
				onNextClick: handleGoToNextPage,
				onBackClick: handleGoToPreviousPage,
			},
		},
		{
			id: 'shipping',
			label: <FormattedMessage id="shipping" defaultMessage="Shipping" />,
			renderContent: () => (
				<ShippingPage
					unitPrice={unitPrice}
					setCurrency={setCurrency}
					currency={currency}
					billingAddressInfo={billingAddressInfo}
					shippingAddressInfo={shippingAddressInfo}
					onAddressChange={setShippingAddressInfo}
					setIsFormValid={setIsShippingFormikValid}
					currencyOptions={currenciesArr}
					shippingSameAsBilling={shippingSameAsBilling}
					onSameAsBillingChange={setShippingSameAsBilling}
					setCountryName={setCountryName}
					onGoToPreviousPage={handleGoToPreviousPage}
				/>
			),
			footer: {
				disableNextButton: !isShippingFormikValid,
				onNextClick: handleGoToNextPage,
				onBackClick: handleGoToPreviousPage,
			},
		},
		{
			id: 'shoppingbag',
			label: <FormattedMessage id="shoppingBag" defaultMessage="Shopping bag" />,
			renderContent: () => (
				<ShoppingBagPage
					units={units}
					setUnits={setUnits}
					totalPrice={totalPrice}
					shippingPrice={shippingCost}
					totalUnitPrice={totalUnitPrice}
					setCurrency={setCurrency}
					currency={currency}
					promoCode={promoCode}
					setPromoCode={setPromoCode}
					onPromoCodeApply={handleApplyPromoCode}
					discount={discount}
					currencyOptions={currenciesArr}
					promoCodeErrorMessage={promoCodeErrorMessage}
					onGoToPreviousPage={handleGoToPreviousPage}
					carePackagePriceSum={carePackagePriceSum}
					carePackageDisplayName={carePackageDisplayName}
					selectedCarePackage={selectedCarePackage}
				/>
			),
			footer: {
				onNextClick: handleGoToNextPage,
				onBackClick: handleGoToPreviousPage,
				nextButtonLabel: (
					<FormattedMessage id="reviewOrder" defaultMessage="Review order" />
				),
				disableNextButton: disableNextPageButton,
			},
		},
		{
			id: 'summary',
			label: <FormattedMessage id="summary" defaultMessage="Summary" />,
			renderContent: () => (
				<SummaryPage
					units={units}
					totalPrice={totalPrice}
					shippingPrice={shippingCost}
					totalUnitPrice={totalUnitPrice}
					onCreateOrder={handleCreateOrder}
					billingInfo={billingAddressInfo}
					shippingInfo={shippingAddressInfo}
					taxNumber={taxNumber}
					currency={currency}
					discount={discount}
					currencyOptions={currenciesArr}
					countryName={countryName}
					onGoToPreviousPage={handleGoToPreviousPage}
					carePackageDisplayName={carePackageDisplayName}
					carePackagePriceSum={carePackagePriceSum}
				/>
			),
			footer: {
				onNextClick: handleCreateOrder,
				onBackClick: handleGoToPreviousPage,
				nextButtonLabel: (
					<FormattedMessage id="confirmOrder" defaultMessage="Confirm order" />
				),
				disableNextButton: disableNextPageButton,
			},
		},
		{
			id: 'orderReceived',
			label: '',
			renderContent: () => <OrderReceivedPage setSelectedPlan={setSelectedPlan} />,
			hideFooter: true,
		},
	];
	return (
		<>
			<MainContent
				title="GoBe Webshop"
				renderContent={() => {
					return (
						<Steps
							steps={steps}
							selectedPageIndex={selectedPageIndex}
							onGoToPreviousPage={handleGoToPreviousPage}
						/>
					);
				}}
			/>

			<Snackbar
				open={orderErrorSnackbarOpen}
				onClose={() => setOrderErrorSnackbarOpen(false)}
				message={'The order could not be sent. Try again shortly.'}
			/>
			<Snackbar
				open={generalErrorSnackbarOpen}
				onClose={() => setGeneralErrorSnackbarOpen(false)}
				message={'An error occurred.'}
			/>
		</>
	);
};
