import React, { useState, useEffect } from 'react';
import {
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonLabel,
	IonModal,
	IonGrid,
	IonList,
	IonFab,
	IonFabButton,
	IonItem,
	IonListHeader,
} from '@ionic/react';
import { trash, add } from 'ionicons/icons';
import FormInputListItem from '../FormInputListItem/FormInputListItem';
import DeleteModal from '../DeleteModal/DeleteModal';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../Authentication/Authenticated';
import { useForm } from 'react-hook-form';
import { publish } from '../../actions/publish';
import Tooltip from 'react-tooltip-lite';

import classes from './RobotGroups.module.css';

import Messages from './RobotGroups.messages';
import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';

interface EditGroupModalProps {
	orgId: string;
	initialGroup: any;
	onDismiss: () => void;
	onRemove: () => void;
}

const EditGroupModal: React.FC<EditGroupModalProps> = (props: any) => {
	const { intl, deviceGroups } = props;
	const { deviceGroupsByOrganizationId } = deviceGroups;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);
	const deviceGroupsState = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId
	);
	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);

	const [selectedGroup, setSelectedGroup] = useState<any>(props.initialGroup);
	const [deleteOpen, setDeleteOpen] = useState(false);

	const { handleSubmit, control, errors, setError } = useForm();

	useEffect(() => {
		if (!props.initialGroup) return;
		setSelectedGroup(
			deviceGroupsByOrganizationId[props.initialGroup.orgId][props.initialGroup.deviceGroupId]
		);
	}, [deviceGroupsByOrganizationId, props.initialGroup]);

	const isDeviceGroupNameTaken = (groupName: string) => {
		if (deviceGroupsState[selectedOrganizationId]) {
			let userGroupsByOrg = deviceGroupsState[selectedOrganizationId];
			let isTaken = false;
			Object.values(userGroupsByOrg).forEach(element => {
				if (element.name === groupName) isTaken = true;
			});
			return isTaken;
		} else return;
	};

	const onSave = handleSubmit(data => {
		data.name = data.name.trim();
		if (data.name !== selectedGroup.name) {
			if (isDeviceGroupNameTaken(data.name)) {
				setError('nameUnique', {
					type: 'manual',
					message: 'error',
				});
				return;
			}
		}
		if (data.name !== selectedGroup.name) {
			publish(`microservice/${props.orgId}/${encodedUser}/updateDeviceGroupInfo`, {
				data: {
					device_group_id: selectedGroup.deviceGroupId,
					name: data.name,
				},
				requestId: 'updateDeviceGroup',
			});
		}
		// let addIds: string[] = [],
		// 	removeIds: string[] = [];
		// Check addable devicesIds
		// if (data.devicesIds) {
		// 	data.devicesIds.forEach((d: any) => {
		// 		if (
		// 			selectedGroup.devicesIds === null ||
		// 			selectedGroup.devicesIds.findIndex((id: string) => id === d.value) < 0
		// 		) {
		// 			addIds.push(d.value);
		// 		}
		// 	});
		// }
		// // Check removable devicesIds
		// if (selectedGroup.devicesIds) {
		// 	selectedGroup.devicesIds.forEach((id: string) => {
		// 		if (
		// 			data.devicesIds === null ||
		// 			data.devicesIds.findIndex((d: any) => d.value === id) < 0
		// 		) {
		// 			removeIds.push(id);
		// 		}
		// 	});
		// }
		// if (addIds.length > 0 || removeIds.length > 0) {
		// 	publish(`microservice/${props.orgId}/${encodedUser}/updateUserGroupDevices`, {
		// 		data: {
		// 			deviceGroupId: selectedGroup.deviceGroupId,
		// 			add: addIds,
		// 			remove: removeIds,
		// 		},
		// 		requestId: 'updateDeviceGroupDevices',
		// 	});
		// }
		props.onDismiss();
	});
	const onCancel = () => {
		props.onDismiss();
	};

	const onDeleteConfirm = () => {
		publish(`microservice/${props.orgId}/${encodedUser}/deleteDeviceGroup`, {
			data: {
				deviceGroupId: selectedGroup.deviceGroupId,
			},
			requestId: 'deleteDeviceGroupId',
		});
		setDeleteOpen(false);
		props.onRemove();
	};

	return (
		<>
			<IonModal
				cssClass={classes.editModal}
				isOpen={props.isOpen}
				swipeToClose
				show-backdrop
				onDidDismiss={props.onDismiss}
			>
				<form onSubmit={onSave} className={classes.addForm}>
					<IonFab vertical="top" horizontal="start" className={classes.deleteFab}>
						<Tooltip
							direction="down"
							content={
								<FormattedMessage
									{...Messages.editGroupModal.deleteTitle}
									values={{ item: 'Group' }}
								/>
							}
						>
							<IonFabButton size="small" onClick={() => setDeleteOpen(true)}>
								<IonIcon icon={trash} />
							</IonFabButton>
						</Tooltip>
					</IonFab>
					<IonGrid className={classNames(classes.modalContent, classes.editGrid)}>
						<IonRow>
							<IonList className={classes.header}>
								<IonListHeader className={classes.modalHeader} lines="none">
									<div className={classes.headerContainer}>
										<IonLabel color="primary" className={classes.headerTitle}>
											<FormattedMessage {...Messages.edit} />
										</IonLabel>
									</div>
									<IonLabel className={classes.headerHint}>
										{selectedGroup?.name}
									</IonLabel>
								</IonListHeader>
							</IonList>
						</IonRow>
						<IonRow className={classes.editableContent}>
							<IonList>
								<IonItem className={classes.addUsersItem} lines="none">
									<IonGrid>
										<IonRow>
											<IonButton
												expand="block"
												size="large"
												fill="outline"
												onClick={() =>
													props.setParameter(
														'deviceGroups',
														'CHANGE_ADD_DEVICES_TO_GROUP_STATE',
														true
													)
												}
											>
												<IonIcon size="small" icon={add} />
												<FormattedMessage
													{...Messages.editGroupModal.addRobots}
												/>
											</IonButton>
										</IonRow>
										{/* {addRobotsCount ? (
											<IonRow className={classes.addedRow}>
												<IonLabel
													className={classes.addedLb}
													color="primary"
												>
													<IonIcon
														size="small"
														icon={checkmarkCircleOutline}
													/>
													<FormattedMessage
														{...Messages.robotsSelected}
														values={{ count: addRobotsCount }}
													/>
												</IonLabel>
											</IonRow>
										) : null} */}
									</IonGrid>
								</IonItem>
								<FormInputListItem
									type="text"
									name="name"
									label={intl.formatMessage({
										id: 'UserGroups.name',
									})}
									defaultValue={selectedGroup ? selectedGroup.name : ''}
									control={control}
									rules={{ required: true }}
									required
									errorMsg={
										errors.name
											? intl.formatMessage({
													id: 'DeviceGroupsPage.nameError',
											  })
											: errors.nameUnique
											? intl.formatMessage({
													id: 'DeviceGroupsPage.nameNotUnique',
											  })
											: ''
									}
								/>
								{selectedGroup ? (
									<IonItem lines="none">
										<IonLabel className={classes.hasRobotsLb}>
											<FormattedMessage
												{...Messages.editGroupModal.hasRobots}
												values={{
													number: selectedGroup.devicesIds?.length || 0,
												}}
											/>
										</IonLabel>
									</IonItem>
								) : null}
							</IonList>
						</IonRow>
						<IonRow className={classes.btnRow}>
							<IonCol>
								<IonButton
									className={classes.cancelBtn}
									expand="block"
									shape="round"
									size="large"
									fill="outline"
									onClick={onCancel}
								>
									{intl.formatMessage({
										id: 'cancel',
									})}
								</IonButton>
							</IonCol>
							<IonCol className={classes.submitCol}>
								<IonButton
									expand="block"
									shape="round"
									type="submit"
									size="large"
									fill="outline"
								>
									{intl.formatMessage({
										id: 'AccountManagementPage.save',
									})}
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</form>
			</IonModal>
			<DeleteModal
				isOpen={deleteOpen}
				title={
					<FormattedMessage
						{...Messages.editGroupModal.deleteTitle}
						values={{ item: 'Group' }}
					/>
				}
				onConfirm={onDeleteConfirm}
				onDismiss={() => setDeleteOpen(false)}
				itemName={selectedGroup?.name + ' Group'}
			/>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	userGroups: state.userGroupsState,
	deviceGroups: state.deviceGroupsState,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(EditGroupModal), 'EditGroupModal')
);
