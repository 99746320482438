import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'RobotsPage.transfer',
		defaultMessage: 'Transfer Robot',
		description: 'Transfer Robot',
	},
	cancel: {
		id: 'RobotsPage.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel',
	},
	save: {
		id: 'RobotsPage.save',
		defaultMessage: 'Add Robots',
		description: 'Add Robots',
	},
	from: {
		id: 'RobotsPage.from',
		defaultMessage: 'From :',
		description: 'From :',
	},
	toOrganization: {
		id: 'RobotsPage.toOrganization',
		defaultMessage: 'To Organization',
		description: 'To Organization',
	},
});
