import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { PurchaseTemplate } from '../templates/PurchaseTemplate';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
	BASEVALIDATIONS,
	CONTACTPERSONVALIDATIONS,
	TAXNUMBERVALIDATION,
} from '../validationSchema';
import { HeaderText } from '../atoms/HeaderText';
import { AddressForm } from '../organisms/AddressForm';
import { OrganizationForm } from '../organisms/OrganizationForm';
import _ from 'lodash';

const validationSchema = yup.object({
	...BASEVALIDATIONS,
	...TAXNUMBERVALIDATION,
	...CONTACTPERSONVALIDATIONS,
});

export const BillingPage = ({
	setCurrency,
	unitPrice,
	currency,
	setIsFormValid,
	currencyOptions,
	addressInfo,
	onAddressChange,
	taxNumber,
	onTaxNumberChange,
}) => {
	const formik = useFormik({
		initialValues: { ...addressInfo, taxNumber },
		validationSchema: validationSchema,
		onSubmit: () => {},
	});

	useEffect(() => {
		validationSchema
			.validate(formik.values)
			.then(formValues => {
				const addressObjValues = _.pick(formValues, [
					'companyName',
					'orderPersonFirstName',
					'orderPersonLastName',
					'streetLine1',
					'streetLine2',
					'zip',
					'city',
					'state',
					'countryCode',
					'phoneNumber',
					'emailAddress',
				]);
				const taxNumberValue = _.get(formValues, 'taxNumber');

				onAddressChange(addressObjValues);
				onTaxNumberChange(taxNumberValue);
				setIsFormValid(true);
			})
			.catch(e => {
				console.log('errors billing', e);
				setIsFormValid(false);
			});
	}, [validationSchema, formik.values]);

	return (
		<PurchaseTemplate
			setCurrency={setCurrency}
			totalPrice={unitPrice}
			currency={currency}
			currencyOptions={currencyOptions}
		>
			<HeaderText variant="h6">
				<FormattedMessage id="billingInformation" defaultMessage="Billing information" />:
			</HeaderText>
			<OrganizationForm formik={formik} />
			<HeaderText variant="h6">
				<FormattedMessage id="address" defaultMessage="Address" />:
			</HeaderText>
			<AddressForm formik={formik} />
		</PurchaseTemplate>
	);
};
