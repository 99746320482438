import React, { FC, useState, Fragment, useEffect, useCallback } from 'react';
import {
	IonButton,
	IonIcon,
	IonLabel,
	IonSegment,
	IonSegmentButton,
	IonGrid,
	IonRow,
	IonCol,
} from '@ionic/react';
import {
	closeCircle,
	add,
	checkmarkCircle,
	checkmarkCircleOutline,
	reader,
	headset,
	barcode,
	document,
	key,
	bugOutline,
	checkmarkDoneCircle,
} from 'ionicons/icons';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import OrganizationForm from '../OrganizationForm/OrganizationForm';
import OrganizationContactForm from '../OrganizationForm/OrganizationContactForm';
import OrganizationSSOForm from '../OrganizationForm/OrganizationSSOForm';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './OrganizationInfo.module.css';
import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';

import Messages from './OrganizationInfo.messages';
import OrganizationAgreementForm from '../OrganizationForm/OrganizationAgreementForm';

const tabs = ['details', 'contact', 'agreements', 'goBeCare', 'sso'];

interface OrganizationInfoProps {
	filterValues: any;
	orgId: string;
}

const OrganizationInfo: FC<OrganizationInfoProps> = (props: any) => {
	const { organization, orgId } = props;

	const [editable, setEditable] = useState(false);
	const [saved, setSaved] = useState(false);
	const [selectedOrg, setSelectedOrg] = useState(organization.organizations[orgId]);
	const [selectedSegment, setSelectedSegment] = useState<string>(tabs[0]);
	const [isTestSandboxSSOButtonVisible, setIsTestSandboxSSOButtonVisible] = useState(false);
	const [shouldTriggerTestSandboxSSO, setShouldTriggerTestSandboxSSO] = useState(false);
	const [isApplySandboxSSOButtonVisible, setIsApplySandboxSSOButtonVisible] = useState(false);
	const [shouldTriggerApplySandboxSSO, setShouldTriggerApplySandboxSSO] = useState(false);
	const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);

	useEffect(() => {
		const allOrganizations = Object.values(organization.organizations); //Object.values(user.organizations);
		const org: any = allOrganizations.find((org: any) => org.orgId === orgId);
		setSelectedOrg({ ...org });
	}, [orgId, organization]);

	useEffect(() => {
		if (editable) {
			if (
				isTestSandboxSSOButtonVisible ||
				isApplySandboxSSOButtonVisible ||
				'sso' === selectedSegment
			) {
				setIsSaveButtonVisible(false);
			} else {
				setIsSaveButtonVisible(true);
			}
		}
	}, [editable, isTestSandboxSSOButtonVisible, isApplySandboxSSOButtonVisible]);

	const openAddOrg = async () => {
		props.setParameter('organizations', 'CHANGE_ADD_NEW_ORGANIZATION_STATE', true);
	};

	const onSegmentChange = (value: any) => {
		setSelectedSegment(value);
		setEditable(false);
	};

	const onEdit = (data: any) => {
		setEditable(true);
		setIsTestSandboxSSOButtonVisible(false);
		setShouldTriggerApplySandboxSSO(false);
	};
	const onSave = () => {
		if ('sso' !== selectedSegment) {
			setEditable(false);
		}
		setSaved(true);
		setTimeout(() => {
			setSaved(false);
		}, 5000);
	};
	const onCancelEdit = () => {
		setEditable(false);
		setSaved(false);
		setIsTestSandboxSSOButtonVisible(false);
		setIsApplySandboxSSOButtonVisible(false);
	};
	const onTestSandboxSSO = () => {
		setShouldTriggerTestSandboxSSO(true);
		setIsTestSandboxSSOButtonVisible(false);
		setIsApplySandboxSSOButtonVisible(true);
	};
	const onApplySandboxSSO = () => {
		setShouldTriggerApplySandboxSSO(true);
	};

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	const [organizationNotification, setOrgnizationNotification] = useState<any>({});

	const handleOrganizationNotification = useCallback(notification => {
		setOrgnizationNotification(notification);
		if (notification?.status !== 'success') {
			onCancelEdit();
		}
		setTimeout(() => {
			setOrgnizationNotification({});
		}, 5000);
	}, []);

	const handleStorageListener = useCallback(event => {
		if (event.storageArea != localStorage) return;
		if (event.key === 'testSandboxResult') {
			handleOrganizationNotification(JSON.parse(event?.newValue || '{}'));
		}
	}, []);

	const handleCustomEventListener = useCallback(event => {
		if (event.type === 'updateOrgSSOSandboxInfo') {
			onTestSandboxSSO();
		}
		handleOrganizationNotification((event as CustomEvent)?.detail || {});
	}, []);

	useEffect(() => {
		window.addEventListener('applySSOSandboxToLive', handleCustomEventListener);
		window.addEventListener('updateOrgSSOSandboxInfo', handleCustomEventListener);
		//window.addEventListener('verifyDomain', handleCustomEventListener);
		window.addEventListener('storage', handleStorageListener);

		return () => {
			window.removeEventListener('applySSOSandboxToLive', handleCustomEventListener);
			window.removeEventListener('updateOrgSSOSandboxInfo', handleCustomEventListener);
			//window.removeEventListener('verifyDomain', handleCustomEventListener);
			window.removeEventListener('storage', handleStorageListener);
		};
	}, [handleOrganizationNotification]);

	useEffect(() => {
		if (saved && 'sso' !== selectedSegment) {
			handleOrganizationNotification({
				status: 'success',
				message: <FormattedMessage {...Messages.saved} />,
			});
		}
	}, [saved]);

	return (
		<Fragment>
			<div className={classes.leftSide}>
				{selectedOrg.orgType !== 'customer' ? (
					<div className={classes.newBtnContainer}>
						<IonLabel color="primary" className={classes.newLb}>
							<FormattedMessage {...Messages.new} />
						</IonLabel>
						<IonButton className={classes.newBtn} shape="round" onClick={openAddOrg}>
							<IonIcon
								className={classes.addIcon}
								slot="icon-only"
								size="small"
								icon={add}
							/>
						</IonButton>
					</div>
				) : null}
			</div>
			<div className={classes.rightSide}>
				<div className={classes.header}>
					<div className={classes.editBtnContainer}>
						<IonButton
							className={
								editable
									? classNames(classes.editBtn, classes.editable)
									: classes.editBtn
							}
							shape="round"
							onClick={onEdit}
							disabled={editable}
							hidden={
								selectedSegment === 'goBeCare' || selectedSegment === 'agreement'
							}
						>
							<IonIcon src="./assets/img/edit.svg" />
						</IonButton>
						{selectedSegment === 'sso' && isTestSandboxSSOButtonVisible && (
							<div
								className={
									editable
										? classes.formBtns
										: classNames(classes.formBtns, classes.hidden)
								}
							>
								<IonIcon
									slot="end"
									size="large"
									icon={bugOutline}
									onClick={onTestSandboxSSO}
								/>
							</div>
						)}
						{selectedSegment === 'sso' && isApplySandboxSSOButtonVisible && (
							<div
								className={
									editable
										? classes.formBtns
										: classNames(classes.formBtns, classes.hidden)
								}
							>
								<IonIcon
									slot="end"
									size="large"
									icon={checkmarkDoneCircle}
									onClick={onApplySandboxSSO}
								/>
							</div>
						)}
						<div
							className={
								editable
									? classes.formBtns
									: classNames(classes.formBtns, classes.hidden)
							}
							hidden={
								selectedSegment === 'goBeCare' || selectedSegment === 'agreement'
							}
						>
							<IonIcon
								slot="end"
								size="large"
								icon={checkmarkCircle}
								onClick={onSave}
								hidden={!isSaveButtonVisible}
							/>
							<IonIcon
								slot="end"
								size="large"
								icon={closeCircle}
								onClick={onCancelEdit}
							/>
						</div>
						{organizationNotification && (
							<>
								{organizationNotification?.status == 'success' && (
									<IonIcon
										slot="end"
										size="small"
										color="primary"
										icon={checkmarkCircleOutline}
									/>
								)}
								<IonLabel
									className={
										organizationNotification?.status == 'success'
											? classes.savedLb
											: classes.saveErrorLb
									}
								>
									{organizationNotification?.message}
								</IonLabel>
							</>
						)}
					</div>
					<IonSegment
						className={classes.tabContainer}
						mode="ios"
						onIonChange={(e: CustomEvent) => onSegmentChange(e.detail.value)}
						value={selectedSegment}
					>
						<IonSegmentButton
							value="details"
							layout="icon-start"
							className={classes.detailsBtn}
						>
							<IonIcon className={classes.iconMargin} size="small" icon={reader} />
							<IonLabel>
								<FormattedMessage {...Messages.details} />
							</IonLabel>
						</IonSegmentButton>
						<IonSegmentButton value="contact" layout="icon-start">
							<IonIcon className={classes.iconMargin} size="small" icon={headset} />
							<IonLabel>
								<FormattedMessage {...Messages.contact} />
							</IonLabel>
						</IonSegmentButton>
						<IonSegmentButton value="agreement" layout="icon-start">
							<IonIcon className={classes.iconMargin} size="small" icon={document} />
							<IonLabel>
								<FormattedMessage {...Messages.agreement} />
							</IonLabel>
						</IonSegmentButton>
						{spinoutType === 'gobe' && (
							<IonSegmentButton
								value="goBeCare"
								layout="icon-start"
								className={classes.goBeCareBtn}
								disabled
							>
								<IonIcon
									className={classes.iconMargin}
									size="small"
									icon={barcode}
								/>
								<IonLabel>
									<FormattedMessage {...Messages.goBeCare} />
								</IonLabel>
							</IonSegmentButton>
						)}
						<IonSegmentButton
							value="sso"
							layout="icon-start"
							className={classes.ssoBtn}
						>
							<IonIcon className={classes.iconMargin} size="small" icon={key} />
							<IonLabel>
								<FormattedMessage {...Messages.sso} />
							</IonLabel>
						</IonSegmentButton>
					</IonSegment>
				</div>
				<IonGrid className={classes.segmentContent}>
					<IonRow className={selectedSegment !== 'details' ? classes.hidden : ''}>
						<OrganizationForm
							organization={selectedOrg}
							isEditable={editable}
							saved={saved}
							segment={selectedSegment}
						/>
					</IonRow>
					<IonRow className={selectedSegment !== 'contact' ? classes.hidden : ''}>
						<OrganizationContactForm
							organization={selectedOrg}
							isEditable={editable}
							saved={saved}
							segment={selectedSegment}
						/>
					</IonRow>
					<IonRow className={selectedSegment !== 'agreement' ? classes.hidden : ''}>
						<OrganizationAgreementForm
							organization={selectedOrg}
							isEditable={editable}
							saved={saved}
							segment={selectedSegment}
						/>
					</IonRow>
					<IonRow className={selectedSegment !== 'goBeCare' ? classes.hidden : ''}>
						<IonGrid>
							<IonRow className={classes.goBeHeader}>
								<IonCol>
									<IonLabel>
										<FormattedMessage {...Messages.robotName} />
									</IonLabel>
								</IonCol>
								<IonCol>
									<IonLabel>
										<FormattedMessage {...Messages.serialNr} />
									</IonLabel>
								</IonCol>
								<IonCol>
									<IonLabel>
										<FormattedMessage {...Messages.activationDate} />
									</IonLabel>
								</IonCol>
								<IonCol>
									<IonLabel>
										<FormattedMessage {...Messages.timeLeft} />
									</IonLabel>
								</IonCol>
								<IonCol>
									<IonLabel>
										<FormattedMessage {...Messages.actions} />
									</IonLabel>
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonRow>
					<IonRow className={selectedSegment !== 'sso' ? classes.hidden : ''}>
						<OrganizationSSOForm
							organization={selectedOrg}
							isEditable={editable}
							saved={saved}
							setEditable={setEditable}
							segment={selectedSegment}
							setIsTestSandboxSSOButtonVisible={setIsTestSandboxSSOButtonVisible}
							shouldTriggerTestSandboxSSO={shouldTriggerTestSandboxSSO}
							setShouldTriggerTestSandboxSSO={setShouldTriggerTestSandboxSSO}
							setIsApplySandboxSSOButtonVisible={setIsApplySandboxSSOButtonVisible}
							shouldTriggerApplySandboxSSO={shouldTriggerApplySandboxSSO}
							key={selectedOrg}
						/>
					</IonRow>
				</IonGrid>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	organization: state.organizationState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(OrganizationInfo), 'Organizations'));
