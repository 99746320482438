import React, { useEffect, useRef, useState, useCallback, useLayoutEffect } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import {
	SET_ROASTER_LARGE_ICONS,
	TOGGLE_SORT_DIRECTION,
	SET_SORT_METHOD,
	SET_SEARCH_TERM,
} from '../../actions/types';
import { setParameter } from '../../actions/setParam';
import { ConnectedProps } from 'react-redux';
import { AppRootState, useTypedSelector } from '../../../../../reducers';
import GoBeCardIcon from './goBeCardIcon';
import { useHistory } from 'react-router-dom';
import SafetyAgreements from './safetyAgreemnets';
import { b64EncodeUnicode } from '../../../../../utils/encoding';
import { publish } from './../../../../../actions/publish';
import { Account } from '../../../../../reducers/accountReducers';
import { Device, DeviceGroup } from '../../../../../types/types';
import { debounce } from 'lodash';
import { SortMethod } from '../../reducers/roasterReducers';
import useFilteredRosterDevicesIds, {
	FilterRequiredDeviceInfo,
} from './useFilteredRosterDevicesIds';
import { getDeviceCurrentState, IDeviceCurrentState } from '../../utils/statusConverter';
import classNames from 'classnames';
import _ from 'lodash';
import GoBeListIcon from './goBeListIcon';
import useThrottledSelector from '../../utils/useThrottledSelector';

const reduxConnector = connect(
	(state: AppRootState) => {
		const sort = {
			isSortedAscending: state.goBeState.roasterState.isSortedAscending as boolean,
			sortMethod: state.goBeState.roasterState.sortMethod as SortMethod,
		};

		return {
			searchTerm: state.goBeState.roasterState.searchTerm as string,
			sort,
			isGridView: state.goBeState.roasterState.isGridView as boolean,
			accountState: state.accountState,
			agreements: state.accountState.user.agreements,
			acceptedAgreements: state.accountState.user.acceptedAgreements,
			username: state.accountState.user.username,
			/** If these related data are loading, we wont apply sort/search */
			isRelatedDataLoading: state.deviceGroupsState.loading || state.deviceState.loading,
		};
	},
	{ setParameter }
	// undefined,
	// { pure: true }
);

const deviceGroupsNamesByIdSelector = (state: AppRootState) =>
	(state.deviceGroupsState.items as DeviceGroup[]).reduce(
		(acc, group) => ({ ...acc, [group.deviceGroupId]: group.name }),
		{} as Record<string, string>
	);

const userDevicesByIdSelector = (state: AppRootState) => {
	return ((state.accountState.user as Account).devices as unknown) as Record<
		string,
		Device & { currentState: IDeviceCurrentState }
	>;
};

const devicesCurrentStateByIdSelector = (state: AppRootState) =>
	(state.deviceState.items as Device[]).reduce(
		(acc, device) => ({
			...acc,
			[device.deviceId]: {
				online: device.online,
				currentState: getDeviceCurrentState(device),
			},
		}),
		{} as Record<string, { online: boolean; currentState: IDeviceCurrentState }>
	);

const orgIdPerDeviceIdSelector = (state: AppRootState) => {
	const userDevicesById = (state.accountState.user as Account).devices;
	const devicesById = (state.deviceState.items as Device[]).reduce(
		(acc, device) => ({
			...acc,
			[device.deviceId]: device,
		}),
		{} as Record<string, Device>
	);
	return Object.keys(userDevicesById).reduce((acc, deviceId) => {
		const orgId = userDevicesById[deviceId]?.orgId ?? devicesById[deviceId]?.orgId;
		if (!orgId) {
			console.error(`RoasterPage: Encountered device: ${deviceId} without orgId!?`);
			return acc;
		}
		return { ...acc, [deviceId]: orgId };
	}, {} as Record<string, string>);
};

const userDevicesIdsSelector = (state: AppRootState) =>
	Object.keys((state.accountState.user as Account).devices);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

const Roaster: React.FC<PropsFromRedux> = ({
	searchTerm,
	sort,
	isGridView,
	setParameter,
	accountState,
	agreements,
	acceptedAgreements,
	username,
	isRelatedDataLoading,
}) => {
	const userDevicesById = useTypedSelector(userDevicesByIdSelector, _.isEqual);
	const devicesCurrentStateById = useThrottledSelector(
		devicesCurrentStateByIdSelector,
		_.isEqual,
		5000
	);
	const deviceGroupsNamesById = useTypedSelector(deviceGroupsNamesByIdSelector, _.isEqual);
	const orgIdPerDeviceId = useTypedSelector(orgIdPerDeviceIdSelector, _.isEqual);
	const userDevicesIds = useTypedSelector(userDevicesIdsSelector, _.isEqual);

	const history = useHistory();
	const roasterRef = useRef<HTMLDivElement | null>(null);
	const [agreementsStatus, changeAgreementStatus] = useState<boolean>(false);
	const [acceptedAgreementsStatus, changeAcceptedAgreementStatus] = useState<boolean>(false);

	const filteredDevicesIds = useFilteredRosterDevicesIds({
		sort,
		searchTerm,
		userDevicesById,
		devicesCurrentStateById,
		deviceGroupsNamesById,
		userDevicesIds,
		isRelatedDataLoading,
	});

	const onToTopClick = () => {
		roasterRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	};

	const cancelAgreementClick = () => {
		changeAgreementStatus(false);
	};

	const agreeContinueClick = async () => {
		await publish(
			`microservice/${accountState.user.selectedOrganizationId}/${b64EncodeUnicode(
				accountState.user.username
			)}/acceptAgreements/user`,
			{
				requestId: 'acceptAgreementsId',
				data: {
					language: 'en',
					ip_address: undefined,
					agreements_ids: [
						agreements.find((agreement: any) => agreement.type === 'safety-agreement')
							.id,
					],
				},
			}
		);
		history.push('/gobe/session');
	};

	useEffect(() => {
		publish(`microservice/${b64EncodeUnicode(username)}/getAcceptedAgreements`, {
			requestId: 'getAcceptedAgreementsId',
			data: {},
		});
	}, [username]);

	useEffect(() => {
		let link = document.getElementById('jsd-widget');
		if (link && (link as any).style.display === 'none') {
			(link as any).style.display = 'block';
		}
	}, []);

	useEffect(() => {
		if (acceptedAgreements.find((agreement: any) => agreement.type === 'safety-agreement')) {
			changeAcceptedAgreementStatus(true);
		}
	}, [acceptedAgreements]);

	const renderRosterView = useCallback(() => {
		const RosterViewComponent = isGridView ? RosterCardView : RosterListView;
		return (
			<RosterViewComponent
				devicesIds={filteredDevicesIds}
				orgIdPerDeviceId={orgIdPerDeviceId}
				isAgreementAccepted={acceptedAgreementsStatus}
				onAgreementAcceptanceChanged={changeAgreementStatus}
			/>
		);
	}, [isGridView, filteredDevicesIds, orgIdPerDeviceId, acceptedAgreementsStatus]);

	const onSearch = useCallback(
		(searchTerm: string) => setParameter('searchTerm', SET_SEARCH_TERM, searchTerm),
		[setParameter]
	);

	const onToggleRosterViewType = useCallback(
		() => setParameter('isGridView', SET_ROASTER_LARGE_ICONS, !isGridView),
		[isGridView, setParameter]
	);

	const onChangeSortMethod = useCallback(
		(sortMethod: SortMethod) => setParameter('sortMethod', SET_SORT_METHOD, sortMethod),
		[setParameter]
	);

	const onToggleSortDirection = useCallback(
		// TODO: Implement this with plain dispatch
		() => setParameter('isSortedAscending', TOGGLE_SORT_DIRECTION),
		[setParameter]
	);

	return (
		<div className="roasterContainer" id="roasterContainer" ref={roasterRef}>
			<div className="goBesContainer">
				<div className="goBesShowSetting">
					<div className="goBesTitle">All GoBe Robots</div>
					<div className="searchSettingContainer">
						<DevicesSearchInput value={searchTerm} onChange={onSearch} />
						<RosterViewTypeToggle
							isGridView={isGridView}
							onToggleRosterViewType={onToggleRosterViewType}
						/>
						<SortByDropDown
							config={SortByConfigs}
							sortOptions={sort}
							onSelectSortMethod={onChangeSortMethod}
							onToggleSortDirection={onToggleSortDirection}
						/>
					</div>
				</div>

				<div className="rosterViewContainer">{renderRosterView()}</div>

				{/* <div
					className={isGridView ? 'toTopContainer' : 'displayNone'}
					onClick={onToTopClick}
				>
					<div className="toTopCircle">
						<span>&#8963;</span>
					</div>
				</div> */}
			</div>

			<div className="roasterFooterContainer">
				<div className="borLogoLines" />
				<div className="borLogoWrapper">
					<img src="../assets/images/black-bor-logo.svg" alt="blue-ocean-robotics-logo" />
				</div>
				<div className="borLogoLines" />
			</div>
			<div className={agreementsStatus ? 'agreementContainer' : 'displayNone'}>
				{agreements.length !== 0 ? (
					<SafetyAgreements
						agreement={{
							message: 'Safety Agreements',
							value: '1000',
							description: agreements.find(
								(agreement: any) => agreement.type === 'safety-agreement'
							).content,
						}}
						messageHeight={1000}
					/>
				) : null}
				<div className="safetyButtonsContainer">
					<div className="blackCancel" onClick={() => cancelAgreementClick()}>
						Cancel
					</div>
					<div className="greenAccept" onClick={() => agreeContinueClick()}>
						Agree and Continue
					</div>
				</div>
			</div>
		</div>
	);
};

export default reduxConnector(Roaster);

const deviceGroupsNamesPerDeviceIdSelector = (state: AppRootState) => {
	const deviceGroupsById = state.deviceGroupsState.items.reduce(
		(acc, deviceGroup) => ({ ...acc, [deviceGroup.deviceGroupId]: deviceGroup }),
		{} as Record<string, DeviceGroup>
	);
	return (state.deviceState.items as Device[]).reduce((acc, device) => {
		return {
			...acc,
			[device.deviceId]: (device.deviceGroupsIds ?? [])
				.map(dGId => deviceGroupsById[dGId]?.name)
				.join(', '),
		};
	}, {} as Record<string, string>);
};

function RosterCardView(props: {
	devicesIds: string[];
	orgIdPerDeviceId: Record<string, string>;
	isAgreementAccepted: boolean;
	onAgreementAcceptanceChanged: (isAgreementAccepted: boolean) => void;
}) {
	const deviceGroupsNamesPerDeviceId = useTypedSelector(
		deviceGroupsNamesPerDeviceIdSelector,
		_.isEqual
	);

	return (
		<div className="rosterCardViewContainer">
			{props.devicesIds.map(deviceId => (
				<GoBeCardIcon
					key={deviceId}
					deviceId={deviceId}
					orgId={props.orgIdPerDeviceId[deviceId]}
					acceptedAgreementsStatus={props.isAgreementAccepted}
					changeAgreementStatus={props.onAgreementAcceptanceChanged}
					deviceGroupsNames={deviceGroupsNamesPerDeviceId[deviceId]}
				/>
			))}
		</div>
	);
}

function RosterListView(props: {
	devicesIds: string[];
	orgIdPerDeviceId: Record<string, string>;
	isAgreementAccepted: boolean;
	onAgreementAcceptanceChanged: (isAgreementAccepted: boolean) => void;
}) {
	const deviceGroupsNamesPerDeviceId = useTypedSelector(
		deviceGroupsNamesPerDeviceIdSelector,
		_.isEqual
	);

	return (
		<div className="rosterListViewContainer">
			<div className="rosterListHeader goBesListTitles">
				<div className="rosterListHeaderCell rosterListCellName">GoBe</div>
				<div className="rosterListHeaderCell rosterListCellCharge">Charge</div>
				<div className="rosterListHeaderCell rosterListCellGroup">Group</div>
				<div className="rosterListHeaderCell rosterListCellLocation">Location</div>
				<div className="rosterListHeaderCell rosterListCellLastUse">Last use</div>
				<div className="rosterListHeaderCell rosterListCellStatus">Status</div>
			</div>
			{props.devicesIds.map(deviceId => (
				<GoBeListIcon
					key={deviceId}
					deviceId={deviceId}
					orgId={props.orgIdPerDeviceId[deviceId]}
					acceptedAgreementsStatus={props.isAgreementAccepted}
					changeAgreementStatus={props.onAgreementAcceptanceChanged}
					deviceGroupsNames={deviceGroupsNamesPerDeviceId[deviceId]}
				/>
			))}
		</div>
	);
}

function DevicesSearchInput(props: {
	value?: string | null;
	onChange: (value: string) => void;
	debounce?: number;
}) {
	const inputRef = useRef<HTMLInputElement | null>(null);

	const doSearch = useCallback(
		debounce(
			(searchTerm: string) => {
				props.onChange(searchTerm);
			},
			150,
			{ trailing: true }
		),
		[props.onChange]
	);

	const [_searchTerm, _setSearchTerm] = useState(props.value ?? '');
	useEffect(() => doSearch(_searchTerm), [_searchTerm, doSearch]);

	const clearInput = () => {
		props.onChange(''); // apply the change immediately
		_setSearchTerm('');
	};

	return (
		<div className="searchGoBes">
			<div className="searchIconWrapper">
				<img src="../assets/images/search.svg" alt="" />
			</div>
			<input
				ref={inputRef}
				placeholder="Search robots"
				value={_searchTerm}
				onChange={e => _setSearchTerm(e.target.value)}
			/>
			<div className="searchClearIconButton" onClick={clearInput}>
				<img className="closeIconImage" src="../assets/images/close.svg" alt="" />
			</div>
		</div>
	);
}

function RosterViewTypeToggle(props: { isGridView: boolean; onToggleRosterViewType: () => void }) {
	const { isGridView, onToggleRosterViewType: onClick } = props;
	return (
		<div className="showModelContainer" onClick={onClick}>
			<div className="largeIconWrapper">
				<img
					src={`../assets/images/${!isGridView ? 'largeIcon.svg' : 'listIcon.svg'}`}
					alt=""
				/>
			</div>
			{!isGridView ? 'Card View' : 'List View'}
		</div>
	);
}

const SortByConfigs: { value: SortMethod; label: string; disabled?: boolean }[] = [
	{ value: 'name', label: 'Name' },
	{ value: 'deviceGroup', label: 'Robot Group' },
	{ value: 'availability', label: 'Available' },
	// TODO: FIXME: Implement the sort methods below
	{ value: 'lastUsage', label: 'Recent', disabled: true },
	{ value: 'isFavourite', label: 'Favourite', disabled: true },
];

function SortByDropDown<T extends string>(props: {
	config: { value: T; label: string; disabled?: boolean }[];
	sortOptions: { sortMethod: T; isSortedAscending: boolean };
	onSelectSortMethod: (sortMethod: T) => void;
	onToggleSortDirection: () => void;
}) {
	const [isDropDownVisible, setIsSortDropDownVisible] = useState(false);
	const toggleDropDown = () => setIsSortDropDownVisible(!isDropDownVisible);

	const dropDownRef = useRef<HTMLDivElement | null>(null);
	useLayoutEffect(() => {
		if (isDropDownVisible) dropDownRef.current?.focus({ preventScroll: true });
	}, [isDropDownVisible]);

	const onClickSortMethod = (sortMethod: T) => {
		toggleDropDown();
		props.onSelectSortMethod(sortMethod);
	};

	return (
		<div
			tabIndex={-1}
			ref={dropDownRef}
			className="sortByContainer"
			onBlur={isDropDownVisible ? toggleDropDown : undefined}
		>
			<div className="sortIcon" onClick={props.onToggleSortDirection}>
				<div
					className={classNames('arrowUpWrapper', {
						arrowUpWrapperRotate: !props.sortOptions.isSortedAscending,
					})}
				>
					<img src="../assets/images/arrow-up.svg" alt="sort-direction-indicator" />
				</div>
			</div>
			<div className="sortText" onClick={toggleDropDown}>
				Sort By
				<div
					className={classNames('chevronUpWrapper', {
						chevronUpWrapperRotate: isDropDownVisible,
					})}
				>
					<img src="../assets/images/chevron-down.svg" alt="dropdown-open-state" />
				</div>
			</div>
			<div className={isDropDownVisible ? 'sortDropDown' : 'sortDropDown sortDropDownHide'}>
				{props.config.map(config => (
					<div
						className={classNames('sortDropDownItem', {
							sortDropDownItemSelected: props.sortOptions.sortMethod === config.value,
							sortDropDownItemDisabled: config.disabled,
						})}
						onClick={
							config.disabled ? undefined : () => onClickSortMethod(config.value)
						}
					>
						{config.label}
					</div>
				))}
			</div>
		</div>
	);
}
