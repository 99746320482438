import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import styled from 'styled-components';
import { Svg } from '../../../../components/atoms/Svg';
import { RoundedButtonOutline } from '../../../../components/atoms/RoundedButtonOutline';
import GreyLines from '../../../../assets/images/greyLines.svg';

const StyledPaper = styled(Paper)(({ theme, backgroundPosition, backgroundImageSrc }) => ({
	width: 312,
	height: 355,
	display: 'flex',
	flexDirection: 'column',
	margin: 21,
	position: 'relative',
	boxShadow: theme.shadows[1],
	borderRadius: 10,
	overflow: 'hidden',
	background: 'url(' + backgroundImageSrc + ') no-repeat white',
	backgroundPosition: backgroundPosition,
}));

const Wrapper = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	display: 'flex',
	flexDirection: 'column',
}));

const StyledSvg = styled(Svg)(({ theme }) => ({
	width: 36,
}));

const HeaderWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
	marginBottom: 32,
	paddingLeft: 24,
	paddingRight: 24,
	paddingTop: 24,
}));

const ContentWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	flex: 1,
	paddingRight: 16,
	paddingLeft: 24,
	paddingBottom: 16,
}));

const BodyText = styled(Typography)(({ theme, disabled }) => ({
	color: disabled ? theme.palette.greyPalette.grey3 : theme.palette.greyPalette.grey4,
}));

const HeaderText = styled(Typography)(({ theme, color, disabled }) => ({
	color: disabled ? theme.palette.greyPalette.grey3 : color,
}));

export const Card = ({
	color,
	headerMessage,
	descriptionMessage,
	icon,
	buttonLabel,
	backgroundImageSrc,
	onPlanChange,
	planType,
	disabled,
	backgroundPosition,
}) => {
	return (
		<StyledPaper
			backgroundPosition={backgroundPosition}
			backgroundImageSrc={disabled ? GreyLines : backgroundImageSrc}
		>
			<Wrapper>
				<HeaderWrapper>
					<HeaderText disabled={disabled} color={color} variant="h1">
						{headerMessage}
					</HeaderText>
					<StyledSvg src={icon} />
				</HeaderWrapper>
				<ContentWrapper>
					<BodyText disabled={disabled} variant="body1">
						{/* {descriptionMessage} */}
					</BodyText>
					<RoundedButtonOutline
						disabled={disabled}
						color={color}
						align="flex-end"
						variant="small"
						onClick={() => onPlanChange(planType)}
					>
						{buttonLabel}
					</RoundedButtonOutline>
				</ContentWrapper>
			</Wrapper>
		</StyledPaper>
	);
};
