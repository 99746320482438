import React, { FC } from 'react';
import { IonModal, IonList, IonItem, IonLabel, IonListHeader, IonButton } from '@ionic/react';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../../components/Authentication/Authenticated';

import classes from './ErrorModal.module.css';
import { RouteComponentProps } from 'react-router';

import Messages from './ErrorModal.messages';

interface TokenErrorModalProps extends RouteComponentProps {
	onConfirm: any;
	onDismiss: any;
}

const TokenErrorModal: FC<TokenErrorModalProps> = (props: any) => {
	return (
		<>
            <IonItem lines="none" className={classes.msgContainer}>
                <div className={classes.headerContainer}>
                    <IonLabel className={classes.headerTitle}>
                        <FormattedMessage
                            {...Messages.oops}
                        />
                    </IonLabel>
                    <IonLabel className={classes.msg}>
                        <FormattedMessage
                            {...Messages.msg}
                        />
                    </IonLabel>
                    <IonLabel className={classes.hint}>
                        <FormattedMessage
                            {...Messages.hint}
                        />
                    </IonLabel>
                </div>
            </IonItem>
            <IonItem lines="none" className={classes.btnContainer}>
                <IonButton
                    expand="block"
                    shape="round"
                    size="large"
                    fill="outline"
                    className={classes.okBtn}
                    onClick={props.onConfirm}
                >
                    <FormattedMessage {...Messages.ok} />
                </IonButton>
            </IonItem>
        </>
	);
};

export default injectIntl(isAuthenticated(TokenErrorModal));
