import React, { FC, Fragment, useState } from 'react';
import EditImageModal from '../EditImageModal/EditImageModal';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './RobotEdit.module.css';

import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './RobotEdit.messages';

import { Device } from '../../types/types';
import { useTypedSelector } from '../../reducers';
import { equalityFnc } from '../../utils/conformState';
import RobotCard from './RobotCard';
import RobotActivity from './RobotActivity';
import RobotDetails from './RobotDetails';

const deviceIcons: Record<string, string> = {
	beam: '../../../assets/img/beam-logo-white.svg',
	gobe: '../../../assets/icons/GOBE-ROBOTS-logo-neg.svg',
};

interface RobotEditProps {
	editableDeviceId: string;
	onClose: () => void;
}

const RobotEdit: FC<RobotEditProps> = props => {
	const { editableDeviceId, onClose } = props;

	// const username: string = useTypedSelector(state => state.accountState.user.username);
	const selectedOrganizationId: string = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;
	const device: Device = useTypedSelector(
		state => {
			return state.deviceState.devicesByOrganizationId[selectedOrganizationId][
				editableDeviceId
			];
		},
		(left, right) => equalityFnc(left, right)
	);

	// const encodedUser = b64EncodeUnicode(username);
	const [editPicOpen, setEditPicOpen] = useState(false);

	const onSavePicture = (link: any) => {
		// publish(`microservice/${selectedOrganizationId}/${encodedUser}/updateUserInfo`, {
		// 	data: {
		// 		username: editableDevice.username,
		// 		profilePictureLink: link,
		// 	},
		// 	requestId: 'updateUserInfo',
		// });
		// setEditPicOpen(false);
	};

	return (
		<Fragment>
			<div className={classes.leftSide}>
				<RobotCard
					orgId={selectedOrganizationId}
					robotId={editableDeviceId}
					onClose={onClose}
					setEditPicOpen={setEditPicOpen}
				/>
				<RobotActivity orgId={selectedOrganizationId} robotId={editableDeviceId} />
			</div>
			<div className={classes.rightSide}>
				<RobotDetails robotId={editableDeviceId} onClose={onClose} />
			</div>
			{editPicOpen ? (
				<EditImageModal
					isOpen={editPicOpen}
					title={
						<FormattedMessage {...Messages.editPicTitle} values={{ item: 'Robot' }} />
					}
					subTitle={device.name}
					pictureLink={deviceIcons[spinoutType]}
					onSave={onSavePicture}
					onDismiss={() => setEditPicOpen(false)}
					avatarClass={classes.editAvatarClass}
				/>
			) : null}
		</Fragment>
	);
};

export default injectIntl(isAuthenticated(RobotEdit, 'RobotEdit'));
