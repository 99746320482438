import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Page } from '../../components/atoms/Page';
import { Footer } from '../../components/molecules/Footer';
import { IoCheckmark } from 'react-icons/io5';
import { Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Step } from '../../components/atoms/Step';

const PageWrapper = styled(Page)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
}));

const ContentWrapper = styled(Paper)(({ theme }) => ({
	display: 'flex',
	flex: 1,
	marginTop: 12,
	flexDirection: 'column',
	position: 'relative',
	borderRadius: 5,
	padding: 24,
	paddingBottom: 48,
}));

const CheckMarkIcon = styled(IoCheckmark)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: 16,
}));

const StepWrapper = styled(Paper)(({ theme }) => ({
	paddingTop: 12,
	paddingLeft: 8,
	paddingRight: 8,
	paddingBottom: 12,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
}));

// Steps props:
// [{
//     id: "#", *required
//     label: "label", *required
//     renderContent: () => {}, *required
//     footer: {}
// }]

export const Steps = ({ steps, selectedPageIndex, onGoToPreviousPage }) => {
	const theme = useTheme();
	const currentTabObj = steps[selectedPageIndex];
	const getFontColor = useCallback(
		stepIndex => {
			if (selectedPageIndex > stepIndex) {
				return theme.palette.primary.main;
			} else if (stepIndex === selectedPageIndex) {
				return theme.palette.secondary.main;
			} else {
				return theme.palette.greyPalette.grey3;
			}
		},
		[selectedPageIndex, theme]
	);

	const handleStepClick = useCallback(
		index => {
			onGoToPreviousPage(index);
		},
		[selectedPageIndex]
	);

	return (
		<PageWrapper>
			<StepWrapper>
				{steps.map((tab, index) => {
					const finished = selectedPageIndex > index;
					const disabled = index >= selectedPageIndex;
					const lastElement = index + 1 === steps.length;
					const color = getFontColor(index);
					return (
						<Step
							key={tab.label + index}
							label={tab.label}
							fontColor={color}
							hideIcon={!finished}
							icon={<CheckMarkIcon />}
							hideDivider={lastElement}
							onClick={() => handleStepClick(index)}
							disabled={disabled}
						/>
					);
				})}
			</StepWrapper>
			<ContentWrapper>
				{currentTabObj.renderContent()}
				{currentTabObj?.hideFooter ? null : <Footer {...currentTabObj?.footer} />}
			</ContentWrapper>
		</PageWrapper>
	);
};
