import React, { FC, useState, useCallback, useEffect } from 'react';
import {
	IonLabel,
	IonList,
	IonItem,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
} from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import isAuthenticated from '../../components/Authentication/Authenticated';
import MainContent from '../../components/MainContent/MainContent';
import Tabs from '../../components/Tabs/Tabs';
import AccountSetup from '../../components/AccountSetup/AccountSetup';
import { injectIntl, FormattedMessage } from 'react-intl';

import { useParams } from 'react-router';
import JWTDecode from 'jwt-decode';

import classes from './AccountSetup.module.css';
import classNames from 'classnames';
import Messages from './AccountSetup.messages';
import ErrorModal from '../../components/ErrorModal/ErrorModal';

import { useTypedSelector } from '../../reducers';
import { publish } from '../../actions/publish';
import { b64EncodeUnicode } from '../../utils/encoding';

const AccountSetupPage: FC = (props: any) => {
	const { intl, location, history } = props;
	let { token } = useParams<Record<string, any>>();

	const [disableNextButtonHanddle, setDisableNextButtonHanddle] = useState(false);

	const [profile, setProfile] = useState<any>();

	const [activePath, setActivePath] = useState(location.pathname);

	const user = useTypedSelector(state => state.accountState.user);

	const tabsData = [
		{
			title: Messages.profileTab,
			path: 'account',
			disabled: false,
			subMenus: [
				{ message: Messages.profile, path: 'profile' },
				// { message: Messages.notifications, path: 'notifications' }, FIXME: Implement notification settings in the back end
			],
		},
	];

	// const [lastSubMenuTab, setLastSubMenuTab] = useState<any>();
	const [currentTab, setCurrentTab] = useState<any | undefined>(tabsData[0]);
	const [currentSubMenu, setCurrentSubMenu] = useState<string | undefined>('profile');
	const [tokenError, setTokenError] = useState('');

	useEffect(() => {
		publish(
			`microservice/${b64EncodeUnicode(user.username)}/getOwnAccountInfo`,
			JSON.stringify({ requestId: 'someId' })
		);
	}, [user.username]);

	useEffect(() => {
		if (token) {
			try {
				const obj: {
					exp: number;
				} = JWTDecode(token);

				if (obj.exp < new Date().getTime() / 1000) {
					setTokenError(intl.formatMessage({ id: 'ConfirmPage.tokenExpired' }));
				}
			} catch (error) {
				setTokenError(intl.formatMessage({ id: 'ConfirmPage.tokenExpired' }));
			}
		}
	}, [intl, token]);

	useEffect(() => {
		setActivePath(location.pathname);
	}, [location.pathname]);

	const navigateToPage = (path: any) => {
		window.dispatchEvent(new Event('hashchange'));
		history.push(path);
		setActivePath(path);
	};

	const onNext = () => {
		const nextSubMenuItemIndex =
			tabsData[0].subMenus.findIndex((x: any) => x.path === currentSubMenu) + 1;

		if (tabsData[0].subMenus[nextSubMenuItemIndex]) {
			return setCurrentSubMenu(tabsData[0].subMenus[nextSubMenuItemIndex].path);
		}

		navigateToPage('gobe');
	};

	const onBack = () => {
		const nextSubMenuItemIndex =
			tabsData[0].subMenus.findIndex((x: any) => x.path === currentSubMenu) - 1;

		if (0 <= nextSubMenuItemIndex && tabsData[0].subMenus[nextSubMenuItemIndex]) {
			return setCurrentSubMenu(tabsData[0].subMenus[nextSubMenuItemIndex].path);
		}

		setCurrentSubMenu(tabsData[0].subMenus[0].path);
	};

	const disableButtonHanddle = useCallback(value => {
		setDisableNextButtonHanddle(value);
	}, []);

	const handdleProfileChange = useCallback((value: any) => {
		setProfile(value);
	}, []);

	const renderTabContent = useCallback(() => {
		if (!profile) setProfile(user);
		//I NEED  currentTab={currentTab} and currentSubMenu={currentSubMenu} because in RobotsSetup i need to know how much submenus I have(for arrow display functionality)
		switch (currentTab.path) {
			case 'account':
				return (
					<AccountSetup
						currentTab={currentTab}
						tabsData={tabsData}
						currentSubMenu={currentSubMenu}
						onSubMenuChange={(name: string) => setCurrentSubMenu(name)}
						disableButton={disableButtonHanddle}
						handleProfile={handdleProfileChange}
					/>
				);

			default:
				return null;
		}
	}, [
		currentSubMenu,
		currentTab,
		disableButtonHanddle,
		handdleProfileChange,
		profile,
		tabsData,
		user,
	]);

	return (
		<>
			<MainContent
				headerChildren={
					<>
						<IonLabel className={classes.mainTitle}>
							<FormattedMessage {...Messages.title} />
						</IonLabel>
					</>
				}
				renderContent={history => {
					return (
						<>
							{tokenError && tokenError.length > 0 ? (
								<ErrorModal
									isOpen
									onConfirm={() => props.history.push('/')}
									onDismiss={() => props.history.push('/')}
									type="token"
								/>
							) : (
								''
							)}
							<div className={classes.main}>
								<Tabs
									data={tabsData}
									currentTab={currentTab.path}
									content={
										<IonGrid
											force-overscroll
											className={classes.tabContentGrid}
										>
											<IonRow>
												<IonGrid className={classes.contentGrid}>
													<IonRow>
														<IonCol className={classes.leftSide}>
															<IonList
																className={classes.list}
																id="container"
															>
																{currentTab.subMenus.map(
																	(item: any, index: number) => {
																		return (
																			<IonItem
																				id={
																					'row-' +
																					item.path
																				}
																				key={index}
																				lines="none"
																				className={
																					currentSubMenu ===
																					item.path
																						? classNames(
																								classes.selectedItem,
																								'selected'
																						  )
																						: ''
																				}
																				onClick={() => {
																					disableNextButtonHanddle ===
																					false
																						? setCurrentSubMenu(
																								item.path
																						  )
																						: console.log();
																				}}
																			>
																				<IonLabel>
																					<FormattedMessage
																						{...item.message}
																					/>
																				</IonLabel>
																			</IonItem>
																		);
																	}
																)}
															</IonList>
														</IonCol>
														<IonCol className={classes.rightSide}>
															{renderTabContent()}
															{currentTab.subMenus.length < 10 && (
																<div
																	className={classes.triangle}
																	id="arrow"
																/>
															)}
														</IonCol>
													</IonRow>
												</IonGrid>
											</IonRow>
											<IonRow>
												<IonCol className={classes.leftCol} />
												<IonCol>
													<IonGrid>
														<IonRow>
															<IonCol>
																{currentTab !== 'account' ? (
																	<IonButton
																		disabled={
																			disableNextButtonHanddle
																		}
																		expand="block"
																		shape="round"
																		type="submit"
																		className={classNames(
																			classes.btn,
																			classes.backBtn
																		)}
																		onClick={onBack}
																	>
																		<IonIcon
																			size="small"
																			icon={chevronBack}
																		/>
																		<IonLabel>
																			{intl.formatMessage({
																				id: 'back',
																			})}
																		</IonLabel>
																	</IonButton>
																) : null}
															</IonCol>

															<IonCol className={classes.btnCol}>
																<IonButton
																	expand="block"
																	shape="round"
																	type="submit"
																	className={classNames(
																		classes.btn,
																		classes.nextBtn
																	)}
																	disabled={
																		disableNextButtonHanddle
																	}
																	onClick={onNext}
																>
																	{intl.formatMessage({
																		id: 'next',
																	})}
																</IonButton>
															</IonCol>
														</IonRow>
													</IonGrid>
												</IonCol>
											</IonRow>
										</IonGrid>
									}
									tabBtnClass={classes.setupTabBtn}
								/>
							</div>
						</>
					);
				}}
			/>
		</>
	);
};

export default injectIntl(isAuthenticated(AccountSetupPage, 'AccountSetupPage'));
