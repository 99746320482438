import React, { useState, useEffect } from 'react';
import {
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonLabel,
	IonModal,
	IonGrid,
	IonList,
	IonFab,
	IonFabButton,
	IonItem,
	IonListHeader,
} from '@ionic/react';
import { trash, add, checkmarkCircleOutline } from 'ionicons/icons';
import FormInputListItem from '../FormInputListItem/FormInputListItem';
import ReactSelect from '../ReactSelect/ReactSelect';
import DeleteModal from '../DeleteModal/DeleteModal';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../Authentication/Authenticated';
import { useForm } from 'react-hook-form';
import { publish } from '../../actions/publish';
import Tooltip from 'react-tooltip-lite';

import classes from './UserGroups.module.css';

import Messages from './UserGroups.messages';
import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';

interface EditGroupModalProps {
	orgId: string;
	initialGroup: any;
	onDismiss: () => void;
	onDelete: () => void;
}

const EditGroupModal: React.FC<EditGroupModalProps> = (props: any) => {
	const { intl, deviceGroups, userGroups, initialGroup, orgId } = props;
	const { deviceGroupsByOrganizationId } = deviceGroups;
	const { userGroupsByOrganizationId } = userGroups;
	const { addUsersCount } = userGroups;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);
	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const userGroupsState = useTypedSelector(state => state.userGroupsState.userGroupsByOrganizationId);
	const [selectedUserGroup, setSelectedUserGroup] = useState(
		JSON.parse(JSON.stringify(initialGroup))
	);
	const [robotGroups, setRobotGroups] = useState<any[]>([]);
	const [deleteOpen, setDeleteOpen] = useState(false);
	// const [expandUrl, setExpandUrl] = useState(false);
	// const [groupPicture, setGroupPicture] = useState<any>();

	const { handleSubmit, control, errors, setError } = useForm();

	useEffect(() => {
		setRobotGroups(
			deviceGroupsByOrganizationId[orgId]
				? Object.values(deviceGroupsByOrganizationId[orgId])
				: []
		);
	}, [orgId, deviceGroupsByOrganizationId]);

	useEffect(() => {
		if (initialGroup && orgId) {
			setSelectedUserGroup(
				JSON.parse(
					JSON.stringify(userGroupsByOrganizationId[orgId][initialGroup.userGroupId])
				)
			);
		}
	}, [initialGroup, orgId, userGroupsByOrganizationId]);

	const isUserGroupNameTaken = (groupName: string) => {
		if (userGroupsState[selectedOrganizationId]) {
			let userGroupsByOrg = userGroupsState[selectedOrganizationId];
			let isTaken = false;
			Object.values(userGroupsByOrg).forEach(element => {
				if (element.name === groupName) isTaken = true;
			});
			return isTaken;
		} else return;
	};

	const onSave = handleSubmit(data => {
		data.name = data.name.trim();
		if (data.name !== selectedUserGroup.name) {
			if (isUserGroupNameTaken(data.name)) {
				setError('nameUnique', {
					type: 'manual',
					message: 'User Group name must be unique',
				});
				return;
			}
		}
		if (data.name !== selectedUserGroup.name) {
			publish(`microservice/${orgId}/${encodedUser}/updateUserGroupInfo`, {
				data: {
					userGroupId: selectedUserGroup.userGroupId,
					name: data.name,
				},
				requestId: 'updateUserGroup',
			});
		}
		let addGroupIds: string[] = [],
			removeGroupIds: string[] = [];
		// Check addable deviceGroupIds
		if (data.deviceGroupsIds) {
			data.deviceGroupsIds.forEach((gr: any) => {
				if (
					selectedUserGroup.deviceGroupsIds == null ||
					selectedUserGroup.deviceGroupsIds.findIndex((id: string) => id === gr.value) < 0
				) {
					addGroupIds.push(gr.value);
				}
			});
		}
		// Check removable deviceGroupIds
		if (selectedUserGroup.deviceGroupsIds) {
			selectedUserGroup.deviceGroupsIds.forEach((id: string) => {
				if (
					data.deviceGroupsIds == null ||
					data.deviceGroupsIds.findIndex((gr: any) => gr.value === id) < 0
				) {
					removeGroupIds.push(id);
				}
			});
		}
		if (addGroupIds.length > 0 || removeGroupIds.length > 0) {
			publish(`microservice/${orgId}/${encodedUser}/updateUserGroupDeviceGroups`, {
				data: {
					userGroupId: selectedUserGroup.userGroupId,
					add: addGroupIds,
					remove: removeGroupIds,
				},
				requestId: 'updateUserGroupDeviceGroups',
			});
		}
		props.onDismiss();
	});
	const onCancel = () => {
		props.onDismiss();
	};

	const onDeleteConfirm = () => {
		publish(`microservice/${orgId}/${encodedUser}/deleteUserGroup`, {
			data: {
				userGroupId: selectedUserGroup.userGroupId,
			},
			requestId: 'deleteUserGroup',
		});
		setDeleteOpen(false);
		props.onDelete();
	};

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 * @param {string} labelProperty - The property that contains the name of the entity
	 * @param {string} valueProperty - The property that contains the identifying value of the entity
	 */
	const selectOptions = (entity: string, labelProperty: string, valueProperty: string) => {
		let label;
		let value;

		const options = [];
		let currentEntities: any;
		if (entity === 'deviceGroup') {
			currentEntities = robotGroups; //deviceGroupsByOrganizationId[orgId];
		}

		if (currentEntities == null) currentEntities = [];
		else {
			for (let i in currentEntities) {
				label = currentEntities[i][labelProperty];
				value = currentEntities[i][valueProperty];

				options.push({
					label,
					value,
				});
			}
		}

		return options;
	};

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 */
	const currentEntities = (entity: string) => {
		const options = [];

		if (selectedUserGroup) {
			let currentEntities: any = selectedUserGroup[entity];
			if (currentEntities === null || currentEntities === undefined) currentEntities = [];
			else {
				for (let i = 0; i < currentEntities.length; i++) {
					const deviceGroupName =
						deviceGroupsByOrganizationId[orgId][currentEntities[i]]?.name;
					options.push({ label: deviceGroupName, value: currentEntities[i] });
				}
			}
		}
		return options;
	};

	return (
		<>
			<IonModal
				cssClass={classes.editModal}
				isOpen={props.isOpen}
				swipeToClose
				show-backdrop
				onDidDismiss={props.onDismiss}
			>
				<form onSubmit={onSave} className={classes.addForm}>
					<IonFab vertical="top" horizontal="start" className={classes.deleteFab}>
						<Tooltip
							direction="down"
							content={
								<FormattedMessage
									{...Messages.deleteTitle}
									values={{ item: 'Group' }}
								/>
							}
						>
							<IonFabButton size="small" onClick={() => setDeleteOpen(true)}>
								<IonIcon icon={trash} />
							</IonFabButton>
						</Tooltip>
					</IonFab>
					<IonGrid className={classNames(classes.modalContent, classes.editGrid)}>
						<IonRow>
							<IonList className={classes.header}>
								<IonListHeader className={classes.modalHeader} lines="none">
									<div className={classes.headerContainer}>
										<IonLabel color="primary" className={classes.headerTitle}>
											<FormattedMessage {...Messages.edit} />
										</IonLabel>
									</div>
									<IonLabel className={classes.headerHint}>
										{selectedUserGroup?.name}
									</IonLabel>
								</IonListHeader>
							</IonList>
						</IonRow>
						<IonRow className={classes.editableContent}>
							<IonList>
								<IonItem className={classes.addUsersItem} lines="none">
									<IonGrid>
										<IonRow>
											<IonButton
												expand="block"
												size="large"
												fill="outline"
												onClick={() =>
													props.setParameter(
														'userGroups',
														'CHANGE_ADD_USERS_TO_GROUP_STATE',
														true
													)
												}
											>
												<IonIcon size="small" icon={add} />
												<FormattedMessage {...Messages.addUsers} />
											</IonButton>
										</IonRow>
										{addUsersCount ? (
											<IonRow className={classes.addedRow}>
												<IonLabel
													className={classes.addedLb}
													color="primary"
												>
													<IonIcon
														size="small"
														icon={checkmarkCircleOutline}
													/>
													<FormattedMessage
														{...Messages.usersSelected}
														values={{ count: addUsersCount }}
													/>
												</IonLabel>
											</IonRow>
										) : null}
									</IonGrid>
								</IonItem>
								<FormInputListItem
									type="text"
									name="name"
									label={intl.formatMessage({
										id: 'UserGroups.name',
									})}
									defaultValue={selectedUserGroup ? selectedUserGroup.name : ''}
									control={control}
									rules={{ required: true }}
									required
									errorMsg={
										errors.name
											? 'Group name is required'
											: errors.nameUnique
											? 'Group name must be unique'
											: ''
									}
								/>
								<ReactSelect
									className={classes.robotGroupSelect}
									defaultValue={currentEntities('deviceGroupsIds')}
									value={currentEntities('deviceGroupsIds')}
									options={selectOptions('deviceGroup', 'name', 'deviceGroupId')}
									placeHolder={intl.formatMessage({
										id: 'InviteModal.deviceGroups',
									})}
									name="deviceGroupsIds"
									control={control}
									isMulti
									isRequired={false}
									fullWidth
								/>
							</IonList>
						</IonRow>
						<IonRow className={classes.btnRow}>
							<IonCol>
								<IonButton
									className={classes.cancelBtn}
									expand="block"
									shape="round"
									size="large"
									fill="outline"
									onClick={onCancel}
								>
									{intl.formatMessage({
										id: 'cancel',
									})}
								</IonButton>
							</IonCol>
							<IonCol className={classes.submitCol}>
								<IonButton
									expand="block"
									shape="round"
									type="submit"
									size="large"
									fill="outline"
								>
									{intl.formatMessage({
										id: 'AccountManagementPage.save',
									})}
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</form>
			</IonModal>
			<DeleteModal
				isOpen={deleteOpen}
				title={<FormattedMessage {...Messages.deleteTitle} values={{ item: 'Group' }} />}
				onConfirm={onDeleteConfirm}
				onDismiss={() => setDeleteOpen(false)}
				itemName={selectedUserGroup?.name + ' Group'}
			/>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	userGroups: state.userGroupsState,
	deviceGroups: state.deviceGroupsState,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(EditGroupModal), 'EditGroupModal')
);
