import React, {useState, useEffect} from 'react';
import { TextField } from '../atoms/TextField';
import { Form } from '../atoms/Form';
import { MenuItem, Grid } from '@material-ui/core';
import { Snackbar } from 'components/Snackbar';
import { gql, useQuery } from '@apollo/client';

const COUNTRY_QUERY = gql`
	query CountryQuery {
		countriesAndContinents {
			countryCode
			countryName
		}
	}
`;

export const AddressForm = ({ formik, disableFields = false }) => {
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const { error, data } = useQuery(COUNTRY_QUERY);
	const countriesArr = data?.countriesAndContinents || [];

	useEffect(() => {
		if (error) {
			setSnackbarOpen(true);
		}
	}, [error]);

	return (
		<>
			<Form onSubmit={formik.handleSubmit}>
				<Grid container spacing={5} direction="row">
					<Grid item xs={12} md={6}>
						<TextField
							disabled={disableFields}
							autocomplete="off"
							label="Street"
							id="streetLine1"
							variant="standard"
							value={formik.values.streetLine1}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={Boolean(formik.errors.streetLine1)}
							helperText={formik.errors.streetLine1}
							required
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							disabled={disableFields}
							label="Street 2"
							id="streetLine2"
							variant="standard"
							value={formik.values.streetLine2}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={Boolean(formik.errors.streetLine2)}
							helperText={formik.errors.streetLine2}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							disabled={disableFields}
							autocomplete="off"
							label="Zip"
							id="zip"
							variant="standard"
							value={formik.values.zip}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={Boolean(formik.errors.zip)}
							helperText={formik.errors.zip}
							required
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							disabled={disableFields}
							autocomplete="off"
							label="City"
							id="city"
							variant="standard"
							value={formik.values.city}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={Boolean(formik.errors.city)}
							helperText={formik.errors.city}
							required
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							disabled={disableFields}
							autocomplete="off"
							label="State"
							id="state"
							variant="standard"
							value={formik.values.state}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={Boolean(formik.errors.state)}
							helperText={formik.errors.state}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							disabled={disableFields}
							autocomplete="off"
							label="Country"
							id="countryCode"
							name="countryCode"
							select
							variant="standard"
							value={formik.values.countryCode}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={Boolean(formik.errors.countryCode)}
							helperText={formik.errors.countryCode}
							required
						>
							{countriesArr.map(country => (
								<MenuItem value={country.countryCode} key={country.countryCode}>
									{country.countryName}
								</MenuItem>
							))}
						</TextField>
					</Grid>
				</Grid>
			</Form>
			<Snackbar
				open={snackbarOpen}
				onClose={() => setSnackbarOpen(false)}
				message={'Could not load the country data'}
			/>
		</>
	);
};
