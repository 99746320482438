import * as yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const BASEVALIDATIONS = {
	companyName: yup
		.string()
		.required('Company name is required')
		.matches(/^[a-zA-ZæÆøØåÅ\s]+$/, 'Only letters are allowed for this field '),
	orderPersonFirstName: yup
		.string()
		.required('First name is required')
		.matches(/^[a-zA-ZæÆøØåÅ\s]+$/, 'Only letters are allowed for this field '),
	orderPersonLastName: yup
		.string()
		.required('Last name is required')
		.matches(/^[a-zA-ZæÆøØåÅ\s]+$/, 'Only letters are allowed for this field '),
	streetLine1: yup
		.string()
		.required('Street is required')
		.matches(/^[a-zA-ZæÆøØåÅ0-9\s]+$/, 'Only letters and numbers are allowed for this field '),
	streetLine2: yup.string().matches(/^[a-zA-Z0-9\s]+$/, {
		message: 'Only letters and numbers are allowed for this field ',
		excludeEmptyString: true,
	}),
	zip: yup
		.string()
		.required('Zip number is required')
		.matches(/^[a-zA-Z0-9\s]+$/, 'Only letters and numbers are allowed for this field '),
	city: yup
		.string()
		.required('City is required')
		.matches(/^[a-zA-ZæÆøØåÅ\s]+$/, 'Only letters are allowed for this field '),
	state: yup.string().matches(/^[a-zA-ZæÆøØåÅ\s]+$/, {
		message: 'Only letters are allowed for this field ',
		excludeEmptyString: true,
	}),
	countryCode: yup
		.string()
		.required('Country is required')
		.matches(/^[a-zA-Z\s]+$/, 'Only letters are allowed for this field '),
};

export const CONTACTPERSONVALIDATIONS = {
	phoneNumber: yup
		.string()
		.required('Phonenumber is not valid')
		.matches(phoneRegExp, 'Phone number is not valid'),
	emailAddress: yup
		.string()
		.email('Invalid email format')
		.required('Email is required'),
};

export const TAXNUMBERVALIDATION = {
	taxNumber: yup
		.string()
		.required('VAT/TAX number is required')
		.matches(/^[a-zA-Z0-9\s]+$/, 'Only letters and numbers are allowed for this field '),
};
