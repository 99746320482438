import React from 'react';
import styled from 'styled-components';

const RowWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	flex: 1,
	alignItems: 'flex-start',
	justifyContent: 'center',
}));

const LeftSide = styled('div')(({ theme }) => ({
	display: 'flex',
	flex: 1,
	padding: 24,
	flexDirection: 'column',
	alignItems: 'center',
}));

const RightSide = styled('div')(({ theme }) => ({
	display: 'flex',
	flex: 2,
	flexDirection: 'column',
	paddingTop: 45,
	paddingLeft: 24,
}));

export const ColumnContainer = ({ leftSideContent, rightSideContent }) => {
	return (
		<RowWrapper>
			<LeftSide>{leftSideContent}</LeftSide>
			<RightSide>{rightSideContent}</RightSide>
		</RowWrapper>
	);
};
