import React, { FC, useEffect, useState } from 'react';
import {
	IonButton,
	IonIcon,
	IonLabel,
	IonSegment,
	IonSegmentButton,
	IonGrid,
	IonRow,
} from '@ionic/react';
import {
	checkmarkCircle,
	closeCircle,
	checkmarkCircleOutline,
	reader,
	construct,
	globe,
	trash,
} from 'ionicons/icons';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import classes from './RobotEdit.module.css';
import Messages from './RobotEdit.messages';
import RobotForm from '../RobotForm/RobotFormContainer';
import RobotSystem from '../RobotForm/RobotSystem';
import RobotNetwork from '../RobotForm/RobotNetwork';
import { useTypedSelector } from '../../reducers';
import Tooltip from 'react-tooltip-lite';
import { publish } from '../../actions/publish';
import { b64EncodeUnicode } from '../../utils/encoding';
import { useForm } from 'react-hook-form';
import { equalityFnc, getStateEntityByOrgId } from '../../utils/conformState';

const tabs = ['details', 'system', 'network'];

interface RobotDetailsProps {
	robotId: string;
	onClose: any;
}

const RobotDetails: FC<RobotDetailsProps> = props => {
	const { robotId, onClose } = props;

	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const device = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: robotId,
				orgId: selectedOrganizationId,
				propertyOptions: [
					'name',
					'location',
					'timeZone',
					'orgId',
					'deviceType',
					'deviceId',
					'battery',
					'online',
					'currentState',
					'deviceGroupsIds',
				],
			}),
		(left, right) => equalityFnc(left, right, false)
	);
	const { control, setError } = useForm({
		defaultValues: device,
	});
	const [saved, setSaved] = useState(false);
	const [selectedSegment, setSelectedSegment] = useState<string>(tabs[0]);
	const [editable, setEditable] = useState(false);

	const username = useTypedSelector(state => state.accountState.user.username);
	const orgId = useTypedSelector(state => state.selectedOrganizationState.organization.orgId);
	const orgType = useTypedSelector(state => state.selectedOrganizationState.organization.orgType);
	const encodedUser = b64EncodeUnicode(username);
	const devices = useTypedSelector(state => state.deviceState.devicesByOrganizationId);

	const onCancelEdit = () => {
		setEditable(false);
		control.setValue('name', device.name);
		control.setValue('location', device.location);
		control.setValue('timeZone', device.timeZone);
		control.setValue('deviceGroupsIds', device.deviceGroupsIds);
		setSaved(false);
	};

	const onSegmentChange = (value: any) => {
		setSelectedSegment(value);
		setEditable(false);
	};

	const onSaveDevice = () => {
		onEditRobotSubmit(control.getValues());
	};

	const onEditRobot = (data: any) => {
		setEditable(true);
	};

	const onDeleteRobot = () => {
		publish(`microservice/${orgId}/${b64EncodeUnicode(username)}/deleteDevice`, {
			requestId: 'deleteDeviceID',
			data: {
				deviceId: robotId,
			},
		});
		onClose();
	};

	const isDeviceNameTaken = (deviceName: string) => {
		if (deviceName === device.name) return false;
		if (devices[orgId]) {
			let devicesByOrg = devices[orgId];
			let isTaken = false;
			Object.values(devicesByOrg).forEach(element => {
				if (element.name === deviceName) isTaken = true;
			});
			return isTaken;
		} else return;
	};

	const onEditRobotSubmit = (data: any) => {
		if (isDeviceNameTaken(data.name)) {
			return;
		}

		const revisedEntity: string = 'deviceGroupsIds';

		const updateParams: any = {
			name: data.name,
			location: data.location,
			timeZone: data.timeZone
				? data.timeZone.label
					? data.timeZone.label
					: data.timeZone
				: null,
			deviceGroupsIds: Array.isArray(data.deviceGroupsIds)
				? data.deviceGroupsIds
				: [data.deviceGroupsIds],
			removedDeviceGroupsIds: [],
		};

		const removedProp =
			'removed' + revisedEntity.charAt(0).toUpperCase() + revisedEntity.slice(1);
		if (
			updateParams[revisedEntity] === undefined ||
			updateParams[revisedEntity] === null ||
			updateParams[revisedEntity].length === 0
		) {
			updateParams[removedProp] = device[revisedEntity];
			delete updateParams[revisedEntity];
		} else {
			// add removed entities to removed prop and remove entities from updated data if it already exist in edited device.
			for (let i = 0; i < (device[revisedEntity] || []).length; i++) {
				const index = updateParams[revisedEntity].findIndex(
					(e: any) => e.value === device[revisedEntity][i]
				);
				if (index >= 0) updateParams[revisedEntity].splice(index, 1);
				else updateParams[removedProp].push(device[revisedEntity][i]);
			}

			if (updateParams[revisedEntity].length === 0) {
				delete updateParams[revisedEntity];
			} else {
				let valueArr: Array<string> = [];
				updateParams[revisedEntity].forEach((e: any) => {
					valueArr.push(e);
				});
				updateParams[revisedEntity] = valueArr;
			}
			if (updateParams[removedProp].length === 0) delete updateParams[removedProp];
		}

		if (
			updateParams.name !== device.name ||
			updateParams.location !== device.location ||
			updateParams.timeZone !== device.timeZone
		)
			publish(`microservice/${selectedOrganizationId}/${encodedUser}/updateDeviceInfo`, {
				requestId: 'updateDeviceInfoId',
				data: {
					device_type: device.deviceType,
					device_id: device.deviceId,
					name: updateParams.name,
					location: updateParams.location,
					time_zone: updateParams.timeZone,
				},
			});

		if (updateParams.deviceGroupsIds)
			publish(
				`microservice/${selectedOrganizationId}/${encodedUser}/updateDeviceGroupDevices`,
				{
					requestId: 'updateDeviceGroupDevicesId',
					data: {
						deviceGroupId: updateParams.deviceGroupsIds[0].value,
						add: [device.deviceId],
					},
				}
			);

		if (updateParams.removedDeviceGroupsIds)
			publish(
				`microservice/${selectedOrganizationId}/${encodedUser}/updateDeviceGroupDevices`,
				{
					requestId: 'updateDeviceGroupDevicesId',
					data: {
						deviceGroupId: updateParams.removedDeviceGroupsIds[0],
						remove: [device.deviceId],
					},
				}
			);
		setEditable(false);
	};

	return (
		<div>
			<div className={classes.header}>
				<div className={classes.editBtnContainer}>
					<IonButton
						className={
							selectedSegment !== 'details'
								? classes.hidden
								: editable
								? classNames(classes.editBtn, classes.editable)
								: classes.editBtn
						}
						shape="round"
						onClick={onEditRobot}
						disabled={editable}
					>
						<IonIcon src="./assets/img/edit.svg" />
					</IonButton>
					{(orgType === 'bor' && (!device.deviceGroupsIds || device.deviceGroupsIds.length == 0) ) && (
						<div className={classes.btnMargin}>
							<Tooltip
								direction="down"
								content={<FormattedMessage {...Messages.deleteTitle} />}
							>
								<IonButton
									className={
										selectedSegment !== 'details'
											? classes.hidden
											: editable
											? classNames(
													classes.editBtn,
													classes.editable,
													classes.trash
											  )
											: classNames(classes.editBtn, classes.trash)
									}
									shape="round"
									onClick={onDeleteRobot}
									disabled={editable}
								>
									<IonIcon icon={trash} />
								</IonButton>
							</Tooltip>
						</div>
					)}
					<div
						className={
							editable
								? classes.formBtns
								: classNames(classes.formBtns, classes.hidden)
						}
					>
						<IonIcon
							slot="end"
							size="large"
							icon={checkmarkCircle}
							onClick={onSaveDevice}
						/>
						<IonIcon
							slot="end"
							size="large"
							icon={closeCircle}
							onClick={onCancelEdit}
						/>
					</div>
					{!editable && saved ? (
						<>
							<IonIcon
								slot="end"
								size="small"
								color="primary"
								icon={checkmarkCircleOutline}
							/>
							<IonLabel className={classes.savedLb}>
								<FormattedMessage {...Messages.saved} />
							</IonLabel>
						</>
					) : null}
				</div>
				<IonSegment
					className={classes.tabContainer}
					mode="ios"
					onIonChange={(e: CustomEvent) => onSegmentChange(e.detail.value)}
					value={selectedSegment}
				>
					<IonSegmentButton
						value="details"
						layout="icon-start"
						className={classes.detailsBtn}
					>
						<IonIcon className={classes.iconMargin} size="small" icon={reader} />
						<IonLabel>
							<FormattedMessage {...Messages.details} />
						</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton
						value="system"
						layout="icon-start"
						className={classes.networkBtn}
					>
						<IonIcon className={classes.iconMargin} size="small" icon={construct} />
						<IonLabel>
							<FormattedMessage {...Messages.system} />
						</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton
						value="network"
						layout="icon-start"
						className={classes.networkBtn}
					>
						<IonIcon className={classes.iconMargin} size="small" icon={globe} />
						<IonLabel>
							<FormattedMessage {...Messages.network} />
						</IonLabel>
					</IonSegmentButton>
				</IonSegment>
			</div>
			<IonGrid className={classes.segmentContent}>
				<IonRow className={selectedSegment !== 'details' ? classes.hidden : ''}>
					<RobotForm
						control={control}
						device={device}
						deviceId={robotId}
						isEditable={editable}
					/>
				</IonRow>
				<IonRow className={selectedSegment !== 'system' ? classes.hidden : ''}>
					<RobotSystem deviceId={robotId} isEditable={editable} saved={saved} />
				</IonRow>
				<IonRow className={selectedSegment !== 'network' ? classes.hidden : ''}>
					<RobotNetwork deviceId={robotId} isEditable={editable} saved={saved} />
				</IonRow>
			</IonGrid>
		</div>
	);
};

export default RobotDetails;
