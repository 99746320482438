import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import { IonLabel, IonIcon, IonList, IonListHeader, IonAvatar } from '@ionic/react';
import { timeOutline, peopleCircle, cloudOffline } from 'ionicons/icons';
import InfoCard from '../InfoCard/InfoCard';
import ListGrid from '../ListGrid/ListGrid';
import EditImageModal from '../EditImageModal/EditImageModal';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../Authentication/Authenticated';
import { publish } from '../../actions/publish';
import { sortedCollection } from '../../selectors';

import classes from './RobotGroups.module.css';
import classNames from 'classnames';

import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './RobotGroups.messages';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import RobotMoveGroup from '../RobotMoveGroup/RobotMoveGroup';

const robotHeaders = [
	{ title: Messages.robotName, property: 'name' },
	{ title: Messages.location, property: 'location' },
	{ title: Messages.status, property: 'status' },
	{ title: Messages.charge, property: 'charge' },
	{ title: Messages.latestSession, property: '' },
];

interface RobotGroupEditProps {
	group: any;
	onClose: () => void;
	onEditOpen: () => void;
}

const RobotGroupEdit: FC<RobotGroupEditProps> = (props: any) => {
	const devices = useTypedSelector(state => state.deviceState);
	const { deviceGroups, group, onClose, history } = props;
	const { devicesByOrganizationId, sortParams } = devices;
	const { deviceGroupsByOrganizationId } = deviceGroups;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);

	const [editPicOpen, setEditPicOpen] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState(group);
	const [groupMembers, setGroupMembers] = useState([]);
	const [pageSize] = useState(11);

	const [showTransfer, setShowTransfer] = useState(false);
	const [selectedRobot, setSelectedRobot] = useState();

	useEffect(() => {
		if (!group) return;
		setSelectedGroup(deviceGroupsByOrganizationId[group.orgId][group.deviceGroupId]);
	}, [group, deviceGroupsByOrganizationId]);

	useEffect(() => {
		if (!selectedGroup || !selectedGroup.devicesIds || !selectedGroup.orgId) return;
		const devicesByOrg = devicesByOrganizationId[selectedGroup.orgId]
			? Object.values(devicesByOrganizationId[selectedGroup.orgId])
			: [];

		const members: any = devicesByOrg.filter(
			(u: any) => selectedGroup.devicesIds.findIndex((id: string) => id === u.deviceId) > -1
		);
		setGroupMembers(members);
	}, [selectedGroup, devicesByOrganizationId]);

	const formatData = useCallback((item: any, property: string) => {
		switch (property) {
			case 'name':
				return (
					<div className={classes.nameContainer}>
						<IonAvatar className={classes.avatar}>
							{item.status == null || !item.online ? (
								<IonIcon
									className={classNames(classes.profilePic, classes.offlinePic)}
									size="large"
									icon={cloudOffline}
								/>
							) : item.pictureLink ? (
								<img
									className={classes.profilePic}
									src={item.pictureLink}
									alt="Avatar"
								/>
							) : (
								<IonIcon
									className={classNames(classes.profilePic, classes.noPic)}
									size="large"
									src="./assets/icons/Robot-single.svg"
								/>
							)}
						</IonAvatar>
						<IonLabel
							className={
								item.status == null || !item.online ? classes.offlineColor : ''
							}
						>
							{item.name}
						</IonLabel>
					</div>
				);
			case 'status':
				return (
					<div className={classes.statusContainer}>
						{item.status != null ? (
							item.onCall ? (
								<IonLabel className={classes.statusNameLb} {...Messages.onCall}>
									<FormattedMessage {...Messages.onCall} />
								</IonLabel>
							) : item.online === true ? (
								<IonLabel className={classes.statusNameLb}>
									<FormattedMessage {...Messages.online} />
								</IonLabel>
							) : (
								<IonLabel
									className={classNames(
										classes.statusNameLb,
										classes.offlineColor
									)}
								>
									<FormattedMessage {...Messages.offline} />
								</IonLabel>
							)
						) : (
							<IonLabel
								className={classNames(classes.statusNameLb, classes.offlineColor)}
							>
								<FormattedMessage {...Messages.offline} />
							</IonLabel>
						)}
					</div>
				);
			case 'charge':
				return (
					<div className={classes.chargeContainer}>
						{item.battery != null ? (
							<IonLabel
								className={
									item.status == null || !item.online
										? classes.offlineColor
										: classes.chargeNameLb
								}
							>
								{item.battery.charging === true ? (
									<FormattedMessage {...Messages.charge} />
								) : (
									item.battery.level
								)}
							</IonLabel>
						) : (
							<IonLabel className={classes.chargeNameLb} />
						)}
					</div>
				);
			default:
				return (
					<IonLabel
						className={item.status == null || !item.online ? classes.offlineColor : ''}
					>
						{item[property]}
					</IonLabel>
				);
		}
	}, []);

	const onEdit = async (item: any) => {
		history.push({
			pathname: '/fleetManagement',
			data: { tab: 'robots', orgId: item.orgId, editableRobot: item },
		});
	};

	const onSavePicture = (link: any) => {
		publish(`microservice/${group.orgId}/${encodedUser}/updateDeviceGroupInfo`, {
			data: {
				device_group_id: group.deviceGroupId,
				picture_link: link,
			},
			requestId: 'updateDeviceGroup',
		});
		setEditPicOpen(false);
	};

	return (
		<Fragment>
			<div className={classes.editLeftSide}>
				<InfoCard
					imgUrl={selectedGroup.pictureLink}
					noImg={peopleCircle}
					title={selectedGroup.name}
					subTitle={
						selectedGroup.devicesIds && selectedGroup.devicesIds.length > 0 ? (
							<FormattedMessage
								{...Messages.robotsCount}
								values={{
									count: selectedGroup.devicesIds.length,
								}}
							/>
						) : null
					}
					email={selectedGroup.name}
					onClose={onClose}
					onEdit={props.onEditOpen}
					onEditPic={() => setEditPicOpen(true)}
				/>
				<div className={classes.recentContainer}>
					<IonList lines="none">
						<IonListHeader>
							<IonIcon color="primary" size="small" icon={timeOutline} />
							<IonLabel color="primary" className={classes.recentLb}>
								<FormattedMessage {...Messages.recentActivity} />
							</IonLabel>
						</IonListHeader>
					</IonList>
				</div>
			</div>
			<div className={classes.rightSide}>
				<ListGrid
					headers={robotHeaders}
					data={sortedCollection({
						items: groupMembers,
						sortParams: sortParams,
					})}
					pageSize={pageSize}
					itemTemplate={(item, property) => {
						return formatData(item, property);
					}}
					moreContent={item => {
						return (
							<table className={classes.moreList}>
								<tbody>
									<tr>
										<td className={classes.itemContent}>
											<IonLabel
												className={classes.itemLb}
												onClick={() => onEdit(item)}
											>
												<FormattedMessage {...Messages.editRobot} />
											</IonLabel>
										</td>
									</tr>
									<tr>
										<td className={classes.itemContent}>
											<IonLabel className={classes.itemLb}
											onClick={() => {
												setShowTransfer(true);
												setSelectedRobot(item);
											}}>
												<FormattedMessage {...Messages.moveRobotTo} />
											</IonLabel>
										</td>
									</tr>
								</tbody>
							</table>
						);
					}}
					onRowClick={onEdit}
					sortType="SET_DEVICES_SORT_PARAMS"
				/>
			</div>
			<EditImageModal
				isOpen={editPicOpen}
				title={<FormattedMessage {...Messages.editPicTitle} values={{ item: 'Group' }} />}
				subTitle={selectedGroup.name}
				pictureLink={selectedGroup.pictureLink}
				noImg={peopleCircle}
				onSave={onSavePicture}
				onDismiss={() => setEditPicOpen(false)}
			/>
			{showTransfer && (
				<RobotMoveGroup
					isOpen={selectedRobot && showTransfer}
					onDismiss={() => {
						setShowTransfer(false);
						setSelectedRobot(undefined);
					}}
					robot={selectedRobot}
				/>
			)}
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	deviceGroups: state.deviceGroupsState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(RobotGroupEdit), 'RobotGroupEdit'));
