import React, { FC, useState, ReactNode } from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import { IonButton, IonLabel } from '@ionic/react';
// import { IonLabel, IonIcon, IonButton } from '@ionic/react';

import classes from './PopoverBox.module.css';

interface PopoverBoxProps {
	isOpen: boolean;
	target?: ReactNode;
	content?: ReactNode;
}

const PopoverBox: FC<PopoverBoxProps> = ({ isOpen, target, content }) => {
	const [isPopoverOpen, setPopoverOpen] = useState(isOpen);

	return (
		<Popover
			isOpen={isPopoverOpen}
			position="bottom"
			align="center"
			padding={10}
			windowBorderPadding={10}
			onClickOutside={() => setPopoverOpen(false)}
			containerClassName={classes.content}
			content={({ position, targetRect, popoverRect }) => (
				<ArrowContainer
					position={position}
					targetRect={targetRect}
					popoverRect={popoverRect}
					arrowColor="white"
					arrowSize={10}
					arrowStyle={{ opacity: 1 }}
				>
					<div
						style={{
							opacity: 1,
							backgroundColor: 'white',
							borderRadius: '10px',
						}}
						onClick={() => setPopoverOpen(false)}
					>
						<div className={classes.userNameList}>{content}</div>
					</div>
				</ArrowContainer>
			)}
		>
			<div onClick={() => setPopoverOpen(!isPopoverOpen)}>
				{target}
				{/* <IonButton
					// className={classNames(classes.headerBtn, classes.userHeaderBtn)}
					onClick={() => setPopoverOpen(!isPopoverOpen)}
				>
					<IonLabel>Header</IonLabel>
				</IonButton> */}
			</div>
		</Popover>
	);
};

export default PopoverBox;
