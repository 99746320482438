import React, { FC } from 'react';
import { IonModal, IonList, IonItem, IonLabel, IonListHeader, IonButton } from '@ionic/react';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../../components/Authentication/Authenticated';

import classes from './ErrorModal.module.css';
import { RouteComponentProps } from 'react-router';
import TokenErrorModal from './TokenErrorModal';
import AccountAlreadyConfirmedErrorModal from './AccountAlreadyConfirmedErrorModal'
import AccountCreationFailedErrorModal from './AccountCreationFailedErrorModal'
import AccountCreatedModal from './AccountCreatedModal'

import Messages from './ErrorModal.messages';

interface ErrorModalProps extends RouteComponentProps {
	title: any;
	isOpen: boolean;
	onConfirm: any;
	onDismiss: any;
	type: string;
}

const ErrorModal: FC<ErrorModalProps> = (props: any) => {
    const { history } = props;

	const onDismissModal = () => {
        props.onDismiss();
	};

	return (
		<IonModal
			cssClass={classes.addModal}
			isOpen={props.isOpen}
			swipeToClose
			show-backdrop
			onDidDismiss={onDismissModal}
		>
			<IonList className={classes.modalContent}>
				{props.type==='token' ? 
					<TokenErrorModal
						onConfirm={props.onConfirm}
						onDismiss={props.onDismiss}
						/>
				: props.type==='accountAlreadyConfirmed' ?
					<AccountAlreadyConfirmedErrorModal
						onConfirm={props.onConfirm}
						onDismiss={props.onDismiss}
						/>
				: props.type==='accountCreationFailed' ?
					<AccountCreationFailedErrorModal
						onConfirm={props.onConfirm}
						onDismiss={props.onDismiss}
						/>
				: props.type==='success' ?
					<AccountCreatedModal
						onConfirm={props.onConfirm}
						onDismiss={props.onDismiss}
						/>
				: ''}
			</IonList>
		</IonModal>
	);
};

export default injectIntl(isAuthenticated(ErrorModal));
