import React, { useState, useCallback } from 'react';
import { Typography, TextField } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const StyledTextBox = styled(TextField)(() => ({
	fontSize: 15,
	marginRight: 8,
	width: 120,
}));

const BoldText = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	fontWeight: 700,
}));

const PromoCodeWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'baseline',
	flexWrap: 'wrap',
}));

const StyledButton = styled(Button)(({ theme }) => ({
	marginTop: 4,
	marginBottom: 4,
	color: theme.palette.primary.main,
}));

const PriceColumn = ({ row }) => {
	const [promoCode, setPromoCode] = useState('');

	const handleApplyPromoCode = useCallback(() => {
		row.onClick(promoCode);
	}, [promoCode]);

	if (row.type === 'promoCode') {
		return (
			<PromoCodeWrapper>
				<StyledTextBox
					value={promoCode}
					placeholder={row.placeholderText}
					onChange={e => setPromoCode(e.target.value)}
					variant="outlined"
					size="small"
					helperText={row.errorMessage}
					error={row.errorMessage}
				/>
				<StyledButton color="primary" variant="outlined" onClick={handleApplyPromoCode}>
					Apply
				</StyledButton>
			</PromoCodeWrapper>
		);
	} else if (row.type === 'primaryBold') {
		return <BoldText color="primary">{row.value}</BoldText>;
	} else return <Typography>{row.value}</Typography>;
};

export { PriceColumn };
