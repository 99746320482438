import React, { FC, useEffect, useState } from 'react';
import { IonGrid, IonRow, IonList, IonListHeader, IonLabel, IonItem } from '@ionic/react';
import { FormattedMessage, injectIntl } from 'react-intl';

import isAuthenticated from '../../components/Authentication/Authenticated';

import classes from './UserForm.module.css';
import classNames from 'classnames';
import Messages from './UserForm.messages';
import { User } from '../../types/types';
import { getSessionTimeDescription } from '../../utils/time';
import { useTypedSelector } from '../../reducers';

interface UserFormProps {
	user: User;
	orgId: string;
}

const UserFormNonEditable: FC<UserFormProps> = (props: any) => {
	const { orgId, user } = props;
	const [userAdded] = useState(new Date(user.dateJoined || 1));
	const [isAdmin, setIsAdmin] = useState(false);
	const [lastLogin, setLastLogin] = useState('never');
	const [userInfo, setUserInfo] = useState(JSON.parse(JSON.stringify(user)));
	const { username } = user;

	// const userGroups = useTypedSelector(
	// 	state => state.userGroupsState.userGroupsByOrganizationId[orgId]
	// );
	const deviceGroups = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId[orgId]
	);
	const users = useTypedSelector(state => state.usersState.usersByOrganizationId);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	useEffect(() => {
		if (users) {
			const u = users[orgId][username];
			setIsAdmin(u.role && u.role?.match(/admin|Admin/g) ? true : false);
			setUserInfo(JSON.parse(JSON.stringify(u)));
		}
	}, [orgId, username, users]);

	useEffect(() => {
		setLastLogin(
			getSessionTimeDescription(
				user.lastLogin ? new Date(user.lastLogin) : undefined,
				new Date()
			)
		);
	}, [user.lastLogin]);

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 */
	const currentEntities = (
		entity: string,
		labelProperty: string,
		valueProperty: string,
		type?: string
	) => {
		let selectedEntities = [];
		let allEntities: any;
		let parameter: any;
		// if (entity === 'userGroup') {
		// 	allEntities = userGroups;
		// 	parameter = 'userGroupsIds';
		// 	// } else if (entity === 'devices') {
		// 	// 	allEntities = devices.items;
		// 	// 	parameter = 'devicesIds';
		// } else
		if (entity === 'deviceGroup') {
			allEntities = deviceGroups;
			parameter = 'deviceGroupsIds';
		}

		for (let key in allEntities) {
			if (userInfo != null && userInfo[parameter] != null) {
				for (let i in userInfo[parameter]) {
					if (entity === 'userGroup' || entity === 'deviceGroup') {
						if (allEntities[key][valueProperty] === userInfo[parameter][i]) {
							selectedEntities.push(allEntities[key]);
						}
					}
				}
			}
		}

		const options: any = [];
		if (selectedEntities != null && selectedEntities.length > 0) {
			const keyObj: any = {};
			for (let key in selectedEntities) {
				if (keyObj[key] === undefined) {
					keyObj[key] = key;
					options.push({
						label: selectedEntities[key][labelProperty],
						value: selectedEntities[key][valueProperty],
					});
				}
			}
		}

		if (type === 'label') {
			let optionsLabel: Array<any> = [];
			for (let option of options) {
				if (!optionsLabel.includes(option.label)) optionsLabel.push(option.label);
			}

			return optionsLabel;
		}
		return options;
	};

	return (
		<form className={classes.editForm} id="editUserForm">
			<IonGrid className={classes.formGrid}>
				<IonRow>
					<IonList className="ion-padding">
						<IonListHeader>
							<IonLabel>
								<FormattedMessage {...Messages.userDetails} />
							</IonLabel>
						</IonListHeader>
						<IonItem disabled>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.name} />
							</IonLabel>
							<IonLabel className={classes.userName}>
								{userInfo.firstName || ''} {userInfo.lastName || ''}
							</IonLabel>
						</IonItem>
						<IonItem disabled>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.email} />
							</IonLabel>
							<IonLabel className={classes.inputLb}>{username}</IonLabel>
						</IonItem>
						<IonItem disabled>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.administrator} />
							</IonLabel>
							<IonLabel
								position="fixed"
								className={classNames(classes.formLb, classes.inputLb)}
							>
								{isAdmin ? (
									<FormattedMessage {...Messages.yes} />
								) : (
									<FormattedMessage {...Messages.no} />
								)}
							</IonLabel>
						</IonItem>
						<IonItem disabled>
							<IonLabel position="fixed" className={classes.formLb}>
								<FormattedMessage {...Messages.dateAdded} />
							</IonLabel>
							<IonLabel className={classes.inputLb}>
								{`${userAdded.getDate()}/${userAdded.getMonth() +
									1}/${userAdded.getFullYear()}`}
							</IonLabel>
						</IonItem>
						{spinoutType !== 'beam' && (
							<div>
								<IonItem disabled>
									<IonLabel
										position="fixed"
										className={classNames(classes.subTitleLb, classes.formLb)}
									>
										<FormattedMessage {...Messages.lastLogin} />:
									</IonLabel>
								</IonItem>
								<IonItem disabled>
									<IonLabel position="fixed" className={classes.formLb}>
										<FormattedMessage {...Messages.timeAgo} />:
									</IonLabel>
									<IonLabel className={classes.inputLb}>{lastLogin}</IonLabel>
								</IonItem>

								<IonItem disabled>
									<IonLabel position="fixed" className={classes.formLb}>
										<FormattedMessage {...Messages.softwareVersion} />:
									</IonLabel>
									<IonLabel className={classes.inputLb}>1.0.0</IonLabel>
								</IonItem>

								{/* <IonItem disabled>
									<IonLabel
										position="fixed"
										className={classNames(classes.subTitleLb, classes.formLb)}
									>
										<FormattedMessage {...Messages.linkedTo} />:
									</IonLabel>
									<IonLabel className={classes.inputLb} />
								</IonItem> */}
								<IonItem>
									<IonLabel
										position="fixed"
										className={classNames(classes.formLb)}
									>
										<FormattedMessage {...Messages.robotGroup} />:
									</IonLabel>
									{currentEntities(
										'deviceGroup',
										'name',
										'deviceGroupId',
										'label'
									).map((groupName: any, i: number) => {
										return (
											<IonLabel
												position="fixed"
												className={classes.formLbItem}
												key={i}
											>
												{groupName}
											</IonLabel>
										);
									})}
								</IonItem>
								{/* <IonItem>
									<IonLabel
										position="fixed"
										className={classNames(classes.formLb)}
									>
										<FormattedMessage {...Messages.userGroups} />:
									</IonLabel>
									{currentEntities(
										'userGroup',
										'name',
										'userGroupId',
										'label'
									).map((groupName: any, i: number) => {
										return (
											<IonLabel
												position="fixed"
												className={classes.formLbItem}
												key={i}
											>
												{groupName}
											</IonLabel>
										);
									})}
								</IonItem> */}
							</div>
						)}
					</IonList>
				</IonRow>
			</IonGrid>
		</form>
	);
};

export default injectIntl(isAuthenticated(UserFormNonEditable, 'UserFormNonEditable'));
