import React from 'react';
import styled from 'styled-components';
import { IconAndText } from '../../atoms/IconAndText';
import { IoCheckmark } from 'react-icons/io5';
import { Typography } from '@material-ui/core';

const CardContent = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
}));

const Title = styled(Typography)(({ theme, selected }) => ({
	fontWeight: 500,
	marginBottom: 8,
	color: selected ? theme.palette.greyPalette.grey6 : theme.palette.greyPalette.grey8,
}));

const StyledCheckmarkIcon = styled(IoCheckmark)(({ theme, selected }) => ({
	height: 16,
	width: 16,
	color: selected ? theme.palette.greyPalette.grey6 : theme.palette.greyPalette.grey9,
}));

export const Content = ({title, selected, features}) => {
	return (
		<CardContent>
			<Title selected={selected} variant="body1">
				{title}
			</Title>
			{features.map(feature => (
				<IconAndText
					color={selected ? 'white' : undefined}
					icon={<StyledCheckmarkIcon selected={selected} />}
				>
					{feature}
				</IconAndText>
			))}
		</CardContent>
	);
};
