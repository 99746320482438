import React, { useRef } from 'react';
import './index.scss';
import { PropsFromParent } from './model';
import useNavController, { NavKey } from './../useKeyConverter';

const active = '#56ae4d';
const inactive = '#8e8c89';

const Slider: React.FC<PropsFromParent> = ({ onChange, value, icon, onIconClick, id }) => {
	const inputRef = useRef<any>(null);

	const navController = useNavController();

	const handleChange = (min: any, max: any) => (event: any) => {
		onChange(event.target.value);
		const value = event.target.value;
		const progress = (value / max) * 100 + '%';
		const newBackgroundStyle = `linear-gradient(90deg, ${active} 0% ${progress}%,   ${inactive} ${progress}% 100%)`;
		inputRef.current.style.background = newBackgroundStyle;
	};

	const minValue = 0;
	const maxValue = 100;
	const progress = (parseInt(value) / maxValue) * 100 + '%';

	const styleInput = {
		background: `linear-gradient(90deg, ${active} 0% ${progress},   ${inactive} ${progress} 100%)`,
	};

	const onWheelChange = (deltaY: number) => {
		if (deltaY < 0) {
			if (parseInt(value) > 90) {
				onChange(100);
			} else {
				onChange(parseInt(value) + 10);
			}
		} else {
			if (parseInt(value) < 10) {
				onChange(0);
			} else {
				onChange(parseInt(value) - 10);
			}
		}
	};

	return (
		<div className="sliderContainer" id={id}>
			<input
				ref={inputRef}
				id="sliderId"
				className="inputR"
				name="sliderName"
				type="range"
				min={minValue}
				max={maxValue}
				value={value}
				onChange={handleChange(minValue, maxValue)}
				style={styleInput}
				onWheel={event => onWheelChange(event.deltaY)}
				onKeyDown={event => {
					event.preventDefault();
				}}
				onKeyUp={event => {
					event.preventDefault();
				}}
			/>
		</div>
	);
};

export default Slider;
