import React, { FC, useCallback, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { IonLabel } from '@ionic/react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';

import classes from './Robots.module.css';
import classNames from 'classnames';
import Messages from './Robots.messages';

import isAuthenticated from '../Authentication/Authenticated';
import { Device } from '../../types/types';
import ListGrid from '../ListGrid/ListGrid';
import RobotName from './RobotName';
import RobotGroupName from './RobotGroupName';
import RobotStatus from './RobotStatus';
import RobotBattLevel from './RobotBattLevel';
import RobotJoinedDate from './RobotJoinedDate';
import RobotDefaultProps from './RobotDefaultProps';
import RobotMoveGroup from '../RobotMoveGroup/RobotMoveGroup';

interface RobotsProps {
	robotHeaders: Array<Record<string, any>>;
	setSelectedDeviceId: any;
	setIsEditing: any;
	data: Array<any>;
}

const RobotsList: FC<RobotsProps> = (props: any) => {
	const {
		data,
		setSelectedDeviceId,
		setIsEditing,
		robotHeaders,
		selectRobots,
		selectedRobots,
		handleOnCheck,
		checkAll,
		unCheckAll,
	} = props;
	const [pageSize] = useState(11);
	const [showTransfer, setShowTransfer] = useState(false);
	const [selectedRobot, setSelectedRobot] = useState();

	const formatTemplate = useCallback(
		(item: any, property: string) => {
			switch (property) {
				case 'name':
					return (
						<RobotName
							selectRobots={selectRobots}
							robotId={item.deviceId}
							orgId={item.orgId}
						/>
					);
				case 'deviceGroupName':
					return <RobotGroupName robotId={item.deviceId} orgId={item.orgId} />;
				case 'status':
					return <RobotStatus robotId={item.deviceId} orgId={item.orgId} />;
				case 'level':
					return <RobotBattLevel robotId={item.deviceId} orgId={item.orgId} />;
				case 'dateJoined':
					return <RobotJoinedDate robotId={item.deviceId} orgId={item.orgId} />;
				default:
					return (
						<RobotDefaultProps
							robotId={item.deviceId}
							orgId={item.orgId}
							property={property}
						/>
					);
			}
		},
		[selectRobots]
	);

	const onEdit = (device: Device) => {
		setSelectedDeviceId(device.deviceId);
		setIsEditing(true);
	};

	const check = (item: any) => {
		return selectedRobots.some((robot: any) => robot.name === item.name);
	};

	return (
		<>
			<ListGrid
				headers={robotHeaders}
				data={data}
				pageSize={pageSize}
				itemTemplate={(item, property) => {
					return formatTemplate(item, property);
				}}
				moreContent={item => {
					return (
						<table className={classes.moreList}>
							<tbody>
								<tr>
									<td className={classes.itemContent}>
										<IonLabel
											className={classes.itemLb}
											onClick={() => onEdit(item)}
										>
											<FormattedMessage {...Messages.edit} />
										</IonLabel>
									</td>
								</tr>
								<tr>
									<td className={classes.itemContent}>
										<IonLabel
											className={classes.itemLb}
											onClick={() => {
												setShowTransfer(true);
												setSelectedRobot(item);
											}}
										>
											<FormattedMessage {...Messages.moveTo} />
										</IonLabel>
									</td>
								</tr>
							</tbody>
						</table>
					);
				}}
				noDataTemplate={
					<>
						<IonLabel className={classes.noData}>
							<FormattedMessage {...Messages.noRobots} />
						</IonLabel>
						<IonLabel className={classNames(classes.noData, classes.noDataHint)}>
							<FormattedMessage {...Messages.noRobotsHint} />
						</IonLabel>
					</>
				}
				checkAll={() => checkAll(data)}
				handleOnCheck={handleOnCheck}
				unCheckAll={unCheckAll}
				checkItem={check}
				onRowClick={selectRobots ? () => {} : onEdit}
				sortType="SET_DEVICES_SORT_PARAMS"
			/>
			{showTransfer && (
				<RobotMoveGroup
					isOpen={selectedRobot && showTransfer}
					onDismiss={() => {
						setShowTransfer(false);
						setSelectedRobot(undefined);
					}}
					robot={selectedRobot}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => ({
	deviceGroups: state.deviceGroupsState,
	selectedOrganizationType: state.selectedOrganizationState.organization.orgType,
	orgId: state.selectedOrganizationState.organization.orgId,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(RobotsList), 'RobotsList'));
