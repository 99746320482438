import React, { FC, useEffect, useState } from 'react';
import {
	IonLabel,
	IonList,
	IonItem,
	IonCol,
	IonRow,
	IonGrid,
	IonCheckbox,
	IonInput,
} from '@ionic/react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { countries } from 'countries-list';
import { CountrySelect } from '../CustomFormComponents';
import { useForm } from 'react-hook-form';

import isAuthenticated from '../Authentication/Authenticated';

import classes from './OrganizationSetup.module.css';
import Messages from './OrganizationSetup.messages';

import { useTypedSelector } from '../../reducers';
import { equalityFnc } from '../../utils/conformState';

import classNames from 'classnames';

interface BillingShippingProps {
	organization: any;
	monitorizeFormChange?: any;
}

const BillingShipping: FC<BillingShippingProps> = (props: any) => {
	const { intl, organization, monitorizeFormChange } = props;

	const { control, errors, watch, setValue } = useForm();
	const billingCountry = watch('billingDetails_country');
	const shippingCountry = watch('shippingDetails_country');

	const getCountryName = (countryCode: keyof Object): string => {
		if (countries[countryCode] !== undefined) {
			return countries[countryCode].name;
		}
		return '';
	};

	const selectedOrganization = useTypedSelector(
		state => state.selectedOrganizationState.organization,
		(left, right) => equalityFnc(left, right)
	);

	const [checkedRightInputs, setCheckedRightInputs] = useState(() => {
		if (
			JSON.stringify(organization?.billingAddress) ===
			JSON.stringify(organization?.shippingAddress)
		)
			return true;
		else return false;
	});

	const [organizationState, setOrganizationState] = useState<any>(null);

	useEffect(() => {
		if (!organization) {
			return;
		}
		setOrganizationState(JSON.parse(JSON.stringify(organization)));
	}, [organization]);

	useEffect(() => {
		if (!organizationState) {
			return;
		}
		let tempOrganization = JSON.parse(JSON.stringify(organizationState));

		monitorizeFormChange(tempOrganization);
	}, [monitorizeFormChange, organizationState]);

	useEffect(() => {
		if (!organizationState) {
			return;
		}

		let tempOrganizationStr = JSON.stringify(organizationState);
		let tempOrganization = JSON.parse(tempOrganizationStr);

		if (billingCountry) {
			tempOrganization.billingAddress.country = billingCountry.value;
		}

		if (billingCountry && checkedRightInputs) {
			tempOrganization.shippingAddress.country = billingCountry.value;
			setValue('shippingDetails_country', billingCountry);
		}

		if (shippingCountry && !checkedRightInputs) {
			tempOrganization.shippingAddress.country = shippingCountry.value;
			setValue('shippingDetails_country', shippingCountry);
		}

		if (JSON.stringify(tempOrganization) === tempOrganizationStr) {
			return;
		}

		setOrganizationState(tempOrganization);
	}, [billingCountry, checkedRightInputs, organizationState, setValue, shippingCountry]);

	const onInputChange = (side: any, changedProperty: any, value: any) => {
		let tempOrganization = JSON.parse(JSON.stringify(organizationState));

		if (!tempOrganization.billingAddress) {
			tempOrganization.billingAddress = {};
		}

		if (!tempOrganization.shippingAddress) {
			tempOrganization.shippingAddress = {};
		}

		tempOrganization[side][changedProperty] = value[changedProperty];

		if (side === 'billingAddress' && checkedRightInputs === true) {
			tempOrganization.shippingAddress[changedProperty] = value[changedProperty];
		}

		setOrganizationState(tempOrganization);
	};

	const preselectRightInputs = () => {
		let tempOrganization = JSON.parse(JSON.stringify(organizationState));

		if (checkedRightInputs === false) {
			for (let property in tempOrganization.billingAddress) {
				tempOrganization.shippingAddress[property] =
					tempOrganization.billingAddress[property];
			}
		}

		setOrganizationState(tempOrganization);
	};

	if (!organization || !organizationState) {
		return null;
	}

	return (
		<IonGrid className={classNames(classes.billingShippingGrid, 'ion-no-padding')}>
			<IonRow className={classes.header}>
				<IonCol>
					<IonItem className={classes.detailHeaderItem} lines="none">
						<IonLabel className={classes.detailHeader}>
							<FormattedMessage {...Messages.billingDetails} />
						</IonLabel>
					</IonItem>
				</IonCol>
				<IonCol>
					<IonItem className={classes.chkContainer} lines="none">
						<IonLabel className={classes.detailHeader}>
							<FormattedMessage {...Messages.shippingDetails} />
						</IonLabel>
						<IonCheckbox
							onClick={e => {
								if (checkedRightInputs === false) setCheckedRightInputs(true);
								else setCheckedRightInputs(false);
								preselectRightInputs();
							}}
							checked={checkedRightInputs}
							className={classes.sameAsChk}
						/>
						<IonLabel className={classes.sameAsLb}>
							<FormattedMessage {...Messages.sameAs} />
						</IonLabel>
					</IonItem>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol className={classes.firstCol}>
					<IonList className={classes.billingList}>
						<IonItem className={classes.formItem}>
							<IonLabel position="floating">
								{intl.formatMessage({
									id: 'Organizations.companyName',
								})}
							</IonLabel>
							<IonInput
								onIonChange={(selected: any) => {
									onInputChange('billingAddress', 'organizationName', {
										organizationName:
											selected.detail.value ||
											selected.target.children[0]?.value,
									});
								}}
								autofocus
								placeholder={intl.formatMessage({
									id: 'Organizations.companyName',
								})}
								required
								type="text"
								name="organizationNameBilling"
								value={
									organizationState?.billingAddress?.organizationName ||
									selectedOrganization.name
								}
							/>
						</IonItem>

						<IonItem className={classes.formItem}>
							<IonLabel position="floating">
								{intl.formatMessage({
									id: 'Address.street',
								})}
								<span className={classes.required}>*</span>
							</IonLabel>
							<IonInput
								onIonChange={(selected: any) => {
									onInputChange('billingAddress', 'address', {
										address:
											selected.detail.value ||
											selected.target.children[0]?.value,
									});
								}}
								autofocus
								placeholder={intl.formatMessage({
									id: 'Address.addressHint',
								})}
								required
								type="text"
								name="billingDetails_address"
								value={organizationState?.billingAddress?.address}
							/>
						</IonItem>

						<IonItem className={classes.formItem}>
							<IonLabel position="floating">
								{intl.formatMessage({
									id: 'Address.street2',
								})}
							</IonLabel>
							<IonInput
								onIonChange={(selected: any) => {
									onInputChange('billingAddress', 'address2', {
										address2:
											selected.detail.value ||
											selected.target.children[0]?.value,
									});
								}}
								autofocus
								placeholder={intl.formatMessage({
									id: 'Address.addressHint',
								})}
								type="text"
								name="billingDetails_address"
								value={organizationState?.billingAddress?.address2}
							/>
						</IonItem>

						<div className={classes.zipCityContainer}>
							<IonItem className={classes.formItem}>
								<IonLabel position="floating">
									{intl.formatMessage({
										id: 'Address.zip',
									})}
									<span className={classes.required}>*</span>
								</IonLabel>
								<IonInput
									onIonChange={(selected: any) => {
										onInputChange('billingAddress', 'zip', {
											zip:
												selected.detail.value ||
												selected.target.children[0]?.value,
										});
									}}
									autofocus
									type="text"
									name="billingDetails_zip"
									value={organizationState?.billingAddress?.zip}
								/>
							</IonItem>

							<IonItem className={classes.formItem}>
								<IonLabel position="floating">
									{intl.formatMessage({
										id: 'Address.city',
									})}
									<span className={classes.required}>*</span>
								</IonLabel>
								<IonInput
									onIonChange={(selected: any) => {
										onInputChange('billingAddress', 'city', {
											city:
												selected.detail.value ||
												selected.target.children[0]?.value,
										});
									}}
									autofocus
									type="text"
									name="billingDetails_city"
									value={organizationState?.billingAddress?.city}
								/>
							</IonItem>
						</div>

						<IonItem className={classes.formItem}>
							<IonLabel position="floating">
								{intl.formatMessage({
									id: 'Address.state',
								})}
							</IonLabel>
							<IonInput
								onIonChange={(selected: any) => {
									onInputChange('billingAddress', 'state', {
										state:
											selected.detail.value ||
											selected.target.children[0]?.value,
									});
								}}
								autofocus
								type="text"
								name="billingDetails_state"
								value={organizationState?.billingAddress?.state}
							/>
						</IonItem>

						<div className={classes.countrySelectContainer}>
							<CountrySelect
								control={control}
								name="billingDetails_country"
								errors={errors}
								menuPlacement="top"
								marginLess
								required
								initializedValue={organizationState.billingAddress.country}
							/>
						</div>
					</IonList>
				</IonCol>
				<IonCol className={classes.secondCol}>
					<IonList>
						<IonItem className={classes.formItem}>
							<IonLabel position="floating">
								{intl.formatMessage({
									id: 'Organizations.companyName',
								})}
							</IonLabel>
							<IonInput
								onIonChange={(selected: any) => {
									onInputChange('shippingAddress', 'organizationName', {
										organizationName:
											selected.detail.value ||
											selected.target.children[0]?.value,
									});
								}}
								autofocus
								placeholder={intl.formatMessage({
									id: 'Organizations.companyName',
								})}
								disabled={checkedRightInputs}
								required
								type="text"
								name="organizationName"
								value={organizationState?.shippingAddress?.organizationName}
							/>
						</IonItem>

						<IonItem className={classes.formItem}>
							<IonLabel position="floating">
								{intl.formatMessage({
									id: 'Address.street',
								})}
								<span className={classes.required}>*</span>
							</IonLabel>
							<IonInput
								onIonChange={(selected: any) => {
									onInputChange('shippingAddress', 'address', {
										address:
											selected.detail.value ||
											selected.target.children[0]?.value,
									});
								}}
								disabled={checkedRightInputs}
								autofocus
								placeholder={intl.formatMessage({
									id: 'Address.addressHint',
								})}
								required
								type="text"
								name="shippingAddress_Address"
								value={organizationState?.shippingAddress?.address}
							/>
						</IonItem>

						<IonItem className={classes.formItem}>
							<IonLabel position="floating">
								{intl.formatMessage({
									id: 'Address.street2',
								})}
							</IonLabel>
							<IonInput
								onIonChange={(selected: any) => {
									onInputChange('shippingAddress', 'address2', {
										address2:
											selected.detail.value ||
											selected.target.children[0]?.value,
									});
								}}
								disabled={checkedRightInputs}
								autofocus
								placeholder={intl.formatMessage({
									id: 'Address.addressHint',
								})}
								required
								type="text"
								name="shippingAddress_Address2"
								value={organizationState?.shippingAddress?.address2}
							/>
						</IonItem>

						<div className={classes.zipCityContainer}>
							<IonItem className={classes.formItem}>
								<IonLabel position="floating">
									{intl.formatMessage({
										id: 'Address.zip',
									})}
									<span className={classes.required}>*</span>
								</IonLabel>
								<IonInput
									onIonChange={(selected: any) => {
										onInputChange('shippingAddress', 'zip', {
											zip:
												selected.detail.value ||
												selected.target.children[0]?.value,
										});
									}}
									disabled={checkedRightInputs}
									autofocus
									placeholder={intl.formatMessage({
										id: 'Address.addressHint',
									})}
									required
									type="text"
									name="shippingDetails_zip"
									value={organizationState?.shippingAddress?.zip}
								/>
							</IonItem>

							<IonItem className={classes.formItem}>
								<IonLabel position="floating">
									{intl.formatMessage({
										id: 'Address.city',
									})}
									<span className={classes.required}>*</span>
								</IonLabel>
								<IonInput
									onIonChange={(selected: any) => {
										onInputChange('shippingAddress', 'city', {
											city:
												selected.detail.value ||
												selected.target.children[0]?.value,
										});
									}}
									disabled={checkedRightInputs}
									autofocus
									placeholder={intl.formatMessage({
										id: 'Address.addressHint',
									})}
									required
									type="text"
									name="shippingDetails_city"
									value={organizationState?.shippingAddress?.city}
								/>
							</IonItem>
						</div>

						<IonItem className={classes.formItem}>
							<IonLabel position="floating">
								{intl.formatMessage({
									id: 'Address.state',
								})}
							</IonLabel>
							<IonInput
								onIonChange={(selected: any) => {
									onInputChange('shippingAddress', 'state', {
										state:
											selected.detail.value ||
											selected.target.children[0]?.value,
									});
								}}
								disabled={checkedRightInputs}
								autofocus
								placeholder={intl.formatMessage({
									id: 'Address.addressHint',
								})}
								required
								type="text"
								name="shippingDetails_state"
								value={organizationState?.shippingAddress?.state}
							/>
						</IonItem>

						{checkedRightInputs && (
							<IonItem className={classes.formItem}>
								<IonLabel position="floating">
									{intl.formatMessage({
										id: 'Address.country',
									})}
								</IonLabel>
								<IonInput
									disabled
									type="text"
									name=""
									value={getCountryName(
										organizationState?.billingAddress?.country
									)}
								/>
							</IonItem>
						)}
						<div className={checkedRightInputs ? classes.hidden : ''}>
							<div className={classes.countrySelectContainer}>
								<CountrySelect
									control={control}
									name="shippingDetails_country"
									errors={errors}
									menuPlacement="top"
									marginLess
									required
									disabled={checkedRightInputs}
									initializedValue={organizationState?.shippingAddress?.country}
								/>
							</div>
						</div>
					</IonList>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default injectIntl(isAuthenticated(BillingShipping, 'BillingShipping'));
