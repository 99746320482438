import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { Typography } from '@material-ui/core';

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
}));

const PriceText = styled(Typography)(({ theme, selected }) => ({
	color: selected ? theme.palette.greyPalette.grey6 : theme.palette.primary.main,
}));

const SelectedWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
}));

const SelectedIcon = styled(IoCheckmarkCircleOutline)(({ theme }) => ({
	height: 16,
	width: 16,
	color: theme.palette.greyPalette.grey6,
}));

const SelectedText = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	lineHeight: '16px',
	fontFamily: 'roboto',
	paddingLeft: 4,
	color: theme.palette.greyPalette.grey6,
}));


export const Footer = ({selected, price}) => {
	return (
		<Wrapper>
			<PriceText selected={selected} variant="body1">
				{price}
			</PriceText>
			<SelectedWrapper>
				<SelectedIcon selected={selected} />
				<SelectedText selected={selected}>
					<FormattedMessage id="selected" defaultMessage="Selected" />
				</SelectedText>
			</SelectedWrapper>
		</Wrapper>
	);
};
