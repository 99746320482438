import React, { FC } from 'react';

import isAuthenticated from '../Authentication/Authenticated';
import { injectIntl } from 'react-intl';

import classes from './RobotForm.module.css';
import RobotDetails from './RobotDetails';
import RobotDetailsForm from './RobotDetailsForm';

interface RobotFormProps {
	isEditable?: boolean;
	control: any;
	device: any;
}

const RobotFormContainer: FC<RobotFormProps> = props => {
	const { isEditable, control, device } = props;
	return (
		<div className={classes.editForm}>
			<RobotDetails classStyle={isEditable ? classes.hidden : ''} device={device} />
			<RobotDetailsForm
				classStyle={!isEditable ? classes.hidden : ''}
				device={device}
				control={control}
			/>
		</div>
	);
};

export default injectIntl(isAuthenticated(RobotFormContainer, 'RobotFormContainer'));
