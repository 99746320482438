import { MqttClient } from 'mqtt';
import Signaling, {
	ACKPayload,
	ICEPayload,
	SignalingErrorACKNotAllowedBusy,
	SignallingErrorACKTimeout,
} from './signalling';

export type PeerConnectionEndReasonCode =
	| 'NOT_ALLOWED_BUSY'
	| 'NO_ACK'
	| 'PEER_HANGUP'
	| 'LOCAL_HANGUP'
	| 'CLEANUP'
	| 'FAILED_STATE_TIMED_OUT'
	| 'PAUSED_STATE_TIMED_OUT'
	| 'ERROR';

type LocalTrackKey = 'pilotVideo' | 'pilotAudio';
export type RemoteTrackKey = 'wide_cam' | 'zoom_cam' | 'nav_cam' | 'audio';

export const NAV_DATACHANNEL_LABEL = 'nav-datachannel';
export const NON_NAV_DATACHANNEL_LABEL = 'non-nav-datachannel';
export const NON_NAV_DATACHANNEL_LABEL__LEGACY = 'other-datachannel';

/** Simple UUID generator.
 * This serves the purpose of generating a random sequence of numbers;
 * a third-party package is not necessary */
const generateGuid = () => {
	let result, i, j;
	result = '';
	for (j = 0; j < 32; j++) {
		if (j === 8 || j === 12 || j === 16 || j === 20) result = result + '-';
		i = Math.floor(Math.random() * 16)
			.toString(16)
			.toUpperCase();
		result = result + i;
	}
	return result;
};

/**
 * Removes characters that are not compliant with mqtt topics rules - plus(+) and hash (#)
 * @param {string} username
 */
const normalizeRawUsername = (username: string) => {
	return username.replace('+', '.').replace('#', '.');
};

export enum RobotPrimaryCamera {
	WIDE_CAM = 'wide_cam',
	ZOOM_CAM = 'zoom_cam',
}

export interface PrimaryCameraState {
	currentPrimaryCamera: RobotPrimaryCamera;
	isChangingPrimaryCameraTo: RobotPrimaryCamera | null;
}

type IPeerConnectionEvent = 'pause' | 'unpause';

const PAUSED_CONNECTION_TIMEOUT__MS = 15 * (60 * 1000);
const FAILED_CONNECTION_TIMEOUT__MS = 10 * 1000;

// const STATS_SAMPLING_PERIOD_MS = 200;
export default class PeerConnectionWithSignalling {
	private _uuid = generateGuid();
	private _localId: string;
	private _capabilities?: ACKPayload['capabilities'];

	private eventTarget = new EventTarget();
	public addEventListener = (event: IPeerConnectionEvent, listener: (...args: []) => void) => {
		this.eventTarget.addEventListener(event, listener);
	};
	public removeEventListener = (event: IPeerConnectionEvent, listener: (...args: []) => void) => {
		this.eventTarget.removeEventListener(event, listener);
	};

	// private rtpStreamStatsSender: RTCRtpStreamStatsSender<LocalTrackKey | RemoteTrackKey>;

	private signallingClient: Signaling;
	private pc: RTCPeerConnection | undefined;

	private nonNavDatachannel: RTCDataChannel | undefined;
	private remoteMedia: Partial<
		Record<RemoteTrackKey, { track: MediaStreamTrack; transceiver: RTCRtpTransceiver }>
	> = {};
	private get remoteMediaTracks(): MediaStreamTrack[] {
		return Object.values(this.remoteMedia).reduce((otherTracks, val) => {
			if (val === undefined) return otherTracks;
			return [...otherTracks, val.track];
		}, [] as MediaStreamTrack[]);
	}
	private localMediaTracks: Array<MediaStreamTrack> = [];
	private remoteTracksMidsMap: Record<string, RemoteTrackKey> = {
		video0: 'wide_cam',
		video1: 'nav_cam',
		audio2: 'audio',
		video3: 'zoom_cam',
	};
	private _primaryCameraState: PrimaryCameraState = {
		// todo: fixme: Get the initial value from robot in the HELLO request
		currentPrimaryCamera: RobotPrimaryCamera.WIDE_CAM,
		isChangingPrimaryCameraTo: null,
	};
	public get primaryCameraState() {
		return this._primaryCameraState;
	}
	private _onPrimaryCameraStateChanged = (newState: PrimaryCameraState) => {
		this._primaryCameraState = newState;
		if (this.onPrimaryCameraStateChange !== null) {
			this.onPrimaryCameraStateChange(this.primaryCameraState);
		}
	};

	private _primaryMediaStream: MediaStream = new MediaStream();
	public get primaryMediaStream() {
		return this._primaryMediaStream;
	}

	// public onTrack:
	// 	| ((track: MediaStreamTrack, key: RemoteTrackKey, transceiver: RTCRtpTransceiver) => void)
	// 	| null;
	public onStarted: (() => void) | null;
	public onEnded: ((reason: PeerConnectionEndReasonCode) => void) | null;
	public onDataChanel: ((datachannel: RTCDataChannel) => void) | null;
	public onConnectionStateChange: ((connectionState: RTCPeerConnectionState) => void) | null;
	public onPrimaryCameraStateChange: ((newState: PrimaryCameraState) => void) | null;
	public onPrimaryMediaStreamChanged:
		| ((stream: MediaStream, transceiver: RTCRtpTransceiver) => void)
		| null;
	public onNavMediaStreamChanged:
		| ((stream: MediaStream, transceiver: RTCRtpTransceiver) => void)
		| null;

	constructor(
		_localId: string,
		private _peerId: string,
		localInfo: { name: string; avatar?: string },
		mqttClient: MqttClient
	) {
		// this.onTrack = (tr, k, trx) => console.warn('Unhandled callback onTrack', tr, k, trx);
		this.onStarted = () => console.warn('Unhandled callback onStarted');
		this.onEnded = r => console.warn('Unhandled callback onEnded', r);
		this.onDataChanel = d => console.warn('Unhandled callback onDataChanel', d);
		this.onConnectionStateChange = s =>
			console.warn('Unhandled callback onConnectionStateChange', s);

		const normalizedLocalId = normalizeRawUsername(_localId);
		this._localId = normalizedLocalId;

		this.signallingClient = new Signaling(
			mqttClient,
			this._uuid,
			normalizedLocalId,
			_peerId,
			localInfo
		);
		this.signallingClient.onRemoteSDP = this.onPeerSDP.bind(this);
		this.signallingClient.onRemoteICE = this.onPeerICE.bind(this);
		this.signallingClient.onRemoteHangUp = this.onRemoteHangUp.bind(this);

		this.start = this.start.bind(this);
		this.end = this.end.bind(this);
		this.pause = this.pause.bind(this);
		this.unpause = this.unpause.bind(this);
		this.promptIceRestart = this.promptIceRestart.bind(this);
		// this.setVolume = this.setVolume.bind(this);
		// this.setStatusMessage = this.setStatusMessage.bind(this);
	}

	/** Initialize the peer connection, add tracks, and attach callbacks  */
	private async setupPeerConnection(iceServersConfig: RTCIceServer, stream: MediaStream) {
		const peerConnection = new RTCPeerConnection({
			bundlePolicy: 'max-bundle',
			iceServers: [iceServersConfig],
			iceTransportPolicy: 'relay',
		});
		const videoSenders: Array<RTCRtpSender> = [];
		for (const track of stream.getTracks()) {
			const sender = peerConnection.addTrack(track, stream);
			if (track.kind === 'video') {
				videoSenders.push(sender);
				// this.rtpStreamStatsSender.setStatsSource('pilotVideo', track);
			} else if (track.kind === 'audio') {
				// this.rtpStreamStatsSender.setStatsSource('pilotAudio', track);
			}
		}

		// Set preferred params on RTCRtpSender for video
		for (let i = 0; i < videoSenders.length; i++) {
			const sender = videoSenders[i];
			try {
				const params = await sender.getParameters();
				const updatedParams = {
					...params,
					encodings: params.encodings.map(encoding => ({
						...encoding,
						maxBitrate: 0.5 * 10 ** 6, // in bits per second
					})),
					degradationPreference: 'maintain-resolution',
					...({ priority: 'high' } as any),
				};
				await sender.setParameters(updatedParams);
			} catch (error) {
				console.warn(`Error -> peerConnection.transceiver.sender.setParameters`, error);
			}
		}

		const supportsSetCodecPreferences =
			window.RTCRtpTransceiver && 'setCodecPreferences' in window.RTCRtpTransceiver.prototype;
		if (supportsSetCodecPreferences) {
			const { codecs } = RTCRtpSender.getCapabilities('video') as RTCRtpCapabilities;
			console.log('Supported Codecs ', codecs);
			const rearrangedCodecs = [
				...codecs.filter(codec => codec.mimeType === 'video/VP8'),
				...codecs.filter(codec => codec.mimeType === 'video/H264'),
				...codecs.filter(codec => !['video/H264', 'video/VP8'].includes(codec.mimeType)),
			];
			const transceiver = peerConnection
				.getTransceivers()
				.find(t => t.sender && t.sender.track === stream?.getVideoTracks()[0]);
			if (transceiver) {
				// @ts-ignore
				transceiver.setCodecPreferences(rearrangedCodecs);
				console.log('Codec preferences has been set on transceiver');
			} else {
				console.log('transceiver has not been set up on peer connection yet');
			}
		} else {
			console.warn('Unfortunately, specifying preferred codec is not supported');
		}

		peerConnection.ontrack = this._onTrack.bind(this);
		peerConnection.onicecandidate = this.onLocalICE.bind(this);
		peerConnection.onconnectionstatechange = this._onConnectionStateChange.bind(this);
		peerConnection.oniceconnectionstatechange = this._onICEConnectionStateChange.bind(this);
		peerConnection.onicegatheringstatechange = this._onICEGatheringStateChange.bind(this);
		peerConnection.ondatachannel = this._onDataChannel.bind(this);

		this.pc = peerConnection;
	}

	public get capabilities() {
		return this._capabilities;
	}

	public get connectionState() {
		return this.pc?.connectionState || 'new';
	}

	public get uuid() {
		return this._uuid;
	}

	public get localId() {
		return this._localId;
	}

	public get peerId() {
		return this._peerId;
	}

	private started = false;
	public async start(
		localStream: MediaStream,
		initialVolume: number
	): Promise<ACKPayload['capabilities']> {
		console.debug('PeerConnection.start()');

		if (this.started) {
			throw new Error('PeerConnection.start() -> already started');
		}

		try {
			this.started = true;
			const { iceServers, capabilities } = await this.signallingClient.hello(initialVolume);
			this._capabilities = capabilities;
			this.muteMediaTracksBasedOnPauseState(localStream);
			// console.log('ICE_SERVERS', iceServers);
			await this.setupPeerConnection(iceServers, localStream);
			// let the peer know that we are ready for initial offer
			await this.signallingClient.sendREADY().then(this.onStarted);
			// this.rtpStreamStatsSender.start(this.pc!);
			return capabilities;
		} catch (error) {
			// the remote peer did not respond with an OK :(
			console.error('peerConnection.start', error);
			// there was an error with initial signalling stage
			let reason: PeerConnectionEndReasonCode = 'ERROR';
			if (error instanceof SignalingErrorACKNotAllowedBusy)
				// peer is busy and cannot accept call
				reason = 'NOT_ALLOWED_BUSY';
			else if (error instanceof SignallingErrorACKTimeout)
				// peer did not reply at all (within a certain timeout)
				reason = 'NO_ACK';
			this.end(reason);
		}
	}

	private _isEnded = false;
	public get isEnded() {
		return this._isEnded;
	}
	public end(reason: PeerConnectionEndReasonCode = 'LOCAL_HANGUP') {
		console.debug('peerConnection.end', reason);

		if (this._isEnded === true) {
			console.debug('PeerConnection.ed() -> already ended');
			return;
		}

		this._isEnded = true;
		this.cleanupTimeouts();
		// this.rtpStreamStatsSender.stop();
		// notify the remote peer that we are hanging up
		this.signallingClient.hangUp().catch(console.error);
		this.pc?.close();
		this.pc = undefined;
		// we no longer expect media to be played from the remote peer
		this.remoteMediaTracks.forEach(track => track.stop());
		this.remoteMedia = {};
		// NB: We don't end/stop the local tracks in this module.
		// We leave it to the creator of the tracks to end/stop when it deems fit
		this.localMediaTracks = [];
		this.onEnded && this.onEnded(reason);
	}

	private onRemoteHangUp() {
		this.end('PEER_HANGUP');
	}

	private _isPaused = false;
	public get isPaused() {
		return this._isPaused;
	}
	/** Pause the peer connection.
	 * The remote is notified of the pause, and no media is sent or played-from the remote peer
	 */
	public pause() {
		this._isPaused = true;

		try {
			this.nonNavDatachannel?.send('SESSION PAUSE');
		} catch (error) {
			console.error("Unable to send 'SESSION PAUSE' message via datachannel", error);
		}

		this.muteMediaTracksBasedOnPauseState(this.remoteMediaTracks);
		this.muteMediaTracksBasedOnPauseState(this.localMediaTracks);

		this.eventTarget.dispatchEvent(new Event('pause' as IPeerConnectionEvent));

		if (this.pausedConnTimeoutID !== undefined) clearTimeout(this.pausedConnTimeoutID);
		this.pausedConnTimeoutID = setTimeout(
			() => this.end('PAUSED_STATE_TIMED_OUT'),
			PAUSED_CONNECTION_TIMEOUT__MS
		);
	}

	/** Resume the peer connection from a prior paused state.
	 * The remote is notified of the resumption, and media sent or played-from the remote peer
	 */
	public unpause() {
		this._isPaused = false;
		try {
			this.nonNavDatachannel?.send('SESSION UNPAUSE');
		} catch (error) {
			console.error("Unable to send 'SESSION UNPAUSE' message via datachannel", error);
		}
		this.muteMediaTracksBasedOnPauseState(this.remoteMediaTracks);
		this.muteMediaTracksBasedOnPauseState(this.localMediaTracks);

		this.eventTarget.dispatchEvent(new Event('unpause' as IPeerConnectionEvent));

		if (this.pausedConnTimeoutID !== undefined) clearTimeout(this.pausedConnTimeoutID);
	}

	private muteMediaTracksBasedOnPauseState = (
		trackSource: MediaStream | MediaStream[] | MediaStreamTrack | MediaStreamTrack[]
	): void => {
		if (Array.isArray(trackSource))
			return trackSource.forEach((source: MediaStreamTrack | MediaStream) =>
				this.muteMediaTracksBasedOnPauseState(source)
			);
		else if (trackSource instanceof MediaStream)
			return trackSource
				.getTracks()
				.forEach(track => this.muteMediaTracksBasedOnPauseState(track));

		// FIXME: For audio tracks, unmute only if the user had not previously muted the track
		trackSource.enabled = !this._isPaused;
	};

	// NB: For now, these functions will not be exposed.
	// Rather, the caller component, will directly call datachannel.send in the appropriate places
	// The ideal implementation will be to have all of such functions exposed from this class.

	// /** Set the perceived volume of our audio on the remote peer's end  */
	// public setVolume(value: Number) {
	// 	try {
	// 		this.nonNavDatachannel?.send(`VOL ${value}`);
	// 	} catch (error) {
	// 		console.error(`Unable to send 'VOL ${value}' message via datachannel`, error);
	// 	}
	// }

	// /** Send status message to the remote peer */
	// public setStatusMessage(message: String) {
	// 	try {
	// 		this.nonNavDatachannel?.send(`MSG ${message}`);
	// 	} catch (error) {
	// 		console.error(`Unable to send 'MSG ${message}' message via datachannel`, error);
	// 	}
	// }

	private _onTrack(e: RTCTrackEvent) {
		console.debug('peerConnection.pc.onTrack', e);
		const remoteTrackKey = this.remoteTracksMidsMap[e.transceiver.mid!];
		if (remoteTrackKey === undefined) {
			console.error(
				'Invalid mid',
				`mid '${e.transceiver.mid}' does not correspond to any RemoteTrackKey`
			);
			return;
		}

		this.remoteMedia[remoteTrackKey] = { track: e.track, transceiver: e.transceiver };

		if (e.track.kind === 'video') {
			const isEventForCurrentPrimaryCamera =
				remoteTrackKey === this.primaryCameraState.currentPrimaryCamera;
			if (isEventForCurrentPrimaryCamera) {
				this._primaryMediaStream = new MediaStream([
					...this.primaryMediaStream.getAudioTracks(),
					e.track,
				]);
				this.onPrimaryMediaStreamChanged &&
					this.onPrimaryMediaStreamChanged(this.primaryMediaStream, e.transceiver);
			} else if (remoteTrackKey === 'nav_cam') {
				const navMediaStream = new MediaStream([e.track]);
				this.onNavMediaStreamChanged &&
					this.onNavMediaStreamChanged(navMediaStream, e.transceiver);
			}
		} else {
			// audio
			this._primaryMediaStream = new MediaStream([
				...this.primaryMediaStream.getVideoTracks(),
				e.track,
			]);
		}

		// this.onTrack && this.onTrack(e.track, remoteTrackKey, e.transceiver);
		this.muteMediaTracksBasedOnPauseState(e.track);
		// notify the statistics sender that a remote media track is available
		// this.rtpStreamStatsSender.setStatsSource(key, e.track);
	}

	private _onDataChannel(ev: RTCDataChannelEvent) {
		console.info('ondatachannel', ev);
		/** Labels of the other datachannel, which is not used for navigation-related stuff */
		const nonNavLabels = [NON_NAV_DATACHANNEL_LABEL, NON_NAV_DATACHANNEL_LABEL__LEGACY];
		if (nonNavLabels.includes(ev.channel.label)) {
			this.nonNavDatachannel = ev.channel;
		}
		this.onDataChanel && this.onDataChanel(ev.channel);
	}

	private _onICEGatheringStateChange(ev: Event) {
		console.info('ice-gathering-state ', this.pc?.iceGatheringState);
	}

	/** Setup a timeout to end peer connection if `failed` for some time */
	private handleFailedConnectionState = () => {
		if (this.connectionState === 'failed') {
			// a timeout has already been scheduled, abort
			if (this.failedConnStateTimeoutID !== undefined) return;

			const isAllSendersFailed = (this.pc?.getSenders() || []).every(
				sender =>
					sender.transport?.state === 'failed' || sender.transport?.state === 'closed'
			);
			const isAllReceiversFailed = (this.pc?.getReceivers() || []).every(
				receiver =>
					receiver.transport?.state === 'failed' || receiver.transport?.state === 'closed'
			);

			console.log({ isAllReceiversFailed, isAllSendersFailed }, 'FAILED_TRANSPORTS');

			this.failedConnStateTimeoutID = setTimeout(
				() => this.end('FAILED_STATE_TIMED_OUT'),
				FAILED_CONNECTION_TIMEOUT__MS
			);
		} else {
			if (this.failedConnStateTimeoutID !== undefined) {
				clearTimeout(this.failedConnStateTimeoutID);
				this.failedConnStateTimeoutID = undefined;
			}
		}
	};

	private _onConnectionStateChange() {
		console.debug('peerConnection._onConnectionStateChange ', this.connectionState);
		this.handleFailedConnectionState();
		this.onConnectionStateChange && this.onConnectionStateChange(this.connectionState);
	}

	private _onICEConnectionStateChange() {
		const iceConnectionState = this.pc?.iceConnectionState;
		console.debug('peerConnection._onICEConnectionStateChange ', this.connectionState);
		if (iceConnectionState === 'failed') {
			this.signallingClient
				.promptICERestart()
				.catch(error => console.error('Error prompting iceRestart', error));
		}
	}

	/** Callback to handle ICE candidates generated from this local */
	private async onLocalICE(e: RTCPeerConnectionIceEvent) {
		if (!e.candidate) {
			console.debug('peerConnection.onLocalICE NULL');
			return;
		}

		return this.signallingClient
			.sendICE({
				sdpMLineIndex: e.candidate.sdpMLineIndex!,
				candidate: e.candidate?.candidate || null,
			})
			.catch(error => {
				console.error('peerConnection.onLocalICE', error);
			});
	}

	/** Callback to handle sdp from peer */
	private async onPeerSDP(key: string, offer: Omit<RTCSessionDescription, 'toJSON'>) {
		if (offer.type !== 'offer') {
			console.error('peerConnection.onPeerSDP Invalid remote SDP type', offer);
			return;
		} else if (this.pc === undefined) {
			console.error('this.pc is not defined. Call this.setupPeerConnection() first');
			return;
		}

		console.debug('onPeerSDP\n', offer.sdp);
		try {
			// set received offer from peer
			await this.pc.setRemoteDescription(offer);
			// set corresponding answer for the received offer
			await this.pc.setLocalDescription(await this.pc.createAnswer());
			// send answer to peer, via signalling channel
			// We use the same key as what the remote peer sent, to indicate that this answer is for that specific offer
			await this.signallingClient.sendSDPToPeer(key, this.pc.localDescription!);
		} catch (error) {
			// catch any errors and log them only.
			// We really don't want to be throwing here in this callback
			console.error('peerConnection.onPeerSDP', error);
		}
	}

	/** Callback to handle ice from peer */
	private async onPeerICE(data: ICEPayload) {
		if (this.pc === undefined) {
			console.error('this.pc is not defined. Call this.setupPeerConnection() first');
			return;
		}
		console.debug('peerConnection.onPeerICE', data.candidate, data.sdpMLineIndex);
		try {
			await this.pc.addIceCandidate({
				candidate: data.candidate!, // TODO: Check that the incoming candidate is never null
				sdpMLineIndex: data.sdpMLineIndex,
			});
		} catch (err) {
			console.error('peerConnection.onPeerICE error: ', err);
		}
	}

	/** Used to time out and end a session when it remains in the failed state for too long */
	private failedConnStateTimeoutID: ReturnType<typeof setTimeout> | undefined;
	/** Used to time out and end session when it is paused for too long */
	private pausedConnTimeoutID: ReturnType<typeof setTimeout> | undefined;
	private cleanupTimeouts = () => {
		if (this.failedConnStateTimeoutID !== undefined)
			clearTimeout(this.failedConnStateTimeoutID);
		if (this.pausedConnTimeoutID !== undefined) clearTimeout(this.pausedConnTimeoutID);
	};

	public promptIceRestart() {
		this.signallingClient.promptICERestart();
	}

	public togglePrimaryCamera = async (): Promise<RobotPrimaryCamera> => {
		const isSwitchingPrimaryCamera = this.primaryCameraState.isChangingPrimaryCameraTo !== null;
		if (isSwitchingPrimaryCamera) {
			throw new Error(`Cannot switch camera. Already switching`);
		}

		const toCameraType: RobotPrimaryCamera =
			this.primaryCameraState.currentPrimaryCamera === RobotPrimaryCamera.WIDE_CAM
				? RobotPrimaryCamera.ZOOM_CAM
				: RobotPrimaryCamera.WIDE_CAM;

		this._onPrimaryCameraStateChanged({
			...this.primaryCameraState,
			isChangingPrimaryCameraTo: toCameraType,
		});
		const _switch = async () => {
			// eslint-disable-next-line camelcase
			if (!this._capabilities?.super_zoom_1)
				throw new Error('GoBeSuperZoom1 is not enabled for this peer connection');

			type ICommand = 'REQUEST_CAMERA_SWITCH' | 'SHOULD_SWITCH_CAMERA';
			type IEvent =
				| 'CAN_SWITCH_CAMERA'
				| 'CANNOT_SWITCH_CAMERA'
				| 'DID_SWITCH_CAMERA'
				| 'FAILED_TO_SWITCH_CAMERA'
				| 'INVALID_CAMERA_SWITCH_MESSAGE';
			function isEventType(value: string): value is IEvent {
				const possibleValues: Record<IEvent, any> = {
					CAN_SWITCH_CAMERA: true,
					CANNOT_SWITCH_CAMERA: true,
					DID_SWITCH_CAMERA: true,
					FAILED_TO_SWITCH_CAMERA: true,
					INVALID_CAMERA_SWITCH_MESSAGE: true,
				};
				return Object.keys(possibleValues).includes(value);
			}
			/** Send a message to the remote peer over datachannel and wait for a response */
			const makeRequest = (
				dataChannel: RTCDataChannel,
				request: { type: ICommand; value: string; id: string },
				timeoutMs: number = REQUEST_TIMEOUT_MS
			) => {
				return new Promise<{ type: IEvent; value: string }>((resolve, reject) => {
					const onDataChannelMessage = (e: MessageEvent) => {
						const [type, value, forRequestId] = (e.data as string).split(' ');
						if (forRequestId !== request.id) return;

						clearTimeout(timeoutId);
						dataChannel.removeEventListener('message', onDataChannelMessage);

						if (isEventType(type)) resolve({ type: type as IEvent, value });
						else
							reject(
								new Error(`INVALID_RESPONSE ${JSON.stringify({ type, value })}`)
							);
					};
					dataChannel.addEventListener('message', onDataChannelMessage);
					const timeoutId = setTimeout(() => {
						dataChannel.removeEventListener('message', onDataChannelMessage);
						reject(new Error('NO_RESPONSE'));
					}, timeoutMs);
					dataChannel.send(`${request.type} ${request.value} ${request.id}`);
				});
			};

			if (!this.nonNavDatachannel) {
				throw new Error('Datachannel has not been initialized');
			}

			const requestId = generateGuid();
			const REQUEST_TIMEOUT_MS = 10 * 1000;

			await makeRequest(this.nonNavDatachannel, {
				type: 'REQUEST_CAMERA_SWITCH',
				value: toCameraType,
				id: requestId,
			}).then(response => {
				if (response.type === 'CAN_SWITCH_CAMERA') {
					// const mid = Number.parseInt(response.value);
					// const expectedMediaTrackMidKey = `video${mid}`;
					// this.remoteTracksMidsMap[expectedMediaTrackMidKey] = toCameraType;
				} else if (response.type === 'CANNOT_SWITCH_CAMERA')
					throw new Error(`Cannot switch camera. Reason: ${response.value}`);
				else throw Error(`INVALID_RESPONSE: ${JSON.stringify(response)}`);
			});
			return makeRequest(this.nonNavDatachannel!, {
				type: 'SHOULD_SWITCH_CAMERA',
				value: toCameraType,
				id: requestId,
			}).then(response => {
				if (response.type === 'DID_SWITCH_CAMERA') {
					return toCameraType;
				} else if (response.type === 'FAILED_TO_SWITCH_CAMERA')
					throw new Error(`Failed to switch camera. Reason: ${response.value}`);
				else throw new Error(`INVALID_RESPONSE: ${JSON.stringify(response)}`);
			});
		};
		return _switch()
			.then(newCameraType => {
				const mediaTrack = this.remoteMedia[newCameraType]?.track;
				if (mediaTrack) {
					this._primaryMediaStream = new MediaStream([
						...this.primaryMediaStream.getAudioTracks(),
						mediaTrack,
					]);
					const transceiver = this.remoteMedia[newCameraType]!.transceiver;
					this.onPrimaryMediaStreamChanged &&
						this.onPrimaryMediaStreamChanged(this.primaryMediaStream, transceiver);
				}

				this._onPrimaryCameraStateChanged({
					currentPrimaryCamera: newCameraType,
					isChangingPrimaryCameraTo: null,
				});
				return newCameraType;
			})
			.catch(error => {
				this._onPrimaryCameraStateChanged({
					...this.primaryCameraState,
					isChangingPrimaryCameraTo: null,
				});
				throw error;
			});
	};
}
