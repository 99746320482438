import React from 'react';
import { TextField } from '../atoms/TextField';
import { Form } from '../atoms/Form';
import { Grid } from '@material-ui/core';

export const OrganizationForm = ({ formik, hidePhone = false, hideEmail = false, disableFields = false, hideTaxNumber = false }) => {
	return (
		<Form onSubmit={formik.handleSubmit}>
			<Grid container spacing={4} direction="row">
				<Grid item xs={12} md={6}>
					<TextField
						disabled={disableFields}
						id="companyName"
						label="Company Name"
						value={formik.values.companyName}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						variant="standard"
						error={Boolean(formik.errors.companyName)}
						helperText={formik.errors.companyName}
						required
					/>
				</Grid>
				{hideTaxNumber ? null : <Grid item xs={12} md={6}>
					<TextField
						disabled={disableFields}
						autoComplete='off'
						label="VAT/TAX No."
						id="taxNumber"
						variant="standard"
						value={formik.values.taxNumber}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.taxNumber)}
						helperText={formik.errors.taxNumber}
						required
					/>
				</Grid>}
				<Grid item xs={12} md={6}>
					<TextField
						disabled={disableFields}
						label="First Name"
						id="orderPersonFirstName"
						variant="standard"
						value={formik.values.orderPersonFirstName}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.orderPersonFirstName)}
						helperText={formik.errors.orderPersonFirstName}
						required
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						disabled={disableFields}
						label="Last Name"
						id="orderPersonLastName"
						variant="standard"
						value={formik.values.orderPersonLastName}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={Boolean(formik.errors.orderPersonLastName)}
						helperText={formik.errors.orderPersonLastName}
						required
					/>
				</Grid>
				{hidePhone ? null : (
					<Grid item xs={12} md={6}>
						<TextField
							label="Phone"
							id="phoneNumber"
							variant="standard"
							value={formik.values.phoneNumber}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={Boolean(formik.errors.phoneNumber)}
							helperText={formik.errors.phoneNumber}
							required
						/>
					</Grid>
				)}
				{hideEmail ? null : (
					<Grid item xs={12} md={6}>
						<TextField
							label="Email"
							id="emailAddress"
							variant="standard"
							value={formik.values.emailAddress}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={Boolean(formik.errors.emailAddress)}
							helperText={formik.errors.emailAddress}
							required
						/>
					</Grid>
				)}
			</Grid>
		</Form>
	);
};
