import React, { FC, Fragment, useState, useEffect } from 'react';
import {
	IonLabel,
	IonItem,
	IonIcon,
	IonAvatar,
	IonNote,
	IonButton,
	IonList,
	IonListHeader,
} from '@ionic/react';
import {
	closeCircle,
	personCircle,
	timeOutline,
	call,
	checkmarkCircle,
	checkmarkCircleOutline,
	shieldCheckmark,
} from 'ionicons/icons';
import InfoCard from '../InfoCard/InfoCard';
import UserForm from '../UserForm/UserForm';
import EditImageModal from '../EditImageModal/EditImageModal';
import { getUserGroupNames } from '../../utils/formatData';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { publish } from '../../actions/publish';
import Tooltip from 'react-tooltip-lite';

import classes from './UserEdit.module.css';
import classNames from 'classnames';

import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './UserEdit.messages';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';

interface UserEditProps {
	editableUser: any;
	onClose: () => void;
}

const UserEdit: FC<UserEditProps> = (props: any) => {
	// const { selectedOrganization, userGroups, editableUser, onClose } = props;
	const { selectedOrganization, editableUser, onClose } = props;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	const [editable, setEditable] = useState(false);
	const [saved, setSaved] = useState(false);
	const [shouldPublish, setShouldPublish] = useState(false);
	const [cancelled, setCancelled] = useState(false);
	const [editPicOpen, setEditPicOpen] = useState(false);
	const [fullName, setFullName] = useState<string | undefined>();
	// const [groupNames, setGroupNames] = useState([]);

	useEffect(() => {
		if (!editableUser) {
			onClose();
			return;
		} else {
			setFullName(
				((editableUser.firstName || '') + ' ' + (editableUser.lastName || '')).trim()
			);
			// setGroupNames(
			// 	getUserGroupNames(
			// 		editableUser.userGroupsIds,
			// 		selectedOrganization.orgId,
			// 		userGroups
			// 	)
			// );
		}
	}, [editableUser, onClose, selectedOrganization.orgId]);

	const onEditUser = (data: any) => {
		setEditable(true);
	};
	const onDeleteUser = (email: any) => {
		publish(`microservice/${selectedOrganization.orgId}/${encodedUser}/deleteUser`, {
			requestId: 'deleteUserRequestId',
			data: {
				username: email,
			},
		});

		onClose();
	};
	const onSaveUser = () => {
		setSaved(true);
		setShouldPublish(true);
		setCancelled(false);
		setEditable(false);
		setTimeout(() => {
			setSaved(false);
		}, 5000);
	};
	const onSavePicture = (link: any) => {
		publish(`microservice/${selectedOrganization.orgId}/${encodedUser}/updateUserInfo`, {
			data: {
				username: editableUser.username,
				profilePictureLink: link,
			},
			requestId: 'updateUserInfo',
		});
		setEditPicOpen(false);
	};
	const onCancelEdit = () => {
		setEditable(false);
		setSaved(false);
		setCancelled(true);
	};

	if (!editableUser) return <div />;
	return (
		<Fragment>
			<div className={classes.leftSide}>
				<InfoCard
					imgUrl={editableUser.profilePictureLink}
					title={fullName}
					// subTitle={
					// 	groupNames && groupNames.length > 0 ? (
					// 		groupNames.length > 1 ? (
					// 			<IonLabel className={classes.moreNames}>
					// 				<FormattedMessage
					// 					{...Messages.in}
					// 					values={{
					// 						group: groupNames[0],
					// 					}}
					// 				/>
					// 				<Tooltip
					// 					direction="up"
					// 					content={
					// 						<ul className={classes.groupNameItem}>
					// 							{groupNames.slice(1).map((gr: any, i: number) => {
					// 								return (
					// 									<li key={i}>
					// 										<IonLabel>{gr}</IonLabel>
					// 										<br />
					// 									</li>
					// 								);
					// 							})}
					// 						</ul>
					// 					}
					// 				>
					// 					<span className={classes.andLb}>
					// 						<FormattedMessage {...Messages.and} />
					// 					</span>
					// 					<span className={classes.moreInLb}>
					// 						<FormattedMessage
					// 							{...Messages.moreIn}
					// 							values={{
					// 								count: groupNames.length - 1,
					// 							}}
					// 						/>
					// 					</span>
					// 				</Tooltip>
					// 			</IonLabel>
					// 		) : (
					// 			<FormattedMessage
					// 				{...Messages.in}
					// 				values={{
					// 					group: groupNames,
					// 				}}
					// 			/>
					// 		)
					// 	) : null
					// }
					email={editableUser.username}
					noteContent={
						editableUser.role?.match(/admin|Admin/g) ? (
							<>
								<IonNote className={classes.adminNote}>
									<FormattedMessage {...Messages.admin} />
								</IonNote>
								<IonIcon
									className={classes.shieldIcon}
									color="primary"
									slot="icon-only"
									size="small"
									icon={shieldCheckmark}
								/>
							</>
						) : (
							''
						)
					}
					onEditPic={() => setEditPicOpen(true)}
					onClose={onClose}
					onDelete={editableUser && username === editableUser.username ? null : onDeleteUser}
				/>
				{spinoutType !== 'beam' && (
					<div className={classes.recentContainer}>
						<IonList lines="none">
							<IonListHeader>
								<IonIcon color="primary" size="small" icon={timeOutline} />
								<IonLabel color="primary" className={classes.recentLb}>
									<FormattedMessage {...Messages.recentActivity} />
								</IonLabel>
							</IonListHeader>
							<IonItem>
								<IonAvatar slot="start">
									{editableUser.profilePictureLink ? (
										<img
											className={classes.profilePic}
											src={editableUser.profilePictureLink}
											alt="Avatar"
										/>
									) : (
										<IonIcon
											className={classNames(
												classes.profilePic,
												classes.noPic
											)}
											size="large"
											icon={personCircle}
										/>
									)}
								</IonAvatar>
								<IonLabel>{fullName}</IonLabel>
								<IonIcon className={classes.icon} size="small" icon={call} />
								<IonLabel className={classes.info}>
									<FormattedMessage {...Messages.invited} />
								</IonLabel>
								<IonIcon
									className={classes.timeIcon}
									size="small"
									slot="end"
									icon={timeOutline}
								/>
							</IonItem>
						</IonList>
					</div>
				)}
			</div>
			<div className={classes.rightSide}>
				<div className={classes.header}>
					<div className={classes.editBtnContainer}>
						<IonButton
							className={
								editable
									? classNames(classes.editBtn, classes.editable)
									: classes.editBtn
							}
							shape="round"
							onClick={onEditUser}
							disabled={editable}
						>
							<IonIcon src="./assets/img/edit.svg" />
						</IonButton>
						<div
							className={
								editable
									? classes.formBtns
									: classNames(classes.formBtns, classes.hidden)
							}
						>
							<IonIcon
								slot="end"
								size="large"
								icon={checkmarkCircle}
								onClick={onSaveUser}
							/>
							<IonIcon
								slot="end"
								size="large"
								icon={closeCircle}
								onClick={onCancelEdit}
							/>
						</div>
						{!editable && saved ? (
							<>
								<IonIcon
									slot="end"
									size="small"
									color="primary"
									icon={checkmarkCircleOutline}
								/>
								<IonLabel className={classes.savedLb}>
									<FormattedMessage {...Messages.saved} />
								</IonLabel>
							</>
						) : null}
					</div>
					{/* <div className={classes.btnContainer}>
						<IonButton className={classes.detailsBtn} shape="round">
							<IonIcon color="primary" size="small" slot="start" icon={reader} />
							<IonLabel className={classes.info}>
								<FormattedMessage {...Messages.details} />
							</IonLabel>
						</IonButton>
					</div> */}
				</div>
				<UserForm
					user={editableUser}
					isEditable={editable}
					save={shouldPublish}
					cancel={cancelled}
					setEditable={(edit: boolean) => {
						setEditable(edit);
						setCancelled(false);
						setShouldPublish(false);
					}}
					onClose={() => {
						setSaved(false);
						setCancelled(false);
						onClose();
					}}
				/>
			</div>
			<EditImageModal
				isOpen={editPicOpen}
				title={<FormattedMessage {...Messages.editPicTitle} values={{ item: 'User' }} />}
				subTitle={fullName}
				pictureLink={editableUser.profilePictureLink}
				onSave={onSavePicture}
				onDismiss={() => setEditPicOpen(false)}
			/>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	selectedOrganization: state.selectedOrganizationState.organization,
	// userGroups: state.userGroupsState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(UserEdit), 'UserEdit'));
