import React from 'react';
import styled from 'styled-components';
import gobeImg from '../../../../assets/images/GoBeRobotFront.jpg';

const Wrapper = styled('div')(({ theme }) => ({
	height: 425,
	width: 'auto',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: 40,
}));

const StyledImage = styled('img')(({ theme }) => ({
	height: 'auto',
	width: '35%',
}));

export const Caroussel = () => {
	return (
		<Wrapper>
			<StyledImage alt="Gobe robot from front with person" src={gobeImg} />
		</Wrapper>
	);
};
