import React, { useEffect, useState } from 'react';
import {
	IonContent,
	IonPage,
	IonLabel,
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonList,
	IonButton,
} from '@ionic/react';
import { injectIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setParameter } from '../../actions/setParam';
import JWTDecode from 'jwt-decode';

import classNames from 'classnames';
import classes from './SetPassword.module.css';

import FormInputListItem from '../../components/FormInputListItem/FormInputListItem';
import LoginTemplate from '../../components/LoginTemplate/LoginTemplate';
import { automaticLogin } from '../../providers/mqtt';
import { SET_MQTTCLIENT, UPDATE_USER } from '../../actions/types';
import { publish } from '../../actions/publish';
import { eyeSharp, eyeOffSharp, checkmarkCircle } from 'ionicons/icons';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import PasswordRequirements from '../../components/PasswordRequirements/PasswordRequirements';
import { checkLength, checkOneLowerCase, checkOneNumeric, checkOneUpperCase } from '../../utils/validator';

const SetPassword: React.FC = (props: any) => {
	const { resetState, intl, history, setParameter } = props;
	const [requestId, setRequestId] = useState<string | null>();
	const [tokenError, setTokenError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [showInputErrorPassword, setShowInputErrorPassword] = useState(true);
	const [passwordLengthError, setPasswordLengthError] = useState('');
	const [showInputErrorRetyped, setShowInputErrorRetyped] = useState(true);
	const [retypedLengthError, setRetypedLengthError] = useState('');
	const [passwordInputValue, setPasswordInputValue] = useState('');
	const [retypedInputValue, setRetypedInputValue] = useState('');
	const [passwordType, setPasswordType] = useState(true);
	const [passwordTypeConfirm, setPasswordTypeConfirm] = useState(true);
	let { token } = useParams<Record<any, any>>();

	const user = useTypedSelector(state => state.accountState.user);
	const { control, handleSubmit, watch } = useForm();
	const password = watch('password');
	useEffect(() => {
		if (token) {
			try {
				const obj: {
					exp: number;
					iat: number;
					username: string;
					temp_password: string;
				} = JWTDecode(token);

				if (obj.username && obj.temp_password) {
					automaticLogin(obj.username, obj.temp_password, (response: any) => {
						if (response && response.connected) {
							user.loggedIn = true;
							user.username = obj.username;
							user.password = obj.temp_password;
							setParameter('user', UPDATE_USER, user);
							setParameter('client', SET_MQTTCLIENT, response.client);
						} else {
							setParameter('client', SET_MQTTCLIENT, { connected: false });
							alert('Cannot verify mqtt client');
						}
					});
					if (obj.exp < new Date().getTime() / 1000) {
						setTokenError(intl.formatMessage({ id: 'ConfirmPage.tokenExpired' }));
					}
				}
			} catch (error) {
				setTokenError('');
				history.push('/');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	useEffect(() => {
		if (requestId && requestId === resetState.id) {
			if (resetState.success && resetState.type === 'resetPassword') {
				setParameter('', 'CLEAR_PASSWORD_REDUCER', {});
				alert('Password successfully reset!');
				history.push('/');
			} else if (resetState.success === false && resetState.type === 'resetPassword') {
				alert('An error happened, please try setting your password again');
				setParameter('', 'CLEAR_PASSWORD_REDUCER', {});
				window.location.reload();
			}
		}
	}, [history, requestId, resetState, setParameter]);

	const checkPasswordLength = (event: any) => {
		if (!event.detail.value) return;
		const value = event.detail.value;
		const targetName = event.target.name;
		switch (targetName) {
			case 'password':
				setPasswordInputValue(value);
				setPasswordError('');
				if (value.length < 8) {
					setShowInputErrorPassword(true);
					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordLength' })
					);
				} else {
					setShowInputErrorPassword(false);
					setPasswordLengthError('');
				}
				return;
			case 'confirmPassword':
				setRetypedInputValue(value);
				setPasswordError('');
				if (value.length < 8) {
					setShowInputErrorRetyped(true);
					setRetypedLengthError(intl.formatMessage({ id: 'ConfirmPage.passwordLength' }));
				} else {
					if (password !== value) {
						setShowInputErrorRetyped(true);
						setRetypedLengthError(
							intl.formatMessage({ id: 'ConfirmPage.passwordNoMatch' })
						);
					} else {
						setShowInputErrorRetyped(false);
						setRetypedLengthError('');
					}
				}
				return;
			default:
				break;
		}
	};

	const resetPassword = handleSubmit(data => {
		if (user.username) {
			if (!checkLength(data.password)) {
				console.log('the new password is invalid');
				return;
			}
			if (data.password !== data.confirmPassword) {
				setTokenError('');
				setPasswordError(intl.formatMessage({ id: 'ConfirmPage.passwordNoMatch' }));
			} else {
				setRequestId(`resetPasswordId-${user.username}`);

				if (token.includes('&source')) {
					token = token.split('&source')[0];
				}

				publish(`microservice/${b64EncodeUnicode(user.username)}/resetPassword`, {
					requestId: `resetPasswordId-${user.username}`,
					data: { password: data.password, token },
				});
				setTokenError('');
				setPasswordError('');
			}
		} else {
			console.log("Couldn't reset password");
		}
	});

	return (
		<IonPage>
			<IonContent>
				<LoginTemplate
					hintMessage={props.intl.formatMessage({
						id: 'SetPassword.resetWelcome',
					})}
					mainContent={
						<div>
							<IonLabel className={classes.errorContainer}>
								{tokenError || passwordError}
							</IonLabel>
							<IonList className={classNames(classes.forgotPswList, 'ion-padding')}>
								{password && (
									<PasswordRequirements
										password={password}
										checkMarkContainer={
											retypedLengthError !== '' &&
											classes.checkMarkContainerError
										}
									/>
								)}
								<FormInputListItem
									type={passwordType ? 'password' : 'text'}
									name="password"
									control={control}
									rules={{ required: true }}
									errorMsg={
										showInputErrorPassword ? passwordLengthError : undefined
									}
									onChange={checkPasswordLength}
									required
									label={intl.formatMessage({
										id: 'SetPassword.password',
									})}
									placeholderText={intl.formatMessage({
										id: 'LoginPage.passwordHint',
									})}
									iconUrl={passwordType ? eyeSharp : eyeOffSharp}
									iconCallback={() => setPasswordType(prev => !prev)}
								/>
								{ !showInputErrorRetyped && <div className={classes.checkMarkConfirmationContainer}>
									<IonIcon size="small" color="primary" icon={checkmarkCircle} />
								</div>}
								<FormInputListItem
									type={passwordTypeConfirm ? 'password' : 'text'}
									name="confirmPassword"
									control={control}
									rules={{
										required: true,
									}}
									errorMsg={
										showInputErrorRetyped ? retypedLengthError : undefined
									}
									onChange={checkPasswordLength}
									required
									label={intl.formatMessage({
										id: 'SetPassword.retypePassword',
									})}
									placeholderText={intl.formatMessage({
										id: 'SetPassword.retypePassword',
									})}
									iconUrl={passwordTypeConfirm ? eyeSharp : eyeOffSharp}
									iconCallback={() => setPasswordTypeConfirm(prev => !prev)}
								/>
							</IonList>
						</div>
					}
					btnContent={
						<IonButton
							className={classes.btnFont}
							shape="round"
							size="large"
							disabled={
								tokenError ||
								passwordError ||
								retypedLengthError ||
								passwordLengthError ||
								passwordInputValue.length < 8 ||
								retypedInputValue.length < 8
									? true
									: false
							}
							onClick={resetPassword}
						>
							{props.intl.formatMessage({
								id: 'SetPassword.reset',
							})}
						</IonButton>
					}
				/>
			</IonContent>
		</IonPage>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	resetState: state.setPasswordState,
});

export default injectIntl(connect(mapStateToProps, { setParameter })(SetPassword));
