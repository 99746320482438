import React, { FC, useState, useEffect } from 'react';
import {
	IonIcon,
	IonLabel,
	IonList,
	IonItem,
	IonListHeader,
	IonAvatar,
	IonSegment,
	IonSegmentButton,
	IonInput,
	IonCol,
	IonSelect,
	IonSelectOption,
	IonRow,
	IonGrid,
} from '@ionic/react';
import { personCircle, camera, globe, checkmarkCircle, person } from 'ionicons/icons';
import FormInputListItem from '../FormInputListItem/FormInputListItem';

import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';
import isURL from 'validator/lib/isURL';
import scaleImage from '../../actions/imageScaler';
import { useForm } from 'react-hook-form';
import { LanguageSelect, PhoneNumberInput } from '../CustomFormComponents';

import capitalize from '../../actions/capitalize';

import classes from './AccountSetup.module.css';
import classNames from 'classnames';

import Messages from './AccountSetup.messages';
import { eyeSharp, eyeOffSharp } from 'ionicons/icons';
import { useTypedSelector } from '../../reducers';
import PasswordRequirements from '../PasswordRequirements/PasswordRequirements';
import { checkPassword } from '../../utils/validator';

interface ProfileSettingsProps {
	goTo?: () => void;
	monitorizeFormChange?: any;
	nextButtonDisableFunctionHandle?: any;
	updatePhoneNumber: (PhoneNumber: string) => undefined;
}

const ProfileSettings: FC<ProfileSettingsProps> = (props: any) => {
	const {
		intl,
		monitorizeFormChange,
		nextButtonDisableFunctionHandle,
		updatePhoneNumber,
		isSSOUser,
	} = props;
	const { control, watch, errors } = useForm();
	const password = watch('password');
	const retypedPassword = watch('retypedPassword');
	const phoneNumber = watch('phoneNumber');

	const profilePictureLink = useTypedSelector(
		state => state.accountState.user.profilePictureLink
	);

	const [passwordType, setPasswordType] = useState(false);
	const [passwordTypeConfirm, setPasswordTypeConfirm] = useState(false);

	// set this to false when the proper service is up and running
	const [expandUrl, setExpandUrl] = useState(true);
	const [userPicture, setUserPicture] = useState<any>();
	const [reader] = useState(new FileReader());

	const [showInputErrorPassword, setShowInputErrorPassword] = useState(false);
	const [passwordLengthError, setPasswordLengthError] = useState('');
	const [showInputErrorRetyped, setShowInputErrorRetyped] = useState(false);
	const [retypedLengthError, setRetypedLengthError] = useState('');

	const user = useTypedSelector(state => state.accountState.user);

	useEffect(() => {
		setUserPicture(profilePictureLink);
	}, [profilePictureLink]);

	useEffect(() => {
		if (typeof phoneNumber === 'string') {
			updatePhoneNumber(phoneNumber);
		}
	}, [phoneNumber, updatePhoneNumber]);

	const onChangeContent = (value: string) => {
		console.log(value);
		switch (value) {
			case 'file':
				const e = document.getElementById('fileSelector') as HTMLInputElement;
				e.click();
				break;
			case 'url':
				// uncomment when the proper service is up and running
				// setExpandUrl(!expandUrl);
				break;
			default:
				break;
		}
	};

	const onChangeInput = (label: string, value: any) => {
		monitorizeFormChange(label, value);
	};

	const setPicture = (event: any) => {
		let f = event.target.files[0] || undefined;
		if (f === undefined) return;
		reader.onload = () => {
			scaleImage(reader.result, props, (srcObject: any) => {
				setUserPicture(srcObject);
			});
		};
		reader.readAsDataURL(f);
	};

	const setUrlPicture = (e: CustomEvent) => {
		if (isURL(e.detail.value, { allow_underscores: true })) {
			setUserPicture(e.detail.value);
			monitorizeFormChange('profilePictureLink', e.detail.value);
		} else {
			// display error
		}
	};

	const goTo = (item: string) => {
		props.goTo(item);
	};

	const checkPasswordLength = (event: any) => {
		if (!event.detail.value) return;
		const value = event.detail.value;
		const targetName = event.target.name;

		switch (targetName) {
			case 'password':
				if (value.length < 8) {
					setShowInputErrorPassword(true);
					setPasswordLengthError(
						intl.formatMessage({ id: 'ConfirmPage.passwordLength' })
					);

					if (retypedPassword && retypedPassword.length >= 8) {
						setShowInputErrorRetyped(false);
						setRetypedLengthError('');
					}

					monitorizeFormChange('password', '');
				} else {
					setShowInputErrorPassword(false);
					setPasswordLengthError('');
				}

				if (value.length >= 8 && retypedPassword && retypedPassword.length >= 8) {
					if (value !== retypedPassword) {
						setShowInputErrorRetyped(true);
						setRetypedLengthError(
							intl.formatMessage({ id: 'ConfirmPage.passwordNoMatch' })
						);
						monitorizeFormChange('password', '');
					} else {
						monitorizeFormChange('password', retypedPassword);
						setShowInputErrorRetyped(false);
						setRetypedLengthError('');
					}
				}
				break;
			case 'retypedPassword':
				if (value.length < 8) {
					monitorizeFormChange('password', '');
					setShowInputErrorRetyped(true);
					setRetypedLengthError(intl.formatMessage({ id: 'ConfirmPage.passwordLength' }));
				} else {
					if (value !== password) {
						setShowInputErrorRetyped(true);
						setRetypedLengthError(
							intl.formatMessage({ id: 'ConfirmPage.passwordNoMatch' })
						);
						monitorizeFormChange('password', '');
					} else {
						monitorizeFormChange('password', password);
						setShowInputErrorRetyped(false);
						setRetypedLengthError('');
					}
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		if (!password || (password && password.length === 0)) {
			setShowInputErrorPassword(false);
			setPasswordLengthError('');
		}

		if (!retypedPassword || (retypedPassword && retypedPassword.length === 0)) {
			setShowInputErrorRetyped(false);
			setRetypedLengthError('');
		}

		if (password && password.length >= 8 && retypedPassword && retypedPassword.length >= 8) {
			if (password === retypedPassword && checkPassword(password)) {
				nextButtonDisableFunctionHandle(false);
			} else nextButtonDisableFunctionHandle(true);
		} else {
			nextButtonDisableFunctionHandle(true);
		}

		if (
			(!password && !retypedPassword) ||
			(password && password.length === 0 && retypedPassword && retypedPassword.length === 0)
		) {
			nextButtonDisableFunctionHandle(false);
		}
	}, [nextButtonDisableFunctionHandle, password, retypedPassword]);

	return (
		<IonGrid className={classes.formGrid}>
			<IonRow>
				<IonList className="ion-padding">
					<IonListHeader className="ion-no-margin">
						<IonIcon className={classes.personIcon} size="small" icon={person} />
						<IonLabel className={classes.detailHeader}>
							<FormattedMessage {...Messages.profile} />
						</IonLabel>
					</IonListHeader>
				</IonList>
			</IonRow>
			<IonRow>
				<IonCol className={classes.firstCol}>
					<IonGrid className={classNames('ion-no-padding')}>
						<IonRow>
							<IonCol className={classes.formItem}>
								<FormInputListItem
									onChange={(selected: any) => {
										onChangeInput('firstName', {
											firstName:
												selected.detail.value ||
												selected.target.children[0]?.value,
										});
									}}
									type="text"
									name="firstName"
									defaultValue={user.firstName}
									label={intl.formatMessage({
										id: 'InviteModal.firstname',
									})}
									placeholderText={intl.formatMessage({
										id: 'InviteModal.firstnameHint',
									})}
									control={control}
									rules={{ required: true }}
									className={classes.formItem}
								/>
							</IonCol>
							<IonCol>
								<FormInputListItem
									type="text"
									name="lastName"
									onChange={(selected: any) => {
										onChangeInput('lastName', {
											lastName:
												selected.detail.value ||
												selected.target.children[0]?.value,
										});
									}}
									defaultValue={user.lastName}
									label={intl.formatMessage({
										id: 'InviteModal.lastname',
									})}
									placeholderText={intl.formatMessage({
										id: 'InviteModal.lastnameHint',
									})}
									control={control}
									rules={{ required: false }}
									className={classes.formItem}
								/>
							</IonCol>
						</IonRow>
					</IonGrid>
					<IonList>
						<IonItem
							lines="none"
							className={classNames(classes.selectContainer, 'ion-no-padding')}
						>
							<div className={classes.customFormComponentContainer}>
								<LanguageSelect
									control={control}
									initializedValue="en"
									name="language__e"
									errors={errors}
									menuPlacement="auto"
									marginLess
									required
								/>
							</div>
						</IonItem>
						<IonItem
							lines="none"
							className={classNames(classes.selectContainer, 'ion-no-padding')}
						>
							<div className={classes.customFormComponentContainer}>
								<PhoneNumberInput
									control={control}
									errors={errors}
									initializedCountry="DK"
									marginLess
									name="phoneNumber"
								/>
							</div>
						</IonItem>

						{isSSOUser ? (
							''
						) : (
							<div>
								<IonList
									className={classNames(classes.forgotPswList, 'ion-padding')}
								>
									{password && (
										<PasswordRequirements
											checkMarkContainer={
												retypedLengthError === ''
													? classes.checkMarkContainer
													: classes.checkMarkContainerError
											}
											customContainer={classes.passwordTooltip}
											password={password}
										/>
									)}
									<FormInputListItem
										type={passwordType ? 'password' : 'text'}
										autocomplete="new-password"
										name="password"
										control={control}
										rules={{ required: true }}
										errorMsg={
											showInputErrorPassword ? passwordLengthError : undefined
										}
										required
										onChange={checkPasswordLength}
										label={intl.formatMessage({
											id: 'SetPassword.newPassword',
										})}
										placeholderText={intl.formatMessage({
											id: 'LoginPage.passwordHint',
										})}
										iconUrl={passwordType ? eyeSharp : eyeOffSharp}
										iconCallback={() => setPasswordType(prev => !prev)}
										className={classes.formItem}
									/>
									{!showInputErrorRetyped && retypedPassword && (
										<div className={classes.checkMarkConfirmationContainer}>
											<IonIcon
												size="small"
												color="primary"
												icon={checkmarkCircle}
											/>
										</div>
									)}
									<FormInputListItem
										type={passwordTypeConfirm ? 'password' : 'text'}
										autocomplete="new-password"
										name="retypedPassword"
										control={control}
										rules={{
											required: true,
										}}
										errorMsg={
											showInputErrorRetyped ? retypedLengthError : undefined
										}
										onChange={checkPasswordLength}
										required
										label={intl.formatMessage({
											id: 'SetPassword.repeatPassword',
										})}
										placeholderText={intl.formatMessage({
											id: 'SetPassword.retypePassword',
										})}
										iconUrl={passwordTypeConfirm ? eyeSharp : eyeOffSharp}
										iconCallback={() => setPasswordTypeConfirm(prev => !prev)}
										className={classes.formItem}
									/>
								</IonList>
							</div>
						)}
					</IonList>
				</IonCol>
				<IonCol>
					<IonList>
						<IonListHeader
							className={classNames(classes.profileListHeader, 'ion-no-padding')}
							lines="none"
						>
							<div className={classes.profileHeader}>
								<IonLabel>
									{user.firstName} {user.lastName}
								</IonLabel>
							</div>
						</IonListHeader>
						<IonItem lines="none">
							<IonAvatar
								className={classNames(
									classes.userAvatar,
									'ion-margin-end ion-margin-start'
								)}
							>
								<img
									className={classNames(classes.fullImage)}
									src={userPicture || personCircle}
									alt="avatar"
								/>
							</IonAvatar>
						</IonItem>
						<IonItem lines="none" className={classes.msgContainer}>
							<IonSegment
								className={classes.tabContainer}
								mode="ios"
								onIonChange={(e: any) => onChangeContent(e.detail.value)}
							>
								<IonSegmentButton
									className={
										expandUrl ? classNames(classes.hidden) : 'ion-no-padding'
									}
									value="file"
									layout="icon-start"
								>
									<IonIcon size="small" icon={camera} />
									<IonLabel>
										<FormattedMessage {...Messages.upload} />
									</IonLabel>
								</IonSegmentButton>
								{expandUrl ? (
									<div className={classNames(classes.urlExpand)}>
										<div
											className={classNames(classes.iconContainer)}
											// uncomment when the proper service is up and running
											// onClick={e => {
											// 	setExpandUrl(!expandUrl);
											// }}
										>
											<IonIcon
												className={classes.iconExpanded}
												size="small"
												icon={globe}
											/>
										</div>
										<IonInput
											className={classNames(classes.expandInput)}
											type="url"
											placeholder={intl.formatMessage({
												id: 'AccountManagementPage.addUrl',
											})}
											onIonChange={e => setUrlPicture(e)}
										/>
									</div>
								) : (
									<IonSegmentButton
										className="ion-no-padding"
										value="url"
										layout="icon-start"
									>
										<IonIcon size="small" icon={globe} />
										<IonLabel>
											<FormattedMessage {...Messages.web} />
										</IonLabel>
									</IonSegmentButton>
								)}
							</IonSegment>

							<input
								className={classNames(classes.hidden)}
								id="fileSelector"
								type="file"
								accept="image/*"
								onChange={setPicture}
							/>
						</IonItem>
					</IonList>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default injectIntl(isAuthenticated(ProfileSettings, 'ProfileSettings'));
