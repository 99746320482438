import React, { FC, useCallback, Fragment } from 'react';
import Select from 'react-select';
import { IonLabel, IonText } from '@ionic/react';
import classNames from 'classnames';
import classes from './ReactSelect.module.css';
import { Controller } from 'react-hook-form';

interface ReactSelectProps {
	name: string;
	control?: any;
	label?: string;
	defaultValue?: any;
	value?: any;
	options: any;
	placeHolder?: string | React.ReactNode;
	className?: any;
	lbClass?: any;
	onChange?: (selected: any) => void;
	isRequired?: boolean;
	errorMsg?: string;
	isDisabled?: boolean;
	isSearchable?: boolean;
	isMulti?: boolean;
	height?: string;
	fullWidth?: boolean;
	position?: string;
}

const ReactSelect: FC<ReactSelectProps> = ({
	name,
	control,
	label,
	defaultValue,
	value,
	options,
	placeHolder,
	className,
	lbClass,
	onChange,
	isRequired,
	errorMsg,
	isDisabled,
	isSearchable,
	isMulti,
	height,
	fullWidth,
	position,
}) => {
	const customStyles = {
		control: (styles: any) => ({
			...styles,
			boxShadow: 'none',
			borderColor: 'gray',
			'&:hover': {
				borderColor: 'gray',
			},
			'&:focus': {
				borderColor: 'gray',
			},
		}),
		option: (styles: any, state: any) => {
			return {
				...styles,
				backgroundColor: state.isDisabled
					? null
					: state.isSelected
					? state.data.color
					: state.isFocused
					? '#ffffff'
					: null,
				borderRadius: state.isDisabled
					? null
					: state.isSelected
					? state.data.color
					: state.isFocused
					? '4px'
					: null,
				color: state.isDisabled ? '#ccc' : state.isSelected ? 'black' : '#5c5956',
				cursor: state.isDisabled ? 'not-allowed' : 'pointer',
				':active': {
					...styles[':active'],
					backgroundColor:
						!state.isDisabled && (state.isSelected ? state.data.color : '#CCC'),
				},
			};
		},
		placeholder: () => ({
			color: '#6d6e70',
		}),
		menu: (styling: any, state: any) => ({
			...styling,
			position: position ? position : 'relative',
			borderRadius: '4px',
			width: fullWidth ? '100%' : 8 * getLongestOption() + 55 + 'px',
			zIndex: 10,
			height: state.selectProps.height,
			overflowY: 'scroll',
		}),
		menuList: () => ({
			borderRadius: '4px',
		}),
	};
	const getLongestOption = useCallback(() => {
		if (options && options.length > 0) {
			const longest = options.sort(function(a: any, b: any) {
				if (a.label === undefined || b.label === undefined) return 30;
				return b.label.length - a.label.length;
			})[0];
			return longest.label.length;
		} else return 30;
	}, [options]);

	return (
		<Fragment>
			{label ? (
				<IonLabel
					position={lbClass ? 'fixed' : 'floating'}
					className={lbClass ? lbClass : classes.selectLabel}
				>
					{label}
					{label && isRequired ? <IonText>*</IonText> : null}
				</IonLabel>
			) : null}
			<Controller
				as={
					<Select
						value={value}
						options={options}
						placeholder={placeHolder}
						className={
							className
								? classNames(className, classes.selectField)
								: classes.selectField
						}
						styles={customStyles}
						menuIsOp2en
						isDisabled={isDisabled}
						isSearchable={isSearchable}
						isMulti={isMulti}
						height={height}
					/>
				}
				name={name}
				defaultValue={defaultValue}
				control={control}
				onChange={([selected]) => {
					if (onChange) {
						onChange(selected);
					} else return selected;
				}}
				rules={{ required: isRequired }}
			/>
			{errorMsg ? <IonText className={classes.errorMsg}>{errorMsg}</IonText> : null}
			{/* {!isDisabled && (
            <input
               tabIndex={-1}
               autoComplete="off"
               style={{ opacity: 0, height: 0, width: 0 }}
               value={value}
               required={required}
            />
         )} */}
		</Fragment>
	);
};

export default ReactSelect;
