import { defineMessages } from 'react-intl';

export default defineMessages({
	cancel: {
		id: 'Users.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel user deletion',
	},
	delete: {
		id: 'delete',
		defaultMessage: 'Delete',
		description: 'Delete',
	},
	confirm: {
		id: 'confirm',
		defaultMessage: 'Are you sure?',
		description: 'Are you sure?',
	},
	deleteTitle: {
		id: 'Users.delete',
		defaultMessage: 'Remove from Organization',
		description: 'Remove from organization',
	},
});
