import { createSelector } from 'reselect';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

const itemsSelector = state => {
	return state && state.items;
};

export const sortSelector = state => {
	return state && state.sortParams;
};

function orderByType(data, type) {
	switch (type) {
		case 'date':
			return Date.parse(data);
		case 'float':
			return parseFloat(data);
		default:
			return data;
	}
}

export const sortedCollection = createSelector(itemsSelector, sortSelector, (collection, sort) => {
	if (sort) {
		return orderBy(collection, c => orderByType(get(c, sort.key), sort.type), [
			sort.order || 'desc',
		]);
	}
});
