import React, { FC, useState, useCallback, useEffect } from 'react';
import { IonSelect, IonSelectOption, IonIcon, IonFab, IonFabButton } from '@ionic/react';
import { person, people, trash, add, business } from 'ionicons/icons';
import isAuthenticated from '../../components/Authentication/Authenticated';
import MainContent from '../../components/MainContent/MainContent';
import Tabs from '../../components/Tabs/Tabs';
// import SortControl from '../../components/SortControl/SortControl';
// import ReactSelect from '../../components/ReactSelect/ReactSelect';

import AddOrganizationModal from '../../components/AddOrganizationModal/AddOrganizationModal';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import DeleteModalOrgDeviceError from '../../components/DeleteModal/DeleteModalOrgDeviceError';
import Users from '../../components/Users/Users';
import UserGroups from '../../components/UserGroups/UserGroups';
import Robots from '../../components/Robots/Robots';
import RobotGroups from '../../components/RobotGroups/RobotGroups';
import OrganizationInfo from '../../components/OrganizationInfo/OrganizationInfo';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { setParameter } from '../../actions/setParam';
import { publish } from '../../actions/publish';

import Messages from './FleetManagement.messages';

import classes from './FleetManagement.module.css';
import { equalityFnc } from '../../utils/conformState';
import { useTypedSelector } from '../../reducers';
import { Organization } from '../../types/types';
import { b64EncodeUnicode } from '../../utils/encoding';
import { subscribeToOrganization } from '../../providers/mqtt';
import { Redirect } from 'react-router-dom';

const FleetManagement: FC = (props: any) => {
	const {
		selectedOrganizationId,
		selectedOrganizationName,
		organization,
		location,
		setParameter,
		history,
		userRoles,
	} = props;
	const [selectedOrgId, setSelectedOrgId] = useState<string>(selectedOrganizationId);
	const [selectedOrgName, setSelectedOrgName] = useState<string>(selectedOrganizationName);
	const [currentTab, setCurrentTab] = useState<string | undefined>('users');
	const [isAddHidden, setIsAddHidden] = useState(false);
	const [isDeleteHidden, setIsDeleteHidden] = useState(false);
	const [showAddOrganizationModal, setAddOrganizationModal] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [deleteErrorOpen, setDeleteErrorOpen] = useState(false);
	const [allOrganizations, setAllOrganizations] = useState<Array<any>>(
		Object.values(organization.organizations || {})
	);
	const { orgTypesAllowed, newOrganizationOpen } = organization;
	const wrappedClient = useTypedSelector(state => state.mqttState.client);
	const username = useTypedSelector(state => state.accountState.user.username);
	const userOrganizationId = useTypedSelector(
		state => state.accountState.user.selectedOrganizationId
	);
	let encodedUser = b64EncodeUnicode(username);
	const devices = useTypedSelector(
		state => state.deviceState.devicesByOrganizationId,
		(left, right) => equalityFnc(left, right, !isDeleteHidden)
	);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);
	const reloadOrg = useTypedSelector(state => state.selectedOrganizationState.reloadOrganization);


	const constructTabsData = (skipUsers: boolean) => {
		return [
			...(skipUsers
				? []
				: [
						{
							title: Messages.users,
							path: 'users',
							icon: person,
							filter: [
								Messages.all,
								Messages.admins,
								Messages.notAdmins,
								Messages.registrationIncomplete,
							],
						},
				  ]),
			...(spinoutType === 'gobe'
				? [
						/* Removed temporarily until stability is reached
						{
							title: Messages.userGroups,
							path: 'userGroups',
							icon: people,
							filter: [Messages.all, Messages.noMembers, Messages.noDeviceGroups],
						},*/
						{
							title: Messages.robots,
							path: 'robots',
							icon: './assets/icons/Robot-single.svg',
							filter: [
								Messages.all,
								Messages.available,
								Messages.unavailable,
								Messages.unassigned,
							],
						},
						{
							title: Messages.deviceGroups,
							path: 'robotGroups',
							icon: './assets/icons/Robot-group.svg',
							filter: [Messages.all],
						},
				  ]
				: []),
			{
				title: Messages.organizationInfo,
				path: 'organization-info',
				icon: business,
			},
		];
	};

	const selectedOrg: Organization = allOrganizations.find((o: any) => o.orgId === selectedOrgId);
	const [tabsData, setTabsData] = useState<Array<any>>(
		constructTabsData(spinoutType === 'beam' && selectedOrg.orgType === 'customer')
	);

	useEffect(() => {
		const currentTab =
			spinoutType === 'beam' && selectedOrg.orgType === 'customer'
				? 'organization-info'
				: 'users';
		setCurrentTab(currentTab);
	}, [selectedOrg.orgType, spinoutType, tabsData]);

	useEffect(() => {
		setAllOrganizations(Object.values(organization.organizations));
	}, [organization, organization.organizations]);

	useEffect(() => {
		if (!selectedOrgId) return;
		setParameter('showLoader', 'CHANGE_LOADER', true);
		publish(`microservice/${selectedOrgId}/${b64EncodeUnicode(username)}/getOrgInfo`, {
			requestId: `getOrgInfoId-${selectedOrgId}`,
			data: {},
		});
		/* publish(`microservice/${selectedOrgId}/${b64EncodeUnicode(username)}/getOrgSSOInfo`, {
			requestId: `getOrgSSOInfoId-${selectedOrgId}`,
			data: {},
		}); */
	}, [selectedOrgId, setParameter, username]);

	useEffect(() => {
		if (location.data) {
			setCurrentTab(location.data.tab);
			setSelectedOrgId(location.data.orgId);
		}
	}, [location.data]);

	useEffect(() => {
		const selectedOrg: Organization = allOrganizations.find(
			(o: any) => o.orgId === selectedOrgId
		);
		//set selected organization state to the changed organization
		for (let key in selectedOrg) {
			setParameter(key, `UPDATE_SEL_ORG_${key.toUpperCase()}`, selectedOrg[key]);
		}
		if (!selectedOrg) {
			alert("Organization data wasn't loaded");
			return;
		}
		// Get agreements for organization
		setParameter('', 'UNLOAD_ORG_ACCEPTED_AGREEMENTS', {});
		if (selectedOrg.orgId) {
			publish(`microservice/${selectedOrg.orgId}/${encodedUser}/getOrgAcceptedAgreements`, {
				requestId: 'getOrgAcceptedAgreementsId',
				data: {
					spinoutType: spinoutType ? spinoutType : '',
				},
			});
		}

		setIsAddHidden(orgTypesAllowed[selectedOrg.orgType].length < 1 ? true : false);
		setIsDeleteHidden(
			selectedOrg.orgType === 'bor' /* || !getRole(user)?.match(/bor|spinout/g) */
				? true
				: true // 'false' changed to 'true' to hide the delete button until stability is reached
		);
		setSelectedOrgName(selectedOrg.name);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allOrganizations, orgTypesAllowed, selectedOrgId, setParameter]);

	useEffect(() => {
		if (reloadOrg) {
			setSelectedOrgId(userOrganizationId);
			setParameter('reload', 'SET_RELOAD_ORGANIZATION', false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadOrg]);

	useEffect(() => {
		if (newOrganizationOpen) {
			setAddOrganizationModal(true);
		} else {
			setAddOrganizationModal(false);
		}
	}, [newOrganizationOpen]);

	const OrganizationOptions = () => {
		const options = Object.values(allOrganizations).map((organization: any, index) => {
			if (organization)
				return (
					<IonSelectOption value={organization.orgId} key={'option-' + index}>
						{organization.name}
					</IonSelectOption>
				);

			return <div key={'option-' + index} />;
		});

		return <div>{options}</div>;
	};

	const onOrganizationChange = (id: string) => {
		const selectedOrg: Organization = allOrganizations.find((o: any) => o.orgId === id);

		// subscribing to the organization here gives faster login than loading all on initial load.
		subscribeToOrganization(wrappedClient, id);

		setCurrentTab('');
		setTabsData(
			constructTabsData(spinoutType === 'beam' && selectedOrg.orgType === 'customer')
		);
		setSelectedOrgId(id);
	};

	const onTabChange = (selected: string | undefined) => {
		setCurrentTab('');
		setTimeout(() => {
			setCurrentTab(selected);
		}, 10);
	};

	const renderTabContent = useCallback(() => {
		switch (currentTab) {
			case 'users':
				return (
					<Users
						filterValues={tabsData.find(td => td.path === currentTab)?.filter}
						orgId={selectedOrgId}
						history={history}
					/>
				);
			case 'userGroups':
				return (
					<UserGroups
						filterValues={tabsData.find(td => td.path === currentTab)?.filter}
						orgId={selectedOrgId}
						editableUserGroup={location.data ? location.data.editableUserGroup : null}
						history={history}
					/>
				);
			case 'robots':
				return (
					<Robots
						filterValues={tabsData.find(td => td.path === currentTab)?.filter}
						orgId={selectedOrgId}
						editableRobotId={
							location.data?.editableRobot
								? location.data.editableRobot.deviceId
								: null
						}
						history={history}
					/>
				);
			case 'robotGroups':
				return (
					<RobotGroups
						filterValues={tabsData.find(td => td.path === currentTab)?.filter}
						orgId={selectedOrgId}
						history={history}
					/>
				);
			case 'organization-info':
				return (
					<OrganizationInfo
						filterValues={tabsData.find(td => td.path === currentTab)?.filter}
						orgId={selectedOrgId}
					/>
				);
			default:
				return null;
		}
	}, [currentTab, history, location.data, selectedOrgId, tabsData]);

	const openAddOrganization = async () => {
		props.setParameter('organizations', 'CHANGE_ADD_NEW_ORGANIZATION_STATE', true);
	};
	const openDeleteOrganization = async () => {
		if (selectedOrgId) {
			if (devices[selectedOrgId] != null) {
				setDeleteErrorOpen(true);
			} else {
				setDeleteOpen(true);
			}
		}
	};
	const onDeleteConfirm = () => {
		publish(`microservice/${selectedOrgId}/${encodedUser}/deleteOrg`, {
			requestId: 'deletedOrganization',
		});

		setDeleteOpen(false);
		setSelectedOrgId(userOrganizationId);
	};
	const openEditOrganization = () => {
		setCurrentTab('organization-info');
	};

	return userRoles ? (
		<MainContent
			headerChildren={
				<>
					<IonSelect
						className={classes.orgSelect}
						id="changeOrganization"
						interface="popover"
						value={selectedOrgId}
						onIonChange={e => onOrganizationChange(e.detail.value)}
					>
						<OrganizationOptions />
					</IonSelect>
					<IonFab className={classes.fabBtnContainer}>
						<IonFabButton
							size="small"
							className={classes.deleteFabBtn}
							onClick={openDeleteOrganization}
							hidden={isDeleteHidden}
						>
							<IonIcon className={classes.fabIcon} icon={trash} />
						</IonFabButton>
						<IonFabButton
							size="small"
							className={classes.editFabBtn}
							onClick={openEditOrganization}
						>
							<IonIcon className={classes.fabIcon} src="./assets/icons/edit.svg" />
						</IonFabButton>
						<IonFabButton
							id="openAddOrganizationFab"
							size="small"
							className={classes.addFabBtn}
							onClick={openAddOrganization}
							hidden={isAddHidden}
						>
							<IonIcon className={classes.fabIcon} icon={add} />
						</IonFabButton>
					</IonFab>
				</>
			}
			renderContent={history => {
				return (
					<div className={classes.main}>
						<Tabs
							data={tabsData}
							currentTab={currentTab}
							content={renderTabContent()}
							onClick={onTabChange}
						/>
						<AddOrganizationModal
							isOpen={showAddOrganizationModal}
							onDismiss={() =>
								props.setParameter(
									'organizations',
									'CHANGE_ADD_NEW_ORGANIZATION_STATE',
									false
								)
							}
							orgId={selectedOrgId}
						/>
						<DeleteModal
							isOpen={deleteOpen}
							title={
								<FormattedMessage
									{...Messages.deleteTitle}
									values={{ item: 'Organization' }}
								/>
							}
							onConfirm={onDeleteConfirm}
							onDismiss={() => setDeleteOpen(false)}
							itemName={selectedOrgName + ' Organization'}
						/>
						<DeleteModalOrgDeviceError
							isOpen={deleteErrorOpen}
							title={
								<FormattedMessage
									{...Messages.deleteErrorTitle}
									values={{ item: 'Organization' }}
								/>
							}
							onConfirm={() => setDeleteErrorOpen(false)}
							onDismiss={() => setDeleteErrorOpen(false)}
							itemName={selectedOrgName + ' Organization Deletion Error'}
						/>
					</div>
				);
			}}
		/>
	) : (
		<Redirect to="/gobe" />
	);
};

const mapStateToProps = (state: any) => ({
	selectedOrganizationId: state.selectedOrganizationState.organization.orgId,
	selectedOrganizationName: state.selectedOrganizationState.organization.name,
	organization: state.organizationState,
	userRoles: state.accountState.user.roles,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(FleetManagement), 'FleetManagement'));
