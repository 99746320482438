import React, { FC, useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonLabel, IonList } from '@ionic/react';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './ProfileSettings.module.css';
import classNames from 'classnames';
import Messages from './ProfileSettings.messages';
import { useTypedSelector } from '../../reducers';
import { AcceptedAgreementsPayload } from '../../reducers/accountReducers';
import Moment from 'moment';
import OrganizationAgreementModal from '../OrganizationForm/OrganizationAgreementModal';
import { Agreement } from '../../types/types';

interface AgreementsSettingsSubmenuProps {
	goTo?: () => void;
}

const AgreementsSettingsSubmenu: FC<AgreementsSettingsSubmenuProps> = (props: any) => {
	const acceptedAgreementsByUser: Array<any> = useTypedSelector(
		state => state.accountState.user.acceptedAgreements
	);

	const [selectedAgreement, setSelectedAgreement] = useState<Agreement | null>(null);

	const openAgreement = (agreement: Agreement) => {
		setSelectedAgreement(agreement);
	};

	return (
		<div className={classes.editForm}>
			<IonGrid className={classes.formGrid}>
				<IonRow className={classes.detailsHeader}>
					<IonCol size="3">
						<IonLabel>
							<FormattedMessage {...Messages.agreements} />
						</IonLabel>
					</IonCol>
					<IonCol size="3">
						<IonLabel>
							<FormattedMessage {...Messages.version} />
						</IonLabel>
					</IonCol>
					<IonCol size="3">
						<IonLabel>
							<FormattedMessage {...Messages.acceptedDate} />
						</IonLabel>
					</IonCol>
					<IonCol size="3">
						<IonLabel>
							<FormattedMessage {...Messages.acceptedBy} />
						</IonLabel>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol className={classes.detailsCol}>
						<IonList className={classes.noTopPadding}>
							{acceptedAgreementsByUser && acceptedAgreementsByUser.length > 0
								? acceptedAgreementsByUser.map(
										(agreement: Agreement, index: any) => {
											if (
												agreement.type === 'privacy-and-cookie-policy' ||
												agreement.type === 'terms-of-use' ||
												agreement.type === 'safety-agreement'
											)
												return (
													<IonRow className={classes.agreementRow}>
														<IonCol size="3">
															<div
																className={classNames(
																	classes.inputLbAgreement,
																	classes.agreementRowContent
																)}
																key={agreement.type + index}
																onClick={e => {
																	openAgreement(agreement);
																}}
															>
																<FormattedMessage
																	id={`Organizations.${agreement.type}`}
																/>
															</div>
														</IonCol>
														<IonCol size="3">
															<div
																className={
																	classes.agreementRowContent
																}
															>
																{agreement.version}
															</div>
														</IonCol>
														<IonCol size="3">
															<div
																className={
																	classes.agreementRowContent
																}
															>
																{Moment(
																	agreement?.dateOfAcceptance
																).format('DD/MM/YYYY') || ''}
															</div>
														</IonCol>

														<IonCol size="3">
															<div
																className={
																	classes.agreementRowContent
																}
															>
																{agreement.userId || ''}
															</div>
														</IonCol>
													</IonRow>
												);
											else return null;
										}
								  )
								: null}
						</IonList>
					</IonCol>
				</IonRow>
			</IonGrid>
			<OrganizationAgreementModal
				isOpen={selectedAgreement ? true : false}
				selectedAgreement={selectedAgreement}
				onDismissModal={() => {
					setSelectedAgreement(null);
				}}
			/>
		</div>
	);
};

export default injectIntl(isAuthenticated(AgreementsSettingsSubmenu, 'AgreementsSettingsSubmenu'));
