import React, { FC, ReactNode } from 'react';
import classes from './Checkbox.module.css';

interface CheckboxProps {
	label: string | ReactNode;
	onChange: any;
}

const Checkbox: FC<CheckboxProps> = ({ label, onChange }) => {
	return (
		<label className={classes.container}>
			{label}
			<input type="checkbox" onClick={onChange} />
			<span className={classes.checkmark} />
		</label>
	);
};

export default Checkbox;
