import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Config from '../../config';

export default function requireAuth(WrappedComponent, componentName) {
	class Authentication extends Component {
		render() {
			if (
				this.props.client &&
				this.props.client.mqttClient &&
				this.props.client.mqttClient.connected
			) {
				return <WrappedComponent {...this.props} />;
			} else if (
				this.props.client &&
				!this.props.client.connected &&
				Config.publicPageComponentNames.includes(componentName)
			) {
				return <WrappedComponent {...this.props} />;
			}

			return <Redirect to="/" />;
		}
	}

	function mapStateToProps(state) {
		return { client: state.mqttState.client };
	}

	return connect(mapStateToProps)(Authentication);
}
