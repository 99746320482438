import { MqttClient } from 'mqtt';

class EventEmitter<T extends string> {
	private callbacks: Record<T, Record<string, (...args: any[]) => void>>;
	constructor() {
		this.callbacks = {} as Record<T, Record<string, (...args: any[]) => void>>;
	}

	protected emit(event: T, ...data: any[]) {
		const eventCallbacks = this.callbacks[event] as
			| Record<string, (...args: any[]) => void>
			| undefined;
		if (eventCallbacks) {
			Object.values<(...args: any) => void>(eventCallbacks).forEach(cb => {
				cb(...data);
			});
		}
	}

	public addEventListener(event: T, cb: (...args: any[]) => void): string {
		const listenerId = Math.random()
			.toString(16)
			.substring(1);
		if (!this.callbacks[event]) this.callbacks[event] = {};
		const eventCallbacks = this.callbacks[event] as Record<string, (...args: any[]) => void>;
		eventCallbacks[listenerId] = cb;
		return listenerId;
	}

	public removeListener(event: T, listenerId: string) {
		const eventCallbacks = this.callbacks[event] as Record<string, (...args: any[]) => void>;
		delete eventCallbacks[listenerId];
	}
}

type RTCStatsSource = RTCPeerConnection | RTCRtpReceiver;

type ListenableStats =
	| 'averageRtcpInterval'
	| 'bytes'
	| 'totalBytes'
	| 'framesDecoded'
	| 'totalFramesDecoded';

/** Emits statistics on an RTC source every `sampleRate` milliseconds */
export class RTCInboundRtpStreamStatsEmitter extends EventEmitter<ListenableStats> {
	private source: RTCStatsSource;
	private selector: MediaStreamTrack | null | undefined;
	private sampleRate: number;
	private intervalId: any = null;

	private prevStats: RTCInboundRtpStreamStats | null = null;

	constructor(
		source: RTCStatsSource,
		selector?: MediaStreamTrack | null,
		sampleRate: number = 500
	) {
		super();
		this.source = source;
		this.selector = selector;
		this.sampleRate = sampleRate;
	}

	private notifyListeners(stats: RTCStatsReport) {
		return new Promise<void>((resolve, reject) => {
			stats.forEach((report: RTCInboundRtpStreamStats) => {
				if ((report.type as any) === 'inbound-rtp') {
					this.emit('averageRtcpInterval', report.averageRtcpInterval);
					this.emit('totalBytes', report.bytesReceived);
					if (this.prevStats) {
						this.emit('bytes', report.bytesReceived - this.prevStats.bytesReceived);
						this.emit(
							'framesDecoded',
							report.framesDecoded - this.prevStats.framesDecoded
						);
					} else {
						this.emit('bytes', report.bytesReceived);
						this.emit('framesDecoded', report.framesDecoded);
					}
					this.prevStats = report;
				}
			});
			resolve();
		});
	}

	public start() {
		this.source.getStats(this.selector).then(stats => this.notifyListeners(stats));
		this.intervalId = setInterval(async () => {
			this.source.getStats(this.selector).then(stats => {
				this.notifyListeners(stats);
			});
		}, this.sampleRate);
	}

	public stop() {
		if (this.intervalId) clearInterval(this.intervalId);
	}
}

export class RTCRtpStreamStatsSender<T extends string> {
	private mqttClient: MqttClient;
	private mqttTopic: string;

	private period: number;
	private intervalId?: ReturnType<typeof setInterval>;

	private pc?: RTCPeerConnection;
	private tracks: Record<T, MediaStreamTrack>;

	private stopped = false;

	constructor(
		period: number,
		mqttClient: MqttClient,
		mqttTopicOptions: { localId: string; peerId: string; uuid: string }
	) {
		this.period = period;
		this.tracks = {} as Record<T, MediaStreamTrack>;
		this.mqttClient = mqttClient;
		const { localId, peerId, uuid } = mqttTopicOptions;
		this.mqttTopic = `rtc-stats/${localId}/${peerId}/${uuid}`;
	}

	public setStatsSource(key: T, track: MediaStreamTrack) {
		this.tracks[key] = track;
	}

	public start(peerConnection: RTCPeerConnection) {
		this.pc = peerConnection;
		this.stopped = false;

		this.getStats().then(stats => this.sendStats(stats));
		this.intervalId = setInterval(() => {
			this.getStats().then(stats => this.sendStats(stats));
		}, this.period);
	}

	public stop() {
		this.stopped = true;
		if (this.intervalId !== undefined) clearInterval(this.intervalId);
		this.pc = undefined;
	}

	private async getStats(): Promise<Record<T, any>> {
		const promises: Array<Promise<[T, any]>> = [];
		if (this.pc !== undefined) {
			for (const [key, track] of Object.entries<MediaStreamTrack>(this.tracks)) {
				promises.push(
					this.pc
						.getStats(track)
						.then(statsReport => [key as T, this.processRTCStatsReport(statsReport)])
				);
			}
		}
		const result = await Promise.all(promises);
		return Object.fromEntries(result) as Record<T, any>;
	}

	private processRTCStatsReport(report: RTCStatsReport) {
		const data = Array.from(report.values()).reduce<Partial<Record<RTCStatsType, any>>>(
			(acc, curr, i) => {
				return { ...acc, [curr.type]: curr };
			},
			{}
		);
		return data;
	}

	private sendStats(stats: Record<T, any>) {
		if (Object.keys(stats).length === 0) {
			console.warn('No statistics has been gathered. Mqtt message will not be sent');
			return;
		}
		if (this.stopped) {
			console.debug('RTCRtpStreamStatsSender is stopped. Mqtt message will not be sent');
			return;
		}
		// console.debug(stats);
		this.mqttClient.publish(this.mqttTopic, JSON.stringify(stats), { qos: 2 }, err => {
			if (err) {
				console.error('Error sending rtc statistics', err);
			}
		});
	}
}

interface RTCInboundRtpStreamStats extends RTCStats {
	averageRtcpInterval: number;

	/** A 64-bit integer which indicates the total number of bytes
	 * that have been received so far for this media source. */
	bytesReceived: number;

	/** A long integer value indicating the total number of frames of video
	 * which have been correctly decoded so far for this media source.
	 * This is the number of frames that would have been rendered if none were dropped.
	 * Only valid for video streams. */
	framesDecoded: number;

	/** A DOMHighResTimeStamp indicating the time at which the last packet was received for this source.
	 * The timestamp property, on the other hand,
	 * indicates the time at which the statistics object was generated. */
	lastPacketReceivedTimestamp: number;

	/** An integer specifying the number of times the receiver has notified the sender
	 * that some amount of encoded video data for one or more frames has been lost,
	 * using Picture Loss Indication (PLI) packets.
	 * This is only available for video streams. */
	pliCount: number;
}

interface RTCOutboundRtpStreamStats {}
