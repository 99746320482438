import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'flex-start',
	marginBottom: 8,
}));

const StyledTypography = styled(Typography)(({ theme, color }) => ({
	paddingLeft: 4,
	fontSize: 12,
	lineHeight: '16px',
	color: color ? color : theme.palette.greyPalette.grey9,
}));

export const IconAndText = ({ icon, children, color }) => {
	return (
		<Wrapper>
			{icon}
			<StyledTypography color={color} variant="body1">
				{children}
			</StyledTypography>
		</Wrapper>
	);
};
