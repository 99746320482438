import React, { useState } from 'react';
import { Popover, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import numeral from 'numeral';
import { MenuItem } from '@material-ui/core';
import { IoChevronDown } from 'react-icons/io5';
import { IoChevronUp } from 'react-icons/io5';
import { currencyTrans } from '../../utils';

const InlineText = styled(Typography)({
	display: 'inline',
});

const DescriptionText = styled(Typography)(({ theme }) => ({
	color: theme.palette.greyPalette.grey1,
	marginTop: 8,
}));

const PriceText = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontFamily: theme.typography.fontFamily,
	fontWeight: 600,
	fontSize: 28,
	lineHeight: 1,
}));

const LeftWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
}));
const RightWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'baseline',
}));
const Wrapper = styled('div')({
	marginBottom: 30,
	paddingRight: 24,
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
});

const StyledChevronDown = styled(IoChevronDown)(() => ({
	width: 15,
	height: 12,
	marginBottom: 3,
	marginLeft: 3,
}));

const StyledChevronUp = styled(IoChevronUp)(() => ({
	width: 15,
	height: 12,
	marginBottom: 3,
	marginLeft: 3,
}));

const StyledPopoverLabel = styled('div')(() => ({
	marginLeft: 8,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-end',
}));
const StyledTypography = styled(Typography)(() => ({
	fontSize: 18,
	'&:hover': {
		color: '#78787A',
		cursor: 'pointer',
	},
}));
const StyledPopover = styled(Popover)(() => ({
	'& .MuiPopover-paper': {
		boxShadow: '0px 2px 10px #00000029',
		border: '1px solid #d1d1d1',
		borderRadius: 5,
		padding: 8,
	},
}));
const StyledMenuItem = styled(MenuItem)(() => ({
	width: 109,
	display: 'flex',
	justifyContent: 'space-between',
	borderRadius: 5,
}));


export const Header = ({ totalPrice, setCurrency, currency, currencyOptions }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [popoverVisible, setPopoverVisible] = useState(false);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
		setPopoverVisible(true);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setPopoverVisible(false);
	};

	return (
		<Wrapper>
			<LeftWrapper>
				<InlineText variant="h1">
					<FormattedMessage
						id="startPageHeader"
						defaultMessage="<bold>Buy</bold> GoBe Robot"
						values={{
							bold: (...chunks) => <InlineText variant="h4">{chunks}</InlineText>,
						}}
					/>
				</InlineText>
				<DescriptionText variant="body1">
					{/* <FormattedMessage
						id="shortProductDescription"
						defaultMessage="Short description text about the product"
					/> */}
				</DescriptionText>
			</LeftWrapper>
			<RightWrapper>
				<PriceText component="h1">{numeral(totalPrice).format('0,0')}</PriceText>
				<Typography component="h1">
					<StyledPopoverLabel onClick={e => handleClick(e)}>
						<StyledTypography>{currencyTrans[currency]}</StyledTypography>
						{popoverVisible ? <StyledChevronUp /> : <StyledChevronDown />}
					</StyledPopoverLabel>
					<StyledPopover
						open={popoverVisible}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						{currencyOptions.map(cur => (
							<StyledMenuItem
								value={cur.currencyCode}
								key={cur.currencyCode}
								onClick={() => {
									setCurrency(cur.currencyCode);
									handleClose();
								}}
								selected={currency === cur.currencyCode}
							>
								<Typography>{cur.currencyCode}</Typography>
								<Typography> {currencyTrans[cur.currencyCode]}</Typography>
							</StyledMenuItem>
						))}
					</StyledPopover>
				</Typography>
			</RightWrapper>
		</Wrapper>
	);
};
