import React, { FC, useEffect, useState } from 'react';
import {
	IonList,
	IonItem,
	IonLabel,
	IonListHeader,
	IonModal,
	IonButton,
	IonCol,
	IonRow,
} from '@ionic/react';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { FormattedMessage, injectIntl } from 'react-intl';
import classes from './OrganizationForm.module.css';
import { AcceptedAgreementsPayload } from '../../reducers/accountReducers';

import Messages from './OrganizationForm.messages';

interface AgreementModalProps {
	selectedAgreement: AcceptedAgreementsPayload;
	onDismissModal: any;
	isOpen: boolean;
}

const OrganizationAgreementModal: FC<AgreementModalProps> = props => {
	const { selectedAgreement, onDismissModal, isOpen } = props;

	const [height, setHeight] = useState(0);
	const [isModalOpenState, setIsModalOpenState] = useState<any>();

	useEffect(() => {
		setIsModalOpenState(isOpen || false);
	}, [isOpen]);

	const getElement = (id: string) => {
		return document.getElementById(id) as HTMLElement;
	};

	const getElementHeight = () => {
		const element = getElement('agreementModal');
		if (element && element.clientHeight) {
			setHeight(element.clientHeight * 0.5);
		}
	};

	const formatText = (text: string) => {
		const textArr = text.split('-');
		for (let i = 0; i < textArr.length; i++) {
			textArr[i] =
				textArr[i].substr(0, 1).toUpperCase() + textArr[i].substr(1, textArr[i].length);
		}
		return textArr.join(' ');
	};

	return (
		<IonModal
			cssClass={classes.addModal}
			id="agreementModal"
			isOpen={isModalOpenState}
			swipeToClose
			show-backdrop
			onDidDismiss={onDismissModal}
			onDidPresent={e => getElementHeight()}
		>
			<IonList className={classes.modalContent}>
				<IonListHeader className={classes.modalHeader} lines="none">
					<div className={classes.headerContainer}>
						<IonLabel className={classes.headerTitle} color="primary">
							{formatText(selectedAgreement?.type || '')}
						</IonLabel>
					</div>
				</IonListHeader>
				<IonItem style={{ width: '100%' }}>
					<iframe
						className={classes.agreementFrame}
						style={{
							height,
						}}
						title={selectedAgreement?.type}
						id={selectedAgreement?.type}
						src={
							'data:text/html;charset=utf-8;http-equiv=content-type,' +
							escape(
								selectedAgreement?.content
									.replace('72pt 72pt 72pt 72pt', '0 10px 0 0')
									.replace(/href/g, ' target="_blank" href')
							)
						}
					/>
				</IonItem>
			</IonList>

			<IonRow className={classes.btnRow}>
				<IonCol className={classes.submitCol}>
					<IonButton
						expand="block"
						shape="round"
						type="submit"
						size="large"
						fill="outline"
						onClick={() => {
							setIsModalOpenState(false);
						}}
					>
						<FormattedMessage {...Messages.ok} />
					</IonButton>
				</IonCol>
			</IonRow>
		</IonModal>
	);
};

export default injectIntl(
	isAuthenticated(OrganizationAgreementModal, 'OrganizationAgreementModal')
);
