import React from 'react';
import classes from './FMFooterLogo.module.css';

export const FMFooterLogo: React.FC = () => (
	<div className={classes.wrapper}>
		<div className={classes.line} />
		<img className={classes.blueOceanLogo} src="./assets/img/blue-ocean-logo.svg" alt="Blue Ocean Robotics" />
		<div className={classes.line} />
	</div>
);
