import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { RoundedButtonFilled } from 'components/atoms/RoundedButtonFilled';

const FooterWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'flex-end',
	paddingRight: 16,
}));

export const Footer = ({
	onNextClick,
	disableNextButton,
	nextButtonLabel,
}) => {
	const theme = useTheme();

	const buttonColor = theme?.palette?.primary?.main;

	return (
		<FooterWrapper>
			<RoundedButtonFilled
				onClick={onNextClick}
				color={buttonColor}
				disableButton={disableNextButton}
			>
				{nextButtonLabel ? nextButtonLabel : <FormattedMessage id="next" defaultMessage="Next" />}
			</RoundedButtonFilled>
		</FooterWrapper>
	);
};
