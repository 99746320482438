import React from 'react';
import styled from 'styled-components';
import { RoundedButtonBase } from './RoundedButtonBase';

const RoundedButtonFilledDefault = styled(RoundedButtonBase)(({ theme, $color, $align }) => ({
	color: theme.palette.common.white,
	backgroundColor: $color,
	border: `1px solid ${$color}`,
	alignSelf: $align ? $align : 'auto',
	'&:hover, &.Mui-focusVisible': {
		backgroundColor: theme.palette.common.white,
		boxShadow: 'none',
		border: `1px solid ${$color}`,
		color: $color,
	},
	'&:disabled': {
		color: 'white',
		opacity: 0.5,
	},
}));

const LargeRoundedButtonFilled = styled(RoundedButtonFilledDefault)(({ theme, style }) => ({
	padding: '12px 70px',
	...style
}));

const SmallRoundedButtonFilled = styled(RoundedButtonFilledDefault)(({ theme, style }) => ({
	padding: '8px 32px',
	...style
}));

export const RoundedButtonFilled = ({
	variant = 'large',
	children,
	color = 'black',
	align,
	onClick,
	disableButton,
	style,
}) => {
	if (variant === 'small') {
		return (
			<SmallRoundedButtonFilled
				style={{ ...style }}
				$color={color}
				$align={align}
				onClick={onClick}
			>
				{children}
			</SmallRoundedButtonFilled>
		);
	}
	return (
		<LargeRoundedButtonFilled
			style={{ ...style }}
			$color={color}
			$align={align}
			onClick={onClick}
			disabled={disableButton}
		>
			{children}
		</LargeRoundedButtonFilled>
	);
};
