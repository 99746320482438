import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Wrapper = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: 24,
});

const InlineText = styled(Typography)({
	display: 'inline',
	textAlign: 'center',
});

const SubTitle = styled(Typography)({
	textAlign: 'center',
	marginTop: 30,
	lineHeight: 2,
});

const Container = styled('div')(({ theme }) => ({
	display: 'flex',
	flex: 2,
	alignItems: 'center',
	justifyContent: 'center',
}));

export const Header = () => {
	return (
		<Container>
			<Wrapper>
				<InlineText variant="h1">
					<FormattedMessage
						id="startPageHeader"
						defaultMessage="All Go<primary>Be</primary> Robots you love are online here in our GoBe WebShop."
						values={{
							primary: (...chunks) => (
								<InlineText variant="h1" color="primary">
									{chunks}
								</InlineText>
							),
						}}
					/>
				</InlineText>
				<SubTitle variant="body2">
					<FormattedMessage
						id="startPageSubtitleFirst"
						defaultMessage="GoBe Robot is the best telepresence robot for business in 2020.{break}GoBe Robot you control it remotely online."
						values={{ break: <br /> }}
					/>
				</SubTitle>
			</Wrapper>
		</Container>
	);
};
