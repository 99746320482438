import React, { useState, useEffect } from 'react';
import {
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonLabel,
	IonModal,
	IonGrid,
	IonList,
	IonListHeader,
	IonItem,
	IonSearchbar,
	IonFabButton,
} from '@ionic/react';
import { personAdd, add, personCircle, remove } from 'ionicons/icons';
import FormInputListItem from '../FormInputListItem/FormInputListItem';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';
import { useForm } from 'react-hook-form';

import classes from './CreateAddItemsModal.module.css';
import classNames from 'classnames';

import Messages from './CreateAddItemsModal.messages';

interface AddItemsModalProps {
	showHeaderIcon?: boolean;
	headerIcon?: string;
	itemIcon?: string;
	itemIconStyle?: any;
	title: any;
	titleHint: any;
	availableTitle: any;
	selectedTitle: any;
	selectedTxt: {
		id: string;
		defaultMessage: string;
		description: string;
	};
	noSelection: {
		id: string;
		defaultMessage: string;
		description: string;
	};
	noAvailable?: {
		id: string;
		defaultMessage: string;
		description: string;
	};
	searchMessageId: string;
	availableItems: any;
	isOpen: boolean;
	orgId: string;
	initialData: any;
	nameProperty: string;
	takenNames: string[];
	addBtnTitle?: {
		id: string;
		defaultMessage: string;
		description: string;
	};
	onAdd: (selectedItems: any) => void;
	onDismiss?: void;
	onCreate?: (data: any, selectedItems: any) => void;
}

const AddItemsModal: React.FC<AddItemsModalProps> = (props: any) => {
	const { intl } = props;
	const { handleSubmit, control, errors, setError, clearError } = useForm();

	const [searchText, setSearchText] = useState('');
	const [available, setAvailable] = useState<any[]>(props.availableItems);
	const [selectedItems, setSelectedItems] = useState([]);

	useEffect(() => {
		setAvailable(props.availableItems);
	}, [props.availableItems]);

	const onSelect = (selectedItem: any) => {
		setSelectedItems(prev => prev.concat(selectedItem));

		setAvailable((prev: any[]) =>
			prev.filter(
				(item: any) => item[props.nameProperty] !== selectedItem[props.nameProperty]
			)
		);
	};
	const onUnSelect = (unSelected: any) => {
		setSelectedItems(prev =>
			prev.filter((r: any) => r[props.nameProperty] !== unSelected[props.nameProperty])
		);
		setAvailable((prev: any[]) => prev.concat(unSelected));
	};

	const isNameTaken = (name: string) => {
		let isTaken = false;
		if (props.takenNames) {
			Object.values(props.takenNames).forEach(element => {
				if (element === name) {
					isTaken = true;
				}
			});
		}
		return isTaken;
	};

	const onCreate = handleSubmit(data => {
		data.name = data.name.trim();
		if (isNameTaken(data.name)) {
			setError('nameUnique', {
				type: 'manual',
				message: 'error',
			});
			return;
		} else {
			clearError('nameUnique');
			props.onCreate(data, selectedItems);
		}
	});

	const onCancel = () => {
		props.onDismiss();
		selectedItems.forEach((u: any) => {
			if (
				available.findIndex(
					(availableUser: any) =>
						availableUser[props.nameProperty] === u[props.nameProperty]
				) < 0
			) {
				setAvailable((prev: any[]) => prev.concat(u));
			}
		});
		setSelectedItems([]);
		setSearchText('');
	};

	const modalContent = (confirmBtn: React.ReactNode) => {
		return (
			<>
				<IonList>
					{props.header ? (
						props.header
					) : (
						<IonListHeader className={classes.modalHeader} lines="none">
							<div className={classes.headerContainer}>
								{props.showHeaderIcon ? (
									<IonIcon
										className={classes.headerIcon}
										color="primary"
										size="large"
										icon={props.headerIcon ? props.headerIcon : personAdd}
									/>
								) : null}
								<IonLabel color="primary" className={classes.headerTitle}>
									{props.title}
								</IonLabel>
							</div>
							<IonLabel className={classes.headerHint}>{props.titleHint}</IonLabel>
						</IonListHeader>
					)}
				</IonList>
				<IonGrid className={classes.modalContent}>
					{props.onCreate ? (
						<IonRow className={classes.groupNameContainer}>
							<FormInputListItem
								type="text"
								name="name"
								label={intl.formatMessage({
									id: 'UserGroups.name',
								})}
								control={control}
								rules={{ required: true }}
								required
								errorMsg={
									errors.name
										? intl.formatMessage({
												id: 'DeviceGroupsPage.nameError',
										  })
										: errors.nameUnique
										? intl.formatMessage({
												id: 'DeviceGroupsPage.nameNotUnique',
										  })
										: ''
								}
							/>
						</IonRow>
					) : null}
					<IonRow className={classes.itemsContent}>
						<IonList>
							<IonGrid className="ion-no-padding">
								<IonRow className={classes.usersRow}>
									<IonCol>
										<IonLabel color="primary" className={classes.colTitle}>
											{props.availableTitle}
										</IonLabel>
										{available.length > 0 ? (
											<>
												<IonSearchbar
													className={classes.searchBar}
													searchIcon="none"
													showCancelButton="never"
													value={searchText}
													placeholder={props.intl.formatMessage({
														id: props.searchMessageId,
													})}
													onIonChange={e =>
														setSearchText(e.detail.value!)
													}
													autocomplete="on"
												/>
												<IonList lines="none" className={classes.usersList}>
													{available.map((item: any, index: number) => {
														if (item && item.name) {
															return (
																<IonItem
																	key={index}
																	className={
																		item[props.nameProperty]
																			.toLowerCase()
																			.indexOf(searchText) >
																		-1
																			? classNames(
																					classes.userItem,
																					'ion-no-padding'
																			  )
																			: classes.hidden
																	}
																>
																	<div
																		className={
																			classes.userPicContainer
																		}
																		style={props.itemIconStyle}
																	>
																		<IonIcon
																			className={
																				classes.userPic
																			}
																			size="large"
																			icon={
																				props.itemIcon
																					? props.itemIcon
																					: personCircle
																			}
																		/>
																	</div>
																	<IonLabel
																		className={classes.userName}
																	>
																		{item[props.nameProperty]}
																	</IonLabel>
																	<IonFabButton
																		size="small"
																		className={
																			classes.selectBtn
																		}
																		onClick={() =>
																			onSelect(item)
																		}
																	>
																		<IonIcon
																			className={
																				classes.fabIcon
																			}
																			icon={add}
																		/>
																	</IonFabButton>
																</IonItem>
															);
														} else {
															return null;
														}
													})}
												</IonList>
											</>
										) : (
											<IonLabel className={classes.noUsers}>
												{props.noAvailable ? (
													<FormattedMessage {...props.noAvailable} />
												) : (
													<FormattedMessage {...Messages.noAvailable} />
												)}
											</IonLabel>
										)}
									</IonCol>
									<IonCol>
										<IonLabel color="primary" className={classes.colTitle}>
											{props.selectedTitle}
										</IonLabel>
										{selectedItems.length > 0 ? (
											<>
												<IonLabel className={classes.usersSelected}>
													<FormattedMessage
														{...props.selectedTxt}
														values={{
															count: selectedItems.length,
														}}
													/>
												</IonLabel>
												<IonList
													lines="none"
													className={classes.noSelection}
												>
													{selectedItems.map((item: any, index: any) => {
														return (
															<IonItem
																key={index}
																className={classNames(
																	classes.userItem,
																	'ion-no-padding'
																)}
															>
																<div
																	className={
																		classes.userPicContainer
																	}
																	style={props.itemIconStyle}
																>
																	<IonIcon
																		className={classes.userPic}
																		size="large"
																		icon={
																			props.itemIcon
																				? props.itemIcon
																				: personCircle
																		}
																	/>
																</div>
																<IonLabel
																	className={classes.userName}
																>
																	{item[props.nameProperty]}
																</IonLabel>
																<IonFabButton
																	size="small"
																	className={classes.selectBtn}
																	onClick={() => onUnSelect(item)}
																>
																	<IonIcon
																		className={classes.fabIcon}
																		icon={remove}
																	/>
																</IonFabButton>
															</IonItem>
														);
													})}
												</IonList>
											</>
										) : (
											<IonLabel className={classes.noUsers}>
												<FormattedMessage {...props.noSelection} />
											</IonLabel>
										)}
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonList>
					</IonRow>
					<IonRow className={classes.btnRow}>
						<IonCol>
							<IonButton
								className={classes.cancelBtn}
								expand="block"
								shape="round"
								size="large"
								fill="outline"
								onClick={onCancel}
							>
								{intl.formatMessage({
									id: 'cancel',
								})}
							</IonButton>
						</IonCol>
						<IonCol className={classes.submitCol}>{confirmBtn}</IonCol>
					</IonRow>
				</IonGrid>
			</>
		);
	};

	return (
		<IonModal
			cssClass={classes.addModal}
			isOpen={props.isOpen}
			swipeToClose
			show-backdrop
			onDidDismiss={onCancel}
		>
			{props.onCreate ? (
				<form onSubmit={onCreate} className={classes.mainContainer}>
					{modalContent(
						<IonButton
							expand="block"
							shape="round"
							type="submit"
							size="large"
							fill="outline"
							// disabled={selectedItems.length === 0}
						>
							{intl.formatMessage({
								id: 'create',
							})}
						</IonButton>
					)}
				</form>
			) : (
				<div className={classes.mainContainer}>
					{modalContent(
						<IonButton
							expand="block"
							shape="round"
							size="large"
							fill="outline"
							onClick={() => props.onAdd(selectedItems)}
							disabled={selectedItems.length === 0}
						>
							<FormattedMessage
								{...(props.addBtnTitle ? props.addBtnTitle : Messages.addBtnTitle)}
							/>
						</IonButton>
					)}
				</div>
			)}
		</IonModal>
	);
};

export default injectIntl(isAuthenticated(AddItemsModal, 'AddItemsModal'));
