import { defineMessages } from 'react-intl';

export default defineMessages({
	name: {
		id: 'UserGroups.name',
		defaultMessage: 'Group name',
		description: 'Name of user group',
	},
	dateAdded: {
		id: 'Users.dateAdded',
		defaultMessage: 'Date Added',
		description: 'Date added',
	},
	members: {
		id: 'UserGroups.members',
		defaultMessage: 'Members',
		description: 'Members',
	},
	edit: {
		id: 'UserGroups.edit',
		defaultMessage: 'Edit group',
		description: 'Edit group',
	},
	addUsers: {
		id: 'UserGroups.addUsers',
		defaultMessage: 'Add users',
		description: 'Add users',
	},
	addUsersHint: {
		id: 'UserGroups.addUsersHint',
		defaultMessage: 'Please choose users to add to {group}',
		description: 'Please choose users to add to group',
	},
	availableUsers: {
		id: 'UserGroups.createModal.availableUsers',
		defaultMessage: 'Available users',
		description: 'Available users',
	},
	noAvailable: {
		id: 'UserGroups.createModal.noAvailableUsers',
		defaultMessage: 'No available selected',
		description: 'No available selected',
	},
	selectedUsers: {
		id: 'UserGroups.createModal.selectedUsers',
		defaultMessage: 'Selected users',
		description: 'Selected users',
	},
	noSelection: {
		id: 'UserGroups.createModal.noSelection',
		defaultMessage: 'No users selected',
		description: 'No users selected',
	},
	usersSelected: {
		id: 'UserGroups.createModal.usersSelected',
		defaultMessage:
			'{count, number} {count, plural, one {user} other {users}} has been selected',
		description: 'Number of selected users',
	},
	noUserGroups: {
		id: 'UserGroups.noUserGroups',
		defaultMessage: 'No User Groups here!',
		description: 'No User Groups here!',
	},
	noUserGroupsHint: {
		id: 'UserGroups.noGroupAdded',
		defaultMessage: "You haven't added any User Groups yet!",
		description: "You haven't added any User Groups yet!",
	},
	deleteTitle: {
		id: 'deleteTitle',
		defaultMessage: 'Delete this {item}',
		description: 'Delete this {item}',
	},
	memberCount: {
		id: 'UserGroups.memberCount',
		defaultMessage: '{count} Members',
		description: 'Number of user members',
	},
	recentActivity: {
		id: 'Users.recentActivity',
		defaultMessage: 'Recent activity',
		description: 'Recent activity',
	},
	saved: {
		id: 'saved',
		defaultMessage: 'Saved',
		description: 'Saved',
	},
	users: {
		name: {
			id: 'UsersPage.name',
			defaultMessage: 'Name',
			description: 'User name',
		},
		email: {
			id: 'InviteModal.email',
			defaultMessage: 'Email Address',
			description: 'User email',
		},
		groups: {
			id: 'UsersPage.userGroups',
			defaultMessage: 'User Groups',
			description: 'User groups',
		},
		dateAdded: {
			id: 'UsersPage.dateAdded',
			defaultMessage: 'Date Added',
			description: 'Date added',
		},
		privileges: {
			id: 'UsersPage.privileges',
			defaultMessage: 'Privileges',
			description: 'Privileges',
		},
		latestSession: {
			id: 'UsersPage.latestSession',
			defaultMessage: 'Latest session',
			description: 'Latest session',
		},
	},
	editUser: {
		id: 'Users.edit',
		defaultMessage: 'Edit user',
		description: 'Edit user',
	},
	addToGroup: {
		id: 'User.addGroup',
		defaultMessage: 'Add to group',
		description: 'Add to group',
	},
	remove: {
		id: 'UserGroups.remove',
		defaultMessage: 'Remove',
		description: 'Remove',
	},
	editPicTitle: {
		id: 'editPicTitle',
		defaultMessage: 'Edit {item} Image',
		description: 'Edit image',
	},
	addToGroupModal: {
		addToGroupsHint: {
			id: 'User.addToGroupsHint',
			defaultMessage: 'Please choose groups to add to {user}',
			description: 'Please choose groups for the selected user',
		},
		availableGroups: {
			id: 'Users.availableGroups',
			defaultMessage: 'Available groups',
			description: 'Available groups',
		},
		selectedGroups: {
			id: 'Users.selectedGroups',
			defaultMessage: 'Selected groups',
			description: 'Selected groups',
		},
		groupsSelected: {
			id: 'Users.groupsSelected',
			defaultMessage:
				'{count, number} {count, plural, one {user group} other {user groups}} has been selected',
			description: 'Number of selected groups',
		},
		noSelection: {
			id: 'Users.noSelection',
			defaultMessage: 'No groups selected',
			description: 'No groups selected',
		},
	},
	created: {
		id: 'UserGroups.created',
		defaultMessage: '{name} has been created',
		description: 'A new user group has been created',
	},
	justCreated: {
		id: 'UserGroups.justCreated',
		defaultMessage: 'Has just been created',
		description: 'A new user group has just been created',
	},
	yearsAgo: {
		id: 'UserGroups.yearsAgo',
		defaultMessage: 'years ago',
		description: 'years ago',
	},
});
