import React, { useState } from 'react';
import useDelayedShowOrHide from '../../../utils/useDelayedShowOrHide';
import './DrivingImpairmentIndicator.scss';

type Props = {
	isMenuExpanded: boolean;
	isAbsoluteMenuVisible: boolean;
	isDrivingImpaired: boolean;
};

const showOrHideConfig = {
	showDelayMs: 0, // show the indicator immediately when driving is impaired
	hideDelayMs: 1000, // hide the indicator after a delay of X ms, when driving is no longer impaired
};

export default function ImpairedDrivingIndicator({
	isMenuExpanded,
	isAbsoluteMenuVisible,
	isDrivingImpaired,
}: Props) {
	const [isMinimized, setIsMinimized] = useState(false);
	const isVisible = useDelayedShowOrHide(isDrivingImpaired, showOrHideConfig);

	return isMinimized ? (
		<div
			// FIXME: This convoluted conditional statement is - ugly - consider re-implementing with `classnames`
			className={
				isVisible
					? isAbsoluteMenuVisible
						? isMenuExpanded
							? 'failedSessionConnectionCircle failedShowAbsoluteMenuExpand'
							: 'failedSessionConnectionCircle failedShowAbsoluteMenu'
						: 'failedSessionConnectionCircle'
					: isAbsoluteMenuVisible
					? isMenuExpanded
						? 'failedSessionConnectionCircle failedShowAbsoluteMenuExpand displayNone'
						: 'failedSessionConnectionCircle failedShowAbsoluteMenu displayNone'
					: 'failedSessionConnectionCircle displayNone'
			}
			onClick={() => setIsMinimized(false)}
		>
			<div className="warningIconWrapper">
				<img src="../../assets/images/green-warning.svg" alt="" />
			</div>
		</div>
	) : (
		<div
			// FIXME: This convoluted conditional statement is - ugly - consider re-implementing with `classnames`
			className={
				isVisible
					? isAbsoluteMenuVisible
						? isMenuExpanded
							? 'failedSessionConnectionContainer failedShowAbsoluteMenuExpand'
							: 'failedSessionConnectionContainer failedShowAbsoluteMenu'
						: 'failedSessionConnectionContainer'
					: isAbsoluteMenuVisible
					? isMenuExpanded
						? 'failedSessionConnectionContainer failedShowAbsoluteMenuExpand displayNone'
						: 'failedSessionConnectionContainer failedShowAbsoluteMenu displayNone'
					: 'failedSessionConnectionContainer displayNone'
			}
		>
			<div className="headingContainer">
				<div className="warningIconWrapper">
					<img src="../../assets/images/green-warning.svg" alt="" />
				</div>
				<div className="impairedHeading">Driving Impaired</div>
				<div className="closeCircleIconWrapper" onClick={() => setIsMinimized(true)}>
					<img src="../../assets/images/grey-close-circle.svg" alt="" />
				</div>
			</div>
			<div className="textSessionConnectionContainer">
				The driving speed has been limited due to network problems or your device being
				under a high load.
			</div>
		</div>
	);
}
