import React, { FC, useState } from 'react';
import { IonGrid, IonRow, IonList, IonLabel, IonCol } from '@ionic/react';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './OrganizationForm.messages';
import classes from './OrganizationForm.module.css';
import { useTypedSelector } from '../../reducers';
import { AcceptedAgreementsPayload } from '../../reducers/accountReducers';
import OrganizationAgreementModal from './OrganizationAgreementModal';
import classNames from 'classnames';
import { mmmDDYYYYFormat, timeFormat } from '../../utils/formatTime';

interface OrganizationAgreementProps {
	organization: any;
	isEditable?: boolean;
	saved: boolean;
	segment: string;
}

const OrganizationAgreementForm: FC<OrganizationAgreementProps> = props => {
	const { isEditable } = props;
	const [selectedAgreement, setSelectedAgreement] = useState<AcceptedAgreementsPayload | null>(
		null
	);
	const agreements: Array<any> = useTypedSelector(
		state => state.selectedOrganizationState.organization.acceptedAgreements
	);

	const openAgreement = (agreement: AcceptedAgreementsPayload) => {
		setSelectedAgreement(agreement);
	};

	return (
		<div className={classes.editForm}>
			<IonGrid className={classes.formGrid}>
				<IonRow className={classes.detailsHeader}>
					<IonCol size="3">
						<IonLabel>
							<FormattedMessage {...Messages.agreements} />
						</IonLabel>
					</IonCol>
					<IonCol size="2">
						<IonLabel>
							<FormattedMessage {...Messages.version} />
						</IonLabel>
					</IonCol>
					<IonCol size="2">
						<IonLabel>
							<FormattedMessage {...Messages.acceptedDate} />
						</IonLabel>
					</IonCol>
					<IonCol size="2">
						<IonLabel>
							<FormattedMessage {...Messages.acceptedTime} />
						</IonLabel>
					</IonCol>
					<IonCol size="3">
						<IonLabel>
							<FormattedMessage {...Messages.acceptedBy} />
						</IonLabel>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol className={classes.detailsCol}>
						<IonList className={classes.noTopPadding}>
							{agreements && agreements.length > 0
								? agreements.map((agreement: AcceptedAgreementsPayload, index) => {
										if (
											agreement.type === 'business-terms-and-conditions' ||
											agreement.type === 'sub-processing-agreement' ||
											agreement.type === 'data-processing-agreement'
										)
											return (
												<IonRow className={classes.agreementRow}>
													{/* <IonItem
														disabled={!isEditable}
														key={agreement.type + index}
													>
														<IonLabel
															position="fixed"
															className={classes.formLb}
														>
															<FormattedMessage
																id={`Organizations.${agreement.type}`}
															/>
														</IonLabel>
														<IonButton
															className={classes.inputLbAgreement}
															onClick={e => {
																openAgreement(agreement);
															}}
														>
															{agreement.agreementId}
														</IonButton>
													</IonItem> */}
													<IonCol size="3">
														<div
															className={classNames(
																classes.inputLbAgreement,
																classes.agreementRowContent
															)}
															key={agreement.type + index}
															onClick={e => {
																openAgreement(agreement);
															}}
														>
															<FormattedMessage
																id={`Organizations.${agreement.type}`}
															/>
														</div>
													</IonCol>
													<IonCol size="2">
														<div
															className={classes.agreementRowContent}
														>
															{agreement.version}
														</div>
													</IonCol>
													<IonCol size="2">
														<div
															className={classes.agreementRowContent}
														>
															{mmmDDYYYYFormat(
																agreement.dateOfAcceptance
															)}
														</div>
													</IonCol>
													<IonCol size="2">
														<div
															className={classes.agreementRowContent}
														>
															{timeFormat(agreement.dateOfAcceptance)}
														</div>
													</IonCol>
													<IonCol size="3">
														<div
															className={classes.agreementRowContent}
														>
															{agreement.userId}
														</div>
													</IonCol>
												</IonRow>
											);
										else return null;
								  })
								: null}
						</IonList>
					</IonCol>
				</IonRow>
			</IonGrid>
			<OrganizationAgreementModal
				isOpen={selectedAgreement ? true : false}
				selectedAgreement={selectedAgreement}
				onDismissModal={() => {
					setSelectedAgreement(null);
				}}
			/>
		</div>
	);
};

export default injectIntl(isAuthenticated(OrganizationAgreementForm, 'OrganizationAgreementForm'));
