/* eslint-disable camelcase */
import React, { useEffect, useRef, useState, useCallback, useLayoutEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import LocalVideo from './localVideo';
import RemotePrimaryCamVideo from './remoteVideo';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import {
	SET_DATA_CHANNEL,
	SET_SHARE_SCREEN_STATUS,
	SET_FULL_SCREEN_STATUS,
} from '../../actions/types';
import {
	SET_IS_EXPANDED,
	SET_SHOW_ABSOLUTE_MENU,
	SET_SHOW_MENU,
} from '../../../../../actions/types';
import PauseOrEndSessionOverlay from './sessionEndPause';
import ShareScreen from './shareScreen';
import { PropsFromParent } from './model';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from '../../../../../reducers';
import { openFullscreen, closeFullScreen } from '../../utils/fullScreen';
import { useIonViewDidEnter } from '@ionic/react';
import RobotUnavailableOverlay from './failedInitPeerConnection';
import SessionNetworkFailureOverlay from './failedSessionPeerConnection';
import useCallerPeerConnection from './useCallerPeerConnection';
import { publish } from 'redux-mqtt';
import { PeerConnectionEndReasonCode } from './useCallerPeerConnection/peerConnection';
import ImpairedDrivingIndicator from './useKeyConverter/DrivingImpairmentIndicator';
import { IClientOptions } from 'mqtt';
import useNavController from './useKeyConverter/navControl';
import KeyboardNavInput from './useKeyConverter/keyboardNavInput';
import { RtpReceiverID } from './useKeyConverter/useDrivingImpairment';
import MediaAccessDeniedOverlay from './mediaDevicesAccessDenied';
import ActiveNavigationInputIndicator, {
	buildOnNavInputFocusChanged,
	IActiveNavInput,
} from '../../components/activeNavigationMode';
import AutoDockingInput from './autoDocking/AutoDockingInput';
import NavViewWithSessionOptions from './NaNavViewWithSessionOptions';

const NO_REMOTE_VIDEO_TIMEOUT = 60 * 1000;

const reduxConnector = connect(
	(state: AppRootState) => ({
		shareScreenStatus: state.goBeState.sessionState.shareScreenStatus,
		fullScreenStatus: state.goBeState.sessionState.fullScreenStatus,
		dataChannel1: state.goBeState.sessionState.dataChannel1 as RTCDataChannel | undefined,
		robotId: state.goBeState.sessionState.robotId,
		robotName: state.goBeState.sessionState.robotName,
		navSpeed: state.goBeState.sessionState.navSpeed,
		accountState: state.accountState,
		showMenu: state.menuState.showMenu,
		showAbsoluteMenu: state.menuState.showAbsoluteMenu,
		isMenuExpanded: state.menuState.isExpanded,
		showAccountInformation: state.menuState.showAccountInformation,
		mqttConfig: state.mqttState.mqttConfig as Partial<IClientOptions>,
		localVoiceVolume: state.goBeState.sessionState.localVoiceVolume,
		isDataFetchLoaderShowing: state.fetchDataState.showLoader,
		drivingMode: state.goBeState.sessionState.drivingMode,
	}),
	{ setParameter, publish }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

/** The various overlays (covers the entire session screen)
 * that may be shown at any point in time during a session */
type ISessionOverlay =
	| 'MediaAccessDenied'
	| 'EndOrPauseSessionConfirmation' // overlay where we ask the user to confirm ending the session
	| 'SessionNetworkFailure' // overlay shown when the underlying peer connection fails
	| 'UnavailableRobot' // overlay shown when unable to establish session because robot is unavailable
	| 'NoRemoteVideo'; // overlay shown when we dont ever get any video from the robot at the start of a session
// | 'PermissionError'; // overlay shown when we are unable to get access to the user's media devices at the start of a session

const Session: React.FC<PropsFromRedux> = ({
	dataChannel1,
	shareScreenStatus,
	fullScreenStatus,
	robotId,
	robotName,
	navSpeed,
	setParameter,
	accountState,
	showMenu,
	showAbsoluteMenu,
	isMenuExpanded,
	showAccountInformation,
	publish,
	mqttConfig,
	localVoiceVolume,
	isDataFetchLoaderShowing,
	drivingMode,
}) => {
	const history = useHistory();

	const [currNavInput, setCurrNavInput] = useState<IActiveNavInput | null>(null);
	const onKeyboardNavInputFocusChanged = buildOnNavInputFocusChanged('keyboard', setCurrNavInput);
	const onJoystickActivationChanged = buildOnNavInputFocusChanged('joystick', setCurrNavInput);
	const onAutoDockingActiveChanged = buildOnNavInputFocusChanged('auto-docking', setCurrNavInput);

	const navigateToRosterPage = useCallback(() => history.replace('/gobe'), [history]);

	const initialLocalVolume = useRef(Number.parseFloat(localVoiceVolume));
	const [hasPrimaryVideoStartedPlaying, setHasPrimaryVideoStartedPlaying] = useState(false);

	const { currentOverlay, showOverlay, hideOverlay } = useSessionOverlay();

	const [navDataChannel, setNavDataChannel] = useState<RTCDataChannel | undefined>();

	const onDataChannel = useCallback(
		(dataChannel: RTCDataChannel) => {
			if (dataChannel.label === 'nav-datachannel') {
				(window as any).datachannel = dataChannel;
				setNavDataChannel(dataChannel);
			} else {
				console.log('OTHER-DATACHANNEL created');
				setParameter('dataChannel1', SET_DATA_CHANNEL, dataChannel);
				dataChannel.send(`VOL ${localVoiceVolume}`);
			}
		},
		[localVoiceVolume, setParameter]
	);

	const [localStream, setLocalMediaStream] = useState<MediaStream | null>(null);

	const onPeerConnectionStarted = useCallback(() => {
		// TODO: Add some logic for this...
		console.debug('session.Component PeerConnection started');
	}, []);

	const onPeerConnectionEnded = useCallback(
		(reason: PeerConnectionEndReasonCode) => {
			function assertUnreachable(x: never): never {
				throw new Error("Didn't expect to get here");
			}
			switch (reason) {
				case 'LOCAL_HANGUP':
				case 'PAUSED_STATE_TIMED_OUT':
					navigateToRosterPage();
					return;
				case 'PEER_HANGUP': // yeah, our peer is a robot! - this might be an error
				case 'FAILED_STATE_TIMED_OUT':
				case 'ERROR':
					showOverlay('SessionNetworkFailure');
					return;
				case 'CLEANUP':
					return;
				case 'NO_ACK':
				case 'NOT_ALLOWED_BUSY':
					showOverlay('UnavailableRobot');
					return;
				default:
					// this is a type-safe way to ensure that all cases
					//  	of the PeerConnectionEndReasonCode are handled in this switch
					return assertUnreachable(reason);
			}
		},
		[navigateToRosterPage, showOverlay]
	);

	// setup the controls for the peer connection
	const {
		robotCapabilities,
		endPeerConnection,
		startPeerConnection,
		pausePeerConnection,
		unpausePeerConnection,
		togglePrimaryCamera,
		primaryCameraState,
		isPeerConnectionPaused: isSessionPaused,
		primaryMediaStream,
		primaryRTPTransceiver,
		navMediaStream,
		navRTPTransceiver,
	} = useCallerPeerConnection({
		mqttConfig,
		callerInfo: {
			avatar: accountState.user.profilePictureLink,
			name: `${accountState.user.firstName} ${accountState.user.lastName}`,
			id: accountState.user.username,
			username: accountState.user.username,
			password: accountState.user.password,
		},
		robotId,
		onDataChannel,
		onStarted: onPeerConnectionStarted,
		onEnded: onPeerConnectionEnded,
	});

	// Now start the peer connection
	useEffect(() => {
		if (!localStream) return;

		startPeerConnection(localStream, initialLocalVolume.current);
	}, [startPeerConnection, localStream]);

	/** True if the user can see at least a frame of the video,
	 * and the video is not obscured by any fullscreen-overlays */
	const isVideoVisible = hasPrimaryVideoStartedPlaying && currentOverlay === null;

	const videoRtpReceivers = useMemo(() => {
		return {
			primaryCam: primaryRTPTransceiver?.receiver,
			navCam: navRTPTransceiver?.receiver,
		} as Partial<Record<RtpReceiverID, RTCRtpReceiver>>;
	}, [navRTPTransceiver, primaryRTPTransceiver]);
	const { navController, isNavigationInProgress, isDrivingImpaired } = useNavController({
		speed: navSpeed,
		isPeerConnectionPaused: isSessionPaused,
		rtpReceivers: videoRtpReceivers,
		datachannel: navDataChannel,
		isVideoVisible,
	});

	useLayoutEffect(() => {
		let link = document.getElementById('jsd-widget');
		if (link && (link as any).style.display !== 'none') {
			(link as any).style.display = 'none';
		}
	}, []);

	useIonViewDidEnter(() => {
		window.addEventListener('hashchange', () => {
			if (window.location.pathname !== '/gobe/session') {
				closeFullScreen();
			}
		});
	});

	useEffect(() => {
		setParameter('showMenu', SET_SHOW_MENU, false);
		setParameter('showAbsoluteMenu', SET_IS_EXPANDED, false);
	}, [setParameter]);

	useEffect(() => {
		return () => {
			setParameter('showMenu', SET_SHOW_MENU, true);
			setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, false);
			setParameter('isExpanded', SET_IS_EXPANDED, true);
		};
	}, [setParameter]);

	const onClickUnpauseSession = () => {
		unpausePeerConnection();
		hideOverlay();
	};

	const stopShareClick = () => {
		setParameter('shareScreenStatus', SET_SHARE_SCREEN_STATUS, !shareScreenStatus);
	};

	const renderShareScreen = () => {
		if (shareScreenStatus) {
			return (
				<div className={shareScreenStatus ? '' : 'displayNone'}>
					<ShareScreen stopShareClick={stopShareClick} />
				</div>
			);
		} else {
			return null;
		}
	};

	useEffect(() => {
		const fullScreenChangeHandler = () => {
			setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, !fullScreenStatus);
		};
		document.addEventListener('fullscreenchange', fullScreenChangeHandler);
		return () => {
			document.removeEventListener('fullscreenchange', fullScreenChangeHandler);
		};
	}, [fullScreenStatus, setParameter]);

	useEffect(() => {
		if (fullScreenStatus) {
			setParameter('showMenu', SET_SHOW_MENU, false);
			setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, false);
		} else {
			setParameter('showMenu', SET_SHOW_MENU, false);
			setParameter('showAbsoluteMenu', SET_SHOW_ABSOLUTE_MENU, true);
		}
	}, [fullScreenStatus, setParameter]);

	useEffect(() => {
		openFullscreen();
		return () => {
			closeFullScreen();
		};
	}, []);

	useLayoutEffect(() => {
		if (hasPrimaryVideoStartedPlaying) return;
		const primaryCamVideoTimeoutID = setTimeout(
			() => showOverlay('NoRemoteVideo'),
			NO_REMOTE_VIDEO_TIMEOUT
		);
		return () => clearTimeout(primaryCamVideoTimeoutID);
	}, [hasPrimaryVideoStartedPlaying, showOverlay]);
	const onPrimaryVideoPlaybackBegan = () => setHasPrimaryVideoStartedPlaying(true);

	const isKeyboardNavInputEnabled =
		currentOverlay === null &&
		!isDataFetchLoaderShowing &&
		(currNavInput === 'keyboard' || currNavInput == null) &&
		primaryCameraState.currentPrimaryCamera === 'wide_cam';

	// IMPORTANT : we disable the right click to avoid the ghost movements when the user use the joystick and click the right button
	document.addEventListener('contextmenu', event => {
		event.preventDefault();
	});

	const isJoystickControlSupportedByRobot = !!robotCapabilities?.mouse_control_with_joystick;

	return (
		<div className="Session" id="Session">
			<PauseOrEndSessionOverlay
				isVisible={currentOverlay === 'EndOrPauseSessionConfirmation'}
				isSessionPaused={isSessionPaused}
				onClickResumeSession={onClickUnpauseSession}
				onClickEndSession={endPeerConnection}
				onClickPauseSession={pausePeerConnection} // TODO: Prevent pausing until peer connection is actually started!!
				onClickCancel={hideOverlay}
			/>

			<RobotUnavailableOverlay
				isVisible={
					currentOverlay === 'UnavailableRobot' || currentOverlay === 'NoRemoteVideo'
				}
				onClickBackToRoster={navigateToRosterPage}
				onClickTryAgain={navigateToRosterPage} // TODO: Implement this, and allow user to call robot again
			/>
			<SessionNetworkFailureOverlay
				isVisible={currentOverlay === 'SessionNetworkFailure'}
				onClickBackToRoster={navigateToRosterPage}
				robotName={robotName}
			/>

			<MediaAccessDeniedOverlay
				isVisible={currentOverlay === 'MediaAccessDenied'}
				onClickBackToRoster={navigateToRosterPage}
				robotName={robotName}
			/>

			{renderShareScreen()}
			<LocalVideo
				startWideCameraStats={() => undefined}
				stopWideCameraStats={() => undefined}
				wideCameraStats=""
				isGreyedOut={false}
				isPaused={false}
				shouldShowLoadingIndicator={!hasPrimaryVideoStartedPlaying}
				onGotMediaStream={setLocalMediaStream}
				onMediaDevicesAccessError={() => showOverlay('MediaAccessDenied')}
			/>

			<AutoDockingInput
				onActivenessChanged={onAutoDockingActiveChanged}
				navDataChannel={navDataChannel}
				isPeerConnectionPaused={isSessionPaused}
				isVideoVisible={isVideoVisible}
			/>

			<KeyboardNavInput
				navController={navController}
				disabled={!isKeyboardNavInputEnabled}
				onFocusChanged={onKeyboardNavInputFocusChanged}
				// nonFocusableChild={sessionOptionsDivElement}
			>
				<RemotePrimaryCamVideo
					robotId={robotId}
					primaryCameraState={primaryCameraState}
					isGreyedOut={false}
					isPaused={false}
					onPlaybackBegan={onPrimaryVideoPlaybackBegan}
					mediaStream={primaryMediaStream}
				/>
				{isJoystickControlSupportedByRobot ? null : (
					/* If the robot does not support joystick control, we render the nav-view within the keyboard input.
					This makes it possible to the pilot to be able to control the robot with keyboard
					even when the mouse cursor is on the nav-view. */
					<NavViewWithSessionOptions
						// * SessionOptions props
						onClickHangUp={() => showOverlay('EndOrPauseSessionConfirmation')}
						togglePrimaryCamera={togglePrimaryCamera}
						primaryCameraState={primaryCameraState}
						isSuperZoom1Enabled={!!robotCapabilities?.super_zoom_1}
						localStream={localStream}
						hasPrimaryVideoStartedPlaying={hasPrimaryVideoStartedPlaying}
						// * NavigationVideo props
						isGreyedOut={false}
						isPaused={false}
						mediaStream={navMediaStream}
						navController={navController}
						handleJoystickEnabled={onJoystickActivationChanged}
						isJoystickMounted={false}
						// * extra props
						isNavigating={isNavigationInProgress}
					/>
				)}
			</KeyboardNavInput>

			<div
				className={
					showAbsoluteMenu
						? isMenuExpanded
							? 'robotNameContainer robotNameContainerMenuExpand'
							: 'robotNameContainer robotNameContainerMenu'
						: 'robotNameContainer'
				}
			>
				<div className="robotHeadWrapper">
					{drivingMode ? (
						<img alt="" src="../assets/images/robot-head.svg" />
					) : (
						<img alt="" src="../assets/images/black-robot-head.svg" />
					)}
				</div>
				<span className={!drivingMode ? 'black-text' : ''}>{robotName}</span>
			</div>

			<ActiveNavigationInputIndicator
				activeNavInput={currNavInput}
				isMenuExpanded={isMenuExpanded}
				isMenuAbsolute={showAbsoluteMenu}
			/>

			<ImpairedDrivingIndicator
				isMenuExpanded={isMenuExpanded}
				isAbsoluteMenuVisible={showAbsoluteMenu}
				isDrivingImpaired={isDrivingImpaired}
			/>

			{isJoystickControlSupportedByRobot ? (
				/* If the robot does not support joystick control, we render the nav-view within the keyboard input.
					This makes it possible to the pilot to be able to control the robot with keyboard
					even when the mouse cursor is on the nav-view. */
				<NavViewWithSessionOptions
					// * SessionOptions props
					onClickHangUp={() => showOverlay('EndOrPauseSessionConfirmation')}
					togglePrimaryCamera={togglePrimaryCamera}
					primaryCameraState={primaryCameraState}
					isSuperZoom1Enabled={!!robotCapabilities?.super_zoom_1}
					localStream={localStream}
					hasPrimaryVideoStartedPlaying={hasPrimaryVideoStartedPlaying}
					// * NavigationVideo props
					isGreyedOut={false}
					isPaused={false}
					mediaStream={navMediaStream}
					navController={navController}
					handleJoystickEnabled={onJoystickActivationChanged}
					isJoystickMounted={
						primaryCameraState.currentPrimaryCamera === 'wide_cam' &&
						currNavInput !== 'auto-docking'
					}
					// * extra props
					isNavigating={isNavigationInProgress}
				/>
			) : null}
		</div>
	);
};

export default React.memo(reduxConnector(Session));

function useSessionOverlay() {
	/**
	 * Bigger number means higher priority.
	 * If a higher priority overlay is currently set, a lower one cannot override it.
	 */
	const overlaysPriority = useRef<Record<ISessionOverlay, number>>({
		EndOrPauseSessionConfirmation: 0,
		SessionNetworkFailure: 1,
		UnavailableRobot: 1,
		NoRemoteVideo: 3,
		MediaAccessDenied: 4,
	}).current;

	const [currentOverlay, setOverlay] = useState<ISessionOverlay | null>(null);

	const showOverlay = useCallback(
		(newOverlay: ISessionOverlay) => {
			setOverlay(currentOverlay => {
				if (currentOverlay === null) return newOverlay;
				else if (overlaysPriority[currentOverlay] > overlaysPriority[newOverlay])
					return currentOverlay;
				else return newOverlay;
			});
		},
		[overlaysPriority]
	);

	const hideOverlay = useCallback(() => setOverlay(null), []);

	return { currentOverlay, showOverlay, hideOverlay };
}
