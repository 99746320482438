import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { PurchaseTemplate } from '../templates/PurchaseTemplate';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { BASEVALIDATIONS } from '../validationSchema';
import { HeaderText } from '../atoms/HeaderText';
import { AddressForm } from '../organisms/AddressForm';
import { OrganizationForm } from '../organisms/OrganizationForm';
import _ from 'lodash';

const TopWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
}));

const validationSchema = yup.object({ ...BASEVALIDATIONS });

export const ShippingPage = ({
	setCurrency,
	unitPrice,
	currency,
	billingAddressInfo,
	shippingAddressInfo,
	onAddressChange,
	setIsFormValid,
	currencyOptions,
	shippingSameAsBilling,
	onSameAsBillingChange,
}) => {
	const addressValues = shippingSameAsBilling ? billingAddressInfo : shippingAddressInfo;
	const formik = useFormik({
		initialValues: { ...addressValues },
		validationSchema: validationSchema,
		onSubmit: () => {},
	});

	useEffect(() => {
		validationSchema
			.validate(formik.values)
			.then(formValues => {
				const addressObjValues = _.pick(formValues, [
					'companyName',
					'orderPersonFirstName',
					'orderPersonLastName',
					'streetLine1',
					'streetLine2',
					'zip',
					'city',
					'state',
					'countryCode',
				]);

				onAddressChange(addressObjValues);
				setIsFormValid(true);
			})
			.catch(e => {
				console.log('shipping error', e);
				setIsFormValid(false);
			});
	}, [validationSchema, formik.values]);

	return (
		<PurchaseTemplate
			setCurrency={setCurrency}
			totalPrice={unitPrice}
			currency={currency}
			currencyOptions={currencyOptions}
		>
			<TopWrapper>
				<HeaderText variant="h6">
					<FormattedMessage
						id="shippingInformation"
						defaultMessage="Shipping information"
					/>
					:
				</HeaderText>
				<FormControlLabel
					control={
						<Checkbox
							checked={shippingSameAsBilling}
							onChange={e => onSameAsBillingChange(e.target.checked)}
							name="sameAsBilling"
							color="primary"
						/>
					}
					label="Same as billing details"
				/>
			</TopWrapper>
			<OrganizationForm
				formik={formik}
				hidePhone
				hideEmail
				hideTaxNumber
				disableFields={shippingSameAsBilling}
			/>
			<HeaderText variant="h6">
				<FormattedMessage id="address" defaultMessage="Address" />:
			</HeaderText>
			<AddressForm formik={formik} disableFields={shippingSameAsBilling} />
		</PurchaseTemplate>
	);
};
