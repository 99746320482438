import { useRef, useState, useLayoutEffect, useCallback } from 'react';
import { RTCInboundRtpStreamStatsEmitter } from '../rtcStats';

function minMax(_number: number, opt: { min: number; max: number }): number {
	return Math.min(opt.max, Math.max(opt.min, _number));
}

const MEDIA_STATISTICS_SAMPLING_PERIOD__MS = 200;

/** How fast or slow we want to increase the penalty.   NB: Smaller value is faster  */
const MIN_TIME_FROM_0_TO_1_PENALTY__MS = 3 * 1000;

/** How fast or slow we want to decrease the penalty.   NB: Smaller value is faster  */
const MIN_TIME_FROM_1_TO_0_PENALTY__MS = 2 * 1000;

/* The penalty may be decreased by this much (maximum) */
const MAX_PENALTY_DEC_STEP =
	MEDIA_STATISTICS_SAMPLING_PERIOD__MS / MIN_TIME_FROM_1_TO_0_PENALTY__MS;

/* The penalty may be increase by this much (maximum) */
const MAX_PENALTY_INC_STEP =
	MEDIA_STATISTICS_SAMPLING_PERIOD__MS / MIN_TIME_FROM_0_TO_1_PENALTY__MS;

function useVideoFramesPenalty(
	rtpReceiver: RTCRtpReceiver | undefined,
	expectedFrameRate: number,
	debugName?: string
): number {
	const [penalty, setPenalty] = useState(1.0);
	const frameRatePerSamplingPeriodRef = useRef(
		(expectedFrameRate / 1000) * MEDIA_STATISTICS_SAMPLING_PERIOD__MS
	);

	useLayoutEffect(() => {
		if (rtpReceiver === undefined) return;

		const penaltyMinMax = { min: 0, max: 1 };

		/* At least 1, to prevent division-by-zero errors */
		const EXPECTED_FRAMES_DECODED = Math.max(frameRatePerSamplingPeriodRef.current, 1);

		const statsListener = new RTCInboundRtpStreamStatsEmitter(
			rtpReceiver,
			null, // there is only one MediaStreamTrack associated with an RTCRtpReceiver
			MEDIA_STATISTICS_SAMPLING_PERIOD__MS
		);
		statsListener?.start();

		let prevFramesDecoded = 0;
		const statsListenerId = statsListener?.addEventListener(
			'framesDecoded',
			(framesDecoded: number) => {
				/* Fraction of expected frames that was actually decoded. No bigger than 1.0 */
				const framesFraction = Math.min(framesDecoded / EXPECTED_FRAMES_DECODED, 1);

				// the number of decoded frames, is increasing
				if (framesDecoded > 0 && framesDecoded >= prevFramesDecoded) {
					// reduce the penalty
					setPenalty(penalty => {
						// the more frames decoded, the more penalty is decremented
						return minMax(
							penalty - MAX_PENALTY_DEC_STEP * framesFraction,
							penaltyMinMax
						);
						// console.debug({
						// 	curr: framesDecoded,
						// 	prev: prevFramesDecoded,
						// 	penalty: newPenalty,
						// 	dir: 'DEC',
						// 	dbg: debugName,
						// });
					});
				}
				// the number of decoded frames is decreasing or is zero
				else {
					// increase the penalty
					setPenalty(penalty => {
						return minMax(
							// the less frames decoded, the more penalty is incremented
							penalty + MAX_PENALTY_INC_STEP * (1 - framesFraction),
							penaltyMinMax
						);
						// console.debug({
						// 	curr: framesDecoded,
						// 	prev: prevFramesDecoded,
						// 	penalty: newPenalty,
						// 	dir: 'INC',
						// 	dbg: debugName,
						// });
						// return newPenalty;
					});
				}

				prevFramesDecoded = framesDecoded;
			}
		);

		return () => {
			statsListener?.stop();
			statsListener?.removeListener('framesDecoded', statsListenerId!);
		};
	}, [rtpReceiver, debugName]);

	return penalty;
}

export type RtpReceiverID = 'primaryCam' | 'navCam';

/** Hook to enable or disable navController based on the media flow  */
export default function useDrivingImpairment(
	maxPrimaryVideoFramesPerSecond: number,
	maxNavVideoFramesPerSecond: number
) {
	// we expect that at least a big fraction of the frame rate should be achieved
	const PRIMARY_VIDEO_FPS = useRef(maxPrimaryVideoFramesPerSecond * 0.95).current;
	const NAV_VIDEO_FPS = useRef(maxNavVideoFramesPerSecond * 0.6).current;

	const [primaryRtpReceiver, setPrimaryRtpReceiver] = useState<RTCRtpReceiver>();
	const [navRtpReceiver, setNavRtpReceiver] = useState<RTCRtpReceiver>();

	const primaryVideoPenalty = useVideoFramesPenalty(primaryRtpReceiver, PRIMARY_VIDEO_FPS);
	const navVideoPenalty = useVideoFramesPenalty(navRtpReceiver, NAV_VIDEO_FPS);

	/** Set the rtp receiver for a video track that needs to be tracked
	 * in order to allow/disallow navigation */
	const onRtpReceiver = useCallback((cam: RtpReceiverID, rtcpReceiver: RTCRtpReceiver) => {
		// console.debug(`onRtpReceiver() -> ${cam}`);
		if (cam === 'navCam') setNavRtpReceiver(rtcpReceiver);
		else setPrimaryRtpReceiver(rtcpReceiver);
	}, []);

	return {
		onRtpReceiver,
		// TODO: Discuss with team if this should be a weighted sum instead
		// Note that the penalty is 1 for a camera, until its rtpReceiver has been added
		penalty: Math.max(primaryVideoPenalty, navVideoPenalty), // apply the biggest penalty
	};
}
