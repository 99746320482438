/* eslint-disable camelcase */
import {
	SET_DATA_CHANNEL,
	SET_WIFI_STATUS,
	SET_BATTERY_STATUS,
	SET_LOCAL_VOICE_STATUS,
	SET_REMOTE_VOICE_STATUS,
	SET_REMOTE_VIDEO_ZOOM_STATUS,
	SET_MORE_OPTIONS_STATUS,
	SET_NAV_OPTIONS_STATUS,
	SET_SHARE_SCREEN_STATUS,
	SET_FULL_SCREEN_STATUS,
	SET_REMOTE_VOICE_VOLUME,
	SET_LOCAL_VOICE_VOLUME,
	SET_REMOTE_VIDEO_ZOOM,
	SET_NAV_VIDEO_ZOOM,
	SET_NAV_SPEED,
	SET_ROBOT_ID,
	SET_ROBOT_NAME,
	SET_HIDE_NAV_OPTIONS_STATUS,
	SET_INITIAL,
	SET_DRIVING_MODE_STATUS,
	SET_NAV_CAMERA_HEIGHT,
} from '../actions/types';

type SessionStateModel = {
	dataChannel1: any;
	wifiStatus: boolean;
	batteryStatus: boolean;
	endSessionStatus: boolean;
	localVoiceStatus: boolean;
	remoteVoiceStatus: boolean;
	remoteVideoZoomStatus: boolean;
	moreOptionsStatus: boolean;
	navOptionsStatus: boolean;
	failedConnectStatus: boolean;
	shareScreenStatus: boolean;
	fullScreenStatus: boolean;
	localVoiceVolume: string;
	remoteVoiceVolume: string;
	remoteVideoZoom: string;
	navVideoZoom: string;
	navSpeed: string;
	robotId: string;
	robotName: string;
	hideNavOptionsStatus: boolean;
	drivingMode: boolean;
	navCameraHeight: number;
};

type SessionActionsModel = {
	type: string;
	payload: SessionStateModel;
};

const Initial_State = {
	dataChannel1: null,
	navStream: null,
	wifiStatus: false,
	batteryStatus: false,
	localVoiceStatus: false,
	remoteVoiceStatus: false,
	remoteVideoZoomStatus: false,
	moreOptionsStatus: false,
	navOptionsStatus: false,
	shareScreenStatus: false,
	fullScreenStatus: false,
	failedConnectStatus: false,
	localVoiceVolume: '19',
	remoteVoiceVolume: '50',
	remoteVideoZoom: '0',
	navVideoZoom: '0',
	navSpeed: '50',
	robotId: '',
	robotName: '',
	hideNavOptionsStatus: true,
	drivingMode: true,
	navCameraHeight: 250,
};

export default (state = Initial_State, action: SessionActionsModel) => {
	switch (action.type) {
		case SET_DRIVING_MODE_STATUS:
			return { ...state, drivingMode: action.payload.drivingMode };
		case SET_NAV_CAMERA_HEIGHT:
			return { ...state, navCameraHeight: action.payload.navCameraHeight };
		case SET_DATA_CHANNEL:
			return { ...state, dataChannel1: action.payload.dataChannel1 };
		case SET_WIFI_STATUS:
			return { ...state, wifiStatus: action.payload.wifiStatus };
		case SET_BATTERY_STATUS:
			return { ...state, batteryStatus: action.payload.batteryStatus };
		case SET_LOCAL_VOICE_STATUS:
			return { ...state, localVoiceStatus: action.payload.localVoiceStatus };
		case SET_REMOTE_VOICE_STATUS:
			return { ...state, remoteVoiceStatus: action.payload.remoteVoiceStatus };
		case SET_REMOTE_VIDEO_ZOOM_STATUS:
			return { ...state, remoteVideoZoomStatus: action.payload.remoteVideoZoomStatus };
		case SET_MORE_OPTIONS_STATUS:
			return { ...state, moreOptionsStatus: action.payload.moreOptionsStatus };
		case SET_NAV_OPTIONS_STATUS:
			return { ...state, navOptionsStatus: action.payload.navOptionsStatus };
		case SET_SHARE_SCREEN_STATUS:
			return { ...state, shareScreenStatus: action.payload.shareScreenStatus };
		case SET_FULL_SCREEN_STATUS:
			return { ...state, fullScreenStatus: action.payload.fullScreenStatus };
		case SET_HIDE_NAV_OPTIONS_STATUS:
			return { ...state, hideNavOptionsStatus: action.payload.hideNavOptionsStatus };
		case SET_LOCAL_VOICE_VOLUME:
			return { ...state, localVoiceVolume: action.payload.localVoiceVolume };
		case SET_REMOTE_VOICE_VOLUME:
			return { ...state, remoteVoiceVolume: action.payload.remoteVoiceVolume };
		case SET_REMOTE_VIDEO_ZOOM:
			return { ...state, remoteVideoZoom: action.payload.remoteVideoZoom };
		case SET_NAV_VIDEO_ZOOM:
			return { ...state, navVideoZoom: action.payload.navVideoZoom };
		case SET_NAV_SPEED:
			return { ...state, navSpeed: action.payload.navSpeed };
		case SET_ROBOT_ID:
			return { ...state, robotId: action.payload.robotId };
		case SET_ROBOT_NAME:
			return { ...state, robotName: action.payload.robotName };
		case SET_INITIAL:
			return { ...Initial_State, failedConnectStatus: state.failedConnectStatus };
		default:
			return state;
	}
};
