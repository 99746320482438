import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const SubHeader = styled(Typography)(({ theme }) => ({
	color: theme.palette.greyPalette.grey1,
}));

const SumHeader = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	fontWeight: 700,
}));

const BoldBodyText = styled(Typography)(({ theme }) => ({
	fontWeight: 600,
}));

const LabelColumn = ({ isFirst, row, isLast }) => {
	if (isFirst) {
		return <BoldBodyText>{row.label}</BoldBodyText>;
	} else if (row.type === 'subHeader') {
		return <SubHeader variant="h5">{row.label}:</SubHeader>;
	} else if (isLast) {
		return <SumHeader>{row.label}</SumHeader>;
	} else if (row.type === 'contactInfo') {
		return (
			<div>
				<Typography>{row.contactName}</Typography>
				<BoldBodyText>{row.companyName}</BoldBodyText>
			</div>
		);
	} else return <Typography>{row.label}</Typography>;
};

export { LabelColumn };
