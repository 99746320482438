import { MqttRouter } from 'mqtt-sub-router';
import { store } from '../store/store';

const DevicesRouter = new MqttRouter()
	.listen('update', ({ data, params, topic, originalTopic, mqttClient }) => {
		const deviceId = originalTopic.split('/')[0];
		const state = store.getState().deviceState;
		const userRole = store.getState().accountState.user.roles;
		let organizationId;

		if (!data) return;
		const index = state.items.findIndex(d => d.deviceId === deviceId);

		if (index > -1) organizationId = state.items[index].orgId;

		store.dispatch({
			type: `UPDATE_DEVICE_PROPS`,
			payload: { deviceId, props: data, organizationId, userRole },
		});
	})
	.listen('online', ({ data, params, topic, originalTopic, mqttClient }) => {
		const deviceId = originalTopic.split('/')[0];
		const state = store.getState().deviceState;
		const userRole = store.getState().accountState.user.roles;
		let organizationId;

		if (!data) return;
		const index = state.items.findIndex(d => d.deviceId === deviceId);

		if (index > -1) organizationId = state.items[index].orgId;

		store.dispatch({
			type: `UPDATE_DEVICE_PROPS`,
			payload: { deviceId, props: data, organizationId, userRole },
		});
	});

export default DevicesRouter;
