import React, { FC, useState, useEffect, useCallback } from 'react';
import { IonGrid, IonRow, IonInput, IonList, IonItem, IonLabel, IonCol } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { countries } from 'countries-list';
import { CountrySelect } from '../CustomFormComponents';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import { publish } from '../../actions/publish';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './OrganizationForm.messages';

import classes from './OrganizationForm.module.css';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';

interface OrganizationFormProps {
	organization: any;
	isEditable?: boolean;
	onSubmit: (data: any) => void;
	saved: boolean;
	segment: string;
}

const OrganizationForm: FC<OrganizationFormProps> = (props: any) => {
	const { isEditable, organization, intl, saved, segment } = props;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);
	const { register, handleSubmit, control, errors } = useForm();
	const getCountryName = (countryCode: keyof Object): string => {
		if (countries[countryCode] !== undefined) {
			return countries[countryCode].name;
		}
		return '';
	};
	const parseOrganization = useCallback((organization: any) => {
		let org = JSON.parse(JSON.stringify(organization));
		if (!org) return null;
		org = {
			orgId: org ? org.orgId : '',
			name: org ? org.name : '',
			billingAddress: org ? org.billingAddress : {},
			createdAt: org && org.createdAt ? new Date(org.createdAt) : new Date('01/01/1970'),
			language: org && org.language ? org.language : 'English',
		};

		return org;
	}, []);

	const [changingOrganization, setChangingOrganization] = useState(
		parseOrganization(organization)
	);

	useEffect(() => {
		if (saved && segment === 'details') {
			handleSubmit(onEditSubmit)();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [saved, segment]);

	useEffect(() => {
		if (!isEditable) {
			setChangingOrganization(parseOrganization(organization));
		}
	}, [isEditable, organization, parseOrganization]);

	const updateLocalOrgState = (e: CustomEvent, prop: any) => {
		const change = e.detail.value;
		let orgToChange = Object.assign(changingOrganization);

		if (prop.includes('.')) {
			prop = prop.split('.');
			if (!orgToChange[prop[0]] && prop[1]) {
				orgToChange[prop[0]] = {};
			}
			orgToChange[prop[0]][prop[1]] = change;
		} else orgToChange[prop] = change;
		setChangingOrganization(orgToChange);
	};

	const onEditSubmit = (data: any) => {
		if (
			!organization.orgId ||
			!changingOrganization.name ||
			!changingOrganization.billingAddress.address ||
			!changingOrganization.billingAddress.city ||
			!changingOrganization.billingAddress.country ||
			!changingOrganization.billingAddress.state ||
			!changingOrganization.billingAddress.zip
		) {
			console.error('empty fields on org edition is not allowed');
			return;
		}

		publish(`microservice/${organization.orgId}/${encodedUser}/updateOrgBasicInfo`, {
			data: {
				orgId: organization.orgId,
				name: changingOrganization.name,
				billingAddress: changingOrganization.billingAddress,
			},
			requestId: 'updateOrgBasicInfoId',
		});
	};

	return (
		<form onSubmit={handleSubmit(onEditSubmit)} className={classes.editForm}>
			<IonGrid className={classes.formGrid}>
				<IonRow className={classes.detailsHeader}>
					<IonLabel>
						<FormattedMessage {...Messages.details} />
					</IonLabel>
				</IonRow>
				<IonRow>
					<IonCol className={classes.detailsCol}>
						<div className={classes.subTitle}>
							<IonLabel position="fixed" className={classes.subTitleLb}>
								<FormattedMessage {...Messages.orgDetails} />:
							</IonLabel>
						</div>
						<IonList>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.companyName} />
								</IonLabel>

								<IonLabel
									className={!isEditable ? classes.inputLb : classes.hidden}
								>
									{changingOrganization.name}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="name"
									placeholder={intl.formatMessage({
										id: 'Organizations.companyNameHint',
									})}
									value={changingOrganization.name}
									// ref={register({ required: true })}
									readonly={!isEditable}
									onIonChange={e => updateLocalOrgState(e, 'name')}
								/>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.createdOn} />
								</IonLabel>
								<IonLabel className={classes.createdOnLb}>
									{`${changingOrganization.createdAt.getDate()}/${changingOrganization.createdAt.getMonth() +
										1}/${changingOrganization.createdAt.getFullYear()}`}
								</IonLabel>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.language} />
								</IonLabel>

								<IonLabel className={classes.inputLb}>
									{changingOrganization.language}
								</IonLabel>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.street} />
								</IonLabel>
								<IonLabel
									className={!isEditable ? classes.inputLb : classes.hidden}
								>
									{changingOrganization && changingOrganization.billingAddress
										? changingOrganization.billingAddress.address
										: ''}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="address"
									placeholder={intl.formatMessage({
										id: 'Address.streetHint',
									})}
									value={
										changingOrganization && changingOrganization.billingAddress
											? changingOrganization.billingAddress.address
											: ''
									}
									// ref={register({ required: true })}
									readonly={!isEditable}
									onIonChange={e =>
										updateLocalOrgState(e, 'billingAddress.address')
									}
								/>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.zip} />
								</IonLabel>
								<IonLabel
									className={!isEditable ? classes.inputLb : classes.hidden}
								>
									{changingOrganization && changingOrganization.billingAddress
										? changingOrganization.billingAddress.zip
										: ''}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="zip"
									placeholder={intl.formatMessage({
										id: 'Address.zipHint',
									})}
									value={
										changingOrganization && changingOrganization.billingAddress
											? changingOrganization.billingAddress.zip
											: ''
									}
									// ref={register({ required: true })}
									readonly={!isEditable}
									onIonChange={e => updateLocalOrgState(e, 'billingAddress.zip')}
								/>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.city} />
								</IonLabel>
								<IonLabel
									className={!isEditable ? classes.inputLb : classes.hidden}
								>
									{changingOrganization && changingOrganization.billingAddress
										? changingOrganization.billingAddress.city
										: ''}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="city"
									placeholder={intl.formatMessage({
										id: 'Address.cityHint',
									})}
									value={
										changingOrganization && changingOrganization.billingAddress
											? changingOrganization.billingAddress.city
											: ''
									}
									// ref={register({ required: true })}
									readonly={!isEditable}
									onIonChange={e => updateLocalOrgState(e, 'billingAddress.city')}
								/>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.state} />
								</IonLabel>
								<IonLabel
									className={!isEditable ? classes.inputLb : classes.hidden}
								>
									{changingOrganization && changingOrganization.billingAddress
										? changingOrganization.billingAddress.state
										: ''}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="state"
									placeholder={intl.formatMessage({
										id: 'Address.stateHint',
									})}
									value={
										changingOrganization && changingOrganization.billingAddress
											? changingOrganization.billingAddress.state
											: ''
									}
									// ref={register({ required: true })}
									readonly={!isEditable}
									onIonChange={e =>
										updateLocalOrgState(e, 'billingAddress.state')
									}
								/>
							</IonItem>
							<div className={classes.countrySelectItem}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.country} />
								</IonLabel>
								<IonLabel
									// Hide country editing until stabilized
									// className={!isEditable ? classes.inputLb : classes.hidden}
									className={!isEditable ? classes.inputLb : classes.inputLb}
								>
									{getCountryName(
										changingOrganization && changingOrganization.billingAddress
											? changingOrganization.billingAddress.country
											: 'DK'
									)}
								</IonLabel>
								{/* Hide country editing until stabilized */}
								{/* <div className={isEditable ? classes.fullWidth : classes.hidden}> */}
								<div className={isEditable ? classes.hidden : classes.hidden}>
									<CountrySelect
										control={control}
										initializedValue={
											changingOrganization &&
											changingOrganization.billingAddress
												? changingOrganization.billingAddress.country
												: 'DK'
										}
										name="country"
										errors={errors}
										menuPlacement="top"
										standardInput
										required
									/>
								</div>
							</div>
						</IonList>
					</IonCol>
				</IonRow>
			</IonGrid>
		</form>
	);
};
const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	selectedOrganization: state.selectedOrganizationState.organization,
	organizations: state.organizationState.organizations,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(OrganizationForm), 'OrganizationForm'));
