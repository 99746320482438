import React from 'react';
import { Table as MuiTable, TableBody } from '@material-ui/core';
import { TableCell, TableRow } from '../../../components/atoms/TableComponents';
import { LabelColumn } from './LabelColumn';
import { DescriptionColumn } from './DescriptionColumn';
import { PriceColumn } from './PriceColumn';

const Table = ({ rows }) => {
	return (
		<MuiTable>
			<TableBody>
				{rows.map((row, index) => {
					const isFirst = index === 0;
					const isLast = rows.length === index + 1;
					return (
						<TableRow key={ index}>
							<TableCell component="th" scope="row" width="250px">
								<LabelColumn
									isFirst={isFirst}
									isLast={isLast}
									row={row.labelColumn}
								/>
							</TableCell>
							<TableCell component="th" scope="row" width="200px">
								<DescriptionColumn row={row.descriptionColumn} />
							</TableCell>
							<TableCell isLast={isLast}>
								<PriceColumn row={row.priceColumn} />
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</MuiTable>
	);
};
export { Table };
