import React, { useEffect, useLayoutEffect, useState } from 'react';
import useAutoDockingController, { AutoDockingController } from './autoDockingController';
import AutoDockingStatusIndicator from './AutoDockingStatusIndicator';
import useDockingKeyPressStateChart from './useDockingKeyPressStateChart';

type AutoDockingInputProps = {
	navDataChannel?: RTCDataChannel;
	isPeerConnectionPaused: boolean;
	isVideoVisible: boolean;
	onActivenessChanged: (isActive: boolean) => void;
};

type AutoDockingStatus = AutoDockingController['status'];

/**
 * Handles capturing keyboard events related to docking,
 * and forwarding to AutoDockingController
 * */
export default function AutoDockingInput({
	navDataChannel,
	isPeerConnectionPaused,
	isVideoVisible,
	onActivenessChanged,
}: AutoDockingInputProps) {
	const controller = useAutoDockingController({
		datachannel: navDataChannel,
		isPeerConnectionPaused,
		isVideoVisible,
	});
	const [controllerStatus, setControllerStatus] = useState<AutoDockingStatus>(controller.status);

	const {
		state: keyPressState,
		onKeyDown,
		onKeyUp,
		onDockingInitiated,
		onDockingTerminated,
		onDockingError,
	} = useDockingKeyPressStateChart({
		sendSTART: controller.start,
		sendSTOP: controller.stop,
		sendCONTINUE: controller.onDockCONTINUE,
		sendRESET: controller.reset,
	});

	useEffect(() => {
		const isKeyPressedDown = ([
			'pressedButWaiting',
			'pressedAndEngaged.withKeyDown',
			'pressedAndEngaged.withKeyUp',
		] as Array<typeof keyPressState>).includes(keyPressState);
		onActivenessChanged(isKeyPressedDown);
	}, [keyPressState]);

	useLayoutEffect(() => {
		const onStatusChanged = () => {
			setControllerStatus(controller.status);

			const { stage, state } = controller.status;
			if (state === 'FAILED') {
				onDockingError();
			} else if (stage === 'STARTING' && state === 'SUCCESS') {
				onDockingInitiated();
			} else if (stage === 'DOCKING' && state === 'SUCCESS') {
				onDockingTerminated();
			}
		};
		controller.addEventListener('status-changed', onStatusChanged);
		return () => {
			controller.removeEventListener('status-changed', onStatusChanged);
		};
	}, [controller, onDockingInitiated, onDockingTerminated, onDockingError]);

	useLayoutEffect(() => {
		document.addEventListener('keydown', onKeyDown, { capture: true });
		document.addEventListener('keyup', onKeyUp, { capture: true });
		return () => {
			document.removeEventListener('keydown', onKeyDown, { capture: true });
			document.removeEventListener('keyup', onKeyUp, { capture: true });
		};
	}, [onKeyDown, onKeyUp]);

	return <AutoDockingStatusIndicator status={controllerStatus} />;
}
