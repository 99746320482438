import React, { FC, useState, useEffect } from 'react';
import { IonLabel, IonNote } from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'react-tooltip-lite';

import InfoCard from '../InfoCard/InfoCard';
import classes from './RobotEdit.module.css';
import Messages from './RobotEdit.messages';

import { getDeviceGroupNames } from '../../utils/formatData';
import { useTypedSelector } from '../../reducers';
import { equalityFnc, getStateEntityByOrgId } from '../../utils/conformState';

const deviceIcons: Record<string, string> = {
	beam: '../../../assets/img/beam-logo-white.svg',
	gobe: '../../../assets/icons/GOBE-ROBOTS-logo-neg.svg',
};

interface RobotCardProps {
	robotId: string;
	orgId: string;
	onClose: () => void;
	setEditPicOpen: any;
}

const RobotCard: FC<RobotCardProps> = props => {
	const { robotId, orgId, onClose, setEditPicOpen } = props;

	const deviceGroups = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId[orgId],
		(left, right) => equalityFnc(left, right)
	);
	const device = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: robotId,
				orgId,
				propertyOptions: [
					'deviceGroupsIds',
					'orgId',
					'deviceId',
					'deviceType',
					'name',
					'location',
				],
			}),
		(left, right) => equalityFnc(left, right)
	);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	const [groupNames, setGroupNames] = useState([]);

	useEffect(() => {
		if (!device.deviceGroupsIds) return;
		setGroupNames(getDeviceGroupNames(device.deviceGroupsIds, deviceGroups));
	}, [device.deviceGroupsIds, deviceGroups]);

	return (
		<InfoCard
			imgUrl={deviceIcons[spinoutType]}
			title={device.name}
			subTitle={
				groupNames && groupNames.length > 0 ? (
					groupNames.length > 2 ? (
						<IonLabel className={classes.moreNames}>
							<FormattedMessage
								{...Messages.in}
								values={{
									group: groupNames[0],
								}}
							/>
							<Tooltip
								direction="up"
								content={
									<ul className={classes.groupNameItem}>
										{groupNames.slice(1).map((gr: any, i: number) => {
											return (
												<li key={i}>
													<IonLabel>{gr}</IonLabel>
													<br />
												</li>
											);
										})}
									</ul>
								}
							>
								<span className={classes.andLb}>
									<FormattedMessage {...Messages.and} />
								</span>
								<span className={classes.moreInLb}>
									<FormattedMessage
										{...Messages.moreIn}
										values={{
											nr: groupNames.length - 1,
										}}
									/>
								</span>
							</Tooltip>
						</IonLabel>
					) : (
						<FormattedMessage
							{...Messages.in}
							values={{
								group: groupNames,
							}}
						/>
					)
				) : null
			}
			entityType="robot"
			noteContent={
				<>
					<IonNote className={classes.locationNote}>{device.location}</IonNote>
				</>
			}
			onEditPic={() => setEditPicOpen(true)}
			onClose={onClose}
			avatarClass={classes.avatarClass}
		/>
	);
};

export default RobotCard;
