import React from 'react';
import { StepPageWrapper } from '../../../../components/atoms/StepPageWrapper';
import { ColumnContainer } from '../../../../components/organisms/ColumnContainer';
import { RobotInfo } from '../organisms/RobotInfo';
import { Header } from '../modules/Header';

export const PurchaseTemplate = ({
	children,
	hideHeader,
	totalPrice,
	setCurrency,
	currency,
	currencyOptions = [],
}) => {
	return (
		<StepPageWrapper>
			<ColumnContainer
				leftSideContent={<RobotInfo />}
				rightSideContent={
					<div>
						{hideHeader ? null : (
							<Header
								totalPrice={totalPrice}
								setCurrency={setCurrency}
								currency={currency}
								currencyOptions={currencyOptions}
							/>
						)}
						{children}
					</div>
				}
			/>
		</StepPageWrapper>
	);
};
