import React, { FC, useState, useCallback } from 'react';
import { IonIcon, IonLabel, IonAvatar } from '@ionic/react';
import { personCircle, shieldCheckmark } from 'ionicons/icons';
import ListGrid from '../ListGrid/ListGrid';
import Tooltip from 'react-tooltip-lite';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './UsersGrid.module.css';
import classNames from 'classnames';

import { FormattedDate, FormattedMessage } from 'react-intl';
import ListMessages from '../ListGrid/ListGrid.messages';
import Messages from '../UsersGrid/UsersGrid.messages';
import { formatTimeAgo } from '../../utils/formatTime';

interface UsersGridProps {
	data: any;
	moreContent: React.ReactNode;
	onRowClick: any;
	checkItem: any;
	handleOnCheck: any;
	// checkAll: any;
	// unCheckAll: any;
}

const UsersGrid: FC<UsersGridProps> = (props: any) => {
	const {
		selectedOrganization,
		history,
		selectUser,
		onUnCheck,
		selectedUsers,
		// checkAll,
		// unCheckAll,
	} = props;
	const headers = [
		{
			title: Messages.name,
			property: 'name',
			checkbox: selectUser,
			onUnCheck,
			selectedNumber: selectedUsers && selectedUsers.length,
		},
		{
			title: Messages.email,
			property: 'username',
			hideMd: true,
		},
		// {
		// 	title: Messages.groups,
		// 	property: 'userGroupName',
		// 	centered: true,
		// },
		{ title: Messages.dateAdded, property: 'dateJoined' },
		{ title: Messages.privileges, property: 'role', centered: true },
		{ title: Messages.latestSession, property: '', hideMd: true },
	];

	const [pageSize, setPageSize] = useState(11);
	const organizationId = selectedOrganization.orgId;

	// const check = (item: any) => {
	// 	return selectedUsers.some((user: any) => user.username === item.username);
	// };

	// const goToGroup = useCallback(
	// 	(group: any) => {
	// 		history.push({
	// 			pathname: '/fleetManagement',
	// 			data: { tab: 'userGroups', orgId: organizationId, editableUserGroup: group },
	// 		});
	// 	},
	// 	[history, organizationId]
	// );

	const formatTemplate = useCallback(
		(item: any, property: string) => {
			switch (property) {
				case 'name':
					return (
						<div className={classes.nameContainer}>
							{!selectUser && (
								<IonAvatar className={classes.userAvatar}>
									{item.profilePictureLink ? (
										<img
											className={classes.profilePic}
											src={item.profilePictureLink}
											alt="Avatar"
										/>
									) : (
										<IonIcon
											className={classNames(
												classes.profilePic,
												classes.noPic
											)}
											size="large"
											icon={personCircle}
										/>
									)}
								</IonAvatar>
							)}
							<IonLabel className={classes.userNameLb}>{item.name}</IonLabel>
						</div>
					);
				// case 'userGroupName':
				// 	if (item.userGroups?.length > 1) {
				// 		return (
				// 			<IonLabel className={classes.groupLink}>
				// 				<Tooltip
				// 					direction="up"
				// 					styles={{ width: '100%' }}
				// 					content={
				// 						<ul className={classes.groupNameItem}>
				// 							{item.userGroups.map((gr: any, i: number) => {
				// 								return (
				// 									<li key={i}>
				// 										<IonLabel>{gr.name}</IonLabel>
				// 										<br />
				// 									</li>
				// 								);
				// 							})}
				// 						</ul>
				// 					}
				// 				>
				// 					{item.userGroupName}
				// 				</Tooltip>
				// 			</IonLabel>
				// 		);
				// 	} else
				// 		return (
				// 			<div
				// 				className={classes.groupLink}
				// 				onClick={(event: any) => {
				// 					event.stopPropagation();
				// 					if (item.userGroups.length > 0) goToGroup(item.userGroups[0]);
				// 				}}
				// 			>
				// 				{item.userGroupName}
				// 			</div>
				// 		);
				case 'role':
					return item[property] ? (
						<div className={classes.roleContainer}>
							<Tooltip
								direction="up"
								content={<FormattedMessage {...Messages.admin} />}
							>
								<IonIcon
									className={classes.shieldIcon}
									color="primary"
									slot="icon-only"
									size="small"
									icon={shieldCheckmark}
								/>
							</Tooltip>
						</div>
					) : null;
				case 'dateJoined':
					if (item[property]) {
						const now = Date.now(); // for testing => new Date('2020-04-27T10:03:48.114Z').getTime();
						const nowDt = new Date(now);
						const dateJoined = new Date(item[property]);

						const yearDiff = Math.abs(
							dateJoined.getUTCFullYear() - nowDt.getUTCFullYear()
						);

						if (yearDiff > 0) {
							return (
								<FormattedMessage
									{...ListMessages.timeAgo.timeYears}
									values={{ number: yearDiff }}
								/>
							);
						} else return formatTimeAgo(dateJoined, now);
					} else return null;
				default:
					return item[property];
			}
		},
		[selectUser]
	);

	return (
		<ListGrid
			id="users-list-grid"
			headers={headers}
			data={props.data}
			pageSize={pageSize}
			itemTemplate={(item, property) => {
				return formatTemplate(item, property);
			}}
			// checkItem={check}
			// checkAll={checkAll}
			// unCheckAll={unCheckAll}
			moreContent={props.moreContent}
			noDataTemplate={
				<>
					<IonLabel className={classes.noData}>
						<FormattedMessage {...Messages.noUsers} />
					</IonLabel>
					<IonLabel className={classNames(classes.noData, classes.noDataHint)}>
						<FormattedMessage {...Messages.noUsersHint} />
					</IonLabel>
				</>
			}
			onRowClick={props.onRowClick}
			// handleOnCheck={props.handleOnCheck}
			sortType="SET_USERS_SORT_PARAMS"
		/>
	);
};

const mapStateToProps = (state: any) => ({
	selectedOrganization: state.selectedOrganizationState.organization,
	// userGroups: state.userGroupsState,
});

export default isAuthenticated(connect(mapStateToProps, { setParameter })(UsersGrid), 'UsersGrid');
