import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export const Snackbar = ({ open, onClose, message, snackbarType = 'error' }) => {
	return (
		<MuiSnackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={open}
			autoHideDuration={6000}
			onClose={onClose}
		>
			<MuiAlert elevation={6} variant="filled" severity={snackbarType} onClose={onClose}>
				{message}
			</MuiAlert>
		</MuiSnackbar>
	);
};
