import React, { useCallback } from 'react';
import Battery from '../../../components/battery';
import './index.scss';
import BatteryDetails from '../batteryDetails';
import { useDispatch } from 'react-redux';
import { setParameter } from '../../../actions/setParam';
import { useTypedSelector } from '../../../../../../reducers';
import { useHistory } from 'react-router-dom';
import { SET_ROBOT_ID, SET_ROBOT_NAME } from '../../../actions/types';
import { equalityFnc } from '../../../utils/deviceStateConverter';
import {
	statusIconConverter,
	statusTextConverter,
	getDeviceCurrentState,
} from '../../../utils/statusConverter';
import { getStateEntityByOrgId } from '../../../../../../utils/conformState';
import classNames from 'classnames';

interface PropsFromParent {
	deviceId: number | string;
	orgId: number | string;
	changeAgreementStatus: Function;
	acceptedAgreementsStatus: boolean;
	deviceGroupsNames?: string | null;
}

const GoBeListIcon: React.FC<PropsFromParent> = ({
	deviceId,
	orgId,
	changeAgreementStatus,
	acceptedAgreementsStatus,
	deviceGroupsNames,
}) => {
	const item: any = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'accountState', {
				entityId: deviceId,
				orgId: orgId,
				propertyOptions: [
					'online',
					'status',
					'battery',
					'currentState',
					'name',
					'groupName',
					'battery',
					'location',
				],
			}),
		(left, right) => equalityFnc(left, right)
	);
	const currentState = getDeviceCurrentState(item);

	const dispatch = useDispatch();
	const history = useHistory();

	// let usedStatus = useCallback(() => {
	// 	if (item.currentState) {
	// 		return item.currentState;
	// 	} else {
	// 		return JSON.parse(item.status)?.currentState;
	// 	}
	// }, [item.currentState, item.status]);

	const onStartSession = useCallback(async () => {
		if (item.online) {
			if (getDeviceCurrentState(item) === 'available') {
				await dispatch(setParameter('robotId', SET_ROBOT_ID, deviceId));
				await dispatch(setParameter('robotName', SET_ROBOT_NAME, item.name));
				if (acceptedAgreementsStatus === false) {
					await changeAgreementStatus(true);
				} else {
					history.push('/gobe/session');
					// if (updatedRobotsSelector(updatedRobots, deviceId)) {
					// 	history.push('/gobe/session');
					// } else {
					// 	history.push('/gobe/session-legacy');
					// }
				}
			}
		}
	}, [acceptedAgreementsStatus, changeAgreementStatus, deviceId, dispatch, history, item]);

	if (item) {
		return (
			<div
				className={classNames('rosterListRow oneGoBeListContainer', {
					oneGoBeListOffline: !item.online,
				})}
			>
				<div className="rosterListRowCell rosterListCellName oneGoBeListName">
					<div
						className={
							item.online
								? currentState === 'available'
									? 'playGoBeListContainer'
									: 'playGoBeListContainer cursorDefault'
								: 'playGoBeListContainer playGoBeListOffline'
						}
						onClick={() => onStartSession()}
					>
						<div className="playListCircle">
							<div className="statusIconWrapper">
								<img
									src={`../../assets/images/${
										item.online
											? currentState === 'available'
												? 'play-circle.svg'
												: `${statusIconConverter(currentState)}`
											: 'grey-offline.svg'
									}`}
									alt="current state"
								/>
							</div>
						</div>
						<div className="goBeStatusDetail">
							<div className="goBeStatusDetailText">
								{statusTextConverter(currentState)}
							</div>
							<div className="goBeStatusDetailTriangle" />
						</div>
					</div>
					{item.name}
				</div>
				<div className="rosterListRowCell rosterListCellCharge goBeBatteryCharge">
					<div
						className={
							item.online
								? 'batteryListRoaster'
								: 'batteryListRoaster batteryListRoasterOff'
						}
					>
						<Battery
							batteryPercent={item.battery ? item.battery.level : 40}
							online={item.online}
							charging={item.battery ? item.battery.charging : false}
						/>{' '}
						<div className="batteryDetailsWrapper">
							<BatteryDetails
								batteryPercent={item.battery ? item.battery.level : 40}
								charging={item.battery ? item.battery.charging : false}
							/>{' '}
						</div>
					</div>
					<div className="batteryListText">{item.remainBattery}</div>
				</div>
				<div className="rosterListRowCell rosterListCellGroup goBeListItem">
					{deviceGroupsNames}
				</div>
				<div className="rosterListRowCell rosterListCellLocation goBeListItem">
					{item.location}
				</div>
				<div className="rosterListRowCell rosterListCellLastUse goBeListItem">
					{item.lastSeen}
				</div>
				<div className="rosterListRowCell rosterListCellStatus goBeListItem statusPlay">
					<div className={item.online ? 'statusText' : 'statusText statusTextOffline'}>
						{item.online ? statusTextConverter(getDeviceCurrentState(item)) : 'offline'}
					</div>
					<div className="playOfflineContainer">
						<div
							className={
								item.online
									? item.currentState === 'available'
										? 'greenPlayWrapper'
										: 'greenPlayWrapper cursorDefault'
									: 'greenPlayWrapper cursorDefault'
							}
							onClick={() => onStartSession()}
						>
							<img
								src={`../assets/images/${
									item.online
										? statusTextConverter(getDeviceCurrentState(item)) ===
										  'Available'
											? 'green-play.svg'
											: 'grey-play.svg'
										: 'grey-offline.svg'
								}`}
								alt="start-session"
							/>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default GoBeListIcon;
