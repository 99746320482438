import React, { FC, useState } from 'react';
import {
	IonLabel,
	IonCard,
	IonCardTitle,
	IonCardContent,
	IonItem,
	IonIcon,
	IonAvatar,
	IonButton,
	IonFabButton,
	IonFab,
	IonGrid,
	IonRow,
} from '@ionic/react';
import { closeCircle, personCircle, trash, camera, close } from 'ionicons/icons';
import Tooltip from 'react-tooltip-lite';
import isAuthenticated from '../../components/Authentication/Authenticated';

import classes from './InfoCard.module.css';
import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';

import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './InfoCard.messages';

interface InfoCardProps {
	imgUrl?: string;
	noImg?: any;
	title: string;
	subTitle?: any;
	email: string;
	noteContent?: React.ReactNode;
	entityType?: string;
	onClose: () => void;
	onEdit?: (e: any) => void;
	onEditPic?: (e: any) => void;
	onDelete?: (e: any) => void;
	avatarClass?: any;
}

const InfoCard: FC<InfoCardProps> = props => {
	const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);

	const onCancelDelete = () => {
		setShowDeleteOverlay(false);
	};
	const onConfirmedDelete = () => {
		setShowDeleteOverlay(false);
		if (props.onDelete) {
			props.onDelete(props.email);
		}
	};

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	return (
		<div className={classes.infoContainer}>
			<IonCard className={classes.dataCard}>
				<IonCardContent>
					<IonItem lines="none" className={classes.dataCardContent}>
						<IonIcon
							slot="end"
							size="large"
							icon={closeCircle}
							className={classes.closeIcon}
							onClick={props.onClose}
						/>
						<div className={classes.profileContainer}>
							<IonAvatar
								className={
									props.avatarClass ? props.avatarClass : classes.userAvatar
								}
							>
								{props.imgUrl ? (
									<img
										className={classes.profilePic}
										src={props.imgUrl}
										alt="Avatar"
									/>
								) : (
									<IonIcon
										className={classNames(classes.profilePic, classes.noPic)}
										size="large"
										icon={props.noImg ? props.noImg : personCircle}
									/>
								)}
								{spinoutType !== 'beam' && (
									<IonFab className={classes.editPicFab}>
										<IonFabButton
											size="small"
											className={classes.editPicBtn}
											onClick={props.onEditPic}
										>
											<IonIcon
												className={classes.editPicIcon}
												icon={camera}
											/>
										</IonFabButton>
									</IonFab>
								)}
							</IonAvatar>
							<IonLabel className={classes.titleLb}>
								<IonCardTitle>
									<div className={classes.textWrap}>{props.title}</div>
								</IonCardTitle>
								{props.subTitle}
								<div className={classes.noteContainer}>{props.noteContent}</div>
							</IonLabel>
						</div>
						{props.onDelete ? (
							<IonFab
								vertical="bottom"
								horizontal="end"
								className={classes.deleteFab}
							>
								<Tooltip
									direction="down"
									content={<FormattedMessage {...Messages.deleteTitle} />}
								>
									<IonFabButton
										size="small"
										onClick={() => setShowDeleteOverlay(true)}
									>
										<IonIcon icon={trash} />
									</IonFabButton>
								</Tooltip>
							</IonFab>
						) : props.onEdit ? (
							<IonFab vertical="bottom" horizontal="end" className={classes.editFab}>
								<IonFabButton size="small" onClick={props.onEdit}>
									<IonIcon
										className={classes.fabIcon}
										src="./assets/icons/edit.svg"
									/>
								</IonFabButton>
							</IonFab>
						) : (
							''
						)}
					</IonItem>
				</IonCardContent>
			</IonCard>
			{showDeleteOverlay ? (
				<IonGrid className={classes.deleteOverlay}>
					<IonRow>
						{/* <IonFab horizontal="center">
							<IonFabButton
								size="small"
								className={classes.closeFabBtn}
								onClick={() => setShowDeleteOverlay(false)}
							>
								<IonIcon size="small" icon={close} />
							</IonFabButton>
						</IonFab> */}
						<IonLabel className={classes.confirmLb}>
							<FormattedMessage {...Messages.confirm} />
						</IonLabel>
					</IonRow>
					<IonRow className={classes.actionContainer}>
						<IonButton
							className={classes.cancelBtn}
							shape="round"
							onClick={onCancelDelete}
						>
							<FormattedMessage {...Messages.cancel} />
						</IonButton>
						<IonButton
							className={classes.deleteBtn}
							shape="round"
							onClick={onConfirmedDelete}
						>
							<FormattedMessage {...Messages.delete} />
						</IonButton>
					</IonRow>
				</IonGrid>
			) : null}
		</div>
	);
};

export default injectIntl(isAuthenticated(InfoCard, 'InfoCard'));
