import React, { FC } from 'react';
import classes from './Robots.module.css';
import { IonAvatar, IonLabel, IonIcon } from '@ionic/react';
import classNames from 'classnames';

import { cloudOffline } from 'ionicons/icons';
import { useTypedSelector } from '../../reducers';
import { equalityFnc, getStateEntityByOrgId } from '../../utils/conformState';

const deviceIcons: Record<string, string> = {
	beam: '../../../assets/img/beam-logo-white.svg',
	gobe: '../../../assets/icons/GOBE-ROBOTS-logo-neg.svg',
};
interface RobotNameProps {
	robotId: string;
	orgId: string;
	selectRobots: boolean;
}

const RobotName: FC<RobotNameProps> = props => {
	const { robotId, orgId, selectRobots } = props;

	const robot = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'deviceState', {
				entityId: robotId,
				orgId,
				propertyOptions: ['online', 'pictureLink', 'name'],
			}),
		(left, right) => equalityFnc(left, right)
	);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	return (
		<div className={classes.nameContainer}>
			{!selectRobots && (
				<IonAvatar
					className={
						robot.online
							? classes.robotAvatar
							: (classes.robotAvatar, classes.robotAvatarOffline)
					}
				>
					{robot.pictureLink ? (
						<img
							className={classes.profilePic}
							src={robot.online ? robot.pictureLink : cloudOffline}
							alt="Avatar"
						/>
					) : (
						<IonIcon
							className={
								robot.online
									? classNames(classes.profilePic, classes.noPic)
									: classNames(classes.profilePic, classes.offlinePic)
							}
							size="large"
							icon={robot.online ? deviceIcons[spinoutType] : cloudOffline}
						/>
					)}
				</IonAvatar>
			)}
			<IonLabel
				className={
					robot.online ? classes.userNameLb : (classes.userNameLb, classes.textGrey)
				}
			>
				{robot.name}
			</IonLabel>
		</div>
	);
};

export default RobotName;
