import { Device } from '../../../../types/types';

export type IDeviceCurrentState =
	| 'inSession'
	| 'available'
	| 'reserved'
	| 'onHold'
	| 'configuring'
	| 'unavailable'
	| 'updating';

export const getDeviceCurrentState = (device: any): IDeviceCurrentState => {
	if (device.currentState) {
		return device.currentState;
	} else {
		if (device.status && JSON.parse(device.status)?.currentState) {
			return JSON.parse(device.status)?.currentState;
		} else {
			return 'unavailable';
		}
	}
};

export const statusIconConverter = (usedStatus: any) => {
	switch (usedStatus) {
		case 'available':
			return 'available';
		case 'reserved':
			return 'grey-calendar.svg';
		case 'onHold':
			return 'grey-pause.svg';
		case 'configuring':
			return 'grey-config.svg';
		case 'inSession':
			return 'grey-headset.svg';
		case 'unavailable':
			return 'grey-minimize.svg';
		case 'updating':
			return 'grey-cloud-download.svg';
	}
};

export const statusTextConverter = (usedStatus: any) => {
	switch (usedStatus) {
		case 'available':
			return 'Available';
		case 'reserved':
			return 'Reserved';
		case 'onHold':
			return 'On Hold';
		case 'configuring':
			return 'Configuring';
		case 'inSession':
			return 'In Session';
		case 'unavailable':
			return 'Unavailable';
		case 'updating':
			return 'Updating';
	}
};
