import React, { FC, useState, Fragment, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import { sortedCollection } from '../../selectors';

import isAuthenticated from '../../components/Authentication/Authenticated';

import classes from './Robots.module.css';
import classNames from 'classnames';

import Messages from './Robots.messages';
// import PopoverBox from '../PopoverBox/PopoverBox';
import RobotEdit from '../../components/RobotEdit/RobotEdit';
import { AppRootState, useTypedSelector } from '../../reducers';
import RobotsOptions from './RobotsOptions';
import RobotsList from './RobotsList';
import { equalityFnc, getDevices } from '../../utils/conformState';
import { Device } from '../../types/types';
import { store } from '../../store/store';

const headers = [
	{ title: Messages.group, property: 'deviceGroupName' },
	{ title: Messages.location, property: 'location' },
	{ title: Messages.status, property: 'status' },
	{ title: Messages.charge, property: 'level' },
	{ title: Messages.serialNumber, property: 'deviceId' },
];

interface RobotsProps {
	filterValues: any;
	orgId: string;
	editableRobotId?: any;
	history: any;
}

const Robots: FC<RobotsProps> = (props: any) => {
	const { intl, organization, editableRobotId, history, filterValues } = props;

	const orgId = organization.orgId;
	const organizationName = organization.name;
	const selectedOrganizationType = organization.orgType;
	const deviceGroups = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId,
		(left, right) => equalityFnc(left, right)
	);
	const devices = useTypedSelector(
		state => getDevices(state, orgId),
		(left, right) => equalityFnc(left, right)
	);
	const sortParams = useTypedSelector(
		state => state.deviceState.sortParams,
		(left, right) => equalityFnc(left, right)
	);

	const [isEditing, setIsEditing] = useState(false);
	const [isAddNewRobotShow, setAddNewRobotShow] = useState(false);
	const [deviceToShow, setDeviceToShow] = useState([]);
	const [selectedDeviceId, setSelectedDeviceId] = useState<string | undefined>(undefined);
	const [searchText, setSearchText] = useState('');
	const [deviceGroupNames, setDeviceGroupNames] = useState<string[]>([]);
	const [filterValue, setFilterValue] = useState<string>('all');

	const [selectRobots, setSelectRobots] = useState(false);
	const [selectedRobots, updateSelectedRobots] = useState<any[]>([]);

	const formatData = useCallback(
		(data: any) => {
			return data.map((d: any) => {
				d.level = d.status ? JSON.parse(d.status).battery.level : null;

				d.deviceGroupName = '';

				if (d.deviceGroupsIds) {
					console.log('deviceGroups', deviceGroups);
					if (deviceGroups != null && deviceGroups[orgId] != null) {
						for (const key in deviceGroups[orgId]) {
							if (deviceGroups[orgId][key]?.deviceGroupId === d.deviceGroupsIds[0])
								d.deviceGroupName = deviceGroups[orgId][key].name;
						}
					}
				} else {
					d.deviceGroupName = intl.formatMessage({ id: 'unassigned' });
				}
				return d;
			});
		},
		[deviceGroups, intl, orgId]
	);

	const headers = [
		{
			title: Messages.name,
			property: 'name',
			checkbox: selectRobots,
			onUnCheck: () => {
				setSelectRobots(false);
				unCheckAll();
			},
			selectedNumber: selectedRobots.length,
		},
		{ title: Messages.group, property: 'deviceGroupName' },
		{ title: Messages.location, property: 'location' },
		{ title: Messages.status, property: 'status' },
		{ title: Messages.charge, property: 'level' },
		{ title: Messages.serialNumber, property: 'deviceId' },
	];

	const handleOnCheck = (robot: any, bool: boolean) => {
		if (bool) {
			let obj = selectedRobots.find(o => o.deviceId === robot.deviceId);
			if (!obj) updateSelectedRobots(selectedRobots.concat(robot));
		} else {
			updateSelectedRobots(
				selectedRobots.filter((item: any) => item.deviceId !== robot.deviceId)
			);
		}
	};
	const checkAll = (data: any) => {
		updateSelectedRobots(data);
	};
	const unCheckAll = () => {
		updateSelectedRobots([]);
	};

	useEffect(() => {
		if (!editableRobotId) return;
		setSelectedDeviceId(editableRobotId);
		setIsEditing(true);
	}, [editableRobotId]);

	useEffect(() => {
		let arr: Array<any> = [];
		arr = devices || [];

		if (searchText) {
			arr = arr.filter(
				(r: any) => r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
			);
		}
		let collection: any = sortedCollection({
			items: arr ? arr : [],
			sortParams: sortParams,
		});
		setDeviceToShow(collection);
	}, [devices, searchText, selectedOrganizationType, sortParams]);

	useEffect(() => {
		if (!deviceGroups[orgId]) return;

		let names: any = Object.values(deviceGroups[orgId]).map(
			(deviceGroup: any) => deviceGroup.name
		);

		const filteredNames = names.filter((name: string) => name !== 'Unassigned');
		//filterValues.splice(1, 0, filteredNames); Add this once stability is reached
		setDeviceGroupNames(names);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceGroups, filterValues, orgId]);

	useEffect(() => {
		if (filterValue === undefined) return;
		let all: any = devices || [];

		// DO NOT REMOVE THIS! robot was passed by ref and not value
		all = JSON.parse(JSON.stringify(all));
		const devicesInfo = JSON.parse(
			JSON.stringify(
				(store.getState() as AppRootState).deviceState.devicesByOrganizationId[orgId] || {}
			)
		);
		const deviceIdKeys = Object.keys(devicesInfo);

		switch (filterValue) {
			case 'all':
				setDeviceToShow(all);
				break;
			case 'available':
				let available: any = all.filter((item: any) => {
					if (!deviceIdKeys.includes(item.deviceId)) return null;

					if (
						devicesInfo[item.deviceId].currentState === 'available' &&
						devicesInfo[item.deviceId].online
					) {
						return item;
					}
					return null;
				});
				setDeviceToShow(available);
				break;
			case 'unavailable':
				let unavailable: any = all.filter((item: any) => {
					if (!deviceIdKeys.includes(item.deviceId)) return null;
					if (devicesInfo[item.deviceId].currentState !== 'available') return item;
					return null;
				});
				setDeviceToShow(unavailable);
				break;
			case 'unassigned':
				let unassigned: any = all.filter((item: Device) => {
					if (!deviceIdKeys.includes(item.deviceId)) return null;
					if (devicesInfo[item.deviceId].deviceGroupsIds?.length === 0) return item;
					return null;
				});
				setDeviceToShow(unassigned);
				break;
			default:
				if (deviceGroupNames.findIndex((name: any) => name === filterValue) > -1) {
					let byGroup: any = all.filter((item: any) => {
						if (!deviceIdKeys.includes(item.deviceId)) return null;
						const deviceGroupId = devicesInfo[item.deviceId].deviceGroupsIds
							? devicesInfo[item.deviceId].deviceGroupsIds[0]
							: null;
						if (
							deviceGroups[orgId][deviceGroupId] &&
							deviceGroups[orgId][deviceGroupId].name === filterValue
						)
							return item;
						return null;
					});
					setDeviceToShow(byGroup);
				}
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterValue]);

	useEffect(() => {
		if (history.location.data?.editableRobot) {
			const robot = history.location.data.editableRobot;
			setSelectedDeviceId(JSON.parse(JSON.stringify(robot)).deviceId);
			history.location.data.editableRobot = undefined;
		}
	}, [history.location.data]);

	useEffect(() => {
		if (selectedOrganizationType === 'bor') {
			setAddNewRobotShow(true);
		}
	}, [selectedOrganizationType]);

	return !isEditing ? (
		<Fragment>
			<div className={classes.leftSide}>
				<RobotsOptions
					showTransferRobot={devices.length > 0}
					organizationName={organizationName}
					selectedRobots={selectedRobots}
					selectRobots={selectRobots}
					setSelectRobots={setSelectRobots}
					isAddNewRobotShow={isAddNewRobotShow}
					deviceToShow={deviceToShow}
					searchText={searchText}
					setSearchText={setSearchText}
					filterValues={filterValues}
					setFilterValue={setFilterValue}
					headers={headers}
					handleOnCheck={selectRobots && handleOnCheck}
					unCheckAll={unCheckAll}
				/>
			</div>
			<div className={classes.rightSide}>
				<RobotsList
					robotHeaders={headers}
					setSelectedDeviceId={setSelectedDeviceId}
					setIsEditing={setIsEditing}
					data={formatData(deviceToShow)}
					selectRobots={selectRobots}
					selectedRobots={selectedRobots}
					onUnCheck={() => {
						setSelectRobots(false);
						unCheckAll();
					}}
					checkAll={checkAll}
					unCheckAll={unCheckAll}
					handleOnCheck={selectRobots && handleOnCheck}
					check
				/>
			</div>
		</Fragment>
	) : (
		<RobotEdit editableDeviceId={selectedDeviceId} onClose={() => setIsEditing(false)} />
	);
};

const mapStateToProps = (state: any) => ({
	organization: state.selectedOrganizationState.organization,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(Robots), 'Robots'));
