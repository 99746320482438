import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Card } from '../molecules/Card';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import AddOnSvg from '../../../../assets/icons/addOnIcon.svg';
import PurchaseSvg from '../../../../assets/icons/purchaseIcon.svg';
import RaasSvg from '../../../../assets/icons/raasIcon.svg';
import RentalSvg from '../../../../assets/icons/rentalIcon.svg';

import AddOnSvgDisabled from '../../../../assets/icons/addOnIconDisabled.svg';
import PurchaseSvgDisabled from '../../../../assets/icons/purchaseIconDisabled.svg';
import RaasSvgDisabled from '../../../../assets/icons/raasIconDisabled.svg';
import RentalSvgDisabled from '../../../../assets/icons/rentalIconDisabled.svg';

import GreenLines from '../../../../assets/images/greenLines.svg';
import BlueLines from '../../../../assets/images/blueLines.svg';
import YellowLines from '../../../../assets/images/yellowLines.svg';
import OrangeLines from '../../../../assets/images/orangeLines.svg';

const Wrapper = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flex: 1,
	flexWrap: 'wrap',
});

const ThinTypography = styled(Typography)(({ disabled, theme }) => ({
	display: 'inline',
	color: disabled ? theme.palette.greyPalette.grey3 : 'auto',
}));

const Container = styled('div')(({ theme }) => ({
	display: 'flex',
	flex: 1,
}));

export const Cards = ({ onPlanChange }) => {
	const theme = useTheme();
	const purchaseColor = theme.palette.webshopPalette.purchase;
	const raasColor = theme.palette.webshopPalette.raas;
	const rentalColor = theme.palette.webshopPalette.rental;
	const addonsColor = theme.palette.webshopPalette.addons;

	const purchaseDisabled = false;
	const raasDisabled = true;
	const rentalDisabled = true;
	const addOnsDisabled = true;

	return (
		<Container>
			<Wrapper>
				<Card
					disabled={purchaseDisabled}
					planType="purchase"
					icon={purchaseDisabled ? PurchaseSvgDisabled : PurchaseSvg}
					color={purchaseColor}
					headerMessage={
						<FormattedMessage id="purchaseHeader" defaultMessage="Purchase" />
					}
					descriptionMessage={
						<FormattedMessage
							id="purchaseDescription"
							defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
						/>
					}
					buttonLabel={<FormattedMessage id="choose" defaultMessage="Choose" />}
					backgroundImageSrc={GreenLines}
					onPlanChange={onPlanChange}
					backgroundPosition="53% 100%"
				/>
				<Card
					disabled={raasDisabled}
					planType="raas"
					icon={raasDisabled ? RaasSvgDisabled : RaasSvg}
					color={raasColor}
					backgroundImageSrc={YellowLines}
					headerMessage={
						<FormattedMessage
							id="raasPlanHeader"
							defaultMessage="RaaS <light>Plan</light>"
							values={{
								light: (...chunks) => (
									<ThinTypography disabled={raasDisabled} variant="h3">
										{chunks}
									</ThinTypography>
								),
							}}
						/>
					}
					descriptionMessage={
						<FormattedMessage
							id="raasPlanDescription"
							defaultMessage="In consequat suscipit lorem, sed lacinia risus consequat vel."
						/>
					}
					buttonLabel={<FormattedMessage id="choose" defaultMessage="Choose" />}
					onPlanChange={onPlanChange}
					backgroundPosition="62% 100%"
				/>
				<Card
					disabled={rentalDisabled}
					planType="rental"
					icon={rentalDisabled ? RentalSvgDisabled : RentalSvg}
					color={rentalColor}
					backgroundImageSrc={OrangeLines}
					headerMessage={
						<FormattedMessage
							id="rentalPlanHeader"
							defaultMessage="Rental <light>Plan</light>"
							values={{
								light: (...chunks) => (
									<ThinTypography disabled={rentalDisabled} variant="h3">
										{chunks}
									</ThinTypography>
								),
							}}
						/>
					}
					descriptionMessage={
						<FormattedMessage
							id="rentalPlanDescription"
							defaultMessage="Mauris at congue mi. Proin et dapibus turpis, accumsan sodales turpis."
						/>
					}
					buttonLabel={<FormattedMessage id="choose" defaultMessage="Choose" />}
					onPlanChange={onPlanChange}
					backgroundPosition="85% 100%"
				/>
				<Card
					disabled={addOnsDisabled}
					planType="addons"
					icon={addOnsDisabled ? AddOnSvgDisabled : AddOnSvg}
					color={addonsColor}
					backgroundImageSrc={BlueLines}
					headerMessage={<FormattedMessage id="addOnsHeader" defaultMessage="Add-ons" />}
					descriptionMessage={
						<FormattedMessage
							id="addOnsDescription"
							defaultMessage="Curabitur sit amet tortor aliquet, sodales nisi sit amet, lobortis nunc."
						/>
					}
					buttonLabel={<FormattedMessage id="add" defaultMessage="Add" />}
					onPlanChange={onPlanChange}
					backgroundPosition="77% 100%"
				/>
			</Wrapper>
		</Container>
	);
};
