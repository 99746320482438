import { PrimaryCameraState, RobotPrimaryCamera } from '../useCallerPeerConnection/peerConnection';
import React from 'react';

type PrimaryCameraToggleProps = {
	disabled: boolean;
	togglePrimaryCamera: () => void;
	primaryCameraState: PrimaryCameraState;
};
const NextCameraActionIcons: Record<RobotPrimaryCamera, string> = {
	[RobotPrimaryCamera.WIDE_CAM]: '../../assets/images/zoom-in.svg',
	[RobotPrimaryCamera.ZOOM_CAM]: '../../assets/images/zoom-out.svg',
};
export default function PrimaryCameraToggleButton({
	primaryCameraState,
	togglePrimaryCamera,
	disabled,
}: PrimaryCameraToggleProps) {
	const isSwitchingPrimaryCamera = primaryCameraState.isChangingPrimaryCameraTo !== null;
	const isDisabled = isSwitchingPrimaryCamera || disabled;

	return (
		<div className={isDisabled ? 'circleRectangleDisabled' : 'blackCircle'}>
			<div
				className="iconWrapper zoomWrapper"
				onClick={isDisabled ? undefined : togglePrimaryCamera}
			>
				<img src={NextCameraActionIcons[primaryCameraState.currentPrimaryCamera]} alt="" />
			</div>
			{/* 
				<div className="rectangleLocalVoice" onClick={() => { return false; }}>
					<Slider
						onChange={onRemoteVideoZoomChange}
						value={remoteVideoZoom}
						icon="zoom-green.svg"
						id="remoteVideoZoom"
					/>
				</div> 
			*/}
		</div>
	);
}
