import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Container = styled('div')(({ theme }) => ({
	display: 'flex',
	flex: 1,
	padding: 24,
}));

const Wrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
	flexDirection: 'column',
}));

const GreyTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.greyPalette.grey1,
}));

export const Footer = () => {
	return (
		<Container>
			<Wrapper>
				<GreyTypography variant="body1">
					<FormattedMessage
						id="startPageSubTextFirst"
						defaultMessage="Coronavirus can force you home, {break}but GoBe Robots help you
        work and visit others safely with a remove presence."
						values={{
							break: <br />,
						}}
					/>
				</GreyTypography>
				<GreyTypography variant="body1">
					<FormattedMessage id="beThereNow" defaultMessage="Be THERE now!" />
				</GreyTypography>
			</Wrapper>
		</Container>
	);
};
