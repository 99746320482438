import {
	// INITIALIZE_DEVICES,
	INSERT_NEW_DEVICE_BEGIN,
	INSERT_NEW_DEVICE_SUCCESS,
	UPDATE_DEVICE,
	FETCH_DEVICES_BEGIN,
	FETCH_DEVICES_SUCCESS,
	FETCH_DEVICES_FAILURE,
	SET_DEVICES_SORT_PARAMS,
	SET_DEVICE_FOR_TRANSFER,
	RESET_APP,
	DELETE_DEVICE_SUCCESS,
	UPDATE_DEVICE_PROPS,
	UPDATE_DEVICES_DEVICE_GROUP,
	CHANGE_ADD_NEW_ROBOT_STATE,
} from '../actions/types';
import { Device } from '../types/types';

export interface initialDeviceState {
	items: Array<Device>;
	loading: boolean;
	error: string | null;
	sortParams: Record<string, string>;
	devicesByOrganizationId: Record<string, Record<string, Device>>;
	addNewRobotOpen: boolean;
	getAccountDevices: boolean;
}

const initialState: initialDeviceState = {
	items: [],
	loading: false,
	error: null,
	sortParams: { key: 'name', order: 'asc' },
	devicesByOrganizationId: {},
	addNewRobotOpen: false,
	getAccountDevices: false,
};

// type DeviceAction =
// 	| { type: 'FETCH_DEVICES_BEGIN'; devices: Array<string> }
// 	| { type: 'FETCH_DEVICES_SUCCESS'; devices: Array<number> };

export default function devicesReducer(state = initialState, action: Record<string, any>) {
	let organizationId: string;
	let index: number;
	let props: Record<string, any>;
	let deviceId: string;
	let device;
	let editedDevicesByOrganizationId;
	switch (action.type) {
		case FETCH_DEVICES_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};

		case FETCH_DEVICES_SUCCESS:
			const devices: Record<string, Device> = {};
			organizationId = '';
			let items = state.items;
			action.payload.devices.forEach((element: Device) => {
				organizationId = element.orgId;
				devices[element.deviceId] = element;

				if (!items.find(item => item.deviceId === element.deviceId)) {
					items.push(element);
				}
			});
			// Get account devices information.
			const accountDevices = action.payload.accountDevices;
			if (accountDevices) {
				(Object.values(accountDevices) as Array<Device>).forEach((element: Device) => {
					if (organizationId === element.orgId && devices[element.deviceId]) {
						if (element.online) devices[element.deviceId].online = element.online;
						if (element.currentState)
							devices[element.deviceId].currentState = element.currentState;
						if (element.battery) devices[element.deviceId].battery = element.battery;

						let idx = items.findIndex(item => item.deviceId === element.deviceId);
						if (idx > -1) {
							if (element.online) items[idx].online = element.online;
							if (element.currentState)
								items[idx].currentState = element.currentState;
							if (element.battery) items[idx].battery = element.battery;
						}
					}
				});
			}

			return {
				...state,
				loading: false,
				items: items,
				devicesByOrganizationId: {
					...state.devicesByOrganizationId,
					[organizationId]: devices,
				},
			};

		case FETCH_DEVICES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.error,
				items: [],
			};

		case INSERT_NEW_DEVICE_BEGIN:
			return {
				...state,
				loading: false,
				error: null,
			};
		case INSERT_NEW_DEVICE_SUCCESS:
			const newDevice = action.payload.newDevice;

			if (
				state.devicesByOrganizationId[newDevice.orgId] === null ||
				state.devicesByOrganizationId[newDevice.orgId] === undefined
			) {
				state.devicesByOrganizationId[newDevice.orgId] = {
					[newDevice.deviceId]: newDevice,
				};
			} else {
				state.devicesByOrganizationId[newDevice.orgId][newDevice.deviceId] = newDevice;
			}

			return {
				...state,
				loading: false,
				items: [...state.items, newDevice],
				devicesByOrganizationId: state.devicesByOrganizationId,
			};

		case UPDATE_DEVICE:
			const editedDevice = action.payload.device;
			editedDevicesByOrganizationId = state.devicesByOrganizationId;
			index = state.items.findIndex(d => d.deviceId === editedDevice.deviceId);
			editedDevicesByOrganizationId[editedDevice.orgId][editedDevice.deviceId] = editedDevice;

			return {
				...state,
				loading: false,
				items: [
					...state.items.slice(0, index),
					editedDevice,
					...state.items.slice(index + 1),
				],
				devicesByOrganizationId: editedDevicesByOrganizationId,
			};
		case UPDATE_DEVICES_DEVICE_GROUP:
			props = action.payload;
			let deviceGroupId = props.deviceGroupId;
			editedDevicesByOrganizationId = state.devicesByOrganizationId;

			for (const key in props) {
				let deviceIds: Array<string> = [];
				if (key === 'addedDevicesIds') {
					deviceIds = props[key] || [];
				} else if (key === 'removedDevicesIds') {
					deviceIds = props[key] || [];
				} else continue;

				for (let i = 0; i < deviceIds.length; i++) {
					const index = state.items.findIndex(d => d.deviceId === deviceIds[i]);
					if (index < 0) continue;

					device = state.items[index];
					if (key === 'addedDevicesIds') {
						device.deviceGroupsIds = [props.deviceGroupId];
					} else {
						const index = device.deviceGroupsIds.findIndex(d => d === deviceGroupId);
						if (index >= 0) device.deviceGroupsIds.splice(index, 1);
					}

					state.items[index] = device;
					editedDevicesByOrganizationId[props.orgId][deviceIds[i]] = device;
				}
			}

			return {
				...state,
				loading: false,
				items: [...state.items],
				devicesByOrganizationId: editedDevicesByOrganizationId,
			};
		case UPDATE_DEVICE_PROPS:
			if (!action.payload.userRole) return state;

			props = action.payload.props;
			deviceId = action.payload.deviceId;
			const position = state.items.findIndex(d => d.deviceId === deviceId);
			device = state.items[position];
			const orgId = action.payload.organizationId || device?.orgId;

			if (!orgId) return state;

			for (const key in props) {
				if (key === 'currentState' && props[key] === 'available') device.online = true;
				device[key] = props[key];
			}
			state.items[position] = device;

			return {
				...state,
				items: [...state.items],
				devicesByOrganizationId: {
					...state.devicesByOrganizationId,
					[orgId]: {
						...state.devicesByOrganizationId[orgId],
						[device.deviceId]: device,
					},
				},
			};

		case SET_DEVICES_SORT_PARAMS:
			return {
				...state,
				sortParams: action.payload.sortParams,
			};

		case SET_DEVICE_FOR_TRANSFER: {
			return {
				...state,
				items: [...state.items],
			};
		}

		case RESET_APP:
			return {
				...state,
				items: [],
				loading: false,
				error: null,
			};

		case DELETE_DEVICE_SUCCESS:
			let deletedDevice = action.payload.device;
			let oldOrgId = action.payload.oldOrgId || deletedDevice.orgId;
			let deletedDevicesByOrganizationId = state.devicesByOrganizationId;
			if (oldOrgId && deletedDevice.deviceId)
				delete deletedDevicesByOrganizationId[oldOrgId][deletedDevice.deviceId];

			return {
				...state,
				loading: false,
				items: state.items.filter(element => element.deviceId !== deletedDevice.deviceId),
				devicesByOrganizationId: deletedDevicesByOrganizationId,
			};

		case CHANGE_ADD_NEW_ROBOT_STATE:
			return {
				...state,
				addNewRobotOpen: action.payload.users,
			};

		default:
			// ALWAYS have a default case in a reducer
			return state;
	}
}
